import styled from 'styled-components';

export const Button = styled.button`
background: none;
color: var(--text-color);
font-size: 30px;
margin-left: 20px;

@media (max-width: 500px) {
    font-size: 20px;
    margin-left: 10px;
  }
@media (max-width: 900px) {
    font-size: 20px;
    margin-left: 10px;
  }
@media (max-width: 800px) {
    font-size: 20px;
    margin-left: 10px;
  }
@media (max-width: 700px) {
    font-size: 20px;
    margin-left: 10px;
  }
@media (max-width: 600px) {
    font-size: 20px;
    margin-left: 8px;
  }
@media (max-width: 500px) {
    font-size: 20px;
    margin-left: 5px;
  }
@media (max-width: 400px) {
    font-size: 20px;
    margin-left: 5px;
  }
@media (max-width: 300px) {
    font-size: 15px;
    margin-left: 5px;
  }

`;