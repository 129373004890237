/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Card from "../../../components/card/card";
import styled, { useTheme } from "styled-components";
import { Branch } from "../../../interfaces";
import { ImBin } from "react-icons/im";
import { AiTwotoneEdit } from "react-icons/ai";
import { ADMIN_COMPANY_URL } from "../../../constains";
import { CallNotification } from "../../../components/Alert";
import EditarEmpresa from "../../../components/EditarEmpresa/EditarEmpresa";
// import { useHistory } from "react-router-dom";
// import Aside2 from "../../../components/Sidebar/Aside2";
import AdicionarCompany from "../../../components/AdicionarCompany/AdicionarCompany";
import { IoIosEye } from "react-icons/io";
import LookFiliaisEmpresa from "../../../components/LookFiliaisEmpresa";
import { FaBars } from "react-icons/fa";
import AdicionarBranch from "../../../components/AdicionarBranch/adicionarBranch";

function Filial() {
  const [toggled, setToggled] = useState(false);
  const [idv2, setIdv2] = useState(0);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [list, setList] = useState<Branch[]>([]);
  const [apagar, setApagar] = useState<any>("");
  const { themeName } = useTheme();

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const getData = () => {
    axios
      .get(ADMIN_COMPANY_URL, access)
      .then((res) => {
        setList(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  useEffect(() => {
    getData()
  }, [apagar, token]);

  const handleDelete = (id: string) => {
    // console.log("id", id);
    axios
      .delete(`${ADMIN_COMPANY_URL}/${id}`, access)
      .then((response) => {
        // console.log(response);
        setApagar("foi deletado");
        CallNotification("Sucesso", "Empresa deletada com sucesso!", "success");
        getData();
      })
      .catch((err) => {
        CallNotification("Aviso", "Esta empresa possui filiais!", "warning");
        console.log(err);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const TakeId = (id: any) => {
    return setIdv2(id);
  };

  // console.log("list", list);

  return (
    <AllContent
      className="content"
      style={{ height: "calc(100vh - 106px)", display: "block", width: "100%" }}
    >
      <div>
        <Container fluid>
          <Row>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Teste>
                <div className="adm">
                  <label
                    onClick={() => {
                      setToggled(!toggled);
                    }}
                    style={{
                      position: "relative",
                      marginRight: "30px",
                      top: "-4px",
                      cursor: "pointer",
                    }}
                    className="adminMenu"
                  >
                    {<FaBars color="white" />}
                  </label>
                </div>
              </Teste>

              <Col md={12}>
                <Card
                  title=""
                  category=""
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      <h6
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "35px",
                          height: "100px",
                        }}
                      >
                        Empresa
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {" "}
                        <Button
                          style={{ marginLeft: "1rem" }}
                          variant="primary"
                          onClick={handleShow}
                        >
                          Adicionar empresa
                        </Button>
                      </div>

                      <Table
                        striped
                        hover
                        variant={themeName}
                        style={{ marginTop: "30px" }}
                      >
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((props, index) => {
                            const { id = "id" } = props;

                            return (
                              <tr className="hoverTr" key={index}>
                                <td>{props.name}</td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    position: "relative",
                                  }}
                                >
                                  <Button
                                    className="editEdit"
                                    variant="primary"
                                    onClick={() => {
                                      handleShow2();
                                      TakeId(id);
                                    }}
                                  >
                                    {<AiTwotoneEdit size="20px" />}
                                  </Button>

                                  <Button
                                    className="lixeira"
                                    onClick={() => handleDelete(id)}
                                    style={{ marginLeft: "1rem" }}
                                    variant="primary"
                                  >
                                    {<ImBin size="20px" />}
                                  </Button>

                                  <Button
                                    className="view"
                                    onClick={() => {
                                      handleShow3();
                                      TakeId(id);
                                    }}
                                    style={{ marginLeft: "1rem" }}
                                    variant="primary"
                                  >
                                    {<IoIosEye size="20px" />}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  }
                />
              </Col>
            </div>
          </Row>

          <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Adicionar nova empresa</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#ededed" }}>
              <AdicionarBranch getData={getData} handleClose={handleClose} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show2} size="lg" onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Editar empresa</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#ededed" }}>
              <EditarEmpresa id={idv2} getData={getData} handleClose2={handleClose2} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show3} size="lg" onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Filiais</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#ededed" }}>
              <LookFiliaisEmpresa id={idv2} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose3}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </AllContent>
  );
}

const AllContent = styled.div`
  .hoverTr {
    .rowTd {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
    .lixeira {
      visibility: hidden;
      cursor: pointer;
    }
    .editEdit {
      visibility: hidden;
      cursor: pointer;
    }
    .view {
      visibility: hidden;
      cursor: pointer;
    }
    :hover {
      .lixeira {
        visibility: visible;
        color: white;
      }
      .editEdit {
        visibility: visible;
        color: white;
      }
      .view {
        visibility: visible;
        color: white;
      }
    }
  }
`;
const Teste = styled.div`
  .adm {
    display: none;
  }

  @media (max-width: 768px) {
    .adm {
      display: inline-block;
    }
  }
`;

export default Filial;
