import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Card from "../../components/card/card";
import styled from "styled-components";
import { COMPANY_URL, USER_BY_COMPANY, ViewScroll } from "../../constains";
import { apiGet } from "../../helpers/api";
import { Token } from "../../helpers/token";
import Clientes from "./Datainspections/Clientes";
import DashboardInspectionEquipment from "./Datainspections/Equipment";
import Recentes from "./Datainspections/Recentes";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import DashInspGrafico from "./Graficos/DashInspGrafico";
import { Spin } from "react-cssfx-loading"
import FilterClientes from "../../components/FilterClientes";
import useSWR, { useSWRConfig } from "swr";





function DashboardOs() {

    //@ts-ignore
    const { companyId, id, clientUser } = Token.getTokenData();
    const access = Token.getAccess();
    const [search, setSearch] = useState<any>([]);
    const [timeCollect, setTimeCollect] = useState<any>({ value: 90, label: '90 dias' });


    const url = `${COMPANY_URL}/${companyId}`;
    const fetcher = (url: string) => fetch(url, access).then((res) => res.json());

    const { data, isLoading: loading } = useSWR(url, fetcher);
    const { mutate } = useSWRConfig();
    const run = React.useCallback(() => mutate(url), [mutate]);

    useEffect(() => {
        run();

    }, [companyId]);

    const animatedComponents = makeAnimated();



    const url2 = `${USER_BY_COMPANY}/${companyId}`;

    const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
    let theUser: any = []
    if (loading2 == false) {
        theUser = allUsers?.data?.filter((value: any) => {
            return value.id == id
        })
    }
    const finalArrayClients = theUser?.[0]?.clients?.map((value: any) => { return value.id })

    // ?.filter((value: any) => {

    //     if ( clientUser == true && finalArrayClients.includes(value.clientId)) { return value } 
    //     if ( clientUser == false ) { return value } 


    //     })


   
// console.log(finalArrayClients)


    return (
        <>
            <Container fluid
            // style={{ paddingLeft: '30px' }}
            >
                <ViewScroll style={{
                    marginTop: '20px',
                    // paddingLeft: '20px'
                }}>

                    <Select
                        placeholder="Selecione o cliente"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        onChange={(ev: any) => {

                            // console.log(ev);
                            setSearch(ev);
                        }}
                        value={
                            search.map((valor: any) => {
                                return { value: valor.value, label: valor.label }
                            })
                        }
                        isMulti
                        options={

                            data?.data?.clients !== undefined ?

                                FilterClientes(data?.data?.clients?.filter((value: any) => {

                                    if (clientUser == true && finalArrayClients?.includes(value.id)) { return value }
                                    if (clientUser == false) { return value }


                                }), 'name')
                                    ?.map((valor: any) => {

                                        return { value: valor.id, label: valor.name }
                                    })

                                : []

                        }
                    />

                    <Select

                        placeholder="Selecione o tempo"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(ev: any) => {
                            setTimeCollect(ev)
                        }}
                        value={
                            timeCollect
                        }

                        options={
                            [
                                { value: 7, label: '7 dias' },
                                { value: 30, label: '30 dias' },
                                { value: 90, label: '90 dias' }
                            ]
                        }
                    />

                    <Row >
                        <Col md={12}

                        >
                            <Card
                                // style={{ margin: '40px' }}
                                title="Inspeção por cliente"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <Clientes search={search} timeCollect={timeCollect}  />
                                    </Content>
                                }
                            />
                        </Col>
                        {/* <Col md={6}

                        >
                            <Card
                                // style={{ margin: '40px' }}
                                title="Inspeção por equipamento"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <DashboardInspectionEquipment
                                            search={search}
                                            timeCollect={timeCollect}
                                            setCliente={setCliente}
                                            setEquipamento={setEquipamento}
                                        />
                                    </Content>
                                }
                            />
                        </Col> */}

                    </Row>
                    <Row >
                        <Col md={12}

                        >
                            <Card
                                // style={{ padding: '20px' }}
                                title="Últimas inspeções"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <Recentes
                                            search={search}
                                            timeCollect={timeCollect}
                                         />
                                    </Content>
                                }
                            />
                        </Col>

                    </Row>


                    <Row>

                        <Col md={12}>
                            <Card
                                style={{ minHeight: '700px' }}
                                title="Gráfico"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <DashInspGrafico search={search} timeCollect={timeCollect} />
                                    </Content>


                                }
                            />

                        </Col>

                    </Row>


                </ViewScroll>
            </Container>
        </>
    );
}

const Content = styled(ViewScroll)`
  /* max-height: 40vh; */
  height: 500px;
  overflow: auto;
`;

export default DashboardOs;
