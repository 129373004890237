import * as React from "react";
import Print from "./Link";

export const Step3 = ({ inspections, os }) => {
  // console.log("step3 os", os);
  return (
    <div>
      <Print inspections={inspections} os={os} />
    </div>
  );
};
