import React, { useEffect } from 'react';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export default function InspectionSelect({
    schema,
    formData,
    onChange,
    onSubmit,
    uiSchema,
    disabled,
    // key,
    onClickButtonCancel,
    mode
}: any) {

    var botoes: any = document.getElementsByClassName('btn-info');

    useEffect(() => {



        if (botoes.length > 0) {
            botoes[0].innerHTML = 'Salvar';
            botoes[0].style.color = 'white';

            if (mode == true) {
                botoes[0].style.display = 'inline-block';
            } else {
                botoes[0].style.display = 'none';
            }
        }
    }, [botoes, mode])



    return (

        <div>
            <Form

                schema={schema}
                validator={validator}
                formData={formData}
                onChange={onChange}
                onSubmit={onSubmit}
                uiSchema={uiSchema}
                disabled={disabled}

            // key={key}
            />

            {mode &&
                <Button variant="secondary"
                    disabled={disabled}
                    onClick={onClickButtonCancel}
                    style={{ position: 'relative', left: '90px', bottom: '38px' }}>
                    cancelar</Button>}
        </div>

    )

}

