import React, { useMemo, memo, useState, useEffect } from "react";
import {
  COMPANY_URL,
  EQUIPMENT,
  INSPECTION,
  INSPECTION_MODEL,
  USER_URL,
} from "../../constains";
import { Token } from "../../helpers/token";
import { preload } from "swr";
import axios from "axios";
const { companyId } = Token.getTokenData();
const token = window.localStorage.getItem("accessToken");
const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

const Reqs = async (cache: any, allCache: any) => {




  // let db: any = [];



  // const request = window.indexedDB.open('app', 1);
  // request.onsuccess = async (e: any) => {
  //   db = request.result;

  //   const dataCache = db.transaction('cache')
  //     .objectStore('cache')
  //     .getAll();

  //   dataCache.onsuccess = async () => {
  //     const cache = await dataCache?.result;
  //     // setTeste(cache)
  //     dispatch({ type: "INCREMENT" });
  //   }
  //   dataCache.onsuccess = async () => {
  //     const cache2 = dataCache.result;

  //     cache2.map((value: any) => {

  //       return value._k
  //     })
  //   }
  // }

  // console.log(teste)








  return new Promise<string[]>(async (resolve, reject) => {
    let company: string[] = [];

    const setCompany = (newState: any) => {


      if (typeof newState === "function") {
        company = newState(company);
      }

      else {
        company = newState;
      }

    };
    const fetcher = (url: string) => fetch(url, access).then((res) => res.json());

    async function getDataForCompany(): Promise<any> {
      // const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
      // const { data, isLoading: loading } = useSWR(`${COMPANY_URL}/${companyId}`, fetcher);

      // const theCache = await (preload(`${COMPANY_URL}/${companyId}`, fetcher))



      const url: string = `${COMPANY_URL}/${companyId}`;
      if (!cache.includes(url)) {

        const theCache = await (preload(url, fetcher));

        return theCache
      } else {
        return allCache.filter((d: any) => d._k == url)?.[0]
      }




    }

    async function getDataForModels(): Promise<any> {
      // const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
      const url = `${INSPECTION_MODEL}?companyId=${companyId}`
      // const { data, isLoading: loading } = useSWR(`${INSPECTION_MODEL}?companyId=${companyId}`, fetcher);

      if (!cache.includes(url)) {
        const theCache = await (preload(url, fetcher));

        return theCache?.data?.map((value: any) => {
          setCompany((prevState: any) => [
            ...prevState,
            `${INSPECTION_MODEL}/${value.id}`,
          ]);
        });
      }

      return allCache?.filter((d: any) => d._k == url)?.[0]








    }

    const dataForCompany = await getDataForCompany();

    if (!dataForCompany) return;

    setCompany((prevState: any) => [
      ...prevState,
      `${COMPANY_URL}/${companyId}`,
    ]);

    dataForCompany?.data?.users?.map((value: any) =>
      setCompany((prevState: any) => [...prevState, `${USER_URL}/${value.id}`])
    );

    dataForCompany?.data?.clients?.map((value: any) => {
      setCompany((prevState: any) => [
        ...prevState,
        `${EQUIPMENT}?clientId=${value.id}`,
      ]);









      const getCache = async () => {
        let theCache: any;

        const url: any = `${EQUIPMENT}?clientId=${value.id}`;

        if (!cache.includes(url)) {
          theCache = await (preload(url, fetcher))
          // console.log('1')
          // return theCache
        } else {
          // console.log('2')
          theCache = allCache.filter((d: any) => d._k == url)?.[0].data
          // console.log(allCache.filter((d: any) => d._k == url)?.[0].data)
        }





        // console.log(theCache)



        theCache?.data?.map((value: any) => {
          // console.log(value)
          // console.log('all inspeções por equipamento', value.inspections)
          setCompany((prevState: any) => [
            ...prevState,
            `${EQUIPMENT}/${value.id}`,
          ]);
          // console.log(value.id)


          // ================================================================


          (async () => {
            const url: string = `${EQUIPMENT}/${value.id}`


            // await (preload(`${EQUIPMENT}/${value.id}`, fetcher));

            if (!cache.includes(url)) {

              theCache = await (preload(url, fetcher));

              // return theCache

            } else {
              theCache = allCache.filter((d: any) => d._k == url)?.[0]
            }



          })();






          value.inspections?.map((value: any) => {
            // console.log('all id', value.id)
            setCompany((prevState: any) => [
              ...prevState,
              `${INSPECTION}/${value.id}`,
            ]);

            (async () => {
              const url: string = `${INSPECTION}/${value.id}`;
              if (!cache.includes(url)) {
                theCache = await (preload(url, fetcher));
                // return theCache
              } else {
                theCache = allCache.filter((d: any) => d._k == url)?.[0]
              }
            })();



          });

        });



        // ===================================================================

      }
      getCache();


    });

    const dataForModels: any = await getDataForModels();
    // console.log("dataForModels", dataForModels);

    setCompany((prevState: any) => [
      ...prevState,
      `${INSPECTION_MODEL}?companyId=${companyId}`,
    ]);


    // dataForModels?.data?.map((value: any) => {
    //   setCompany((prevState: any) => [
    //     ...prevState,
    //     `${INSPECTION_MODEL}/${value.id}`,
    //   ]);
    // });

    setTimeout(() => {
      // console.log("return");
      resolve(company);
    }, 5000);
  });








};

export default Reqs;
