import React, { useEffect, useMemo, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import Empresa from "./Empresa";
import Capa from "./Capa";

export default function Config() {
  const selecionado = window.localStorage.getItem("config");
  const [selected, setSelected] = useState<any>(
    selecionado !== null ? selecionado : "empresa"
  );
  const componentOptions: { [key: string]: JSX.Element } = {
    empresa: <Empresa />,
    capa: <Capa />
  };

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "9",
        }}
      >
        <Nav
          fill
          variant="tabs"
          defaultActiveKey={selecionado || "empresa"}
          style={{
            // position: "fixed", zIndex: "999", opacity: '1',
            backgroundColor: `white`,
            width: "100%",
          }}
        >
          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="empresa"
              style={{
                color: selected !== "empresa" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("empresa");
                window.localStorage.setItem("config", "empresa");
              }}
            >
              Empresa
            </Nav.Link>
          </Nav.Item>

          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="capa"
              style={{
                color: selected !== "capa" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("capa");
                window.localStorage.setItem("config", "capa");
              }}
            >
              Capa do relatório
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <div>{componentOptions[selected]}</div>
    </div>
  )

}