import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  CreateEquipment,
  Delete,
  EditEquipment,
  GetClient,
  GetEquipamentOnlyAll,
  GetModel,
} from "./api";
import { Button, Container, InputGroup, Modal } from "react-bootstrap";
import FormB from "react-bootstrap/Form";
import { Token } from "../../helpers/token";
import Select from "react-select";
import { COMPANY_URL, EQUIPMENT, INSPECTION_MODEL } from "../../constains";
import { useRequest } from "ahooks";
import { IonSearchbar, getPlatforms } from "@ionic/react";
import { BsTrash } from 'react-icons/bs'
import { HiOutlinePencilSquare } from "react-icons/hi2";
import _ from "lodash";
import { IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';
import { Spin } from "react-cssfx-loading"
import FilterClientes from "../../components/FilterClientes";
import useSWR, { preload, useSWRConfig } from "swr";
import { alfaFilter } from "../../helpers/utilsFunctions";


function Equipmet({ }: any) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cliente = queryParams.get('name');
  const clienteID: any = queryParams.get('clientid');
  const { mutate } = useSWRConfig();






  const { companyId, clientUser, editarEquipamento, deletarEquipamento } = Token.getTokenData();
  const platform = getPlatforms();
  const [idDelete, setIdDelete] = useState<any>(null);
  const [equipamentoId, setEquipamentoId] = useState<any>(null);
  const [equipamentoEditado, setEquipamentoEditado] = useState<any>({
    clientId: clienteID,
    companyId: companyId,
    InspectionmodelId: null,
    title: null,
  });
  const [equipment, setEquipment] = useState<any>({
    clientId: clienteID,
    companyId: companyId,
    InspectionmodelId: null,
    title: null,
  });

  const [cache, setCache] = useState<any>([]);
  const [cache2, setCache2] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [search, setSearch] = useState("");
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => { setShow3(false) }
  const history = useHistory();



  const url1 = `${EQUIPMENT}?clientId=${clienteID}`;
  const url2 = `${INSPECTION_MODEL}?companyId=${companyId}`;
  const fetcher = (url: string) => fetch(url).then((res) => res.json());

  useMemo(async () => {
    const theCache = await (preload(url1, fetcher));
    setCache(theCache)

  }, [url1])

  useMemo(async () => {
    const theCache2 = await (preload(url2, fetcher));
    setCache2(theCache2)
  }, [url2])


  useMemo(() => {
    setLoading(false)
    // console.log('rodou')
  }, [cache])


  function CreateEquipamento() {
    CreateEquipment(equipment, () => {
      run1();
    })
    getData();
  };

  const { data: te } = useSWR(url1, fetcher);
  const run1 = React.useCallback(() => mutate(url1), [mutate]);

  const getData: any = (() => {
    setCache(te)
  })

  useMemo(() => {
    setCache(te)

  }, [te])


  const EditarEquipamento = (equipamentoId: any) => {
    EditEquipment(equipamentoId, equipamentoEditado, () => {
      run1();
    })
    getData();
  }



  return (
    <div  >
      <div style={{
        position: 'sticky',
        top: '0',
        zIndex: '999',
        backgroundColor: 'white',
        minWidth: '100%',
      }}>


        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>Cliente: {cliente}</div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: '5px',
          fontSize: !platform.includes("desktop") ? '13px' : '16px'
        }}>
          Equipamentos
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: '10px 0px',
            // fontSize: !platform.includes("desktop") ? '11px' : '16px'
          }}
        >
          <Button
            onClick={() => {
              history.push("/inspection");
            }}
            variant="secondary"
            style={{
              textDecoration: "none",
              width: !platform.includes("desktop") ? '30%' : '50%',
              justifyContent: "center",
              alignItems: 'center',
              display: "flex",
              margin: "0px 15px 0px 0px",
              fontSize: !platform.includes("desktop") ? '11px' : '16px'
            }}
          >
            Voltar
          </Button>


          {clientUser ? <></> :
            <Button
              onClick={() => {
                setShow(!show);
              }}
              variant="primary"
              style={{
                textDecoration: "none",
                width: !platform.includes("desktop") ? '40%' : '50%',
                justifyContent: "center",
                alignItems: 'center',
                display: "flex",
                fontSize: !platform.includes("desktop") ? '11px' : '16px'
              }}
            >
              Novo Equipamento
            </Button>
          }

        </div>

        <div style={{

          padding: '10px 0px',

          // height: !platform.includes("desktop") ? '10px' : '40px',
        }}>


          <IonSearchbar

            style={{
              width: '100%',
              fontSize: '20px, !important',
              height: !platform.includes("desktop") ? '10px' : '40px',
              padding: '15px'
            }}
            onIonChange={({ detail }) => setSearch(detail?.value || "")}
          ></IonSearchbar>
        </div>

      </div>

      {loading == true ? < div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh'
      }}>
        <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
        Aguarde...

      </div> :


        (alfaFilter(cache?.data, 'title')
          ?.filter((equip: any) => {
            return equip?.title?.toLowerCase()?.includes(search?.toLowerCase())
          })
          ?.map((value: any, index: any) => {



            return (
              <div
                key={index} style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  width: '99%',
                }}
              >



                <IonAccordionGroup style={{
                  width: !platform.includes("desktop") ? '75%' : '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>

                    <IonItem
                      style={{ width: '100%', cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/inspection/${clienteID}/${value.id}?name=${cliente}&equiname=${value.title}&equiid=${value.id}&clientid=${clienteID}`);
                      }}
                    >

                      <IonLabel style={{
                        width: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: !platform.includes("desktop") ? '11px' : '16px'
                      }}> {value.title}</IonLabel>

                    </IonItem>





                  </div>

                </IonAccordionGroup>

                {/* 

              <Button
                variant="secondary"
                style={{
                  width: !platform.includes("desktop") ? '40px' : '50px',


                }}
                onClick={() => {
                  setEquipamentoId(value.id);
                  setShow4(!show4);

                }}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%'
                }}>

                  <VscGraph style={{
                    color: 'white',
                    height: !platform.includes("desktop") ? '20px' : '25px',
                    width: !platform.includes("desktop") ? '20px' : '25px',
                  }} />
                </div>
              </Button> */}






                {clientUser == true ? <></> :
                  <>


                    {editarEquipamento ?
                      <Button
                        variant="primary"
                        style={{
                          width: !platform.includes("desktop") ? '40px' : '50px',


                        }}
                        onClick={() => {
                          setEquipamentoId(value.id);
                          setShow3(!show3);
                          setEquipamentoEditado({
                            InspectionmodelId: value.InspectionmodelId,
                            title: value.title,
                          })
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%'
                        }}>

                          <HiOutlinePencilSquare style={{
                            height: !platform.includes("desktop") ? '20px' : '25px',
                            width: !platform.includes("desktop") ? '20px' : '25px',
                          }} />
                        </div>
                      </Button>

                      : <></>}

                    {deletarEquipamento ?
                      <Button
                        variant="danger"
                        style={{
                          width: !platform.includes("desktop") ? '40px' : '50px',


                        }}
                        onClick={() => {
                          setIdDelete(value.id);
                          setShow2(!show2);
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%'
                        }}>

                          <BsTrash style={{
                            height: !platform.includes("desktop") ? '20px' : '25px',
                            width: !platform.includes("desktop") ? '20px' : '25px',
                          }} />
                        </div>
                      </Button>
                      : <></>}


                  </>

                }





              </div>
            );



          }))


      }




      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Novo Equipamento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Título</InputGroup.Text>
            <FormB.Control
              onChange={(ev: any) => {
                setEquipment((prevState: any): void => ({
                  ...prevState,
                  title: ev.target.value,
                }));
              }}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </InputGroup>

          <Select
            options={FilterClientes(cache2?.data, 'title')?.map((value: any, index: any) => {
              return { value: value.id, label: value.title };
            })}
            isClearable
            onChange={(ev: any) => {
              setEquipment((prevState: any): void => ({
                ...prevState,
                InspectionmodelId: ev?.value,
              }));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              CreateEquipamento();
              // getOffLine();
              handleClose();
            }}
          >
            Salvar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>








      {/* =========================================x============================================================================= */}





      <Modal show={show3} size="lg" onHide={handleClose3}>
        <Modal.Header>
          <Modal.Title>Editar Equipamento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Título</InputGroup.Text>
            <FormB.Control
              value={equipamentoEditado.title}
              onChange={(ev: any) => {
                setEquipamentoEditado((prevState: any): void => ({
                  ...prevState,
                  title: ev.target.value,
                }));
              }}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </InputGroup>

          <Select
            value={equipamentoEditado.InspectionmodelId !== null ? cache2?.data?.map((value: any, index: any) => {




              if (equipamentoEditado.InspectionmodelId == value.id) {
                return { value: value.id, label: value.title }
              }




            }) : {}}
            options={FilterClientes(cache2?.data, 'title')?.map((value: any, index: any) => {
              return { value: value.id, label: value.title };
            })}
            // isClearable
            onChange={(ev: any) => {
              setEquipamentoEditado((prevState: any): void => ({
                ...prevState,
                InspectionmodelId: ev?.value,
              }));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              EditarEquipamento(equipamentoId);
              handleClose3();

            }}
          >
            Salvar
          </Button>
          <Button variant="secondary" onClick={() => { handleClose3(); }}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>












      {/* ===========================================X============================================================================== */}

      <Modal show={show2} size="lg" onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>
            Tem certeza que deseja excluir este equipamento?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#ededed",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="danger"
            onClick={() => {
              // @ts-ignore
              Delete(idDelete, "equipment", () => { run1() });
              handleClose2();
              getData();
            }}
          >
            Confirmar
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              handleClose2();
            }}
          >
            cancelar
          </Button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default memo(Equipmet);

