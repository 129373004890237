import React from "react";

const Logo = () => (
  <svg
    // style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
    width={100}
    height={100}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M816.20992 172.468907h-610.440533-0.013654a58.688853 58.688853 0 0 0-58.682026 58.682026v610.454187a58.688853 58.688853 0 0 0 58.688853 58.688853H816.196267a58.69568 58.69568 0 0 0 58.69568-58.69568V231.164587a58.682027 58.682027 0 0 0-58.682027-58.69568z"
      fill="#36abe0"
    />
    <path
      d="M722.295467 794.637653H299.68384a46.967467 46.967467 0 0 1-46.96064-46.96064v23.476907a46.953813 46.953813 0 0 0 46.953813 46.953813h422.611627a46.96064 46.96064 0 0 0 46.953813-46.953813v-23.476907a46.946987 46.946987 0 0 1-46.946986 46.96064z"
      fill="#191919"
    />
    <path
      d="M722.295467 219.42272H299.68384a46.96064 46.96064 0 0 0-46.96064 46.953813v481.30048a46.96064 46.96064 0 0 0 46.953813 46.96064h422.611627a46.967467 46.967467 0 0 0 46.953813-46.96064V266.376533a46.946987 46.946987 0 0 0-46.946986-46.953813z"
      fill="#FFFFFF"
    />
    <path
      d="M675.334827 137.250133H569.07776c-2.94912-26.37824-25.053867-46.96064-52.224-46.96064-27.163307 0-49.281707 20.589227-52.230827 46.96064H346.637653h-0.006826a23.483733 23.483733 0 0 0-23.476907 23.476907V231.150933c0 12.970667 10.513067 23.476907 23.476907 23.476907h328.697173c12.970667 0 23.476907-10.513067 23.476907-23.476907V160.72704a23.476907 23.476907 0 0 0-23.47008-23.476907z m-158.481067 35.218774a29.341013 29.341013 0 0 1-29.341013-29.341014v-0.006826a29.334187 29.334187 0 1 1 58.6752 0.006826 29.34784 29.34784 0 0 1-29.334187 29.341014z"
      fill="#BCBCBC"
    />
    <path
      d="M675.334827 231.15776H346.64448a23.483733 23.483733 0 0 1-23.483733-23.476907v23.476907c0 12.970667 10.513067 23.476907 23.476906 23.476907h328.704a23.483733 23.483733 0 0 0 23.476907-23.476907v-23.476907a23.497387 23.497387 0 0 1-23.483733 23.476907z"
      fill="#A5A5A5"
    />
    <path
      d="M391.00416 341.224107l-5.65248-2.833067s-4.52608-3.64544-7.693653 0.402773l-27.52512 37.25312c-1.61792 2.423467-3.76832 0.43008-3.76832 0.43008l-12.12416-9.939626s-3.618133-2.020693-5.87776 2.430293l-5.003947 6.478507s-1.41312 2.78528 2.6624 4.805973l16.459093 12.895573s2.757973 2.402987 5.85728 2.402987l5.010774-0.034133s5.536427-0.2048 8.137386-4.901547l31.232-44.837547c-0.013653 0.006827 2.819413-2.532693-1.713493-4.553386z"
      fill="#36abe0"
    />
    <path
      d="M323.160747 512.90112h70.43072V442.463573H323.160747v70.437547z m117.39136-23.476907h258.259626v-23.476906H440.552107v23.476906zM323.160747 618.55744h70.43072V548.12672H323.160747v70.43072z m117.39136-23.476907h258.259626v-23.483733H440.552107v23.483733z m-117.39136 129.119574h70.43072V653.776213H323.160747v70.423894z m117.39136-23.476907h258.259626v-23.47008H440.552107v23.47008z m0-340.432213v23.476906h258.259626v-23.476906H440.552107z"
      fill="#C6C6C6"
    />
  </svg>
);

export default Logo;
