import * as React from "react";
import { OS_STATUS } from "../../../constains";
// import { Button, Col, Row, Dropdown } from "react-bootstrap";
// import { useTheme } from "styled-components";
import { Os } from "../../../interfaces";
// import _ from "lodash";
import { Table } from "antd";
import styled from "styled-components";

const OssDatails = ({ oss = [] }: { oss: Os[] }) => {
  // const theme = useTheme();
  const counts = {};
// console.log(oss)
  for (const os of oss) {
    //@ts-ignore
    counts[os?.status] = counts[os?.status] ? counts[os?.status] + 1 : 1;
  }

  const columns = [
    {
      title: "OS status",
      dataIndex: "osStatus",
      responsive: ["xs"],
    },
    {
      title: "OS status",
      dataIndex: "osStatus",
      // sorter: (a, b) => a.osStatus - b.osStatus,
      // sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
    {
      title: "To",
      dataIndex: "osQnt",
      // sorter: (a, b) => a.osQnt - b.osQnt,
      // sortDirections: ["descend", "ascend"],
      responsive: ["xs"],
    },
    {
      title: "To",
      dataIndex: "osQnt",
      // sorter: (a, b) => a.osQnt - b.osQnt,
      // sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
  ];

  const data = [
    ...Array.from({ length: 5 }).map((d, index) => ({
      key: index,
      osStatus: OS_STATUS[index],
      osQnt: counts?.[index] || 0,
    })),
    { key: "5", osStatus: "Total", osQnt: oss?.length },
  ];

  return (
    <Container>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName="theme"
      />
    </Container>
  );
};

const Container = styled.div`
  .theme {
    color: ${(props) => props.theme.colors.textColor};
    background-color: ${(props) => props.theme.colors.gray0};
    border-color: ${(props) => props.theme.colors.blue200};
  }

  .ant-table-thead .ant-table-cell {
    background-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.textColor};
  }
`;

export default React.memo(OssDatails);
