import React, { useState, useEffect, SetStateAction, useMemo } from "react";
import { DateInput, DateValue, MonthPicker } from "@mantine/dates";
import { Select, MultiSelect } from "@mantine/core";
import moment from "moment-timezone";

import "dayjs/locale/pt-br";
import styled from "styled-components";
import { Token } from "../../helpers/token";
import { Client, IInspection } from "../../interfaces";
import { apiGet } from "../../helpers/api";
import { INSPECTION, URL_OS } from "../../constains";
import useUrlState from "@ahooksjs/use-url-state";
import { IonSpinner } from "@ionic/react";

type TProps = {
  //   setSelectedClient: (value: Client) => void;
  selectedClient: Client;
  setSelectedClientId: any;
  options: { label: string; value: string | undefined }[];
  mes: number;
  ano: number;
  setMes: (value: number) => void;
  setAno: (value: number) => void;
  inspection: IInspection[];
  os: unknown;
  setDataValue: (value: SetStateAction<Date | null>) => void;
  dataValue: DateValue | undefined;
  setInspection: (value: SetStateAction<unknown[]>) => void;
  setOs: (value: SetStateAction<unknown[]>) => void;
};

enum InspectionType {
  Os = "Os",
  Inspection = "Inspeção",
}




export const Step1 = ({
  selectedClient,
  setSelectedClientId,
  options,
  mes,
  ano,
  setAno,
  setMes,
  setInspection,
  inspection = [],
  dataValue,
  setDataValue,
  os,
  setOs,
}: TProps) => {
  const [type, setType] = useState<InspectionType | null | String>("Inspeção");
  const [state, setState] = useUrlState<any>({});
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();

  useMemo(() => {
    setState((prev: any) => {
      return {
        ...prev,
        tipo: type,
        ano: ano,
        mes: mes,
        clientId: selectedClient?.id,
        start,
        end,
      };
    });
  }, [type, ano, mes, selectedClient, start, end]);

  useEffect(() => {
    if (type == InspectionType.Os) {
      apiGet(
        `${URL_OS}/?mes=${mes + 1}&ano=${ano}${
          selectedClient?.id ? "&clientId=" + selectedClient?.id : ""
        }`,
        setOs
      );
    }
    if (type == InspectionType.Inspection) {
      if (end == null || start == null || selectedClient?.id == null) return;
      const url = new URL(INSPECTION);
      url.search = new URLSearchParams({
        end: end,
        start: start,
        clientId: selectedClient?.id,
      }) as unknown as string;

      setLoading(true);
      apiGet(
        url,
        setInspection,
        () => setLoading(false),
        () => setLoading(false)
      );
    }
  }, [start, end, selectedClient, type]);



  const FilterNumber = (array: any[]) => {
    // @ts-ignore
    return array?.sort((a: any, b: any) => {
      const titleWithoutSpacesa = a?.label?.replace(/\s/g, "");
      const titleWithoutSpacesb = b?.label?.replace(/\s/g, "");
      const keyA = titleWithoutSpacesa.toLowerCase();
      const keyB = titleWithoutSpacesb.toLowerCase();
  
      const regex = /^(\d+)\s*-/;
      const matchA = keyA?.match(regex);
      const matchB = keyB?.match(regex);
  
      if (matchA && matchB) {
        const numericValueA = parseInt(matchA[1]);
        const numericValueB = parseInt(matchB[1]);
  
        if (numericValueA < numericValueB) {
          return -1;
        } else if (numericValueA > numericValueB) {
          return 1;
        }
      } else if (matchA && !matchB) {
        return -1;
      } else if (!matchA && matchB) {
        return 1;
      }
  
      const wordA = keyA?.split("-")[1]?.trim();
      const wordB = keyB?.split("-")[1]?.trim();
  
      if (wordA && wordB) {
        return wordA.localeCompare(wordB);
      } else if (wordA && !wordB) {
        return -1;
      } else if (!wordA && wordB) {
        return 1;
      }
  
      const numericA = parseInt(keyA);
      const numericB = parseInt(keyB);
  
      if (!isNaN(numericA) && !isNaN(numericB)) {
        return numericA - numericB;
      }
  
      return keyA?.localeCompare(keyB);
    });
  };
  
 

  // console.log(options)
  // console.log(selectedClient)
  return (
    <DateContainer>
      <div className="content">
        <DateInput
          // value={value}
          // onChange={(dat) => console.log(moment(dat).unix())}
          onChange={(dat) => {
            const timestampStart = moment(dat)
              // .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format("x");
            setStart(timestampStart);
            // console.log("moment", timestampStart);
            // console.log("dat", dat);
            // console.log("date ", moment(timestampStart).format());
          }}
          label="Data inicial"
          placeholder="Selecione o dia inicial"
          locale="pt-br"
          maxDate={new Date()}
        />
        <DateInput
          // value={value}
          // onChange={setValue}
          onChange={(dat) => {
            const timestampEnd = moment(dat)
              // .utc()
              .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
              .format("x");
            setEnd(timestampEnd);

            // console.log("moment end", timestampEnd, typeof timestampEnd);
            // console.log("selected end", dat);
            // console.log("date end", new Date(parseInt(timestampEnd)));
          }}
          label="Data final"
          placeholder="Selecione o dia final"
          locale="pt-br"
          maxDate={new Date()}
        />

        {/* <MonthPicker
          allowDeselect
          value={dataValue}
          onChange={(date) => {
            setAno(date?.getFullYear() ?? new Date().getFullYear());
            setMes(date?.getMonth() ?? new Date().getMonth());
            setDataValue(date);
          }}
          maxDate={new Date()}
          size="sm"
          locale="pt-br"
        /> */}

        <div className="select-infos">
          <Select
            label="Selecione entre OS e Inspeção"
            placeholder="OS / Inspeção"
            // @ts-ignore
            value={type}
            defaultValue={InspectionType.Inspection}
            data={[InspectionType.Os, InspectionType.Inspection]}
            onChange={setType}
          />
          <Select
            label="Selecione o cliente"
            placeholder=""
            // @ts-ignore
            data={FilterNumber(options)}
            defaultValue={selectedClient?.id}
            onChange={(client) => {
              setSelectedClientId(client);
            }}
            clearable
            searchable
          />
        </div>
      </div>
      <br />

      {/* multi select para inspeção ou os */}
      {/* {selectedClient && type && inspection.length > 0 && (
        <MultiSelect
          label={`Selecione uma ${type} ou deixe vazia para todas encontradas.`}
          placeholder={`Todas ${type}`}
          data={[]}
          //   value={value}
          //   onChange={setValue}
        />
      )} */}

      {selectedClient && type && start && end && inspection.length < 1 ? (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <IonSpinner></IonSpinner>
              <h2>Buscando, aguarde.</h2>
            </div>
          ) : (
            <div style={{ color: "red" }}>
              nenhuma {type} encontrado no periodo selecionado!
            </div>
          )}
        </>
      ) : (
        <div>
          {inspection.length} {type} encontradas!
        </div>
      )}
    </DateContainer>
  );
};

const DateContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #857e7e; */
  flex-direction: column;
  padding-bottom: 10px;

  .action-buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .select-infos {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-left: 2px solid gray;
    padding-left: 10%;
  }
`;
