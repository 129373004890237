import styled from 'styled-components';

export const DivGeral = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
padding-left: 11px;
padding-right: 11px;
align-items: center;
`;
export const DivPrimaria = styled.div`
display: flex;
flex-direction: row;
align-Items: baseline;
justify-Content: space-around;
width: 100%;

`;
export const DivSecundaria = styled.div`
display: flex;
align-Items: baseline;
justify-Content: flex-end;

`;
export const DivButton = styled.div`
display: flex;
flex-direction: row;
align-Items: flex-end;
justify-Content: flex-end;
height: 100%;
width: 100%;
`;
export const SpanTexto = styled.span`
font-size: 18px;
margin-left: 6px;
margin-right: 6px;
`;
export const TextArea = styled.textarea`
height: 200px;
width: 90%;
margin: 30px 0;
font-size: 15px;
padding: 10px;

`;