import React, {
    Dispatch,
    SetStateAction,
    useState,
  } from "react";
  
  import { useTheme } from "styled-components";
  import { DeleteClient, GetClient, UpdateClient } from "./api";
  import { Client } from "../../interfaces/index";
  import { FaMobileAlt } from "react-icons/fa";
  import {
    BsTelephone,
  } from "react-icons/bs";
  import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
  import styled from "styled-components";
  import { getPlatforms } from "@ionic/react";
  import Modal from "../../components/Modal";
  import AdicionarClient from "./AdicionarEmpresa";
  import { Table, Col, Container, Row } from "react-bootstrap";
  
  
  import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
  } from "@ionic/react";
  import ModalConfirm from "../../components/Modal/ModalConfirm";
  
  
  interface Props {
    clients: Client[];
    // setClients: Dispatch<SetStateAction<Client[]>>;
    run: any;
  }

  function BasicExample({ clients = [], run }: Props) {
    const platform = getPlatforms();
    const theme = useTheme();
    const [client, setClient] = React.useState<Client>({
      name: "",
      company: "",
      supervisor: "",
      cel: "",
      tel: "",
      email: "",
      address: "",
      cep: "",
      lat: "",
      lng: "",
    });
    const [show, setShow] = React.useState(false);
    const [idCliente, setIdCliente] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const handleClose = () => setShow(false);
    const handleshow = (client: Client) => {
      setMode("view");
      setClient(client);
      setShow(true);
    };
    const handleEdit = (client: Client) => {
      setMode("edit");
      setClient(client);
      setShow(true);
    };
    const onSubmitHandler = () => {
      UpdateClient(client, () => run());
      handleClose();
    };
    const [mode, setMode] = React.useState<"view" | "edit">("view");
    const onConfirm = () => {
      DeleteClient(idCliente, () => run());
    };
    const FilterNumber = (array: any) => {
      // @ts-ignore
      return array?.sort((a, b) => {
        const keyA = a.name.toLowerCase();
        const keyB = b.name.toLowerCase();
  
        const regex = /^(\d+)\s*-/;
        const matchA = keyA.match(regex);
        const matchB = keyB.match(regex);
  
        if (matchA && matchB) {
          const numericValueA = parseInt(matchA[1]);
          const numericValueB = parseInt(matchB[1]);
  
          if (numericValueA < numericValueB) {
            return -1;
          } else if (numericValueA > numericValueB) {
            return 1;
          }
        } else if (matchA && !matchB) {
          return -1;
        } else if (!matchA && matchB) {
          return 1;
        }
  
        const wordA = keyA.split('-')[1]?.trim();
        const wordB = keyB.split('-')[1]?.trim();
  
        if (wordA && wordB) {
          return wordA.localeCompare(wordB);
        } else if (wordA && !wordB) {
          return -1;
        } else if (!wordA && wordB) {
          return 1;
        }
  
        const numericA = parseInt(keyA);
        const numericB = parseInt(keyB);
  
        if (!isNaN(numericA) && !isNaN(numericB)) {
          return numericA - numericB;
        }
  
        return keyA.localeCompare(keyB);
      });
    }
  
  
  
  
    return (
      <div>
        <Table striped bordered hover variant={theme.themeName}>
  
          <tbody>
            {FilterNumber(clients)?.map((client: Client, index: number) => {
              const {
                name = "--",
                company = "--",
                address = "--",
                supervisor = "--",
                id = "0",
                email = "--",
                cel = "--",
                tel = "--",
              } = client || "--";
  
  
              // console.log(client)
  
  
              return (
                <tr key={index}
  
                >
                  <IonCard
                    style={{
                      width: platform.includes("desktop") ? '100%' : '96vw',
                      cursor: 'pointer',
                    }}
  
                    onClick={() => {
                      handleshow(client);
                      setIdCliente(id);
                    }}>
                    <IonCardHeader>
                      <IonCardTitle>{name}</IonCardTitle>
                      <IonCardSubtitle>{supervisor ? supervisor : "Supervisor não informado."}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <div>
                        <Container fluid>
                          {platform.includes("desktop") ? (
  
                            <>
  
                              <div>
  
                                {/* desktop */}
  
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  margin: '10px 0px'
                                }}>
  
  
                                  <div style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}>
  
  
                                    <div
                                      style={{
                                        height: '28px',
                                        width: '20px',
                                        marginRight: '3px'
                                      }}>
  
                                      <FaMobileAlt
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        color={theme.colors.textColor}
                                        size={20} />
  
  
                                    </div>
  
  
                                    {cel ? cel : "Não informado."}
  
                                  </div>
  
  
  
  
                                  <div style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}>
  
                                    <div style={{
                                      height: '20px',
                                      width: '20px',
                                      marginRight: '3px'
                                    }}>
                                      <HiOutlineMail
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        color={theme.colors.textColor}
                                        size={20} />
                                    </div>
  
                                    {email ? email : "Não informado."}
  
                                  </div>
  
  
                                </div>
  
  
  
  
  
  
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
  
  
  
                                  <div style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}>
                                    <div style={{
                                      height: '28px',
                                      width: '20px',
                                      marginRight: '3px'
                                    }}>
                                      <BsTelephone
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        size={20}
                                        color={theme.colors.textColor}
                                      />
  
                                    </div>
  
  
                                    {tel ? tel : "Não informado."}
  
  
                                  </div>
  
                                  <div style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}>
  
                                    <div style={{
                                      height: '27px',
                                      width: '20px',
                                      marginRight: '3px'
                                    }}>
  
                                      <HiOutlineLocationMarker
                                        style={{ margin: '0px 0px 10px 0px' }}
                                        color={theme.colors.textColor}
                                        size={20}
                                      />
  
                                    </div>
  
                                    {address ?
  
                                      <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}>
  
                                        {address}
  
                                      </div>
  
                                      :
  
                                      "Não informado."
  
                                    }
  
  
                                  </div>
  
  
  
                                </div>
  
  
                              </div>
  
                            </>
  
  
                          )
                            :
  
                            <div>
  
                              {/* mobile */}
  
                              <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                margin: '10px 0px'
                              }}>
  
  
                                <div style={{
                                  fontSize: '12px',
                                  width: '50%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  flexDirection: 'row'
                                }}>
  
  
                                  <div
                                    style={{
                                      height: '27px',
                                      width: '20px',
                                      marginRight: '3px',
                                      wordBreak: 'break-word',
                                    }}>
  
                                    <FaMobileAlt
                                      style={{ margin: '0px 0px 10px 0px' }}
                                      color={theme.colors.textColor}
                                      size={20} />
                                  </div>
  
  
                                  {cel ? cel : "Não informado."}
  
                                </div>
  
  
  
  
                                <div style={{
                                  fontSize: '12px',
                                  width: '50%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  flexDirection: 'row'
                                }}>
  
                                  <div style={{
                                    height: '23px',
                                    width: '20px',
                                    marginRight: '3px'
                                  }}>
                                    <HiOutlineMail
                                      style={{ margin: '0px 0px 10px 0px' }}
                                      color={theme.colors.textColor}
                                      size={20} />
                                  </div>
  
                                  {email ?      <div style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      wordBreak: 'break-word',
                                    }}>
  
                                      {email}
  
                                    </div> : "Não informado."}
  
                                </div>
  
  
                              </div>
  
  
  
  
  
  
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
  
  
  
                                <div style={{
                                  fontSize: '12px',
                                  width: '50%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  flexDirection: 'row'
                                }}>
                                  <div style={{
                                    height: '28px',
                                    width: '20px',
                                    marginRight: '3px',
                                    wordBreak: 'break-word',
                                  }}>
                                    <BsTelephone
  
                                      size={20}
                                      color={theme.colors.textColor}
                                    />
  
                                  </div>
  
  
                                  {tel ? tel : "Não informado."}
  
  
                                </div>
  
                                <div style={{
                                  fontSize: '12px',
                                  width: '50%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                }}>
  
                                  <div style={{
                                    height: '27px',
                                    width: '20px',
                                    marginRight: '3px'
                                  }}>
  
                                    <HiOutlineLocationMarker
                                      style={{ margin: '0px 0px 10px 0px' }}
                                      color={theme.colors.textColor}
                                      size={20}
                                    />
  
                                  </div>
  
                                  {address ?
  
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}>
  
                                      {address}
  
                                    </div>
  
                                    :
  
                                    "Não informado."
  
                                  }
  
  
                                </div>
  
  
  
                              </div>
  
  
                            </div>
  
  
                          }
  
  
                        </Container>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Modal
          type={'cliente'}
          idCliente={idCliente}
          setShowConfirm={setShowConfirm}
          client={client}
          handleEdit={handleEdit}
          title={client.name}
          show={show}
          setShow={setShow}
          onSubmitHandler={mode === "edit" ? onSubmitHandler : () => { }}
          mode={mode}
          content={
            <AdicionarClient client={client} setClient={setClient} mode={mode} />
          }
        />
        <ModalConfirm
          title={"Tem certeza que deseja deletar esta empresa?"}
          show={showConfirm}
          onConfirm={onConfirm}
          setShow={setShowConfirm}
          colorButton={"danger"}
        />
      </div>
    );
  }
  
  export default React.memo(BasicExample);
