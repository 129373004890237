import React, { Dispatch, SetStateAction, useState } from "react";
// import { useTheme } from "styled-components";
import { DeleteOs, GetOs, UpdateOs } from "./api";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import Modal from "../../../components/Modal";
import AdicionarOs from "./AdicionarOs";
import { formatDistance } from "date-fns";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import Card from "../../../components/card/card";
import { OS_STATUS } from "../../../constains";
import {
  colorOptionsPriority,
  colorOptionsStatus,
} from "../../../styles/cssVariables";
import { ptBR } from "date-fns/locale";
import { Os } from "../../../interfaces";
import { createOutline, eyeOutline, trashOutline } from "ionicons/icons";

interface Props {
  OSs: Os[];
  setOSs: Dispatch<SetStateAction<Os[]>>;
}

function BasicExample({ OSs = [], setOSs }: Props) {
  const [OS, setOS] = useState<Os>({
    title: "",
    supervisor: "",
    priority: 0,
    assignTo: "",
    text: "",
    client: "",
  });

  const [mode, setMode] = React.useState<"view" | "edit">("view");
  // const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  // const theme = useTheme();
  //@ts-ignore
  const getData = () => GetOs(setOSs);

  const handleClose = () => setShow(false);

  const handleShow = (Os: Os) => {
    setMode("view");
    //@ts-ignore
    setOS(Os);
    setShow(true);
  };
  const handleEdit = (Os: Os) => {
    setMode("edit");
    //@ts-ignore
    setOS(Os);
    setShow(true);
  };

  const onSubmitHandler = () => {
    UpdateOs(OS, getData);
    handleClose();
  };

  const componentOptionsPriority: { [key: number]: any } = {
    0: <AiOutlineArrowDown size={27} />,
    1: <AiOutlineArrowUp size={27} />,
    2: <AiOutlineArrowUp size={27} />,
  };

  // if (!platform.includes("desktop")) return <Mobile />;
  return (
    <Content>
      <hr />

      <OsView>
        {OSs?.map((value: any, index: number) => {
          return (
            <>
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <OsOrganization key={index + 1}>
                    <>
                      <Imagem>Img </Imagem>
                      <Informacoes>
                        <OsIdName>{value?.title}</OsIdName>

                        <Osgrautempo>
                          <Grau
                            style={{
                              backgroundColor: `${
                                colorOptionsPriority[value?.priority]
                              }`,
                            }}
                          >
                            {componentOptionsPriority[value?.priority]}
                          </Grau>

                          <div
                            style={{
                              backgroundColor: `${
                                colorOptionsStatus[value?.status]
                              }`,
                              color: `black`,
                              borderRadius: "10px",
                              padding: "2px",
                            }}
                          >
                            {OS_STATUS[value?.status]}
                          </div>

                          <Tempo>
                            <BiTimeFive style={{ marginRight: "5px" }} />
                            {formatDistance(
                              new Date(value?.createAt),
                              new Date(),
                              {
                                locale: ptBR,
                              }
                            )}{" "}
                            Atrás.
                          </Tempo>
                        </Osgrautempo>
                      </Informacoes>
                    </>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "auto",
                        // alignItems: "flex-end",
                        marginLeft: "auto",
                        padding: "0px 10px 0px 0px",
                      }}
                    >
                      <IonIcon
                        icon={eyeOutline}
                        style={{
                          cursor: "pointer",
                          width: "40px",
                          height: "30px",
                          margin: "0px 0px 0px 3px",
                        }}
                        onClick={() => {
                          handleShow(value);
                        }}
                      />

                      <IonIcon
                        icon={createOutline}
                        style={{
                          cursor: "pointer",
                          width: "40px",
                          height: "30px",
                          margin: "0px 0px 0px 3px",
                        }}
                        onClick={() => {
                          handleEdit(value);
                        }}
                      />
                      <IonIcon
                        icon={trashOutline}
                        style={{
                          cursor: "pointer",
                          width: "40px",
                          height: "30px",
                          margin: "0px 0px 0px 3px",
                        }}
                        //@ts-ignore
                        onClick={() => DeleteOs(value.id, () => GetOs(setOSs))}
                      />
                    </div>
                  </OsOrganization>
                }
              />
            </>
          );
        })}
      </OsView>
      <Modal
        title="Adicionar OS"
        show={show}
        setShow={setShow}
        onSubmitHandler={mode === "edit" ? onSubmitHandler : () => {}}
        mode={mode}
        content={<AdicionarOs OS={OS} setOS={setOS} mode={mode} />}
      />
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`;

const Imagem = styled.div`
  background-color: var(--gray-500);
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Informacoes = styled.div`
  display: flex;
  flex-direction: column;
`;
const OsIdName = styled.div`
  margin-left: 20px;
  color: var(--blue-700);
  width: auto;
`;
const Osgrautempo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Grau = styled.span`
  /* border: 1px solid var(--red-700); */
  border-radius: 10px;
  height: auto;
  width: auto;
  /* color: var(--red-700); */
  color: black;
  /* background-color: var(--red-300); */
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  margin: 0px 10px 0px 0px;
`;
const Tempo = styled.span`
  margin-left: 10px;
  border: 1px solid var(--blue-700);
  border-radius: 10px;
  height: auto;
  width: auto;
  color: var(--blue-700);
  background-color: var(--blue-200);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const OsOrganization = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: red; */
`;

const OsView = styled.div`
  max-height: 740px;
  min-height: 740px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;

export default React.memo(BasicExample);
