import { IonTextarea } from "@ionic/react";
import React, { memo, useState, useCallback } from "react";
import { Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import styled from "styled-components";
// import { apiGet, apiPut, apiPost, apiDelete } from "../../../helpers/api";
import { useDropzone } from "react-dropzone";
import { FILE_URL } from "../../constains";
import axios from "axios";

const MultipleFile = React.memo(
  ({
    clientId,
    companyId,
    getData,
  }: {
    clientId: string;
    companyId: string;
    getData: () => void;
  }) => {
    const [files, setFiles] = useState([]);
    //state for checking file size
    const [fileSize, setFileSize] = useState(true);
    // for file upload progress message
    const [fileUploadProgress, setFileUploadProgress] = useState(false);
    //for displaying response message
    const [fileUploadResponse, setFileUploadResponse] = useState(null);
    //base end point url

    const uploadFileHandler = (event) => {
      setFiles(event.target.files);
    };

    const fileSubmitHandler = (event) => {
      event.preventDefault();
      setFileSize(true);
      setFileUploadProgress(true);
      setFileUploadResponse(null);

      for (let i = 0; i < files.length; i++) {
        // TODO: LIMIT SIZE
        // if (files[i].size > 100024) {
        //   setFileSize(false);
        //   setFileUploadProgress(false);
        //   setFileUploadResponse(null);
        //   return;
        // }

        //add image
        onLoad(files[i]);
        // getBase64FromImage(files[i], onLoad);
      }
      setFiles([]);

      setFileUploadProgress(false);
    };

    const onLoad = (file: File) => {
      // console.log("onLoad image", fileString);
      const formData = new FormData();

      var newFileName = btoa(unescape(encodeURIComponent(file.name)));
      formData.append("file", file, newFileName);
      formData.append("companyId", companyId);
      formData.append("clientId", clientId);

      const config = {
        headers: {
          // "Content-Disposition": `attachment; filename="` + file.name + `"`,
          "Content-Disposition": "attachment",
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      axios
        .post(FILE_URL, formData, config)
        .then((data) => {
          console.log("Resposta do servidor:", data);
          getData();
        })
        .catch((error) => {
          console.error("Erro:", error);
        });

      // fetch(FILE_URL, {
      //   method: "POST",
      //   body: formData,
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log("Resposta do servidor:", data);
      //     getData();
      //   })
      //   .catch((error) => {
      //     console.error("Erro:", error);
      //   });

      // setImage(fileString);
    };

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
        };

        setFiles((prevState) => [...prevState, file]);
        reader.readAsArrayBuffer(file);
      });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Container>
        <form onSubmit={fileSubmitHandler}>
          <div className="drop-area" {...getRootProps()}>
            <div className="files-wr" data-count-files="1">
              <div className="one-file">
                {/* <label htmlFor="file-1">Adicionar múltiplas arquivos</label> */}
                <input
                  id="file-1"
                  type="image"
                  multiple
                  {...getInputProps()}
                  onChange={(event) => {
                    uploadFileHandler(event);
                  }}
                  // disabled={fileLimit}
                />
                {files.length > 0 ? (
                  <p>{files.length} arquivos carregados</p>
                ) : (
                  <>
                    <p>Para Adicionar múltiplas arquivos</p>
                    <br />
                    <p>Arraste ou click aqui</p>
                  </>
                )}
              </div>
            </div>
          </div>

          {files.length > 0 ? (
            <Button style={{ width: "100%" }} type="submit" variant="danger">
              Carregar arquivos
            </Button>
          ) : null}

          {!fileSize && <p style={{ color: "red" }}>File size exceeded!!</p>}
          {fileUploadProgress && (
            <p style={{ color: "red" }}>Uploading File(s)</p>
          )}
          {fileUploadResponse != null && (
            <p style={{ color: "green" }}>{fileUploadResponse}</p>
          )}
        </form>
        <hr color="gray" />
      </Container>
    );
  }
);
export default MultipleFile;

const Container = styled.div`
  .drop-area {
    border: 1px dashed blue;
    width: 100%;
    /* height: 10px; */
    margin-bottom: 40px;

    .one-file {
      background-color: #ebedea;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-direction: column;
    }
  }
`;

export { MultipleFile };
