import React, { memo, useEffect } from "react";
import axios from "axios";
import { CallNotification } from "../Alert";
import { ADMIN_URL } from "../../constains";
import { User } from "../../interfaces";
// import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
// import Card from "../card/card";


function ViewUser(props: any) {
    // const [image, setImage] = useState<any>(null)
    const token = window.localStorage.getItem("accessToken");
    const access = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const id = props.id;
    const [user, setUser] = React.useState<User>({
        name: "",
        email: "",
        // password: "",
        criarOS: false,
        editarOS: false,
        deletarOS: false,
        visualizarOS: false,
        agendarVisita: false,
        // imageId: '',
        admin: false,
    });

    const onSubmitHandler = (event: any) => {
        event.preventDefault();

        const usuario: User = user;

        axios
            .put(`${ADMIN_URL}/${id}`, usuario, access)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    CallNotification("Sucesso", "usuario editado", "success");
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err);
                CallNotification("Error", "Falha ao editar usuário", "danger");
            });
    };

    useEffect(() => {
        axios
            .get(`${ADMIN_URL}/${id}`, access)
            .then((res) => {
                setUser((prevState) => ({
                    ...prevState,
                    name: res.data.user.name,
                    email: res.data.user.email,
                    admin: res.data.user.admin,
                    agendarVisita: res.data.user.agendarVisita,
                    // password: res.data.user.password,

                    criarOS: res.data.user.criarOS,
                    editarOS: res.data.user.editarOS,
                    deletarOS: res.data.user.deletarOS,
                    visualizarOS: res.data.user.visualizarOS,
                    // imageId: '',

                    criarColaborador: res.data.user.criarColaborador,
                    editarColaborador: res.data.user.editarColaborador,
                    deletarColaborador: res.data.user.deletarColaborador,
                    visualizarColaborador: res.data.user.visualizarColaborador,

                    criarCliente: res.data.user.criarCliente,
                    editarCliente: res.data.user.editarCliente,
                    deletarCliente: res.data.user.deletarCliente,
                    visualizarCliente: res.data.user.visualizarCliente,

                    criarInspection: res.data.user.criarInspection,
                    editarInspection: res.data.user.editarInspection,
                    deletarInspection: res.data.user.deletarInspection,
                    visualizarInspection: res.data.user.visualizarInspection,

                    criarModelo: res.data.user.criarModelo,
                    editarModelo: res.data.user.editarModelo,
                    deletarModelo: res.data.user.deletarModelo,
                    visualizarModelo: res.data.user.visualizarModelo,

                }));
            })
            .catch((error) => {
                console.error(error);
            });
    });





    return (
        <DivGeral>
            <form onSubmit={onSubmitHandler}>
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "auto",
                        }}
                    >
                        <label>Nome:</label>

                        <Input
                            disabled
                            type="text"
                            name="login"
                            value={user.name}
                            onChange={(event) =>
                                setUser((prevState) => ({
                                    ...prevState,
                                    name: event.target.value,
                                }))
                            }
                            required
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "auto",
                        }}
                    >
                        <label>Email:</label>
                        <Input
                            disabled
                            type="text"
                            name="email"
                            value={user.email}
                            onChange={(event) =>
                                setUser((prevState) => ({
                                    ...prevState,
                                    email: event.target.value,
                                }))
                            }
                        ></Input>
                    </div>

                    {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
            }}
          >
            <Span>password:</Span>
            <Input
              name="password"
              value={user.password}
              onChange={(event) =>
                setUser((prevState) => ({
                  ...prevState,
                  password: event.target.value,
                }))
              }
              type="password"
            ></Input>
          </div> */}

                    <DivRow>
                        <DivColumn>
                            <Span>Criar OSs: </Span>

                            <Select
                                disabled
                                value={user.criarOS === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        criarOS: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Editar OSs:</Span>

                            <Select
                                disabled
                                value={user.editarOS === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        editarOS: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Deletar OSs:</Span>

                            <Select
                                disabled
                                value={user.deletarOS === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        deletarOS: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Visualizar OS</Span>
                            <Select
                                disabled
                                value={user.visualizarOS === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        visualizarOS: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>
                        {/* <DivColumn>
              <Span>Agendar Visita:</Span>
              <Select
                value={user.agendarVisita === true ? "1" : "2"}
                onChange={(event: any) =>
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === '1' ? true : false
                  }))
                }
              >
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </Select>
            </DivColumn> */}
                    </DivRow><DivRow>
                        <DivColumn>
                            <Span>Criar Colaborador: </Span>

                            <Select
                                disabled
                                value={user.criarColaborador === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        criarColaborador: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Editar Colaborador:</Span>

                            <Select
                                disabled
                                value={user.editarColaborador === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        editarColaborador: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Deletar Colaborador:</Span>

                            <Select
                                disabled
                                value={user.deletarColaborador === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        deletarColaborador: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Visualizar Colaborador</Span>
                            <Select
                                disabled
                                value={user.visualizarColaborador === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        visualizarColaborador: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>
                        {/* <DivColumn>
              <Span>Agendar Visita:</Span>
              <Select
                value={user.agendarVisita === true ? "1" : "2"}
                onChange={(event: any) =>
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === '1' ? true : false
                  }))
                }
              >
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </Select>
            </DivColumn> */}
                    </DivRow>

                    <DivRow>
                        <DivColumn>
                            <Span>Criar Cliente: </Span>

                            <Select
                                disabled
                                value={user.criarCliente === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        criarCliente: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Editar Cliente:</Span>

                            <Select
                                disabled
                                value={user.editarCliente === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        editarCliente: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Deletar Cliente:</Span>

                            <Select
                                disabled
                                value={user.deletarCliente === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        deletarCliente: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Visualizar Cliente</Span>
                            <Select
                                disabled
                                value={user.visualizarCliente === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        visualizarCliente: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>
                        {/* <DivColumn>
              <Span>Agendar Visita:</Span>
              <Select
                value={user.agendarVisita === true ? "1" : "2"}
                onChange={(event: any) =>
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === '1' ? true : false
                  }))
                }
              >
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </Select>
            </DivColumn> */}
                    </DivRow>







                    <DivRow>
                        <DivColumn>
                            <Span>Criar Inspeção: </Span>

                            <Select
                            disabled
                                value={user.criarInspection === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        criarInspection: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Editar Inspeção:</Span>

                            <Select
                            disabled
                                value={user.editarInspection === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        editarInspection: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Deletar Inspeção:</Span>

                            <Select
                            disabled
                                value={user.deletarInspection === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        deletarInspection: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>


                        <DivColumn>
                            <Span>Visualizar Inspeção</Span>
                            <Select
                            disabled
                                value={user.visualizarInspection === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        visualizarInspection: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>


                        {/* <DivColumn>
              <Span>Agendar Visita:</Span>
              <Select
                value={user.agendarVisita === true ? "1" : "2"}
                onChange={(event: any) =>
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === '1' ? true : false
                  }))
                }
              >
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </Select>
            </DivColumn> */}
                    </DivRow>








                    <DivRow>
                        <DivColumn>
                            <Span>Criar Modelo: </Span>

                            <Select
                            disabled
                                value={user.criarModelo === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        criarModelo: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Editar Modelo:</Span>

                            <Select
                            disabled
                                value={user.editarModelo === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        editarModelo: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>

                        <DivColumn>
                            <Span>Deletar Modelo:</Span>

                            <Select
                            disabled
                                value={user.deletarModelo === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        deletarModelo: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>


                        <DivColumn>
                            <Span>Visualizar Modelo</Span>
                            <Select
                            disabled
                                value={user.visualizarModelo === true ? "1" : "2"}
                                onChange={(event: any) =>
                                    setUser((prevState) => ({
                                        ...prevState,
                                        visualizarModelo: event.target.value === '1' ? true : false
                                    }))
                                }
                            >
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </Select>
                        </DivColumn>


                        {/* <DivColumn>
              <Span>Agendar Visita:</Span>
              <Select
                value={user.agendarVisita === true ? "1" : "2"}
                onChange={(event: any) =>
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === '1' ? true : false
                  }))
                }
              >
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </Select>
            </DivColumn> */}
                    </DivRow>






                    {/* 
          <DivColumn>
            <Span>Foto de perfil:</Span>
            <input
              style={{ width: "auto", height: "auto" }}
              type="file"
              onChange={(e: any) => onChange(e)}
            />
          </DivColumn> */}

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                    >
                        <input
                            style={{
                                width: "20px",
                                height: "20px",
                                margin: "10px 10px 10px 0",
                            }}
                            disabled
                            className="Check"
                            checked={user.admin}
                            onChange={(event) =>
                                setUser((prevState) => ({
                                    ...prevState,
                                    admin: !user.admin,
                                }))
                            }
                            type="checkbox"
                        ></input>

                        <Span className="Position">Admin</Span>
                    </div>

                    {/* <button className="btn btn-primary" type="submit">
                        Confirmar
                    </button> */}
                </div>
            </form>
        </DivGeral>
    );
}

const DivGeral = styled.div`
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  font-size: 16px;
  border: 2px solid #a9a9a9;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
const Input = styled.input`
  border: 1px solid;
  border-radius: 30px;
  padding: 5px;
  font-size: 14px;
  width: 88%;
  margin: 6px 40px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Select = styled.select`
  height: auto;
  width: auto;
  padding: 4px;
  margin: 0px 0px 30px 0px;
`;
const Span = styled.span`
  margin: 10px 0px;
`;

export default memo(ViewUser);
