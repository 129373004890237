import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import DashView from "../components/DashView";
import AdminUsuarios from "../views/Admin/Usuarios";
import { OSs } from "../views/Admin/OSs";
import { OsCli } from "../views/OsCli";
import { MyOs } from "../views/MyOs";
import Company from "../views/Admin/Company";
// import AdminSensor from "../views/Admin/Sensors";
// import Dashboards from "../views/Dashboards";
import Dashboard from "../views/Dashboards/Dashboard";
import { Home } from "../views/Home";
import Login from "../views/Login/Login";
// import Sensores from "../views/Sensores";
// import ConfigSensor from "../views/Sensores/Config";
import {
  PrivateRoute,
  //  ProvideAuth
} from "./ProvideAuth";
import AdminImages from "../views/Admin/Images";
// import Acesso from "../views/Acesso";
import Client from "../views/Clientes";
import AdminClient from "../views/Admin/Clientes";
import { Relatorio } from "../views/relatorio";
// import Agent from "../views/Agentes";
import Page from "./Page";

import {
  IonApp,
  // IonPage,
  IonRouterOutlet,
  IonSplitPane,
  // IonTitle,
  // IonToolbar,
  // setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "../components/Sidebar/Menu";
import MenuCli from "../components/Sidebar/MenuCli";
// import Mobile from "../views/Home/Mobile";
import TabRootPage from "../views/pages/TabRootPage";
import TabRootPage2 from "../views/pages/TabRootPage2";
import Equipe from "../views/Equipe";
import Localizacao from "../views/Localizacao";
import Inspection from "../views/Inspection";
import InspectionCompany from "../views/Inspection/Equipments";
import InspectionCompanyModelos from "../views/Inspection/Modelos";
import InspectionEquipament from "../views/Inspection/inspections/Inspections";
import OnlyInspection from "../views/Inspection/OnlyInspection";
import { Files } from "../views/FilesPage";
import { ClientFiles } from "../views/FilesPage/ClientFiles";
import Empresas from "../views/Empresas";
import { COMPANY_URL } from "../constains";
import { useRequest } from "ahooks";
import { Token } from "../helpers/token";
import { storage } from "../storage/ionic-storage-config";
import AcessoClientUser from "../views/AcessoClientUser";
import Link from "../views/relatorio/Step3/Link";
import LinkOs from "../views/relatorioLinks/Link";
import Config from "../views/Config";

interface IRouterProps {
  setTheme: any;
}

export default function Router({ setTheme }: IRouterProps): any {
  const switchRota = Token.getTokenData().clientUser
  const url = window.location.pathname


  return (
    <IonApp>
      <>

        {
          switchRota ?
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <MenuCli />
                <IonRouterOutlet id="main">
                  <Switch>

                    <PrivateRoute path="/dashboard">
                      <DashView title="Dashboard" setTheme={setTheme}>
                        <Dashboard />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <Inspection />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme}>
                        <InspectionCompany />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/modelos/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <InspectionCompanyModelos />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/:id/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <InspectionEquipament />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/onlyInspection/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <OnlyInspection />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/os">
                      <DashView title="Os" setTheme={setTheme}>
                        <OsCli />
                      </DashView>
                    </PrivateRoute>
                    <PrivateRoute path="/abrir_os">
                      <DashView title="Solicitação de OS's" setTheme={setTheme}>
                        <OsCli />
                      </DashView>
                    </PrivateRoute>


                  </Switch>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>

            :

            <IonReactRouter>
              <IonSplitPane contentId="main">
                {
                  url == '/link' || url == '/linkos' ?
                    <></>
                    :
                    <Menu />
                }
                <IonRouterOutlet id="main">
                  <Switch>
                    <Route path="/page/:name" component={Page} exact />
                    <Route path="/tabs" component={TabRootPage} />
                    <Route path="/tabs2" component={TabRootPage2} />
                    {/* @ts-ignore */}
                    <Route path="/home" component={Home} />
                    <Route path="/login" component={Login} />
                    <Route path="/link"
                    // component={Link}
                    >
                      <div style={{overflowY: 'scroll', height: '100vh'}}> 

                        <Link />
                      </div>

                    </Route>
                    <Route path="/linkos"
                    // component={Link}
                    >
                      <div style={{overflowY: 'scroll', height: '100vh'}}> 

                        <LinkOs />
                      </div>

                    </Route>

                    <PrivateRoute path="/dashboard">
                      <DashView title="Dashboard" setTheme={setTheme} >
                        <Dashboard />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/equipe">
                      <DashView title="Equipe" setTheme={setTheme}>
                        <Equipe />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/empresas">
                      <DashView title="Empresas" setTheme={setTheme} >
                        <Empresas />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/clientes">
                      <DashView title="Clientes" setTheme={setTheme}>
                        <AcessoClientUser />
                      </DashView>
                    </PrivateRoute>
                    {/* <Route path="/" component={TabRootPage} /> */}
                    <Redirect from="/" to="/login" exact />
                    <PrivateRoute path="/os">
                      <DashView title="Os" setTheme={setTheme} >
                        <OsCli />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/minhas-os">
                      <DashView title="Minhas Os" setTheme={setTheme} >
                        <MyOs />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <Inspection />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <InspectionCompany />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/modelos/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme}>
                        <InspectionCompanyModelos />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/inspection/:id/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <InspectionEquipament />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/onlyInspection/:id" exact>
                      <DashView title="Inspeção" setTheme={setTheme} >
                        <OnlyInspection />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/localizacao">
                      <DashView title="Localização" setTheme={setTheme} >
                        {/* @ts-ignore */}
                        <Localizacao />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/relatorio">
                      <DashView title="Relatório" setTheme={setTheme} >
                        <Relatorio />
                      </DashView>
                    </PrivateRoute>


                    <PrivateRoute path="/files/:id">
                      <DashView title="Arquivos" setTheme={setTheme} >
                        <ClientFiles />
                      </DashView>
                    </PrivateRoute>

                    <PrivateRoute path="/files">
                      <DashView title="Arquivos" setTheme={setTheme}>
                        <Files />
                      </DashView>
                    </PrivateRoute>
                    <PrivateRoute path="/config">
                      <DashView title="Configurações" setTheme={setTheme}>
                        <Config />
                      </DashView>
                    </PrivateRoute>




                    {/* ADMIN */}

                    <Route path="/admin/usuarios">
                      <DashView title="Usuarios" setTheme={setTheme}>
                        <AdminUsuarios />
                      </DashView>
                    </Route>

                    <Route path="/admin/company">
                      <DashView title="Company" setTheme={setTheme} >
                        <Company />
                      </DashView>
                    </Route>

                    <Route path="/admin/client">
                      <DashView title="Company Client" setTheme={setTheme} >
                        <AdminClient />
                      </DashView>
                    </Route>

                    <Route path="/admin/os">
                      <DashView title="OS's" setTheme={setTheme} >
                        <OSs />
                      </DashView>
                    </Route>

                    <Route path="/admin/images">
                      <DashView title="Images" setTheme={setTheme}>
                        <AdminImages />
                      </DashView>
                    </Route>
                  </Switch>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>

        }






      </>
    </IonApp>
  );
}
