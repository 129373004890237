import React, { useEffect, useMemo, useState } from "react";
import { Stepper, Button, Group, SelectItem } from "@mantine/core";
import styled from "styled-components";
import useUrlState from "@ahooksjs/use-url-state";

import { parseJwt } from "../../helpers/utils";
import { Step1 } from "./Step1";
import Step2 from "./Step2";
import { Step3 } from "./Step3";
import { Client, Company } from "../../interfaces";
import { COMPANY_URL } from "../../constains";
import { Token } from "../../helpers/token";
import { apiGet } from "../../helpers/api";
import { useLocation } from "react-router-dom";

export const Relatorio = (): React.ReactElement => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const step = parseInt(queryParams.get("step") ?? "0", 10);

  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [active, setActive] = useState(step);
  const [company, setCompany] = useState<Company>();
  const { companyId } = Token.getTokenData();
  const [mes, setMes] = useState<number>(new Date().getMonth());
  const [ano, setAno] = useState<number>(new Date().getFullYear());
  const [inspection, setInspection] = useState<any[]>([]);
  const [os, setOs] = useState<unknown[]>([]);
  const [dataValue, setDataValue] = useState<Date | null>(null);
  const [state, setState] = useUrlState<any>({});

  const inspectionsIds = inspection.map((value: any) => {return value.id}) || [];


  useEffect(() => {
    apiGet(`${COMPANY_URL}/${companyId}`, setCompany);
  }, []);

  const clients = React.useMemo(() => {
    return company?.clients ?? [];
  }, [company]);

  const options: { label: string; value: string | undefined }[] =
    React.useMemo(() => {
      return clients?.map((d: Client) => {
        delete d.equipment;
        return { label: d?.name, value: d?.id };
      });
    }, [clients]);

  const [selectedClient] = React.useMemo(
    () => clients?.filter((client) => client?.id == selectedClientId),
    [clients, selectedClientId]
  );

  const nextStep = () => {
    setState((prev: any) => ({
      ...prev,
      step: active < 3 ? active + 1 : active,
      inspection: JSON.stringify(inspectionsIds),
      clienteName: selectedClient?.name
    }));

    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => {
    setState((prev: any) => ({
      ...prev,
      step: active > 0 ? active - 1 : active,
    }));

    setActive((current) => (current > 0 ? current - 1 : current));
  };


  return (
    <Container>
      <div className="header">
        <Stepper active={active} onStepClick={setActive}>
          <Stepper.Step label="Tipo" description="OS / Inspeção">
            <Step1
              selectedClient={selectedClient}
              setSelectedClientId={setSelectedClientId}
              options={options}
              mes={mes}
              setAno={setAno}
              setMes={setMes}
              ano={ano}
              inspection={inspection}
              os={os}
              setOs={setOs}
              setInspection={setInspection}
              dataValue={dataValue}
              setDataValue={setDataValue}
            />
          </Stepper.Step>
          <Stepper.Step label="Formato" description="Estilo do Relatório">
            <Step2 
            inspection={inspection}
              os={os}
              setOs={setOs}
              setInspection={setInspection} />
          </Stepper.Step>
          {/* <Stepper.Step label="Relatório" description="">
            <Step3 inspections={inspection} os={os} />
          </Stepper.Step> */}
          <Stepper.Completed>
            <Step3 inspections={inspection} os={os} />
          </Stepper.Completed>
        </Stepper>
      </div>

      {/* @ts-ignore */}
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Voltar
        </Button>

        {active < 2 ?
          <Button onClick={nextStep}>Próximo</Button>

          : <></>}

      </Group>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 20px;

  .header {
    padding: 10px 30px;
  }
`;
