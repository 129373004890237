import React, { memo } from "react";
import styled from "styled-components";
import { Button as ButtonMantine, Text } from "@mantine/core";
import {
  removeStorageLocalData,
  updateStorageLocalData,
  useLocalStorage,
} from "../../../helpers/utils";
import { IconAlertTriangle } from "@tabler/icons-react";
import { Token } from "../../../helpers/token";
import { BsTrash } from "react-icons/bs";
import { Button, Modal, Nav } from "react-bootstrap";
import { modals } from "@mantine/modals";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, rem } from "@mantine/core";
import { IconFile, IconPhoto } from "@tabler/icons-react";
import { ImageOffline } from "./ImageOffline";

interface Props {
  chave: string;
  forceUpdate: boolean;
  setForceUpdate: any;
  Inspectionmodel: {
    companyId: string;
    createAt: string;
    formData: object;
    id: string;
    jsonSchema: object;
    title: string;
    uiSchema: object;
    updatedAt: string;
  };
}

export const InspectionsOffline = memo(({
  chave,
  forceUpdate,
  Inspectionmodel,
  setForceUpdate
}: Props) => {
  const { deletarInspection } = Token.getTokenData();
  const [loading2, setLoading2] = React.useState(false);
  const dados = useSelector((data: any) => data.offline);
  // console.log("online", dados);
  const [selectedInspection, setSelectedInspection] = React.useState<any>(null);
  const dispatch = useDispatch();

  const setImage64 = (image: string) => {
    setSelectedInspection((prev: any) =>

    ({
      ...prev,
      data: {
        ...prev?.data,
        images64: [
          ...prev?.data?.images64,
          image
        ]
      },
    }
    )
    );
  };

  // console.log(selectedInspection)

  const deleteImage64 = (indexToRemove: number) => {
    console.log("remove", {
      ...selectedInspection,
      data: {
        ...selectedInspection?.data,
        images64: [
          ...selectedInspection?.data?.images64?.filter(
            (value: any, index: any) => index !== indexToRemove
          ),
        ],
      },
    });

    setSelectedInspection((prev: any) => ({
      ...prev,
      data: {
        ...prev?.data,
        images64: [
          ...prev?.data?.images64?.filter(
            (value: any, index: any) => index !== indexToRemove
          ),
        ],
      },
    }));
  };


  // console.log(selectedInspection)
  // console.log(chave)
  // console.log(Inspectionmodel)


  const data = dados[chave];
  const {
    // data,
    deleteLocalStorageData,
    loading,
    setData,
    updateToServerLocalData,
  } = useLocalStorage(chave, dispatch);

  const upToServer = () => {
    setLoading2(true);
    updateToServerLocalData(() => { setLoading2(false)});

  };

  // Editar dados do local storage
  const onSubmit = async () => {
    const { id, data, formData } = selectedInspection;

    const result: any = await updateStorageLocalData(
      chave,
      id,
      selectedInspection,
      () => { },
      dispatch
    );
    setData(result);

    modals.closeAll();
    setSelectedInspection(null);
  };
  const iconStyle = { width: rem(20), height: rem(20) };

  if (data?.length < 1 || data == null || data == undefined) return null;

  // document.querySelector(".btn-info")?.remove();

  // console.log('data')
  // console.log(data)



  return (
    <Container>
      <h1>
        <IconAlertTriangle color="red" size={50} /> Modificações locais{" "}
        {/* <Text fs="italic" size="xs" c="red">
          selecione o botão abaixo para sincronizar as modificações
        </Text> */}
      </h1>

      {/* <ButtonMantine loading={loading || loading2} onClick={upToServer}>
        Atualizar modificações
      </ButtonMantine> */}

      {data?.map((d: any, i: any) => (
        <div key={i}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Button
              variant="light"
              style={{ width: "100%", margin: "6px" }}
              onClick={() => {
                setSelectedInspection(d);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* {inspectionSelect?.[0]?.title} */}
                {d.id}
              </div>
            </Button>

            <div
              style={{
                margin: "7px 0px 0px 0px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                variant="danger"
                style={{
                  maxHeight: "40px",
                  display: deletarInspection === true ? "flex" : "none",
                }}
                onClick={() => {
                  // setIdDelete(value?.id);
                  // setShow3(!show3);
                  deleteLocalStorageData(d.id);
                }}
              >
                <BsTrash />
              </Button>
            </div>
          </div>
        </div>
      ))}

      {selectedInspection &&
        modals.open({
          size: "80%",
          title: "Inspeção",
          children: (
            <>
              <Tabs defaultValue="dados">
                <Tabs.List>
                  <Tabs.Tab value="dados" icon={<IconFile style={iconStyle} />}>
                    Dados
                  </Tabs.Tab>



                  {/* {selectedInspection?.method == 'POST' ? */}
                  <Tabs.Tab
                    value="midia"
                    icon={<IconPhoto style={iconStyle} />}
                  >
                    Imagens
                  </Tabs.Tab>
                  {/* : ''} */}



                </Tabs.List>

                <Tabs.Panel value="dados">
                  <HideSubmit>
                    <Form
                      schema={Inspectionmodel?.jsonSchema}
                      validator={validator}
                      formData={selectedInspection.data.formData}
                      onChange={(e: any) => {
                        setSelectedInspection((prev: any) => ({
                          ...prev,
                          data: { ...prev?.data, formData: e.formData },
                        }));
                      }}
                      // onSubmit={(e) => {
                      //   onSubmit();
                      // }}
                      uiSchema={Inspectionmodel?.uiSchema}
                      // uiSchema={{
                      //   ...Inspectionmodel?.uiSchema,
                      //   ...customUiSchema,
                      // }}
                      disabled={false}
                    // key={key}
                    />
                  </HideSubmit>
                </Tabs.Panel>

                <Tabs.Panel value="midia">
                  <ImageOffline
                    data={selectedInspection}
                    setImage64={setImage64}
                    deleteImage64={deleteImage64}
                  />
                </Tabs.Panel>
              </Tabs>

              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  modals.closeAll();
                  onSubmit();
                  setSelectedInspection(null);
                }}
              // mt="md"
              >
                Salvar
              </Button>

              <Button
                variant="secondary"
                onClick={() => {
                  modals.closeAll();
                  setSelectedInspection(null);
                }}
              // mt="md"
              >
                Close
              </Button>
            </>
          ),
        })}
    </Container>
  );
})

const HideSubmit = styled.div`
  .btn-info {
    display: none;
  }
`;

const Container = styled.div`
  border: 1px dashed red;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 5px;
`;
