
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { getBase64FromImage } from "../../../helpers/utils";
import { useDropzone } from "react-dropzone";
import { FcGallery, FcPlus } from "react-icons/fc";
import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineCamera } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import { getPlatforms } from "@ionic/react";
// import { Camera, CameraResultType } from '@capacitor/camera';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { saveAs } from 'file-saver';
import { BsDownload } from 'react-icons/bs';
import { Button } from "react-bootstrap";
import { UpdateInspection } from "../api";
import { useParams } from "react-router-dom";
import { AiOutlineFileSync } from "react-icons/ai";

import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/dist/styles.css";
import Resizer from "react-image-file-resizer";



const MultipleImages = React.memo(
  ({
    image,
    setImage,
    mode1,
    imageEdit,
    setImageEdit,
    disable,
    mode2,
    mode,
    setDisable,
    setMode,
    sync,
    setSync
  }: {
    image: any;
    setImage: (value: unknown) => void;
    mode1: string;
    imageEdit: any;
    setImageEdit: any;
    disable: Boolean;
    mode2: string;
    mode: boolean;
    setDisable: any;
    setMode: any;
    sync: any,
    setSync: any

  }) => {

    let { id }: any = useParams();

    const handleDownloadImages = (images: any) => {
      images?.forEach((image: any, index: any) => {
        const format = image.split(',')[0].match(/:(.*?);/)[1];
        const byteCharacters = atob(image.split(',')[1]);
        const byteNumbers = new Array(byteCharacters?.length);
        for (let i = 0; i < byteCharacters?.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: `${format}` });
        saveAs(blob,
          "imagem"
        );
      });
    };



// console.log(image)



    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const handleButtonClick = () => {
      // @ts-ignore
      fileInputRef.current.click();
    };
    const handleButtonClick2 = () => {
      // @ts-ignore
      fileInputRef.current.click();
    };
    const handleFileChange = (ev: any) => {
      onDrop(Array.from(ev?.target?.files))
    };


    const fileSubmitHandler = (files: unknown[]) => {



      for (let i = 0; i < files?.length; i++) {

        getBase64FromImage(files[i], onLoad);
      }


    };

    const onLoad = (fileString: any) => {
      if (mode1 == "add") {
        setImage((prevState: any) => {
          // console.log('prev', prevState)
          return [...prevState, fileString]
        })
      }
    };

    // console.log()
    // console.log(image)

    const onDrop = useCallback((acceptedFiles) => {

      let files: string[] = [];
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
        };

        reader.readAsArrayBuffer(file);
        files = [...files, file];
      });

      fileSubmitHandler(files);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      // accept: 'image/*'
      onDrop
    });



    const platform = getPlatforms();

    // const takePicture = async () => {
    //   const imageCam = await Camera.getPhoto({
    //     quality: 100,
    //     allowEditing: true,
    //     resultType: CameraResultType.DataUrl
    //   });


    //   imageUrl.push(imageCam.dataUrl)



    //   if (mode1 == "add") {
    //     setImage((prev: any) =>
    //       [...prev, imageUrl[0]]
    //     )
    //   };

    //   if (mode2 == 'edit') {
    //     setImageEdit((prev: any) =>
    //       [...prev, imageUrl[0]]
    //     )
    //   }

    // }





    const [open, setOpen] = React.useState(false);
    const [theImage, setTheImage] = React.useState<any>(null);


    // defineCustomElements()

    return (
      <Container>




        {!platform.includes("desktop") ?

          (disable == true ? "" :
            (
              (mode == true ?
                <>
                  <Button
                    variant="primary"
                    onClick={handleButtonClick}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '80%',
                      // border: '2px solid',
                      padding: '15px 10%',
                      margin: '0px 0px 10px 0px'
                    }}>
                    Capturar da Câmera
                    <AiOutlineCamera
                      style={{
                        margin: '0px 0px 0px 10px',
                        height: '35px',
                        width: '35px',
                      }}
                      color="white"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      capture='environment'
                      id='ForInputCam'
                      multiple
                      // onChange={(ev: any) => { onDrop(Array.from(ev.target.files)) }}
                      ref={fileInputRef}
                      onChange={handleFileChange}

                      onClick={(ev: any) => {
                        ev.target.value = ''
                      }}
                    />
                  </Button>
                </>
                : <></>)










            )


          )
          :
          <>

          </>

        }
        {disable == true ? "" :

          (!platform.includes("desktop") ?

            (mode == true ? <Button
              // onClick={handleButtonClick2}
              variant="dark"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                // border: '2px solid black',
                padding: '15px 10%',
                color: 'white'
              }}>





              {/* <TfiGallery
              style={{
                margin: '0px 0px 0px 10px',
                height: '35px',
                width: '35px',

              }}
              color='white'
            />

            <input
             type="file"
             accept="image/*"
             capture="environment"

              id='ForInput'
              multiple
              onChange={handleFileChange}
              ref={fileInputRef2}
              onClick={(ev: any) => {
                ev.target.value = ''
              }}
            /> */}








              <div>

                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Selecionar Imagem</p>

                </div>

              </div>
              <TfiGallery
                style={{
                  margin: '0px 0px 0px 10px',
                  height: '35px',
                  width: '35px',

                }}
                color='white'
              />

            </Button>
              : <></>)







            :


            // ===============================================================================================================================





            (mode == true ?

              <div className="drop-area"
                {...getRootProps()}>



                {image?.length > 0 ?
                  <p style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px'
                  }}>{image?.length} arquivos carregados</p>
                  :
                  (
                    <div>
                      <div
                        style={{
                          height: '100px',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center'

                        }}>
                        <div>Arraste ou click aqui</div>
                        {<FcPlus />}
                      </div>
                    </div>
                  )}
              </div>

              :
              <>
              </>
            )
          )



        }









        {/* ===================================================xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx====================================================== */}




        <>


          <Lightbox

            open={open}
            close={() => setOpen(false)}

            slides={[
              {
                src: theImage,
                alt: "image",
                width: 3840,
                height: 2560,

              }
            ]}



          />


        </>

















        <hr color="gray" style={{ margin: '10px' }} />

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%'
        }}>



          <div style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}>






            {/* ======================================================= */}



            {/* <AiOutlineFileSync
            style={{cursor: 'pointer'}}
            size={30} onClick={() => {setSync(!sync)}} /> */}






            {image?.length > 0 ?
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                cursor: 'pointer',
                marginLeft: '20px',
                // height: '300px',
                // width: '100%'
              }}


              >

                <BsDownload style={{
                  height: '30px',
                  width: '30px',
                  margin: '0px 0px'
                }}

                  onClick={() => {
                    handleDownloadImages(image)
                  }}
                />

                <p style={{
                  fontSize: '13px',
                  margin: '0px 30px 0px 0px'
                }}>{image?.length}</p>

              </div>
              : ''}

          </div>



          {/* hahaahaha */}


          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
          }}>

            {image?.length > 0 ?

              (image?.map((value: any, index: any) => {
                return (
                  <div

                    style={{
                      position: 'relative',
                      display: "flex",
                      flexDirection: 'row',
                      flexWrap: 'wrap',

                    }}

                    key={index}>


                    {mode == true ? <div style={{
                      backgroundColor: '#070707',
                      height: '30px',
                      width: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      left: '5px',
                      top: '5px',
                      border: '1px solid white'
                    }}>

                      <FaTrashAlt style={{
                        // border: '1px solid white',
                        height: '24px',
                        width: '24px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        // position: 'absolute',
                        // left: '5px',
                        // top: '5px'
                      }}
                        onClick={() => {




                          const select = image.splice(image.indexOf(value), 1)[0]


                          const final = image.filter((value2: any) => {
                            return value2 !== select
                          })





                          setImage(

                            final

                          )


                        }} />


                    </div>
                      :


                      <>

                      </>


                    }



                    <div

                      style={{
                        cursor: 'pointer'

                      }}
                      onClick={(ev: any) => {
                        setOpen(true); setTheImage(value)

                        // if (ev.target.className === "imagem_normal") {
                        //   return ev.target.className = "imagem_expandida"
                        // }

                        // if (ev.target.className === "imagem_expandida") {
                        //   return ev.target.className = "imagem_normal"
                        // }

                      }}>


                      <div style={{ maxWidth: '270px', height: 'auto', margin: '10px 10px 10px 10px' }}>
                        <img
                          src={value}
                          alt="Descrição da imagem"
                          style={{ width: '100%', height: 'auto' }}
                        />
                      </div>


                      {/* <img alt="imagem" src={value}
                      // className="imagem_normal"
                      style={{

                        margin: '7px',

                      }}

                    /> */}

                    </div>
                  </div>
                )
              })) : 'Essa inspeção não tem imagens adicionadas ou  está em modo offline'}

          </div>




        </div>

        {mode == true ? <div

          style={{
            margin: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',

          }}>

          <Button
            style={{ width: '200px' }}
            onClick={() => {

              UpdateInspection(id, { images64: image });
              setDisable(!disable);
              setMode(!mode)
            }}
          >
            Salvar
          </Button>

        </div> : <></>}



      </Container >
    );
  }
);
export default MultipleImages;

const Container = styled.div`
display: flex; 
justify-content: center;
align-items: center;
flex-direction: column;
  .drop-area {
    border: 1px dashed;
    width: 50%;
    /* height: 50%; */
    margin-bottom: 40px;
  }
`;
export { MultipleImages };
