import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./Relatorio";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import { IInspection, Os } from "../../../interfaces";
import { CopyButton, Button } from '@mantine/core';






const Print = () => {
  const componentRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tipo = queryParams.get("tipo");
  const clienteId = queryParams.get("clientId") || 'null';
  const data = new Date()
  const href = window.location.href.toString();



  return (
    <div
      style={{
        // overflowY: 'scroll',
        height: "100%",
        // backgroundColor: '#11111126'
      }}
    >

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '30px', alignItems: 'center', flexDirection: 'row' }}>






        {clienteId !== 'null' ?
          <ReactToPrint
            // @ts-ignore
            options={{
              margin: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              },
              orientation: "portrait",
              pageSize: "auto",
            }}
            documentTitle={`Relatório de ${tipo} ( ${moment(data).format("DD/MM/YYYY")} )`}
            trigger={() => <Button style={{ width: '250px' }} variant="filled">Imprimir</Button>}
            content={() => componentRef.current}
            copyStyles={false}
            pageStyle="
        @media print {  
          body {
            width: 1050px;  
          }
          .textCapa {
            // max-width: 200px;
            background-color: red;
          }
         .title {
          font-size: 30px;
         }
         .subTitle {
          font-size: 25px;
         }
         .font {
          font-size: 17px;
         }
         .noborder {
          border: 0px!important;
         }
         .containerFoto {
          // display: grid;
          // grid-template-columns: repeat(2, 1fr);
          // grid-gap: 10px;
          // grid-auto-flow: dense;
          // justify-items: stretch;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center;
          grid-gap: 15px;
         }
         .fotos {
          margin-bottom: 15px;
          // width: 270px;
          max-height: 750px;
         },
          }
          @page{
            size: portrait;
            // margin: 1mm 1mm 1mm 1mm;
          }
            "
          />
          :
          <></>
        }

{clienteId !== 'null' ?

        <div style={{ margin: '0px 0px 0px 30px' }}>

          <CopyButton value={`${href.replace("relatorio", "link")}`}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Link copiado' : 'Copiar link'}
              </Button>
            )}
          </CopyButton>

        </div>
: <></>}




      </div>

      <div style={{}}>
        {/* @ts-ignore */}
        <ComponentToPrint ref={componentRef} />

      </div>
      {/* <Button onClick={handleDownload}>PDF</Button> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: "100%",
          justifyContent: "center",
          marginTop: "100px",
          marginBottom: "70px",
          // height
          // flexDirection:'column'
        }}
      >
        {/* <Button onClick={handleDownload}>PDF</Button> */}
        {/* {clienteId !== 'null' ?
          <ReactToPrint
            // @ts-ignore
            options={{
              margin: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              },
              orientation: "portrait",
              pageSize: "auto",
            }}
            documentTitle={`Relatório de ${tipo} ( ${moment(data).format("DD/MM/YYYY")} )`}
            trigger={() => <Button style={{ width: '250px' }} variant="primary">Imprimir</Button>}
            content={() => componentRef.current}
            copyStyles={false}
            pageStyle="
        @media print {  
          body {
            width: 1050px;  
          }
         .title {
          font-size: 30px;
         }
         .subTitle {
          font-size: 25px;
         }
         .font {
          font-size: 17px;
         }
         .noborder {
          border: 0px!important;
         }
         .containerFoto {
          // display: grid;
          // grid-template-columns: repeat(2, 1fr);
          // grid-gap: 10px;
          // grid-auto-flow: dense;
          // justify-items: stretch;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center;
          grid-gap: 15px;
         }
         .fotos {
          margin-bottom: 15px;
          // width: 270px;
          max-height: 750px;
         },
          }
          @page{
            size: portrait;
            // margin: 2mm 2mm 2mm 0mm;
          }
            "
          />
          :
          <></>
        } */}
      </div>
    </div>
  );
};

export default Print;
