/* eslint-disable no-unused-vars */
import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SelectTheme from "../styles/themes/selectTheme";
import ButtonExit from "./ExitButton";
import { useSelector, useDispatch } from "react-redux";

import { parseJwt } from "../helpers/utils";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  getPlatforms,
} from "@ionic/react";


export default function DashView({ cliente, title, setTheme, ...props }: any) {
  const history = useHistory();
  const token: any = window.localStorage.getItem("accessToken");
  const platform = getPlatforms();
  const decoded = parseJwt(token);
  const urls: any = "/admin";
  const online = useSelector(({ status }) => status?.online);

  // function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
  //   // window.location.reload();

  // }

  return (
    <IonPage>
      <IonHeader>
        {online ? null : (
          <div
            style={{
              background: "red",
              color: "white",
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
            }}
          >
            você está no modo offline
          </div>
        )}
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            {decoded?.payload?.admin === true &&
            window.location.href.includes(urls) === !true ? (
              <Admin onClick={() => history.push("/admin/usuarios")}>
                Admin
              </Admin>
            ) : (
              ""
            )}{" "}
            {decoded?.payload?.admin === true &&
            window.location.href.includes(urls) === true ? (
              <Admin onClick={() => history.push("/dashboard")}>
                Dashboard
              </Admin>
            ) : (
              ""
            )}
            {/* @ts-ignore */}
            <SelectTheme setTheme={setTheme} />
            <ButtonExit />
          </IonButtons>

          <IonTitle>
            {title}

            {/* {`
          ${title} 
           ${title == 'Inspeção' 
          ?
              (cliente == undefined ? '' :
                (cliente !== null ? (cliente !== null ? ' - ' : '') + cliente : ''))
              : ''
            } 
            `} */}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="shadow-root">
        {/* {!platform.includes("desktop") ? (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
          </>
        ) : null} */}

        <ParaScroll
        //  id='overParaFlow'
        >
          {React.cloneElement(props.children, { props })}
        </ParaScroll>
      </IonContent>
    </IonPage>
  );
}

const Admin = styled.button`
  background: none;
  color: var(--text-color);
  font-size: 30px;
  margin-left: 20px;

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
  @media (max-width: 300px) {
    font-size: 15px;
  }
`;

const ParaScroll = styled.div`
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #68696a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #5664e7;
  }
`;
