/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Card from "../../../components/card/card";
import styled, { useTheme } from "styled-components";
import { Sensor } from "../../../interfaces";
import { ImBin } from "react-icons/im";
import { AiTwotoneEdit } from "react-icons/ai";
import { ADMIN_URL_IMAGE } from "../../../constains";
import { CallNotification } from "../../../components/Alert";
import AdicionarSensor from "../../../components/AdicionarSensor";
import { useHistory } from "react-router-dom";
import { FaBars } from "react-icons/fa";

function Images() {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState<Sensor[]>([]);
  const [confirmar, setConfirmar] = useState<any>("");
  const { themeName } = useTheme();
  const [apagar, setApagar] = useState<any>("");
  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    getData();
  }, [confirmar]);

  const getData = () => {
    axios
      .get(`${ADMIN_URL_IMAGE}`, access)
      .then((res) => {
        setImage(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id: number) => {
    axios
      .delete(`${ADMIN_URL_IMAGE}/${id}`, access)
      .then((response) => {
        setApagar("foi deletado");
        CallNotification("Sucesso", "imagem deletada!", "success");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <AllContent
      className="content"
      style={{
        height: "calc(100vh - 60px)",
        display: "block",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Container fluid>
        <Row>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Col md={12}>
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {" "}
                      <Button
                        style={{ marginLeft: "1rem" }}
                        variant="primary"
                        onClick={handleShow}
                      >
                        Adicionar Image
                      </Button>
                    </div>
                    <ScrollDiv>
                      <Table
                        striped
                        hover
                        variant={themeName}
                        style={{ marginTop: "30px" }}
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Tipo de Imagem</th>
                            <th>imagem</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {image?.map((props: any, index) => {
                            const { id = "id" } = props;

                            return (
                              <tr className="hoverTr" key={index}>
                                <td>{props.id}</td>
                                <td>{props.imageType}</td>
                                <td>
                                  <img
                                    src={props.image64}
                                    style={{ height: "70px", width: "70px" }}
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Button
                                    className="lixeira responsiveAll"
                                    onClick={() => handleDelete(id)}
                                    variant="primary"
                                  >
                                    {
                                      <ImBin
                                        size="20px"
                                        className="responsive"
                                      />
                                    }
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </ScrollDiv>
                  </>
                }
              />
            </Col>
          </div>
        </Row>

        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar nova imagem</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#ededed" }}>
            <AdicionarSensor getData={getData} handleClose={handleClose} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </AllContent>
  );
}

const AllContent = styled.div`
  @media (max-width: 768px) {
    .editEdit {
      visibility: visible;
    }
    .lixeira {
      visibility: visible;
    }
    .view {
      visibility: visible;
    }
  }
  .hoverTr {
    @media (max-width: 768px) {
      .editEdit {
        visibility: visible !important;
      }
      .lixeira {
        visibility: visible !important;
      }
      .view {
        visibility: visible !important;
      }
    }
    .rowTd {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
    .lixeira {
      visibility: hidden;
      cursor: pointer;
    }
    .editEdit {
      visibility: hidden;
      cursor: pointer;
    }
    .view {
      visibility: hidden;
      cursor: pointer;
    }
    :hover {
      .lixeira {
        visibility: visible;
        color: white;
      }
      .editEdit {
        visibility: visible;
        color: white;
      }
      .view {
        visibility: visible;
        color: white;
      }
    }
  }
`;

const ScrollDiv = styled.div`
  overflow: auto;
  max-width: 1600px;
  max-height: 450px;

  @media (max-width: 1000px) {
    max-width: 900px;
    max-height: 450px;
  }
  @media (max-width: 900px) {
    max-width: 800px;
    max-height: 450px;
  }
  @media (max-width: 800px) {
    max-width: 700px;
    max-height: 450px;
  }
  @media (max-width: 700px) {
    max-width: 600px;
    max-height: 450px;
  }
  @media (max-width: 600px) {
    max-width: 500px;
    max-height: 450px;
  }
  @media (max-width: 550px) {
    max-width: 430px;
    max-height: 450px;
  }
  @media (max-width: 500px) {
    max-width: 320px;
    max-height: 450px;
  }
  @media (max-width: 400px) {
    max-width: 280px;
    max-height: 450px;
  }
  @media (max-width: 370px) {
    max-width: 260px;
    max-height: 450px;
  }
  @media (max-width: 300px) {
    max-width: 180px;
    max-height: 450px;
  }
`;
export default React.memo(Images);
