import styled from 'styled-components';
import Switch, { ReactSwitchProps } from 'react-switch';

export const ToggleSelector = styled(Switch).attrs<ReactSwitchProps>(
    ({ theme }) => ({
        onColor: theme.colors.info,
        // offColor: theme.colors.black,
        height: 20,
        width: 50,


    })
) <ReactSwitchProps>`
color: var(--color1);
margin: 0 7px;
/* bottom: -0.1rem; */

`;

export const Container = styled.div`

`;
export const FontColor = styled.span`
color: var(--color3)
`;