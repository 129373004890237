import { Button } from "./style";
import { useHistory } from "react-router-dom";

function ExitButton() {
  const history = useHistory();
  const exit = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("theBranch");
    history.push("/");
    window.location.reload();
  };

  return <Button onClick={exit}>Sair</Button>;
}

export default ExitButton;
