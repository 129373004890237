import { Action } from "redux";

interface IStatusReducer {
  action: { type?: string; payload?: any };
  state: IStatus;
}
interface IStatus {
  online: boolean;
}

export const R_STATUS_INITIAL_STATE = {
  online: true,
};
export const statusReducer = (
  state = R_STATUS_INITIAL_STATE,
  action: Action
): IStatus => {
  const { type, payload = true } = action;
  switch (type) {
    case "NETWORK":
      return { ...state, online: payload };

    default:
      return state;
  }
};
