import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import "../../components/FilterOs/style.css";

export function Dropdown({ children }: any) {
  const [select, setSelect] = useState(false);
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
        onClick={() => setSelect(!select)}
        className="hr"
      >
        Filtro
        {select === false ? (
          <Button
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
            }}
            onClick={() => setSelect(!select)}
          >
            <IoIosArrowForward size={30} className="colorTheme" />
          </Button>
        ) : (
          <Button
            style={{ cursor: "pointer", backgroundColor: "transparent" }}
            onClick={() => setSelect(!select)}
          >
            <IoIosArrowDown size={30} className="colorTheme" />
          </Button>
        )}
      </div>

      <>{select === false ? "" : <div> {children}</div>}</>
    </div>
  );
}

const Button = styled.div`
  .colorTheme {
    color: var(--color1);
  }
`;
