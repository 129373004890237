import * as React from "react";
// import { OS_STATUS } from "../../../constains";
import { Table,
  //  Button, Col, Container, Row, Dropdown 
  } from "react-bootstrap";
import { useTheme } from "styled-components";
import { Os } from "../../../interfaces";
// import _ from "lodash";

const LatestOs = ({ oss = [] }: { oss: Os[] }) => {
  const theme = useTheme();
  const MAX_SIZE = 5;
  const ossLimit = oss.slice(0, MAX_SIZE);

  return (
    <>
      <Table striped bordered hover variant={theme.themeName}>
        <thead>
          <tr>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {ossLimit?.filter((value) => {return value.status !== 4})?.map((os, index) => {
       
            return (
              <tr key={index}>
                <td>{os?.title}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default React.memo(LatestOs);
