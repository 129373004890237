/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { Client } from "../../../interfaces";
import AdicionarClient from "./AdicionarClient";
import { CreateClient, GetClient } from "./api";
import ClientTable from "./ClientTable";
// import {
//   IonPage,
//   IonHeader,
//   IonToolbar,
//   IonButtons,
//   IonMenuButton,
//   IonTitle,
//   IonContent,
//   IonToggle,
// } from "@ionic/react";
// import SelectTheme from "../../../styles/themes/selectTheme";
// import { parseJwt } from "../../../helpers/utils";

export default function () {
  const [show, setShow] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client>({
    supervisor: "",
    cel: "",
    tel: "",
    email: "",
    address: "",
    cep: "",
    lat: "",
    lng: "",
    companyId: undefined
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const onSubmitHandler = () => {
    CreateClient(client);
    GetClient(setClients);
    handleClose();
  };

  useEffect(() => {
    GetClient(setClients);
  }, []);

  // const token = window.localStorage.getItem("accessToken") || "";

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Adicionar Client
      </Button>
      <ClientTable clients={clients} setClients={setClients} />
      <Modal
        title="adicionar cliente"
        show={show}
        setShow={setShow}
        onSubmitHandler={onSubmitHandler}
        content={<AdicionarClient client={client} setClient={setClient} />}
      />
    </>
  );
}
