import { combineReducers } from "redux";
import { statusReducer } from "./status";
import { apiDataOfflineReducer } from "./apiDataOffline";
// Importe seus redutores individuais aqui

interface ICounterReducer {
  type: string;
  payload?: any;
  state: any;
}

const counterReducer = (state = 0, action: any) => {
  switch (action.type) {
    case "INCREMENT":
      return state + 1;
    case "DECREMENT":
      return state - 1;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  counter: counterReducer,
  status: statusReducer,
  offline: apiDataOfflineReducer,
});

export default rootReducer;
