import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { apiGet, apiDelete } from "../../helpers/api";
import { FILE_DOWNLOAD_URL, FILE_URL } from "../../constains";
import { File } from "../../interfaces";
import MyDropzone from "./DropFile";
import { Token } from "../../helpers/token";
import Table from "./Table";
import { formatDistance, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment-timezone";
import { getPlatforms } from "@ionic/react";
import { Button } from "react-bootstrap";

export const ClientFiles = (props: any) => {
  const platform = getPlatforms();
  const history = useHistory();
  const [files, setFiles] = useState<File[]>([]);
  const { companyId } = Token.getTokenData();
  const { id }: { id: string } = useParams();
  const getData = () => apiGet(`${FILE_URL}?clientId=${id}`, setFiles);
  const deleteFile = (id: string) => apiDelete(FILE_URL, id, getData);

  React.useEffect(() => {
    getData();
  }, []);
  const formatedData = files.map((file) => ({
    ...file,
    createAt: moment(file.createAt).format("DD/MM/YYYY - HH:mm"),
  }));

  return (
    <>
      <Button
        onClick={() => {
          history.push("/files");
        }}
        variant="secondary"
        style={{
          textDecoration: "none",
          width: '100%',
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          margin: "0px 15px 0px 0px",
          fontSize: !platform.includes("desktop") ? "11px" : "16px",
        }}
      >
        Voltar
      </Button>
      <br />
      <MyDropzone clientId={id} companyId={companyId} getData={getData} />
      <br />
      <Table data={formatedData} deleteFile={deleteFile} />
    </>
  );
};
