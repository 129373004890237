import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdicionarUsuario.css";
import { CallNotification } from "../Alert";
import { ADMIN_URL, COMPANY_URL } from "../../constains";
import { Company, User } from "../../interfaces";
import styled from "styled-components";
import Select from "react-select";

interface Props {
  getData: (data: void) => void;
  handleClose: (data: void) => void;
}

function AdicionarUsuario({ getData, handleClose }: Props) {
  const [image, setImage] = useState<any>(null);
  const [companies, setCompanies] = useState<Company[]>([]);

  const [user, setUser] = React.useState<User>({
    name: "",
    email: "",
    password: "",
    agendarVisita: false,
    image: undefined,
    admin: false,
    companyId: undefined,

    criarOS: false,
    editarOS: false,
    deletarOS: false,
    visualizarOS: false,

    criarColaborador: false,
    editarColaborador: false,
    deletarColaborador: false,
    visualizarColaborador: false,

    criarCliente: false,
    editarCliente: false,
    deletarCliente: false,
    visualizarCliente: false,  
    
    criarInspection: false,
    editarInspection: false,
    deletarInspection: false,
    visualizarInspection: false,   
    
    
    criarModelo: false,
    editarModelo: false,
    deletarModelo: false,
    visualizarModelo: false,

  });

  const companyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  useEffect(() => {
    getAllCompany();
  },[]);

  const getAllCompany = () => {
    axios
      .get(COMPANY_URL, access)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onChange = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const onLoad = (fileString: any) => {
    setImage(fileString);
  };

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    let _user = user;
    if (image)
      _user.image = { create: { image64: image, imageType: "Profile" } };

    axios
      .post(ADMIN_URL, _user, access)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          CallNotification("Sucesso", "usuario cadastrado", "success");
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
        CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      });
  };

  return (
    <DivGeral>
      <form onSubmit={onSubmitHandler}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
            }}
          >
            <label>Nome:</label>

            <Input
              type="text"
              name="login"
              value={user.name}
              onChange={(event) => {
                event.persist();

                setUser((prevState) => ({
                  ...prevState,
                  name: event.target.value,
                }));
              }}
              required
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
            }}
          >
            <label>Email:</label>
            <Input
              type="text"
              name="email"
              value={user.email}
              onChange={(event) => {
                event.persist();
                setUser((prevState) => ({
                  ...prevState,
                  email: event.target.value,
                }));
              }}
            ></Input>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
            }}
          >
            <Span>password:</Span>
            <Input
              name="password"
              type="password"
              value={user.password}
              onChange={(event) => {
                event.persist();
                setUser((prevState) => ({
                  ...prevState,
                  password: event.target.value,
                }));
              }}
            ></Input>

            <Select
              // defaultValue={options[0]}
              isClearable={true}
              isSearchable={true}
              name="company"
              options={companyOptions}
              onChange={(event: any) => {
                //@ts-ignore
                setUser((prevState) => ({
                  ...prevState,
                  companyId: event.value,
                }));
              }}
            />
          </div>

          <DivRow>
            <DivColumn>
              <Span>Criar OSs: </Span>

              <SelectComponent
                value={user.criarOS === true ? "1" : "0"}
                onChange={(event) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    criarOS: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Editar OSs:</Span>

              <SelectComponent
                value={user.editarOS === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    editarOS: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Visualizar todas as OS</Span>
              <SelectComponent
                value={user.visualizarOS === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    visualizarOS: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Deletar OS</Span>
              <SelectComponent
                value={user.deletarOS === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    deletarOS: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>



            {/* <DivColumn>
              <Span>Agendar Visita:</Span>

              <SelectComponent
                value={user.agendarVisita === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn> */}
          </DivRow>

          <DivRow>

            <DivColumn>           
              <Span>Criar Colaborador</Span>
              <SelectComponent
                value={user.criarColaborador === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    criarColaborador: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Editar Colaborador</Span>
              <SelectComponent
                value={user.editarColaborador === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    editarColaborador: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

        

            <DivColumn>           
              <Span>Visualizar Colaborador</Span>
              <SelectComponent
                value={user.visualizarColaborador === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    visualizarColaborador: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Deletar Colaborador</Span>
              <SelectComponent
                value={user.deletarColaborador === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    deletarColaborador: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          </DivRow>  
          
          <DivRow>

            <DivColumn>           
              <Span>Criar Cliente</Span>
              <SelectComponent
                value={user.criarCliente === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    criarCliente: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Editar cliente</Span>
              <SelectComponent
                value={user.editarCliente === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    editarCliente: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

        

            <DivColumn>           
              <Span>Visualizar Cliente</Span>
              <SelectComponent
                value={user.visualizarCliente === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    visualizarCliente: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Deletar Cliente</Span>
              <SelectComponent
                value={user.deletarCliente === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    deletarCliente: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          </DivRow>







              
          <DivRow>

            <DivColumn>           
              <Span>Criar Inspeção</Span>
              <SelectComponent
                value={user.criarInspection === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    criarInspection: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Editar Inspeção</Span>
              <SelectComponent
                value={user.editarInspection === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    editarInspection: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

        

            <DivColumn>           
              <Span>Visualizar Inspeção</Span>
              <SelectComponent
                value={user.visualizarInspection === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    visualizarInspection: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          

            <DivColumn>           
              <Span>Deletar Inspeção</Span>
              <SelectComponent
                value={user.deletarInspection === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    deletarInspection: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

          </DivRow>





          <DivRow>
            <DivColumn>
              <Span>Criar Modelo: </Span>

              <SelectComponent
                value={user.criarModelo === true ? "1" : "0"}
                onChange={(event) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    criarModelo: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Editar Modelo:</Span>

              <SelectComponent
                value={user.editarModelo === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    editarModelo: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Visualizar Modelo</Span>
              <SelectComponent
                value={user.visualizarModelo === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    visualizarModelo: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>

            <DivColumn>
              <Span>Deletar Modelo</Span>
              <SelectComponent
                value={user.deletarModelo === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    deletarModelo: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn>



            {/* <DivColumn>
              <Span>Agendar Visita:</Span>

              <SelectComponent
                value={user.agendarVisita === true ? "1" : "0"}
                onChange={(event: any) => {
                  event.persist();
                  setUser((prevState) => ({
                    ...prevState,
                    agendarVisita: event.target.value === "1" ? true : false,
                  }));
                }}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </SelectComponent>
            </DivColumn> */}
          </DivRow>



          {/* Pega imagem */}

          <DivColumn>
            <Span>Foto de perfil:</Span>
            <input
              style={{ width: "auto", height: "auto" }}
              type="file"
              onChange={(e: any) => {
                e.persist();
                onChange(e);
              }}
            />
          </DivColumn>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              style={{
                width: "20px",
                height: "20px",
                margin: "10px 10px 10px 0",
              }}
              className="Check"
              checked={user.admin}
              onChange={(event) =>
                setUser((prevState) => ({
                  ...prevState,
                  admin: !user.admin,
                }))
              }
              type="checkbox"
            ></input>

            <Span className="Position">Admin</Span>
          </div>

          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => handleClose()}
          >
            Confirmar
          </button>
          {/* <img
            src={ImgDoK}
            alt="teste poha"
            id="imageid"
            style={{ height: "200px", width: "200px" }}
          /> */}
        </div>
      </form>
    </DivGeral>
  );
}

const DivGeral = styled.div`
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  font-size: 16px;
  border: 2px solid #a9a9a9;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
const Input = styled.input`
  border: 1px solid;
  border-radius: 30px;
  padding: 5px;
  font-size: 14px;
  width: 88%;
  margin: 6px 40px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const SelectComponent = styled.select`
  height: auto;
  width: auto;
  padding: 4px;
  margin: 0px 0px 30px 0px;
`;
const Span = styled.span`
  margin: 10px 0px;
  font-size: 10px;
`;

export default AdicionarUsuario;
