import * as React from "react";
// import { OS_STATUS } from "../../../constains";
import { Table,
  //  Button, Col, Container, Row, Dropdown 
  } from "react-bootstrap";
import { useTheme } from "styled-components";
import { Os } from "../../../interfaces";
import _ from "lodash";
import { OS_STATUS } from "../../../constains";

const TopUsersOs = ({ oss = [] }: { oss: Os[] }) => {
  const theme = useTheme();
  const countsUsers = {};

  for (const os of oss) {
    const { users = [] } = os;
    for (const user of users) {
      //@ts-ignore
      countsUsers[user?.id] = countsUsers[user?.id]
        ? { count: countsUsers[user?.id]?.count + 1, name: user.name }
        : { count: 1, name: user.name };
    }
  }

  const Users = _.sortBy(_.values(countsUsers), ["count"]).reverse();


  // console.log(oss)

  return (
    <>
      <Table striped bordered hover variant={theme.themeName}>
        <thead>
          <tr>
            <th>User</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Users.map((user, index) => {
            const { name = "", count = 0 } = user;
            return (
              <tr key={index}>
                <td>{name}</td>
                <td>{count}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default React.memo(TopUsersOs);
