/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CiCirclePlus } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";
import { Token } from "../../helpers/token";
import axios from "axios";
import { ADMIN_BRANCH_URL } from "../../constains";
import { CallNotification } from "../../components/Alert";
import { Checkbox, MultiSelect, Select, Tooltip } from "@mantine/core";
import { Spin } from "react-cssfx-loading";
import { FcInfo } from "react-icons/fc";





export default function Empresa() {
    const [image, setImage] = useState<any>(null);
    const [empresa, setEmpresa] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);
    const fileInputRef = useRef(null);
    const access = Token.getAccess();
    const idEm = Token.getTokenData()?.companyId;

    const handleButtonClick = () => {
        // @ts-ignore
        fileInputRef.current.click();
    };
    const onLoad = (fileString: any) => setImage(fileString);
    const getBase64 = (file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onLoad(reader.result);
    };
    const onChangeGetImage = (e: any) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };
    const handleFileChange = (e: any) => {
        e.persist();
        onChangeGetImage(e);
    };
    useEffect(() => {
        axios
            .get(`${ADMIN_BRANCH_URL}/${idEm}`, access)
            .then((res) => {
                // console.log(res.data.data)
                setEmpresa(res?.data?.data);
                setImage(res?.data?.data?.image64);
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    const updateConfig = () => {
        const put = {
            name: empresa.name,
            cnpj: empresa.cnpj,
            email: empresa.email,
            phone: empresa.phone,
            address: empresa.address,
            image64: image,
            config: empresa.config
        }

        axios
            .put(`${ADMIN_BRANCH_URL}/${idEm}`, put, access)
            .then((res) => {
                CallNotification('Sucesso', 'Configurações edidatas com sucesso', 'success')
            })
            .catch((error) => {
                console.error(error);
                CallNotification('Erro', 'Configurações não foram edidatas com sucesso', 'danger')
            });
    }

    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                }}
            >
                <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
                Aguarde...
            </div>
        );
    // console.log(empresa)
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%', margin: '10px' }}>



                <Form autoComplete={"off"}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="email"
                                    placeholder="Nome da empresa"
                                    value={empresa?.name}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => { setEmpresa((prev: any) => { return { ...prev, name: ev.target.value } }) }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>CNPJ</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="CNPJ da empresa."
                                    value={empresa?.cnpj}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => { setEmpresa((prev: any) => { return { ...prev, cnpj: ev.target.value } }) }}
                                />
                                {/* <Form.Text className="text-muted">
              Pessoa responsavel pela parte tecnica da empresa.
            </Form.Text> */}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Número de telefone"
                                    value={empresa?.phone}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => { setEmpresa((prev: any) => { return { ...prev, phone: ev.target.value } }) }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Título do relatório"
                                    value={empresa?.config?.title}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, title: ev.target.value } } });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="email"
                                    placeholder=" Informe e-mail da empresa"
                                    value={empresa?.email}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => { setEmpresa((prev: any) => { return { ...prev, email: ev.target.value } }) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Endereço da empresa"
                                    value={empresa?.address}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => { setEmpresa((prev: any) => { return { ...prev, address: ev.target.value } }) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Logo</Form.Label>



                                {image ?

                                    <div style={{
                                        // marginTop: '-20px',
                                        borderRadius: '20px',
                                        width: 'auto%',
                                        height: '300px',
                                        maxHeight: '300px',
                                        minHeight: '300px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid black',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}

                                    >
                                        <input
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            type="file"
                                            id='ForInputCam2'
                                            onClick={(ev: any) => {
                                                ev.target.value = ''
                                            }}
                                        />

                                        <img
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain',
                                                // borderRadius: '30px',
                                            }}
                                            onClick={handleButtonClick}
                                            src={image} />

                                        <div
                                            onClick={() => { setImage(null) }}
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '10px',
                                                cursor: 'pointer'
                                            }}>
                                            <TbTrashXFilled size={40} />
                                        </div>

                                    </div>

                                    :



                                    // sem imagem selecionada

                                    <div
                                        onClick={handleButtonClick}
                                        style={{
                                            borderRadius: '20px',
                                            width: '100%',
                                            // height: '400px',
                                            maxHeight: '300px',
                                            minHeight: '300px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '2px solid black',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            position: 'relative'
                                        }}>
                                        <>
                                            <span style={{ fontSize: '27px' }}>
                                                Adicionar imagem
                                            </span>
                                            <CiCirclePlus size={40} /></>
                                        <input
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            type="file"
                                            id='ForInputCam2'
                                            onClick={(ev: any) => {
                                                ev.target.value = ''
                                            }}
                                        />
                                    </div>

                                }

                            </Form.Group>
                        </Col>
                    </Row>

                </Form>



                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-around', alignItems: 'baseline' }}>

                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <MultiSelect
                            style={{ width: '100%' }}
                            label="Inserir Informações no relatório:"
                            placeholder="Selecione uma opção"
                            data={['Informações do cliente', 'Informações da empresa']}
                            value={empresa?.config?.dados}
                            onChange={(ev) => { setEmpresa((prev: any) => ({ ...prev, config: { ...prev.config, dados: ev } })) }}
                        />

                        <Tooltip label="Adiciona no relatório as informações da empresa cadastrada e as informações do cliente cadastrado, é possível selecionar mais de uma opção!" color="cyan" >


                            <div style={{ marginTop: '24px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                        </Tooltip>
                    </div> */}

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                        <Select
                            style={{ width: '100%' }}
                            label="Inserir Informações da empresa no relatório:"
                            placeholder="Selecione uma opção"
                            data={['Capa', 'Página', 'Ambos', "Nenhum"]}
                            value={empresa?.config?.infoEmpresa}
                            onChange={(ev) => {
                                setEmpresa((prev: any) => ({ ...prev, config: { ...prev.config, infoEmpresa: ev } }));
                                // setState((prev: any) => { return { ...prev, infoEmpresa: ev } });

                            }}
                        />

                        <Tooltip label="Adiciona as informações da empresa cadastrada, na capa ou nas páginas de seu relatório, ou em ambos." color="cyan" >


                            <div style={{ marginTop: '24px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                        </Tooltip>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px' }}>

                        <Checkbox
                            label="Adicionar informações do cliente no relatório (endereço, email, cnpj..)"
                            // value={empresa?.empresaInfo}
                            checked={empresa?.config?.clienteInfo}
                            onChange={(ev) => {
                                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, clienteInfo: !empresa?.config?.clienteInfo } } });

                                // setState((prev: any) => { return { ...prev, clienteInfo: !empresa?.config?.clienteInfo } });
                            }}
                        />
                        <Tooltip label="Adiciona nas páginas de seu relatório as informações do cliente cadastrado." color="cyan" >


                            <div style={{ marginTop: '-3px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                        </Tooltip>


                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>


                        <Select
                            style={{ width: '100%', marginTop: '10px' }}
                            label="Inserir logo no relatório:"
                            placeholder="Selecione uma opção"
                            data={['Logo da empresa', 'Logo do cliente', 'Não inserir logo']}
                            value={empresa?.config?.logo}
                            onChange={(ev) => { setEmpresa((prev: any) => ({ ...prev, config: { ...prev.config, logo: ev } })) }}
                        />

                        <Tooltip label="Selecione o tipo de logo para ser inserido na parte interna do relatório." color="cyan" >
                            <div style={{ marginTop: '34px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                        </Tooltip>

                    </div>





                </div>
                <div style={{ margin: '20px' }}>
                    <Button onClick={() => { updateConfig() }}>Atualizar</Button>
                </div>


            </div>




        </div >

    );
}