
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import dark from "./styles/themes/dark";
import light from "./styles/themes/light";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactNotifications } from "react-notifications-component";
import "./App.css";
import "./styles/App.scss";
import "./styles/graphTheme/google-charts.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";
// import TabRootPage from "./views/pages/TabRootPage";
import Router from "./router/index2";
import { CallNotification } from "./components/Alert";
import { App } from "@capacitor/app";
import { useHistory } from "react-router-dom";
import { getPlatforms } from "@ionic/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import useSWR, { useSWRConfig, SWRConfig, preload } from "swr";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Network } from "@capacitor/network";
import { Token } from "./helpers/token";
import Reqs from "./views/Inspection/Req";
import { useInterval } from "ahooks";
import { updateToServerLocalDataSemNotificacao } from "./helpers/utils";
import { useCacheProvider } from '@piotr-cz/swr-idb-cache';
import { storageLocal } from "./storage/ionic-storage-config";



const App2 = () => {
  const cacheProvider = useCacheProvider({
    dbName: 'app',
    storeName: 'cache',
  })

  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();
  const access = Token.getAccess();
  const select: string = window.localStorage.getItem("theme") || "light";
  let selectTheme;
  if (select === "dark") {
    selectTheme = dark;
  } else {
    selectTheme = light;
  }

  const platform = getPlatforms();

  const [tema, setTema] = useState(selectTheme);
  const history = useHistory();
  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());

  App.addListener("backButton", () => {
    const url = window.location.pathname.split("/");
    url.pop();
    const last = url.join("/");
    return history.push(`${last}`);
  });

  Network.addListener("networkStatusChange", (status) => {
    dispatch({ type: "NETWORK", payload: status.connected });
  });



  React.useEffect(() => {


    (async () => {
      if (platform.includes("desktop")) return;

      let db: any;
      const createDatabase = () => {
        const request = window.indexedDB.open('app', 1);
        request.onsuccess = (e: any) => {

          setloading(false)
          db = request.result;

          function getAllcache() {
            const request = db.transaction('cache')
              .objectStore('cache')
              .getAll();

            request.onsuccess = async () => {

              const allCache = request.result;

              const cache = allCache.map((value: any) => {
                return value._k
              });


              const reqs = await Reqs(cache, allCache);


              reqs.forEach((url: string) => {


                if (cache.includes(url)) return;

                (async () => {

                  const theCache = await (preload(url, fetcher))

                  const transaction = db.transaction(['cache'], 'readwrite');

                  const objectStore = transaction.objectStore('cache');


                  objectStore.add({
                    _k: url,
                    data: theCache?.data == undefined ? theCache?.user : theCache?.data,
                    error: undefined,
                    isLoading: false,
                    isValidating: false
                  },
                    url);


                })();

              });

            }

            request.onerror = (err: any) => {
              console.error(`Error to get all cache: ${err}`)
            }
          }

          getAllcache()

        };
      }

      createDatabase()

    })();
  }, []);

  //  LIMPAR DADOS LOCAL

  // const { mutate } = useSWRConfig();
  // mutate(
  //   (key) => true, // quais chaves de cache devem ser atualizadas
  //   undefined, // atualiza os dados de cache para undefined
  //   { revalidate: false } // não revalidar os dados
  // );
  // storageLocal.clear();



  useInterval(() => {
    if (platform.includes("desktop")) return;
    updateToServerLocalDataSemNotificacao(dispatch);
  }, 30000);

  if (!cacheProvider) {
    return <div>carregando...</div>
  }

  return (
    <MantineProvider>
      <ModalsProvider>
        <ThemeProvider theme={tema}>
          <SWRConfig value={{
            provider: cacheProvider,

          }}>
            <ReactNotifications />
            <Router setTheme={setTema} />
          </SWRConfig>
        </ThemeProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App2;
