/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Modal from "../../components/Modal";
import { Client } from "../../interfaces";
import AdicionarClient from "./AdicionarEmpresa";
import { CreateClient, GetClient } from "./api";
import { Spin } from "react-cssfx-loading"

import ClientTable from "./EmpresaTable";
import {
  // IonPage,
  // IonHeader,
  // IonToolbar,
  // IonButtons,
  // IonMenuButton,
  // IonTitle,
  // IonContent,
  // IonToggle,
  IonSearchbar,
} from "@ionic/react";
// import SelectTheme from "../../styles/themes/selectTheme";
import { Token } from "../../helpers/token";
import Card from "../../components/card/card";
import { parseJwt } from "../../helpers/utils";
import { ImTab } from "react-icons/im";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlinePlus } from "react-icons/ai";
import useSWR, { useSWRConfig } from "swr";
import { COMPANY_URL } from "../../constains";

const id = Token.getTokenData()?.companyId;

export default function () {

  const [search, setSearch] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client>({
    name: "",
    supervisor: "",
    cel: "",
    tel: "",
    email: "",
    address: "",
    cep: "",
    lat: "",
    lng: "",
    companyId: `${id}`,
  });
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  const visualizarCliente = decoded?.payload.visualizarCliente;
  const criarCliente = decoded?.payload.criarCliente;
  const { companyId } = Token.getTokenData();

  const url = `${COMPANY_URL}/${companyId}`;
  const fetcher = (url: string) => fetch(url).then((res) => res.json());

  const { data, isLoading: loading } = useSWR(url, fetcher);
  const { mutate } = useSWRConfig();
  const run = React.useCallback(() => mutate(url), [mutate]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const onSubmitHandler = () => {
    CreateClient(client, () => run());
    handleClose();
  };

  useEffect(() => {
    run();
  }, []);



  const filteredClients = data?.data?.clients?.filter((client: any) => {
    return client.name.toLowerCase().includes(search.toLowerCase())
  });


 


  return (
    <div >
      <Row>
        <Col md={12}>
          {visualizarCliente === true ?

            <div>
              <div style={{
                position: 'sticky',
                top: '0',
                zIndex: '9999',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} >

                  {criarCliente === true ?

                    <Button style={{ width: '70%', height: '50px' }} variant="primary" onClick={handleShow}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',

                        }}>



                        <AiOutlinePlus
                          style={{ margin: '0px 4px 2px 0px' }}
                        />
                        <span style={{ fontSize: '14px' }}>
                          Adicionar Empresa
                        </span>


                      </div>



                    </Button> : "Este usuário não tem permisão para criar cliente"}

                </div>

                <IonSearchbar
                  value={search}
                  // @ts-ignore
                  onIonChange={(value) => setSearch(value?.detail?.value)}
                />

              </div>


              {loading == true ? < div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh'
              }}>
                <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
                Aguarde...

              </div> :
                <ClientTable
                  clients={filteredClients}
                  run={run}
                />
              }




              <Modal
                type={'empresa'}
                title="Adicionar Empresa"
                show={show}
                setShow={setShow}
                onSubmitHandler={onSubmitHandler}
                content={

                  <AdicionarClient client={client} setClient={setClient} />

                }

              />


            </div>

            : <div style={{
              // backgroundColor: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // minHeight: '100vh',
            }}>Este usuário não tem permissão para visualizar clientes</div>}
        </Col>
      </Row>
    </div>
  );
}
