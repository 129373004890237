/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Company, Os } from "../../../interfaces";
import Select from "react-select";
import styled from "styled-components";
import { IonTextarea } from "@ionic/react";
import { COMPANY_URL } from "../../../constains";
import axios from "axios";

interface Props {
  mode?: "edit" | "view";
  OS: Os;
  setOS: (value: Os) => void;
}

export default function AdicionarOs({ mode, OS, setOS }: Props) {
  const [companies, setCompanies] = useState<Company[]>([]);
  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const setParameters = (key: string, ev: any) => {
    ev.persist();
    //@ts-ignore
    setOS((prevState: Os): void => ({
      ...prevState,
      [key]: ev?.target?.value,
    }));
  };
  const setParametersSelect = (key: string, ev: any) => {
    // ev.persist();
    //@ts-ignore
    setOS((prevState: Os): void => ({
      ...prevState,
      [key]: ev?.value,
    }));
  };

  const companyOptions = companies?.map((company, index) => ({
    key: index,
    value: company.id,
    label: company.name,
  }));

  useEffect(() => {
    getAllCompany();
  },[]);

  const getAllCompany = () => {
    axios
      .get(COMPANY_URL, access)
      .then((res) => {
        console.log(res.data)
        setCompanies(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const optionsCli = [
    { value: "primeiro", label: "primeiro" },
    { value: "segundo", label: "segundo" },
    { value: "terceiro", label: "terceiro" },
  ];
  const optionsTO = [
    { value: "primeiro", label: "primeiro" },
    { value: "segundo", label: "segundo" },
    { value: "terceiro", label: "terceiro" },
  ];
  const optionsSup = [
    { value: "primeiro", label: "primeiro" },
    { value: "segundo", label: "segundo" },
    { value: "terceiro", label: "terceiro" },
  ];
  const optionsPri = [
    { value: 0, label: "Baixa" },
    { value: 1, label: "Média" },
    { value: 2, label: "Alta" },
  ];

  
  return (
    <ModalDiv>
      <Form autoComplete={"off"}>
        <Row>
          <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Título</Form.Label>
              <Form.Control
                autoComplete={"off"}
                type="text"
                placeholder="Nome da Os"
                value={OS.title}
                disabled={mode === "view" ? true : false}
                onChange={(ev) => setParameters("title", ev)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cliente</Form.Label>
              <Select
                //@ts-ignore
                options={optionsCli}
                value={optionsCli.filter(
                  (option) => option.value === OS.client
                )}
                isDisabled={mode === "view" ? true : false}
                onChange={(ev) => setParametersSelect("client", ev)}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Supervisor</Form.Label>

              <Select
                //@ts-ignore
                options={optionsSup}
                value={optionsSup.filter(
                  (option) => option.value === OS.supervisor
                )}
                isDisabled={mode === "view" ? true : false}
                onChange={(ev) => setParametersSelect("supervisor", ev)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Atribuído para:</Form.Label>
              <Select
                //@ts-ignore
                options={optionsTO}
                value={optionsTO.filter(
                  (option) => option.value === OS.assignTo
                )}
                isDisabled={mode === "view" ? true : false}
                onChange={(ev) => setParametersSelect("assignTo", ev)}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Prioridade</Form.Label>
              <Select
                //@ts-ignore
                options={optionsPri}
                value={optionsPri.filter(
                  (option) => option.value === OS.priority
                )}
                isDisabled={mode === "view" ? true : false}
                onChange={(ev) => setParametersSelect("priority", ev)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Compania</Form.Label>
              <Select
                value={companyOptions.filter(
                  (option) => option.value === OS.companyId
                )}
               
                isDisabled={mode === "view" ? true : false}
                isClearable={true}
                isSearchable={true}
                name="company"
                options={companyOptions}
                onChange={(event: any) => {
                  //@ts-ignore
                  setOS((prevState) => ({
                    ...prevState,
                    companyId: event?.value,
                  }));
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Informações da Os</Form.Label>

              <Div>
                <IonTextarea
                  style={{ height: "100%" }}
                  disabled={mode === "view" ? true : false}
                  placeholder="Insira um texto"
                  value={OS.text}
                  onIonChange={(e) =>
                    //@ts-ignore
                    setOS((prevState: Os): void => ({
                      ...prevState,
                      text: e.detail.value!,
                    }))
                  }
                />
              </Div>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </ModalDiv>
  );
}

const Div = styled.div`
  border: 2px dotted;
  border-color: var(--color1);
  height: 100px;
`;

const ModalDiv = styled.div`
  max-height: 589px;
  min-height: 589px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
