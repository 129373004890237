/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Client } from "../../interfaces";
import { ToggleSelector } from "../../styles/themes/styles";

interface Props {
  client: Client;
  setClient: (value: Client) => void;
  mode?: "edit" | "view";
}

export default function ({ client, setClient, mode = "edit" }: Props) {
  const [createClientUser, setCreateClientUser] =
    React.useState<boolean>(false);

  const genPassword = (): string => Math.random().toString(36).slice(-8);
  const setParameters = (key: string, ev: any) => {
    ev.persist();
    //@ts-ignore
    setClient((prevState: Client): void => ({
      ...prevState,
      [key]: ev?.target?.value,
    }));
  };

// console.log(client)
  
  return (
    <Form autoComplete={"off"}>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Empresa</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="email"
              placeholder="Nome da companhia"
              value={client.name}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("name", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Supervisor</Form.Label>
            <Form.Control
              type="text"
              placeholder="Pessoa responsavel pela parte técnica."
              value={client.supervisor}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("supervisor", ev)}
            />
            {/* <Form.Text className="text-muted">
              Pessoa responsavel pela parte tecnica da empresa.
            </Form.Text> */}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Celular</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Número de celular"
              value={client.cel}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("cel", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Número de telefone"
              value={client.tel}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("tel", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="email"
              placeholder=" Informe e-mail do cliente"
              value={client.email}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("email", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Cep</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Cep do cliente"
              value={client.cep}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("cep", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Endereço do cliente"
              value={client.address}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("address", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Latitude do cliente"
              value={client.lat}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("lat", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Longitude do cliente"
              value={client.lng}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("lng", ev)}
            />
          </Form.Group>
        </Col>
      </Row>
      {/* {mode === "edit" ? (
        <>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Criar usuário para o cliente</Form.Label>
                <ToggleSelector
                  color="red"
                  checked={createClientUser}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={() => {
                    setCreateClientUser(!createClientUser);
                  }}
                />
                <Form.Text className="text-muted">
                  Cria um usuario para que o cliente possa abri novas OS
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {createClientUser ? (
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="password"
                    value={client.clientPassword}
                    onChange={(ev) => setParameters("clientPassword", ev)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      //@ts-ignore
                      setClient((prevState: Client) => ({
                        ...prevState,
                        clientPassword: genPassword(),
                      }));
                    }}
                  >
                    Gerar password
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </>
      ) : null} */}
    </Form>
  );
}
