import React, { useMemo } from "react";
import {
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { ModalsProvider, modals } from "@mantine/modals";
import {
  ActionIcon,
  Button,
  Flex,
  Stack,
  Text,
  Title,
  Tooltip,
  Modal,
} from "@mantine/core";
import {
  IconEdit,
  IconTrash,
  IconDownload,
  IconEye,
} from "@tabler/icons-react";
import { FILE_DOWNLOAD_URL } from "../../constains";
import { MRT_Localization_PT_BR } from "mantine-react-table/locales/pt-BR";
import { PDFView } from "./PDFView";

const Example = ({ data, deleteFile }: any) => {
  const save = (blob: any, fileName: string) => {
    console.log("blob", blob);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; //filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadClick = (id: string, table) => {
    const rowsSelected = table?.getSelectedRowModel()?.flatRows || [];

    if (rowsSelected.length > 0) {
      rowsSelected?.map((d: any, index: number) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `${FILE_DOWNLOAD_URL}/${d?.original?.id}`);
        xhr.responseType = "blob";
        // xhr.onprogress = (e) =>
        // console.log(`${parseInt((e.loaded / e.total) * 100)}%`);
        xhr.onload = (e) => save(xhr.response, d.original.originalFileName);
        xhr.send();
      });
      return;
    }

    window.open(`${FILE_DOWNLOAD_URL}/${id}`, "_blank");
  };

  const openDeleteConfirmModal = (row: MRT_Row<User>, table) => {
    const rowsSelected = table?.getSelectedRowModel()?.flatRows || [];
    if (rowsSelected.length > 0) {
      rowsSelected?.map((d: any) => {
        modals.openConfirmModal({
          title: "Are you sure you want to delete this user?",
          children: (
            <Text>
              Tem certeza que deseja deletar o arquivo{" "}
              {d.original.originalFileName} ? essa ação não poderá ser desfeita
            </Text>
          ),
          labels: { confirm: "Deletar", cancel: "Cancelar" },
          confirmProps: { color: "red" },
          onConfirm: () => deleteFile(d.original.id),
        });
      });

      return;
    }

    modals.openConfirmModal({
      title: "Are you sure you want to delete this user?",
      children: (
        <Text>
          Tem certeza que deseja deletar o arquivo{" "}
          {row.original.originalFileName} ? essa ação não poderá ser desfeita
        </Text>
      ),
      labels: { confirm: "Deletar", cancel: "Cancelar" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteFile(row.original.id),
    });
  };

  const viewPDF = (row: any) => {
    const id = row.original.id as string;
    const { originalFileName = "" } = row.original;
    const path2 = `${FILE_DOWNLOAD_URL}/${id}`;

    modals.open({
      title: originalFileName,
      size: "100%",
      children: (
        <>
          <PDFView path={path2} />
        </>
      ),
    });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "originalFileName", //access nested data with dot notation
        header: "Nome",
      },
      {
        accessorKey: "createAt",
        header: "Data",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal",
    enableEditing: true,
    enableRowSelection: true,
    localization: MRT_Localization_PT_BR,

    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Download">
          <ActionIcon
            color="blue"
            onClick={() => handleDownloadClick(row?.original?.id, table)}
          >
            <IconDownload />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon
            color="red"
            onClick={() => openDeleteConfirmModal(row, table)}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="View">
          <ActionIcon
            // color=""
            onClick={() => viewPDF(row, table)}
          >
            <IconEye />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <ModalsProvider>
      <MantineReactTable table={table} />
    </ModalsProvider>
  );
};

export default Example;
