import React from "react";
import { Group, Text, useMantineTheme, rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { getBase64FromImage } from "../../../helpers/utils";
import { Button } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";

type TImageOffline = {
  data?: unknown;
  setImage64: (value: string) => void;
  deleteImage64: (value: number) => void;
};

export function ImageOffline({
  data = {},
  setImage64,
  deleteImage64,
  ...props
}: TImageOffline) {
  const currentImages = data?.data?.images64 ?? [];
  const theme = useMantineTheme();

  return (
    <>
      <DropZone setImage64={setImage64} {...props} />
      {currentImages?.map((image: string, index: number) => (
        <div>
          <img src={image} />
          <Button
            variant="danger"
            style={{
              maxHeight: "40px",
            }}
            onClick={() => {
              deleteImage64(index);
            }}
          >
            <BsTrash />
          </Button>
        </div>
      ))}
    </>
  );
}

const DropZone = ({ setImage, setImage64, ...props }: any) => {
  const theme = useMantineTheme();

  const fileSubmitHandler = (files: unknown[]) => {
    for (let i = 0; i < files?.length; i++) {
      getBase64FromImage(files[i], onLoad);
    }
  };

  const onLoad = (fileString: any) => {
    setImage64(fileString);
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    let files: string[] = [];
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };

      reader.readAsArrayBuffer(file);
      files = [...files, file];
    });

    fileSubmitHandler(files);
  }, []);

  return (
    <Dropzone
      onDrop={onDrop}
      onReject={(files) => console.log("rejected files", files)}
      maxSize={3 * 1024 ** 2}
      accept={IMAGE_MIME_TYPE}
      {...props}
    >
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: rem(220), pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            size="3.2rem"
            stroke={1.5}
            color={
              theme.colors[theme.primaryColor][
                theme.colorScheme === "dark" ? 4 : 6
              ]
            }
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size="3.2rem"
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size="3.2rem" stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            Drag images here or click to select files
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            Attach as many files as you like, each file should not exceed 5mb
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
};
