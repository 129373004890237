import { IonTextarea } from "@ionic/react";
import React, { memo, useState, useCallback } from "react";
import { Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import styled from "styled-components";
import { IMAGE_URL, URL_OS } from "../../../constains";
import { apiGet, apiPut, apiPost, apiDelete } from "../../../helpers/api";
import { getBase64FromImage } from "../../../helpers/utils";
import { Mode, Os } from "../../../interfaces";
import { useDropzone } from "react-dropzone";

const MAX_COUNT = 5;

interface Props {
  OS: Os;
  setOS: (os: any) => void;
  mode: Mode["mode"];
}

const MultipleImages = React.memo(
  ({ OS, getOS }: { OS: Os; getOS: () => void }) => {
    const [files, setFiles] = useState([]);
    //state for checking file size
    const [fileSize, setFileSize] = useState(true);
    // for file upload progress message
    const [fileUploadProgress, setFileUploadProgress] = useState(false);
    //for displaying response message
    const [fileUploadResponse, setFileUploadResponse] = useState(null);
    //base end point url

    const uploadFileHandler = (event) => {
      setFiles(event.target.files);
    };

    const fileSubmitHandler = (event) => {
      event.preventDefault();
      setFileSize(true);
      setFileUploadProgress(true);
      setFileUploadResponse(null);

      for (let i = 0; i < files.length; i++) {
        // TODO: LIMIT SIZE
        // if (files[i].size > 100024) {
        //   setFileSize(false);
        //   setFileUploadProgress(false);
        //   setFileUploadResponse(null);
        //   return;
        // }

        //add image
        getBase64FromImage(files[i], onLoad);
      }
      setFiles([]);

      setFileUploadProgress(false);
    };

    const onLoad = (fileString: any) => {
      // console.log("onLoad image", fileString);
      const data = {
        image64: fileString,
        osId: OS?.id,
        imageType: "os",
      };
      apiPost(IMAGE_URL, data, getOS);
      // setImage(fileString);
    };

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
        };

        setFiles((prevState) => [...prevState, file]);
        reader.readAsArrayBuffer(file);
      });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Container>
        <form onSubmit={fileSubmitHandler}>
          <div className="drop-area" {...getRootProps()}>
            <div className="files-wr" data-count-files="1">
              <div className="one-file">
                <label htmlFor="file-1">Adicionar múltiplas imagem</label>
                <input
                  id="file-1"
                  type="image"
                  multiple
                  {...getInputProps()}
                  onChange={(event) => {
                    uploadFileHandler(event);
                  }}
                  // disabled={fileLimit}
                />
                {files.length > 0 ? (
                  <p>{files.length} arquivos carregados</p>
                ) : (
                  <p>Arraste ou click aqui</p>
                )}
              </div>
            </div>
          </div>

          {files.length > 0 ? (
            <Button style={{width: '100%'}} type="submit" variant="danger">
              Carregar imagens
            </Button>
          ) : null}

          {!fileSize && <p style={{ color: "red" }}>File size exceeded!!</p>}
          {fileUploadProgress && (
            <p style={{ color: "red" }}>Uploading File(s)</p>
          )}
          {fileUploadResponse != null && (
            <p style={{ color: "green" }}>{fileUploadResponse}</p>
          )}
        </form>
        <hr color="gray"/>
      </Container>
    );
  }
);
export default MultipleImages;

const Container = styled.div`
  .drop-area {
    border: 1px dashed;
    width: 100%;
    /* height: 10px; */
    margin-bottom: 40px;
  }
`;
export { MultipleImages };
