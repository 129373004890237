import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface Props {
  show: boolean;
  title?: string;
  body?: string;
  onConfirm: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  colorButton?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark' | 'link';
}


export default function ModalConfirm({ show, setShow, title, body, onConfirm, colorButton = "danger" }: Props) {
  const handleClose = () => { setShow(false) };

  return (
    <>
      <Modal show={show} onHide={handleClose}>

        <Modal.Header >

          <Modal.Title>{title || "Tem certeza que deseja realizar essa ação?"}</Modal.Title>

        </Modal.Header>

        <Modal.Body>{body || 'Esta ação não pode ser desfeita!'}</Modal.Body>

        <Modal.Footer>
          <Button variant={colorButton} onClick={() => { onConfirm(); handleClose() }}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

