import { Os } from "../../interfaces";

export function FilterOs(
  OSs: any,
  priority: number,
  status: number,
  showOSOpen: boolean
) {
  const theFilter: Os[] = OSs;

  const filteredOss: any = theFilter?.filter((os: Os) => {
    if (priority === null && status === null) {
      return os;
    }
    if (priority !== null && status === null) {
      return os.priority === priority;
    }

    if (status !== null && priority === null) {
      return os.status === status;
    }
    if (status !== null && priority !== null) {
      return os.status === status && os.priority === priority;
    }
    return null
  }).filter((os: Os) => {
    if (!showOSOpen) return os.status !== 2;
    return true;
  });


  return filteredOss;
}
