
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    themeName: 'dark',

    colors: {
        primary: '#1e1e2f',
        secondary: '#27293D',
        tertiary: '#313862',
        white: '#FFF',
        black: '#202124',
        gray: '#5f6368',
        success: '#5f6368',
        info: '#3B5998',
        warning: '#E44C4E',
        header: '#5f6368',
        textColor: '#EEEEEE',
        gray0: "#121720",
        gray100: "#191E29",
        gray200: "#252C38",
        gray300: "#313947",
        gray400: "#3F4759",
        gray500: "#606A80",
        gray600: "#868695",
        gray700: "#DDDEE1",
        gray800: "#EEEEEE",
        gray900: "#FFFFFF",
        gray1000: "#FFFFFF",
        blue200: "#17324D",
        blue300: "#49627A",
        blue400: "#008AC7",
        blue500: "#37A3EF",
        blue700: "#008AC7",
        red300: "#492827",
        red500: "#D44945",
        red700: "#F3756E",
        green200: "#194141",
        green500: "#22C39B",
        green700: "#70E19D",
        yellow200: "#523104",
        yellow500: "#FAA314",
        yellow700: "#FFC648",
        static_gray0: "#FFFFFF",
        static_gray100: "#F5F5F5",
        static_gray200: "#EEEEEE",
        static_gray300: "#DDDEE1",
        static_gray400: "#C8CCD4",
        static_gray500: "#BAC0CB",
        static_gray600: "#868695",
        static_gray700: "#606A80",
        static_gray800: "#364159",
        static_gray900: "#28334A",
        static_gray1000: "#172033",
        static_blue200: "#E1F0FF",
        static_blue300: "#C3E3FA",
        static_blue400: "#91C7F4",
        static_blue500: "#37A3EF",
        static_blue700: "#008AC7",
        static_red300: "#FFBBB9",
        static_red500: "#D9534F",
        static_red700: "#C43535",
        static_green200: "#DBFDEF",
        static_green300: "#335145",
        static_green500: "#53DBB9",
        static_green700: "#01C292",
        static_yellow200: "#FAFAC3",
        static_yellow500: "#F9E47F",
        static_yellow700: "#F89300",
        static_yellow800: "#F17F29",
    },
};