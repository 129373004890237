import { CLIENT_URL } from "../../../constains";
import axios from "axios";
import { Client } from "../../../interfaces";
import { CallNotification } from "../../../components/Alert";
import { Dispatch, SetStateAction } from "react";

const token = window.localStorage.getItem("accessToken");
const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

// interface CreateProps {
//   client: Client;
//   access: any;
//   url: any;
//   sucessCallback: any;
//   failureCallback: any;
// }

export const CreateClient = (
  client: Client,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .post(`${CLIENT_URL}/`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "usuario cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const DeleteClient = (
  id?: string,
  sucessCallback: () => void = () => {},
  failureCallback: () => void = () => {}
) => {
  return axios
    .delete(`${CLIENT_URL}/${id}`, access)
    .then((res) => {
      console.log(res);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};

enum RequestType {
  "client",
  "user",
  "os",
}
const types: any = {
  client: CLIENT_URL,
};

export const Delete = (
  id?: string,
  type?: RequestType,
  sucessCallback: () => void = () => {},
  failureCallback: () => void = () => {}
) => {
  if (!type) return;
  return axios
    .delete(`${types[type]}/${id}`, access)
    .then((res) => {
      console.log(res);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};

export const UpdateClient = (
  client: Client,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .put(`${CLIENT_URL}/${client?.id}`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "usuario cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const GetClient = (
  setState: Dispatch<SetStateAction<Client[]>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .get(`${CLIENT_URL}/`, access)
    .then((res) => {
      console.log(res);
      setState(res.data.data);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};
