/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import axios from "axios";
import { CallNotification } from "../Alert";
import { ADMIN_URL_IMAGE } from "../../constains";

interface Props {
  getData?: (data: void) => void;
  handleClose: (data: void) => void;
}

function AdicionarSensor({ getData = () => {}, handleClose }: Props) {
  const [image, setImage] = useState<any>(null);

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    axios
      .post(ADMIN_URL_IMAGE, { image64: image, imageType: "Profile" }, access)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          CallNotification(
            "Sucesso",
            "Sensor cadastrado com sucesso",
            "success"
          );
          getData();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        CallNotification("Error", "Falha ao cadastrar sensor", "danger");
      });
  };

  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onChange = (e: any) => {
    console.log("e", e);
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const onLoad = (fileString: any) => {
    setImage(fileString);
  };

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <div>
          <label style={{ width: "95%", marginLeft: "11px" }}>Image:</label>
          <input
            style={{ width: "96.2%", marginLeft: "11px" }}
            type="file"
            onChange={(event) => {
              console.log(event);

              onChange(event);
            }}
            required
          ></input>
        </div>
        <button className="btn btn-primary" type="submit">
          Adicionar
        </button>
      </form>
    </div>
  );
}

export default AdicionarSensor;
