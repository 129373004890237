import axios from "axios";
import { Dispatch, SetStateAction } from "react";

// import { USER_URL } from "../constains";
import { CallNotification } from "../components/Alert";
// import { Client } from "../interfaces";

const token = window.localStorage.getItem("accessToken");
const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

export const apiGet = (
  URL: string | URL,
  setState: Dispatch<SetStateAction<any>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .get(`${URL}`, access)
    .then((res) => {
      // console.log(res);
      setState(res.data.data);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

export const apiPut = (
  URL: string,
  data: any,
  setState: Dispatch<SetStateAction<any>> = () => {},
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .put(URL, data, access)
    .then((res) => {
      console.log(res);
      setState(res.data.data);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

export const apiPost = (
  URL: string,
  data: any,
  setState: Dispatch<SetStateAction<any>> = () => {},
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .post(URL, data, access)
    .then((res) => {
      console.log(res);
      setState(res.data.data);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

export const apiDelete = (
  URL: string,
  id: number | string,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .delete(`${URL}/${id}`, access)
    .then((res) => {
      CallNotification("Sucesso", "Item deletado!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar item", "danger");
      failureCallback();
    });
};

const BASE_URL = "http://localhost:3000";

export const getAllComments = async (key: string, postId: string) => {
  return new Promise(async (resolve) => {
    let r = await fetch(`${BASE_URL}/posts/${postId}/comments`);
    let data = await r.json();
    setTimeout(() => resolve(data), 1000);
  });
};

export const addComment = async ({
  commentBody,
  postId,
}: {
  commentBody: string;
  postId: string;
}) => {
  try {
    return await fetch(`${BASE_URL}/comments/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: commentBody, postId }),
    });
  } catch (error) {
    return error;
  }
};

export const deleteComment = async ({ commentId }: any) => {
  try {
    return await fetch(`${BASE_URL}/comments/${commentId}`, {
      method: "DELETE",
    });
  } catch (error) {
    return error;
  }
};
