import { NULL_USER, OS_INITIAL_STATE } from "../constains";
import { User } from "../interfaces";
import { parseJwt } from "./utils";

// interface Payload {
//   admin: boolean;
//   agendarVisita: boolean;
//   companyId: string;
//   createAt: Date;
//   criarOS: false;
//   editarOS: false;
//   email: string;
//   id: string;
//   name: string;
//   updatedAt: Date;
//   visualizarOS: boolean;
// }

interface Access {
  headers: {
    Authorization: string;
  };
}

interface ITokenData {}

class Token {
  static token = window.localStorage.getItem("accessToken") || null;

  static getToken = (): string | null => {
    return this.token;
  };

  static getTokenData = (): User => {
    const { token } = this || { token: {} };
    if (token) return parseJwt(token)?.payload as User;
    // return parseJwt(token)?.payload as User;
    return NULL_USER;
  };

  static getAccess = (): Access => {
    return {
      headers: {
        Authorization: "Bearer " + this.token,
      },
    };
  };
}

export { Token };

// class MyClass {
//   const MY_CONST = 'string'; // <-- this is not possible in ES6
//   constructor(){
//       this.MY_CONST;
//   }
// }
