import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Carregue o arquivo PDF do servidor.
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFView = ({ path }: { path: string }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numpage: number }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={path} // URL do PDF no servidor
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>
          Page {pageNumber} of {numPages}
        </p>

        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={() => setPageNumber(Math.max(1, pageNumber - 1))}
            disabled={pageNumber <= 1}
          >
            {"< Voltar"}
          </button>

          <button
            onClick={() => setPageNumber(Math.min(numPages, pageNumber + 1))}
            disabled={pageNumber >= numPages}
          >
            {"Próxima >"}
          </button>
        </div>
      </div>
    </div>
  );
};
