import React, { useEffect } from "react";
import { Button,
  //  Container, Form, InputGroup 
  } from "react-bootstrap";
import Card from "../card/card";
import {
  DivButton,
  DivGeral,
  DivPrimaria,
  DivSecundaria,
  SpanTexto,
  // TextArea,
} from "./style";
import "./styles.css";
// import { Os } from "../../interfaces";

// import Select, { components, DropdownIndicatorProps } from "react-select";
// import { ColourOption, colourOptions } from "./data";
// import { MyEditor } from "./EditorTexto";
import RichText from "./RichText";
// import { CallNotification } from "../Alert";
import axios from "axios";
import { URL_OS } from "../../constains";

interface Props {
  id: string;
  handleClose: (data: void) => void;
}

function AdicionarOs({ handleClose, id }: Props) {
  // const [text, setText] = useState("");
  const [Os, setOs] = React.useState<any>({
    title: "",
    supervisor: '',
    priority: 0,
    assignTo: '',
    text: "",
    client: '',
  });

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    getOsData();
  },[]);

  const getOsData = () => {
    axios
      .get(`${URL_OS}/${id}`, access)
      .then((res) => {
        // console.log(res)
        setOs((prevState: any) => ({
          ...prevState,
          title: res?.data?.data?.title,
          supervisor: res?.data?.data?.supervisor,
          priority: res?.data?.data?.priority,
          assignTo: res?.data?.data?.assignTo,
          text: res?.data?.data?.text,
          client: res?.data?.data?.client,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card
      title=""
      category=""
      ctTableFullWidth
      ctTableResponsive
      content={
        <DivGeral id="divGeral">
          <form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <span>Título: </span>
              <input
                style={{ width: "100%", marginLeft: "10px" }}
                type="text"
                name="login"
                value={Os.title}
                onChange={(event) =>
                  setOs((prevState: any) => ({
                    ...prevState,
                    title: event.target.value,
                  }))
                }
                disabled
                required
              />
            </div>

            <DivPrimaria>
              <DivSecundaria
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <SpanTexto style={{ marginRight: "12px" }}>Cliente:</SpanTexto>

                <select
                  style={{
                    border: "1px solid",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "16px",
                    width: "200px",
                  }}
                  value={Os.client}
                  onChange={(event: any) =>
                    setOs((prevState: any) => ({
                      ...prevState,
                      client: event.target.value,
                    }))
                  }
                  disabled
                >
                  <option value="Selecione">Selecione</option>
                  <option value="Primeiro Cliente">Primeiro Cliente</option>
                  <option value="Segundo Cliente">Segundo Cliente</option>
                  <option value="Terceiro Cliente">Terceiro Cliente</option>
                </select>

                <button
                  style={{
                    border: "1px solid",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "16px",
                    width: "30px",
                  }}
                >
                  +
                </button>
              </DivSecundaria>

              <DivSecundaria
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <SpanTexto>Prioridade:</SpanTexto>
                <Button
                  onClick={() =>
                    setOs((prevState: any) => ({
                      ...prevState,
                      priority: 0,
                    }))
                  }
                  variant="primary"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                    color: "black",
                    borderColor: "black",
                  }}
                  disabled={Os.priority === 0 ? true : false}
                >
                  Baixa
                </Button>
                <Button
                  onClick={() =>
                    setOs((prevState: any) => ({
                      ...prevState,
                      priority: 1,
                    }))
                  }
                  variant="warning"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                    color: "black",
                    borderColor: "black",
                  }}
                  disabled={Os.priority === 1 ? true : false}
                >
                  Média
                </Button>
                <Button
                  onClick={() =>
                    setOs((prevState: any) => ({
                      ...prevState,
                      priority: 2,
                    }))
                  }
                  variant="danger"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                    color: "black",
                    borderColor: "black",
                  }}
                  disabled={Os.priority === 2 ? true : false}
                >
                  Alta
                </Button>
              </DivSecundaria>
            </DivPrimaria>
            <DivPrimaria>
              <SpanTexto style={{ marginLeft: "3px" }}>Supervisor:</SpanTexto>

              <select
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  width: "200px",
                }}
                value={Os.supervisor}
                onChange={(event: any) =>
                  setOs((prevState: any) => ({
                    ...prevState,
                    supervisor: event.target.value,
                  }))
                }
                disabled
              >
                <option value="Selecione">Selecione</option>
                <option value="Primeiro supervisor">Primeiro supervisor</option>
                <option value="Segundo supervisor">Segundo supervisor</option>
                <option value="Terceiro supervisor">Terceiro supervisor</option>
              </select>
              <SpanTexto style={{ marginLeft: "3px" }}>
                Atribuído para:
              </SpanTexto>
              <select
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  width: "200px",
                }}
                value={Os.assignTo}
                onChange={(event: any) =>
                  setOs((prevState: any) => ({
                    ...prevState,
                    assignTo: event.target.value,
                  }))
                }
                disabled
              >
                <option value="Selecione">Selecione</option>
                <option value="Primeiro tecnico">Primeiro técnico</option>
                <option value="Segundo tecnico">Segundo técnico</option>
                <option value="Terceiro tecnico">Terceiro técnico</option>
              </select>
            </DivPrimaria>

            <RichText value={Os.text} />

            <DivButton>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Fechar
              </Button>

              {/* <button className="btn btn-primary" type="submit">
                Confirmar
              </button> */}
            </DivButton>
          </form>
        </DivGeral>
      }
    />
  );
}

export default AdicionarOs;
