import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Delete, GetImages, GetInspection, UpdateInspection, GetInspectionImages } from './api';
import InspectionSelect from './InspectionSelect';
import { Button, Modal, Nav } from 'react-bootstrap';
import { getPlatforms } from '@ionic/react';
import { CallNotification } from '../../components/Alert';
import { Images } from './Images/Images';
import { Token } from '../../helpers/token';
import { COMPANY_URL, EQUIPMENT, INSPECTION } from '../../constains';
import { useRequest } from 'ahooks';
import useSWR, { useSWRConfig } from 'swr';
import { addStorageLocalData } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { dataURItoBlob } from '@rjsf/utils';
import { Spin } from 'react-cssfx-loading';




function OnlyInspection() {
  const { mutate } = useSWRConfig();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cliente = queryParams.get('name');
  const equipamento = queryParams.get('equiname');
  const equipamentoId = queryParams.get('equiid');
  const clientId = queryParams.get('clientid');
  const voltar: any = queryParams.get('voltar');

  const { companyId, criarInspection, deletarInspection, visualizarInspection, editarInspection } = Token.getTokenData();
  const dispatch = useDispatch();
  let { id }: any = useParams();
  const [show, setShow] = useState<any>(false);

  const [mode, setMode] = useState<any>(false);
  const [selected, setSelected] = useState<any>('inspection');
  const [formData, setFormData] = useState<any>();
  const [image, setImage] = useState<any>([]);
  const access = Token.getAccess();
  const [disable, setDisable] = useState<any>(true);
  const [sync, setSync] = useState<any>(false);
  const platform = getPlatforms();
  const history = useHistory();
  const [forceUpdate, setForceUpdate] = useState(false);
  const handleForceUpdate = () => {
    setForceUpdate(!forceUpdate);
    CallNotification("objeto criado offline", "", "info");
  };
  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const url = `${INSPECTION}/images/${id}`;
  const url2 = `${INSPECTION}/${id}`;
  const urlKey = `${EQUIPMENT}/${equipamentoId}`;
  const url3 = `${EQUIPMENT}/${equipamentoId}`;
  // const url = `${INSPECTION}/${id}`;
  // const inspectionOffLine: any = window.localStorage.getItem(`${id}`)
  // console.log(inspectionOffLine)



  const { data: semImportancia } = useSWR(url3, fetcher);


  const run1 = React.useCallback(() => mutate(url3), [mutate]);




  const { data
    // = JSON.parse(inspectionOffLine)
    , isLoading: loading2 } = useSWR(url2, fetcher);


  const handleClose = () => {
    setShow(!show);
  }

  function atualizarValores(objeto: any, novoValor: any) {
    for (let chave in objeto) {
      if (typeof objeto[chave] === "object") {
        // Se a chave atual é um objeto, chame a função recursivamente
        atualizarValores(objeto[chave], novoValor);
      } else if (chave === "rows") {
        // Se a chave é "rows", atualize o valor
        objeto[chave] = novoValor;
      }
    }
  }

  atualizarValores(data?.data?.Inspectionmodel?.uiSchema, window.innerWidth > 700 ? 20 : 30);




  // console.log(data)



  // const componentOptions: { [key: string]: JSX.Element } = {
  //   inspection:
  //     <>
  //       {data?.data !== undefined ? <InspectionSelect
  //         schema={data?.data?.Inspectionmodel?.jsonSchema}
  //         uiSchema={data?.data?.Inspectionmodel?.uiSchema}
  //         formData={data?.data?.formData}
  //         onChange={(e: any) => setFormData(e.formData)}
  //         onSubmit={() => {
  //           mutate(url, UpdateInspection(id, { formData: formData, images64: image }, () => {
  //             setDisable(!disable);
  //             setMode(!mode)
  //             history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);

  //           }, () => {

  //             setDisable(!disable);
  //             setMode(!mode)
  //             if (formData !== undefined) {
  //               const datas = {
  //                 data: {
  //                   formData: formData,
  //                   images64: image
  //                 }, // inpection model id, equipamento id ,formdata, images64
  //                 method: "PUT",
  //                 url: url2,
  //                 key: urlKey,
  //                 offlineData: "onSuccess delete storage",
  //                 id: id,
  //               };

  //               addStorageLocalData(urlKey, datas, handleForceUpdate, dispatch)
  //             }


  //             if (JSON.stringify(data?.data?.images64) === JSON.stringify(image) && formData == undefined) {
  //               console.log("As arrays são iguais.");
  //             } else {
  //               console.log("As arrays são diferentes.");

  //               const datas = {
  //                 data: {
  //                   formData: data?.data?.formData,
  //                   images64: image
  //                 }, // inpection model id, equipamento id ,formdata, images64
  //                 method: "PUT",
  //                 url: url2,
  //                 key: urlKey,
  //                 offlineData: "onSuccess delete storage",
  //                 id: id,
  //               };

  //               addStorageLocalData(urlKey, datas, handleForceUpdate, dispatch)
  //             }
  //             history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);


  //           }), {
  //             revalidate: true,
  //             rollbackOnError: true,
  //           })
  //         }}
  //         disabled={disable}
  //         onClickButtonCancel={() => {
  //           setDisable(!disable);
  //           history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);
  //         }}
  //         mode={mode}
  //       />
  //         :

  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             justifyContent: "center",
  //             height: "80vh",
  //           }}
  //         >
  //           <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
  //           Aguarde...
  //         </div>


  //       }
  //     </>,
  //   image: <Images
  //     mode1={"add"}
  //     mode={mode}
  //     setMode={setMode}
  //     image={image}
  //     setImage={setImage}
  //     disable={disable}
  //     setDisable={setDisable}
  //     sync={sync}
  //     setSync={setSync}
  //     // inspectionId={inspectionId}
  //     id={id}
  //   />,

  // };




  return (
    <div style={{ padding: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', marginBottom: '10px' }} >Cliente: {cliente}</div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', marginBottom: '10px' }} >Equipamento: {equipamento}</div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'space-between' }}>
        <Button

          onClick={() => {
            history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);
          }}

          variant="secondary"
          style={{
            textDecoration: "none",
            width: !platform.includes("desktop") ? '30%' : '50%',
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
            margin: "0px 15px 0px 0px",
            fontSize: !platform.includes("desktop") ? '11px' : '16px'
          }}
        >
          Voltar
        </Button>
        <Button


          onClick={() => {
            setDisable(!disable)
            setMode(!mode)
          }}


          variant="primary"
          style={{
            textDecoration: "none",
            width: !platform.includes("desktop") ? '30%' : '50%',
            justifyContent: "center",
            alignItems: 'center',
            display: editarInspection == true ? "flex" : 'none',
            margin: "0px 15px 0px 0px",
            fontSize: !platform.includes("desktop") ? '11px' : '16px'
          }}
        >
          Editar
        </Button>

        <Button
          onClick={() => {

            setShow(!show)

          }}
          variant="danger"
          style={{
            textDecoration: "none",
            width: !platform.includes("desktop") ? '30%' : '50%',
            justifyContent: "center",
            alignItems: 'center',
            display: deletarInspection == true ? "flex" : 'none',
            margin: "0px 15px 0px 0px",
            fontSize: !platform.includes("desktop") ? '11px' : '16px'
          }}
        >
          Deletar
        </Button>

      </div>
      <Nav
        fill
        variant="tabs"
        defaultActiveKey={selected}
        activeKey={selected}
      >
        <Nav.Item>
          <Nav.Link
            eventKey="inspection"
            style={{
              color: selected ==
                "inspection"
                ? "#131312"
                : "#0e0d0d",
            }}
            onClick={() => {
              setSelected("inspection");
              // getData();
            }}
          >
            Inspeção
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link

            eventKey="image"
            style={{
              color: selected ==
                "image" ?
                "#0d0c0c" :
                "#0e0d0d",
            }}
            onClick={() => {
              setSelected("image");
              // getData()
            }}
          >
            Imagens
          </Nav.Link>
        </Nav.Item>

      </Nav>







      {/* {componentOptions[selected]} */}

















      {selected == "inspection" ?
        <>
          {data?.data !== undefined ? <InspectionSelect
            schema={data?.data?.Inspectionmodel?.jsonSchema}
            uiSchema={data?.data?.Inspectionmodel?.uiSchema}
            formData={data?.data?.formData}
            onChange={(e: any) => setFormData(e.formData)}
            onSubmit={() => {
              mutate(url, UpdateInspection(id, {
                formData: formData,
                // images64: image 
              }, () => {
                setDisable(!disable);
                setMode(!mode)
                history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);

              }, () => {

                setDisable(!disable);
                setMode(!mode)
                if (formData !== undefined) {
                  const datas = {
                    data: {
                      formData: formData,
                      images64: image
                    }, // inpection model id, equipamento id ,formdata, images64
                    method: "PUT",
                    url: url2,
                    key: urlKey,
                    offlineData: "onSuccess delete storage",
                    id: id,
                  };

                  addStorageLocalData(urlKey, datas, handleForceUpdate, dispatch)
                }


                if (JSON.stringify(data?.data?.images64) === JSON.stringify(image) && formData == undefined) {
                  console.log("As arrays são iguais.");
                } else {
                  console.log("As arrays são diferentes.");

                  const datas = {
                    data: {
                      formData: data?.data?.formData,
                      images64: image
                    }, // inpection model id, equipamento id ,formdata, images64
                    method: "PUT",
                    url: url2,
                    key: urlKey,
                    offlineData: "onSuccess delete storage",
                    id: id,
                  };

                  addStorageLocalData(urlKey, datas, handleForceUpdate, dispatch)
                }
                history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);


              }), {
                revalidate: true,
                rollbackOnError: true,
              })
            }}
            disabled={disable}
            onClickButtonCancel={() => {
              setDisable(!disable);
              history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);
            }}
            mode={mode}
          />
            :

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
              Aguarde...
            </div>


          }
        </>
        :
        <>
          {
            <Images
              mode1={"add"}
              mode={mode}
              setMode={setMode}
              image={image}
              setImage={setImage}
              disable={disable}
              setDisable={setDisable}
              sync={sync}
              setSync={setSync}
              // inspectionId={inspectionId}
              id={id}
            />
          }
        </>
      }

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Tem certeza que deseja excluir esta inspeção?</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#ededed",
            display: 'flex',
            justifyContent: 'space-evenly'
          }}
        >

          <Button
            variant="danger"


            onClick={() => {
              // @ts-ignore
              Delete(id, "inspection", () => { run1() })
              history.push(voltar + `name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clientId}`);
            }}

          >Confirmar</Button>


          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
            }}>cancelar</Button>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>


    </div>
  )


}

export default memo(OnlyInspection)