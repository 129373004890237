import React from "react";
// import { Chart } from "react-google-charts";
import { Os } from "../../interfaces";
import { Pie } from "react-chartjs-2";
import { OS_STATUS, OS_STATUS_COLOR } from "../../constains";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

export const options = { colors: ["red", "blue", "green"] };

const PieOpenCloseOs = React.memo(function PieOpenCloseOs({
  oss,
}: {
  oss: Os[];
}) {
  const counts = {};

  for (const os of oss) {
    //@ts-ignore
    counts[os?.status] = counts[os?.status] ? counts[os?.status] + 1 : 1;
  }

  const chartData = {
    labels: [OS_STATUS[0], OS_STATUS[1], OS_STATUS[2], OS_STATUS[3]],
    datasets: [
      {
        label: "# of Votes",
        //@ts-ignore
        data: [counts?.[0], counts?.[1], counts?.[2], counts?.[3]],
        backgroundColor: [
          OS_STATUS_COLOR[0],
          OS_STATUS_COLOR[1],
          OS_STATUS_COLOR[2],
          OS_STATUS_COLOR[3],
        ],
        borderColor: ["#ececec"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Pie
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "",
            },
          },
        }}
      />
    </>
  );
});

export { PieOpenCloseOs };
