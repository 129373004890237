import styled from "styled-components";
import { User } from "./interfaces";

const host = window.location.hostname;
// const protocol = window.location.protocol;
export const URL_API = `http://${host}`;
export const PORT_API = 3333;
export const LIST_APS = `${URL_API}:${PORT_API}/admin/api/resources/AP/actions/list`;

const dev = `${window.location.origin}/api`;
const prod = "http://wifibr.com.br:3333";
const celular = "http://192.168.1.102:3333"; //will
// const prod = "http://wifibr.com.br/api" não é esse, é o de cima.
const BASE_URL = dev;
// const BASE_URL = prod;

export const LOGIN_URL = `${BASE_URL}/login`;
export const ADMIN_URL = `${BASE_URL}/user`;
export const ADMIN_URL_IMAGE = `${BASE_URL}/image`;

export const USER_URL = `${BASE_URL}/user`;
export const USER_BY_COMPANY = `${BASE_URL}/user/company`;
export const ADMIN_BRANCH_URL = `${BASE_URL}/company`;
export const ADMIN_COMPANY_URL = `${BASE_URL}/company`;
export const COMPANY_URL = `${BASE_URL}/company`;
export const SENSOR_URL = `${BASE_URL}/sensor`;
export const SENSOR_DATA_URL = `${BASE_URL}/sensor/data`;
export const SENSOR_DATA_GROUP_URL = `${BASE_URL}/sensorData/group`;

export const INSPECTION_MODEL = `${BASE_URL}/inspectionModel`;
export const EQUIPMENT = `${BASE_URL}/equipment`;
export const INSPECTION = `${BASE_URL}/inspection`;
export const INSPECTION_COMPANY = `${BASE_URL}/inspection/company`;

export const URL_OS = `${BASE_URL}/os`;
export const IMAGE_URL = `${BASE_URL}/image`;

export const CLIENT_URL = `${BASE_URL}/client`;
export const FILE_URL = `${BASE_URL}/file`;
export const FILE_DOWNLOAD_URL = `${BASE_URL}/file/download`;

export const OS_STATUS: { [key: number]: string } = {
  0: "Aberta", //laranja
  1: "Andamento", //azul
  2: "Concluida", //verde
  3: "Cancelada", //vermelho
  4: "Pendente", //cinza
};

export const OS_STATUS_COLOR: { [key: number]: string } = {
  0: "#fbb02d", //laranja
  1: "#6a8eae", //azul
  2: "#25991f", //verde
  3: "#ef233c", //vermelho
  4: "#b5b5b5", //cinza
};

export const componentOptionsPriority: { [key: number]: string } = {
  0: "Baixa",
  1: "Média",
  2: "Alta",
};
export const optionsPriority = [
  { value: null, label: "Todas" },
  { value: 0, label: "Baixa" },
  { value: 1, label: "Média" },
  { value: 2, label: "Alta" },
];
export const optionsStatus = [
  { value: null, label: "Todas" },
  { value: 0, label: "Aberta" },
  { value: 1, label: "Andamento" },
  { value: 2, label: "Concluida" },
  { value: 3, label: "Cancelada" },
  { value: 4, label: "Pendente" },
];

export const defaultUser: User = {
  name: "",
  email: "",
  password: "",
  agendarVisita: false,
  image: undefined,
  admin: false,

  criarOS: false,
  editarOS: false,
  deletarOS: false,
  visualizarOS: false,

  criarColaborador: false,
  editarColaborador: false,
  deletarColaborador: false,
  visualizarColaborador: false,

  criarCliente: false,
  editarCliente: false,
  deletarCliente: false,
  visualizarCliente: false,

  criarInspection: false,
  editarInspection: false,
  deletarInspection: false,
  visualizarInspection: false,
};

export const ViewScroll = styled.div`
  /* max-height: 740px;
  min-height: 740px; */
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;

export const OS_INITIAL_STATE = {
  title: "",
  priority: 0,
  assignTo: undefined,
  text: "",
  clientId: undefined,
  companyId: undefined,
  users: undefined,
  images: undefined,
  createAt: undefined,
};

export const NULL_USER = {
  email: "",
  name: undefined,
  password: undefined,
  admin: undefined,
  id: undefined,
  company: undefined,
  clientUser: false,

  // criarColaborador?: boolean;
  // editarColaborador?: boolean;
  // deletarColaborador?: boolean;
  // visualizarColaborador?: boolean;

  // criarCliente?: boolean;
  // editarCliente?: boolean;
  // deletarCliente?: boolean;
  // visualizarCliente?: boolean;

  // criarModelo?: boolean;
  // editarModelo?: boolean;
  // deletarModelo?: boolean;
  // visualizarModelo?: boolean;

  // criarOS?: boolean;
  // editarOS?: boolean;
  // deletarOS?: boolean;
  // visualizarOS?: boolean;

  // criarInspection?: boolean;
  // editarInspection?: boolean;
  // deletarInspection?: boolean;
  // visualizarInspection?: boolean;

  // agendarVisita?: boolean;
  // image?: any;
  // companyId?: number | undefined | string;
};
