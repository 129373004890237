import React, { useEffect, useState, useRef, memo, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  CreateInspection,
  GetEquipamentOnly,
  GetModel,
  Delete,
  UpdateInspection,
} from "../api";
import moment from "moment-timezone";
import { Token } from "../../../helpers/token";
import {
  COMPANY_URL,
  EQUIPMENT,
  INSPECTION,
  INSPECTION_MODEL,
} from "../../../constains";
import { Button, Container, Modal, Nav } from "react-bootstrap";
import FilterClientes from "../../../components/FilterClientes";
import styled from "styled-components";
import validator from "@rjsf/validator-ajv8";
import { Images } from "../Images/Images";
import Form from "@rjsf/core";
import Select from "react-select";
import { BsTrash } from "react-icons/bs";
import axios from "axios";
import { CallNotification } from "../../../components/Alert";
import useSWR, { preload, useSWRConfig } from "swr";
import { v4 as uuidv4 } from "uuid";
import { InspectionsOffline } from "../InspectionOffline/inspectionsOffline";
import { addStorageLocalData, updateToServerLocalDataSemNotificacao } from "../../../helpers/utils";
import { Header } from "./Inspection.header";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "ahooks";
import { FaSync } from "react-icons/fa";


function InspectionEquipament() {
  moment.tz.setDefault("America/Sao_Paulo");
  const { mutate } = useSWRConfig();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cliente = queryParams.get('name');
  const equipamento = queryParams.get('equiname');
  const equipamentoId: any = queryParams.get('equiid')
  const clienteId = queryParams.get('clientid')

  const access = Token.getAccess()

  const [modelSelected, setModelSelected] = useState<any>();
  const [data, setData] = useState<any>([]);

  const modalRef: any = useRef(null);
  const history = useHistory();
  const [image, setImage] = useState<Array<string>>([]);
  const [show, setShow] = useState(false);
  // const [show2, setShow2] = useState<any>(false);
  const [show3, setShow3] = useState<any>(false);
  // const { id }: any = useParams();//id do equipamento


  const {
    companyId,
    criarInspection,
    deletarInspection,
    visualizarInspection,
    editarInspection,
  }: any = Token.getTokenData();
  const [formDataDefault, setFormDataDefault] = useState<any>([]);
  const [idDelete, setIdDelete] = useState<any>(null);
  const [selected2, setSelected2] = useState<any>("inspection");
  const handleClose = () => setShow(false);
  const handleClose3 = () => setShow3(false);
  const handleShow = () => {
    setShow(true);
    setSelected2("inspection");
  };
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleForceUpdate = () => {
    setForceUpdate(!forceUpdate);
    CallNotification("objeto criado offline", "", "info");
  };


  const url1 = `${EQUIPMENT}/${equipamentoId}`;
  const url2 = `${INSPECTION_MODEL}?companyId=${companyId}`;
  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const dispatch = useDispatch();

  useMemo(async () => {
    const theCache = await (preload(url1, fetcher));
    setData(theCache)
  }, [url1])

  const { data: te } = useSWR(url1, fetcher);
  const { data: data2 } = useSWR(url2, fetcher);

  const run1 = React.useCallback(() => mutate(url1), [mutate]);

  const dados = useSelector((data: any) => data.offline);
  const getData = () => {
    setData(te)
  }
  useMemo(() => {
    setData(te)

  }, [te])

  useEffect(() => {
    setModelSelected(data?.data?.InspectionmodelId);
  }, []);

  useEffect(() => {
    setImage([]);
    setFormDataDefault({});
  }, [modalRef?.current?.dialog]);

  const create = async (data: any) => {
    const inspection = {
      equipmentId: equipamentoId,
      InspectionmodelId: modelSelected,
      formData: formDataDefault,
      images64: image,

    };
    const url = `${INSPECTION}`;
    const update: any = await axios
      .post(url, inspection, access)
      .then((d) => { run1() })
      .catch((err) => {
        const data = {
          data: inspection,
          method: "POST",
          url: url,
          key: url1,
          offlineData: "onSuccess delete storage",
          id: uuidv4(),
        };
        // storageLocal.set(url1, [data]);
        addStorageLocalData(url1, data, handleForceUpdate, dispatch);
        console.log("deu error", err, "data", data);



        return null;
      });

    const updatedInspection: any = update?.data?.inspection ?? null;
    const DadosAntigos: any = data?.data?.inspections ?? [];

    if (updatedInspection == null) return data;



    data.inspections = [updatedInspection, ...DadosAntigos];
    // console.log(data)
    // setData(data)
    getData();
    return data
  };


  const up = () => {
    // mutate(url1, create, {
    //   revalidate: true,
    //   rollbackOnError: false,
    // });
    create(data);
    // run1()
    handleClose();

  };

  useMemo(() => { run1(); getData(); }, [forceUpdate])

  const inspecaoSelecionada = data2?.data?.filter((value: any) => {
    return value.id == modelSelected;
  });
  const options = FilterClientes(data2?.data, "title")?.map((value: any) => {
    return { label: value?.title, value: value?.id };
  });


  const teste = () => {
    updateToServerLocalDataSemNotificacao(dispatch)
    setTimeout(() => { run1() }, 2000)
    // setTimeout(() => { getData() }, 4000)
  }

// console.log(data)



  return (
    <Container fluid>
      <Header
        equipamentoId={equipamentoId}
        cliente={cliente}
        IdClient={clienteId}
        equipamento={equipamento}
        criarInspection={criarInspection}
        handleShow={handleShow}
      />
      <InspectionsOffline
        chave={url1}
        forceUpdate={forceUpdate}
        Inspectionmodel={inspecaoSelecionada?.[0] ?? {}}
        setForceUpdate={setForceUpdate}
      />

      <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', flexDirection: 'column' }}>

        <FaSync size={25} color='#093338' onClick={() => { teste() }} cursor='pointer' /> 
Sincronizar

      </div>

      {data?.data?.inspections
        ?.slice()
        ?.filter((value: any) => {




          const teste = dados[url1]?.map((result: any) => {
            return result.id
          })


          if (!teste?.includes(value.id)) {
            return value
          }



        })
        // ?.reverse()
        ?.map((value: any, index: any) => {


          return (
            <div key={index}>
              {value !== undefined ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="light"
                    style={{ width: "100%", margin: "3px" }}
                    onClick={() => {
                      history.push(`/onlyInspection/${value.id}?voltar=/inspection/${clienteId}/${equipamentoId}?&name=${cliente}&equiname=${equipamento}&equiid=${equipamentoId}&clientid=${clienteId}`);

                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {value.Inspectionmodel?.title} -{" "}
                      {moment(value?.createAt).format("DD/MM/YYYY - HH:mm")}
                    </div>
                  </Button>

                  <div
                    style={{
                      margin: "7px 0px 0px 0px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Button
                      variant="danger"
                      style={{
                        maxHeight: "40px",
                        display: deletarInspection === true ? "flex" : "none",
                      }}
                      onClick={() => {
                        setIdDelete(value?.id);
                        setShow3(!show3);
                      }}
                    >
                      <BsTrash />
                    </Button>
                  </div>
                </div>
              ) : (
                "Equipamento sem inspeções criadas"
              )}
            </div>
          );
        })}

      <Modal show={show} size="lg" onHide={handleClose} ref={modalRef}>
        <Modal.Header>
          <Modal.Title>Nova inspeção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <Select
            options={options}
            onChange={(ev: any) => {
              setModelSelected(ev.value);
            }}
            // isClearable
            placeholder="selecione um modelo"
            defaultValue={options?.filter((value: any) => {
              return value.value == modelSelected;
            })}
          />
          <DisplayButton>
            <Nav fill variant="tabs" defaultActiveKey={"inspection"}>
              <Nav.Item>
                <Nav.Link
                  eventKey="inspection"
                  style={{
                    color: selected2 == "inspection" ? "#131312" : "#0e0d0d",
                  }}
                  onClick={() => {
                    setSelected2("inspection");
                  }}
                >
                  Inspeção
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="image"
                  style={{
                    color: selected2 == "image" ? "#0d0c0c" : "#0e0d0d",
                  }}
                  onClick={() => {
                    setSelected2("image");
                    // setMode1("add");
                  }}
                >
                  Imagens
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {selected2 == "inspection" ? (
              inspecaoSelecionada?.[0]?.jsonSchema !== undefined ? (
                <Form
                  schema={inspecaoSelecionada?.[0]?.jsonSchema ?? []}
                  validator={validator}
                  formData={formDataDefault}
                  onChange={(e: any) => setFormDataDefault(e.formData)}
                  // onSubmit={() => { }}
                  // onError={(e) => console.log(e)}
                  uiSchema={inspecaoSelecionada?.[0]?.uiSchema ?? []}
                // disabled={disable}
                />
              ) : (
                <></>
              )
            ) : (
              <Images
                mode1={"add"}
                mode={true}
                image={image}
                setImage={setImage}
              // inspectionId={inspectionId}
              />
            )}
          </DisplayButton>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              up();
              // setAtualiza(!atualiza)
              // setFormDataDefault([]);
              // setImage([]);
              // setValueT(null)
              // getData();
            }}
          >
            Salvar
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} size="lg" onHide={handleClose3}>
        <Modal.Header>
          <Modal.Title>
            Tem certeza que deseja excluir esta inspeção?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#ededed",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="danger"
            onClick={() => {
              // @ts-ignore
              Delete(idDelete, "inspection", () => {
                // getData();
                run1();
              }, () => { }
              )
              handleClose3();

              // setValueT(null)
            }}
          >
            Confirmar
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              handleClose3();
            }}
          >
            cancelar
          </Button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Container>
  );
}

export default memo(InspectionEquipament);

const DisplayButton = styled.div`
  .btn {
    display: none;
  }
  .btn-info {
    display: none;
  }
`;
