import React from "react";
import Select from "react-select";
import "./style.css";
import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";

export function SelectFilter({
  optionsPriority,
  osFilterPriority,
  setOsFilterPriority,
  optionsStatus,
  osFilterStatus,
  setOsFilterStatus,
  showOSOpen,
  setShowOsOpen,
}: any) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px 0px 10px 0px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          margin: "0px 30px 0px 0px",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span className="Options_filter">Prioridade: </span>
        <Select
          //@ts-ignore
          options={optionsPriority}
          value={optionsPriority?.filter(
            (option: any) => option.value === osFilterPriority
          )}
          onChange={(ev: any) => setOsFilterPriority(ev.value)}
          className="select_filter"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>

      <div
        style={{
          margin: "0px 30px 0px 0px",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span className="Options_filter">Status:</span>
        <Select
          //@ts-ignore
          options={optionsStatus}
          value={optionsStatus?.filter(
            (option: any) => option.value === osFilterStatus
          )}
          onChange={(ev: any) => setOsFilterStatus(ev.value)}
          className="select_filter"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
      <IonItem>
        <IonCheckbox
          checked={showOSOpen}
          slot="start"
          onIonChange={({ detail }) => setShowOsOpen(detail?.checked)}
        ></IonCheckbox>
        <IonLabel>Exibir OS fechadas</IonLabel>
      </IonItem>
    </div>
  );
}
