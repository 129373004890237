import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { COMPANY_URL, ViewScroll } from "../../constains";
import { apiGet } from "../../helpers/api";
import { Company } from "../../interfaces";
import { Token } from "../../helpers/token";
import DashboardOs from "./DashboardOs";
import DashboardInspection from "./DashboardInspection";

function Dashboard({ setCliente, setEquipamento }: any) {
  const selecionado = window.localStorage.getItem("dashboardSelected" || "Os");
  const [selected, setSelected] = useState<any>(
    selecionado !== null ? selecionado : "Os"
  );
  const componentOptions: { [key: string]: JSX.Element } = {
    Os: <DashboardOs />,
    inspection: (
      <DashboardInspection
      // @ts-ignore
        setCliente={setCliente}
        setEquipamento={setEquipamento}
      />
    ),
  };

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "9",
        }}
      >
        <Nav
          fill
          variant="tabs"
          defaultActiveKey={selecionado || "Os"}
          style={{
            // position: "fixed", zIndex: "999", opacity: '1',
            backgroundColor: `white`,
            width: "100%",
          }}
        >
          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="Os"
              style={{
                color: selected !== "Os" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("Os");
                window.localStorage.setItem("dashboardSelected", "Os");
              }}
            >
              Os
            </Nav.Link>
          </Nav.Item>

          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="inspection"
              style={{
                color: selected !== "inspection" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("inspection");
                window.localStorage.setItem("dashboardSelected", "inspection");
              }}
            >
              Inspection
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <div>{componentOptions[selected]}</div>
    </div>
  );
}

export default Dashboard;
