export default function FilterClientes(array: any, chaveArray: any) {
    // @ts-ignore
    return (array?.sort((a, b) => {
        const keyA = a[chaveArray].toLowerCase();
        const keyB = b[chaveArray].toLowerCase();

        const regex = /^(\d+)\s*-/;
        const matchA = keyA.match(regex);
        const matchB = keyB.match(regex);

        if (matchA && matchB) {
            const numericValueA = parseInt(matchA[1]);
            const numericValueB = parseInt(matchB[1]);

            if (numericValueA < numericValueB) {
                return -1;
            } else if (numericValueA > numericValueB) {
                return 1;
            }
        } else if (matchA && !matchB) {
            return -1;
        } else if (!matchA && matchB) {
            return 1;
        }

        const wordA = keyA.split('-')[1]?.trim();
        const wordB = keyB.split('-')[1]?.trim();

        if (wordA && wordB) {
            return wordA.localeCompare(wordB);
        } else if (wordA && !wordB) {
            return -1;
        } else if (!wordA && wordB) {
            return 1;
        }

        const numericA = parseInt(keyA);
        const numericB = parseInt(keyB);

        if (!isNaN(numericA) && !isNaN(numericB)) {
            return numericA - numericB;
        }

        return keyA.localeCompare(keyB);
    }))
}


const FilterNumber = (array: any) => {
    // @ts-ignore
    return array.sort((a, b) => {
      const keyA = a.name.toLowerCase();
      const keyB = b.name.toLowerCase();

      const regex = /^(\d+)\s*-/;
      const matchA = keyA.match(regex);
      const matchB = keyB.match(regex);

      if (matchA && matchB) {
        const numericValueA = parseInt(matchA[1]);
        const numericValueB = parseInt(matchB[1]);

        if (numericValueA < numericValueB) {
          return -1;
        } else if (numericValueA > numericValueB) {
          return 1;
        }
      } else if (matchA && !matchB) {
        return -1;
      } else if (!matchA && matchB) {
        return 1;
      }

      const wordA = keyA.split('-')[1]?.trim();
      const wordB = keyB.split('-')[1]?.trim();

      if (wordA && wordB) {
        return wordA.localeCompare(wordB);
      } else if (wordA && !wordB) {
        return -1;
      } else if (!wordA && wordB) {
        return 1;
      }

      const numericA = parseInt(keyA);
      const numericB = parseInt(keyB);

      if (!isNaN(numericA) && !isNaN(numericB)) {
        return numericA - numericB;
      }

      return keyA.localeCompare(keyB);
    });
  }
