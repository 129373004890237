import React, { memo, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useTheme } from "styled-components";
import { COMPANY_URL, USER_BY_COMPANY, ViewScroll } from "../../../constains";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Token } from "../../../helpers/token";
import useSWR, { useSWRConfig } from "swr";


function DashboardInspection({ search, timeCollect }: any) {
    const [dados, setDados] = useState<any>([])
    const theme = useTheme();
    const history = useHistory();
    const { companyId, id, clientUser } = Token.getTokenData();
    const access = Token.getAccess();

    const url = `${COMPANY_URL}/${companyId}?span=last${timeCollect.value}days`;
    const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
    const { data, isLoading: loading } = useSWR(url, fetcher);
    const { mutate } = useSWRConfig();
    const run = React.useCallback(() => mutate(url), [mutate]);

    useEffect(() => {
        run();
        window.localStorage.removeItem("clienteSelecionado");
        window.localStorage.removeItem("Equipamento");
        setDados([])
    }, [timeCollect.value]);

    const url2 = `${USER_BY_COMPANY}/${companyId}`;

    const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
    let theUser: any = []
    if (loading2 == false) {
        theUser = allUsers?.data?.filter((value: any) => {
            return value.id == id
        })
    }
    const finalArrayClients = theUser?.[0]?.clients?.map((value: any) => { return value.id })

  

    useEffect(() => {
        data?.data?.clients?.map((data: any) => {
            data?.equipment.forEach((value: any) => {
                setDados((prev: any) => ([...prev, { name: data.name, dados: value }]))
            })
        })
    }, [data?.data?.clients])

    // console.log( data?.data?.clients)
    // @ts-ignore
    const dataForView = dados?.map((data) => {
        return {
            name: data?.name,
            clientId: data?.dados?.clientId,
            inspections: data?.dados?.inspections?.length
        }

    })
    // @ts-ignore
    const grupos = dataForView.reduce((acc, objeto) => {
        const clientId = objeto.clientId;
        if (!acc[clientId]) {
            acc[clientId] = { ...objeto };
        } else {
            acc[clientId].inspections += objeto.inspections;
        }
        return acc;
    }, {});
    // Convertendo o objeto de grupos em um array
    const arrayDeGrupos = Object.values(grupos);

    const tratarFiltro = search.map((valor: any) => {
        return valor.label
    })
    // @ts-ignore
    function verificarStringNoArray(array, string) {
        // Utilize o método includes() para verificar se a string está presente no array
        return array.includes(string);
    }
    return (
        <>
            <Container fluid
            // style={{ paddingLeft: '30px' }}
            >
                <ViewScroll style={{
                    marginTop: '20px',
                    // paddingLeft: '20px'
                }}>


                    <div style={{ maxWidth: '100vw', overflowX: 'auto' }}>

                        <Table striped bordered hover variant={theme.themeName}>
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Inspeções</th>
                                </tr>
                            </thead>




                            <tbody>
                                {arrayDeGrupos
                                    .filter((value: any) => {


                                        if (search.length == 0) {
                                            return value
                                        }


                                        if (verificarStringNoArray(tratarFiltro, value.name)) {
                                            return value
                                        }


                                    })
                                    //@ts-ignore
                                    .sort((a, b) => b.inspections - a.inspections)?.filter((value: any) => {

                                        if ( clientUser == true && finalArrayClients?.includes(value.clientId)) { return value } 
                                        if ( clientUser == false ) { return value } 
                                        
                                        
                                        })
                                    ?.map((data: any, index: any) => {
                                        // console.log(data)
                                        return (
                                            <tr key={index}>
                                                <td
                                                >
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#0d6efd',
                                                            // textDecoration: 'underline'
                                                        }}
                                                        onClick={() => {
                                                            history.push(`inspection/${data.clientId}?name=${data.name}&clientid=${data.clientId}`);
                                                            // setCliente(data.name)
                                                            window.localStorage.setItem('clienteSelecionado', data.name)
                                                        }}

                                                       
                                                    >



                                                        {data.name}

                                                    </span>




                                                </td>
                                                <td
                                                    style={{
                                                        // cursor: 'pointer'
                                                    }}
                                                    // onClick={() => {
                                                    //     history.push(`inspection/${data.clientId}`);
                                                    // }}
                                                >{data.inspections}</td>
                                            </tr>
                                        )

                                    })}



                            </tbody>
                        </Table>
                    </div>

                </ViewScroll>
            </Container >
        </>

    );
}


export default memo(DashboardInspection);
