import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
// import A4 from "./Rectangle.svg";
// import Client from "./client.svg";
import { Token } from "../../helpers/token";
import { ADMIN_BRANCH_URL, CLIENT_URL } from "../../constains";
import { useLocation } from "react-router-dom";
import moment from "moment";
import OsFormat from "./OsFormat";



export const ComponentToPrint = React.forwardRef((props, ref) => {

  const access = Token.getAccess();
  const idEm = Token.getTokenData().companyId;
  const [empresa, setEmpresa] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  // ==================parametros============================================================================================
  const name = queryParams.get("name") || '';
  const cnpj = queryParams.get("cnpj") || '';
  const address = queryParams.get("address") || '';
  const phone = queryParams.get("phone") || '';
  const title = queryParams.get("title") || '';
  const email = queryParams.get("email") || '';
  const info = queryParams.get("empresaInfo") || '';
  const logo = queryParams.get("logo") || '';
  const clienteLogo = queryParams.get("clienteLogo") || '';
  const clientId = queryParams.get("clientId") || '';
  const infoClient = queryParams.get("clienteInfo") || '';
  const tipo = queryParams.get("tipo") || '';
  const capaLogo = queryParams.get("capaLogo") || '';
  const titleCapa = queryParams.get("titleCapa") || '';
  const fontSize = parseInt(queryParams.get("fontSize")) || '';
  const marginType = queryParams.get("marginType") || '';
  const textAli = queryParams.get("textAli") || '';
  const companyId = queryParams.get("companyId") || '';
  const osid = queryParams.get("osid") || '';
  // console.log(osid)
  // =====================================xxxxxxxxxxxxxxxxxxxxxxxxx==========================================================
  const clients = empresa?.clients;
  const clientSelected = clients?.filter((client) => {
    return client.id == clientId;
  });
  const data = new Date();
  const Title = {
    backgroundColor: "#e6e5e5",
    fontWeight: "bold",
    font: "1.2rem sans-Serif",
  };
  const subTitle = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "800",
    opacity: 0.7,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const font = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "400",
    opacity: 0.5,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const fontBold = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "bold",
    opacity: 1,
    fontSize: "16px",
  };
  useEffect(() => {
    axios
      .get(`${ADMIN_BRANCH_URL}/${idEm ?? companyId}`, access)
      .then((res) => {
        setEmpresa(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

// console.log(empresa)
// console.log(clientSelected)

  return (
    <div
      ref={ref}
      style={{

        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        id='relatorio'
        className="noborder"
        // key="key2"
        style={{
          border: '1px solid',
          display: "flex",
          flexDirection: "column",
          // backgroundImage: `url(${A4})`,
          width: "1000px",
          padding: "25px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >

        {clientSelected?.length !== 0 ? <div
          // id='relatorio'
          style={{
            height: '1400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '10px 25px'
          }}>
          <div style={{
            height: '100%',
            width: '100%',
            border: ` 1px ${marginType == '' ? empresa?.config?.marginType : marginType}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden'
            // margin: '1px'
          }}>


            <span
              style={{
                marginTop: '200px',
                marginBottom: '15px'
              }}
            >{moment(data).format("DD/MM/YYYY")}
            </span>


            {capaLogo == '' ? (empresa?.config?.capaLogo == true ? <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // height: '400px',
              maxHeight: '400px',
              // width: '600px',
              maxWidth: '600px',
              // border: '1px solid'
            }}>


              {empresa?.config?.image == null ? <>Adicione um logo para a capa em configurações!</> :
                <img
                  src={empresa?.config?.image}
                  alt="Logo"
                  style={{ width: "100%", maxHeight: "100%" }}
                />
              }
              {/* <img
                src={empresa?.image64}
                alt="Logo"
              // style={{ width: "100%", maxHeight: "100%" }}
              /> */}



            </div> : <></>) : (capaLogo == 'true' ?
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '400px',
                maxHeight: '400px',
                // width: '600px',
                maxWidth: '600px',
                // border: '1px solid'
              }}>

                {empresa?.config?.image == null ? <>Adicione um logo para a capa em configurações!</> :
                  <img
                    src={empresa?.config?.image}
                    alt="Logo"
                    style={{ width: "100%", maxHeight: "100%" }}
                  />
                }

                {/* <img
                src={empresa?.image64}
                alt="Logo"
              // style={{ width: "100%", maxHeight: "100%" }}
              /> */}



              </div>
              : <></>)}


            <div
              style={
                {
                  marginTop: '30px'
                }}>

              <div
                style={{
                  display: 'flex',
                  width: '800px',
                  // border: '1px solid',
                  justifyContent: 'center',
                  alignItems: 'center',

                }}>

                <span style={{
                  maxWidth: '800px',
                  wordWrap: 'break-word',
                  fontSize: fontSize == '' ? empresa?.config?.fontSize : fontSize,
                  textAlign: `${textAli == '' ? empresa?.config?.textAli : textAli}`,



                }}>{titleCapa == '' ? empresa?.config?.titleCapa : titleCapa}</span>

              </div>

            </div>



          </div>
        </div> : <></>}




        <div
          // key="key3"
          style={{
            display: "flex",
            flexDirection: "column",
            // minHeight: '100%',
          }}
        >
          {/* ================================PARTE PARA IRFORMAÇÃO DA EMPRESA======================================================================================*/}


          {clientSelected?.length == 0 ? <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}>Selecione um cliente para visualizar o relatório.</div> :
            (clientSelected?.map((clientoflist, key) => {
              return (
                <>
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      // minHeight: '100%',
                      position: "relative",
                    }}
                  >
                    <h4 className="title">
                      {title !== "" ? title : empresa?.config?.title}
                    </h4>
                    <div
                      style={{
                        position: "absolute",
                        // maxWidth: '200px',
                        // maxHeight: '150px',
                        top: "0px",
                        right: "60px",
                        // borderRadius: '40px',
                        // border: '1px solid '
                      }}
                    >
                      {logo == "" ?
                        (empresa?.config?.logo == true ? (clienteLogo == "" ?
                          (empresa?.config?.clienteLogo == true ? (
                            // empresa?.image64 !== null ? (
                            // <img
                            //   src={empresa?.image64}
                            //   alt="Logo"
                            //   style={{ width: "auto", maxHeight: "120px" }}
                            // />
                            <div style={{ width: "auto", maxHeight: "120px" }}> logo do cliente </div>
                            // )
                            //   :
                            //   (
                            //     <></>
                            //   )
                          ) : (
                            empresa?.image64 !== null ? (
                              <img
                                src={empresa?.image64}
                                alt="Logo"
                                style={{ width: "auto", maxHeight: "120px" }}
                              />
                            )
                              :
                              (
                                <></>
                              )
                          )
                          )
                          :
                          // caso n seja vazio
                          (clienteLogo == "true" ? (
                            // empresa?.image64 !== null ? (
                            //   <img
                            //     src={empresa?.image64}
                            //     alt="Logo"
                            //     style={{ width: "auto", maxHeight: "120px" }}
                            //   />
                            // )
                            // :
                            // (
                            //   <></>
                            // )

                            <div style={{ width: "auto", maxHeight: "120px" }}> logo do cliente </div>
                          ) :

                            (
                              empresa?.image64 !== null ? (
                                <img
                                  src={empresa?.image64}
                                  alt="Logo"
                                  style={{ width: "auto", maxHeight: "120px" }}
                                />
                              )
                                :
                                (
                                  <></>
                                )
                            )


                          )
                        ) : <></>)
                        :
                        (logo == "true" ? (clienteLogo == "" ?
                          (empresa?.config?.clienteLogo == true ? (
                            // empresa?.image64 !== null ? (
                            // <img
                            //   src={empresa?.image64}
                            //   alt="Logo"
                            //   style={{ width: "auto", maxHeight: "120px" }}
                            // />
                            <div style={{ width: "auto", maxHeight: "120px" }}> logo do cliente </div>
                            // )
                            //   :
                            //   (
                            //     <></>
                            //   )
                          ) : (
                            empresa?.image64 !== null ? (
                              <img
                                src={empresa?.image64}
                                alt="Logo"
                                style={{ width: "auto", maxHeight: "120px" }}
                              />
                            )
                              :
                              (
                                <></>
                              )
                          )
                          )
                          :
                          // caso n seja vazio
                          (clienteLogo == "true" ? (
                            // empresa?.image64 !== null ? (
                            //   <img
                            //     src={empresa?.image64}
                            //     alt="Logo"
                            //     style={{ width: "auto", maxHeight: "120px" }}
                            //   />
                            // )
                            // :
                            // (
                            //   <></>
                            // )

                            <div style={{ width: "auto", maxHeight: "120px" }}> logo do cliente </div>
                          ) :

                            (
                              empresa?.image64 !== null ? (
                                <img
                                  src={empresa?.image64}
                                  alt="Logo"
                                  style={{ width: "auto", maxHeight: "120px" }}
                                />
                              )
                                :
                                (
                                  <></>
                                )
                            )


                          )
                        ) : <></>)
                      }
                    </div>
                  </div>



                  {info == "" ? (
                    empresa?.config?.empresaInfo == true ? (
                      <>
                        {empresa?.name !== "" ? (
                          <span className="font">
                            {/* Iridium Engenharia */}
                            {name !== "" ? name : empresa?.name}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.phone !== "" ? (
                          <span className="font">
                            {/* Telefone: (21)21103544 */}
                            <span className="subTitle" style={fontBold}>
                              Telefone:{" "}
                            </span>
                            {phone !== "" ? phone : empresa?.phone}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.cnpj !== "" ? (
                          <span className="font">
                            {/* CNPJ: 36.174.476/0001-96 */}
                            <span className="subTitle" style={fontBold}>
                              CNPJ:{" "}
                            </span>
                            {cnpj !== "" ? cnpj : empresa?.cnpj}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.email !== "" ? (
                          <span className="font">
                            {/* Email: contato@termolibra.com.br */}

                            <span className="subTitle" style={fontBold}>
                              Email:{" "}
                            </span>
                            {email !== "" ? email : empresa?.email}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.address !== "" ? (
                          <span className="font">
                            {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                            <span className="subTitle" style={fontBold}>
                              Endereço:{" "}
                            </span>

                            {address !== "" ? address : empresa?.address}
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div style={{ height: "70px" }}></div>
                    )
                  ) : info == "true" ? (
                    <>
                      {empresa?.name !== "" ? (
                        <span className="font">
                          {/* Iridium Engenharia */}
                          {name !== "" ? name : empresa?.name}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.phone !== "" ? (
                        <span className="font">
                          {/* Telefone: (21)21103544 */}
                          <span className="subTitle" style={fontBold}>
                            Telefone:{" "}
                          </span>
                          {phone !== "" ? phone : empresa?.phone}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.cnpj !== "" ? (
                        <span className="font">
                          {/* CNPJ: 36.174.476/0001-96 */}
                          <span className="subTitle" style={fontBold}>
                            CNPJ:{" "}
                          </span>
                          {cnpj !== "" ? cnpj : empresa?.cnpj}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.email !== "" ? (
                        <span className="font">
                          {/* Email: contato@termolibra.com.br */}

                          <span className="subTitle" style={fontBold}>
                            Email:{" "}
                          </span>
                          {email !== "" ? email : empresa?.email}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.address !== "" ? (
                        <span className="font">
                          {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                          <span className="subTitle" style={fontBold}>
                            Endereço:{" "}
                          </span>

                          {address !== "" ? address : empresa?.address}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div style={{ height: "70px" }}></div>
                  )}

                  {/* ================================PARTE INFORMAÇÃO DO CLIENTE============================================================================================*/}

                  {infoClient == "" ? (empresa?.config?.clienteInfo && (
                    <Table key={key} style={{ marginTop: "20px" }}>
                      <thead>
                        <tr>
                          <td className="subTitle" colSpan={2} style={Title}>
                            Informações do cliente
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="font" style={subTitle}>
                              Nome do cliente
                            </span>
                            <div className="font" style={font}>
                              {/* BODYTECH - VOGUE */}
                              {/* {clientoflist?.name} */}
                              {clientoflist?.name !== null
                                ? clientoflist?.name
                                : "Não cadastrado."}
                            </div>
                          </td>
                          {/* <td>
                          <span className="font" style={subTitle}>
                            CPF/CNPJ
                          </span>
                          <div style={font}> */}
                          {/* 07.737.623/0077-99 */}
                          {/* {clientoflist?.cnpj !== null
                              ? clientoflist?.cnpj
                              : "Não cadastrado."}
                          </div>
                        </td> */}
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span className="font" style={subTitle}>
                              E-mail
                            </span>

                            <div className="font" style={font}>
                              {/* thiago.souza@bodytech.com.br */}
                              {/* {clientoflist?.email} */}
                              {clientoflist?.email !== null
                                ? clientoflist?.email
                                : "Não cadastrado."}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span className="font" style={subTitle}>
                              Endereço
                            </span>
                            <div className="font" style={font}>
                              {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                              {clientoflist?.address !== null
                                ? clientoflist?.address
                                : "Não cadastrado."}
                              {/* {clientoflist?.address} */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )) : (
                    infoClient == "true" ?
                      <Table key={key} style={{ marginTop: "20px" }}>
                        <thead>
                          <tr>
                            <td className="subTitle" colSpan={2} style={Title}>
                              Informações do cliente
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className="font" style={subTitle}>
                                Nome do cliente
                              </span>
                              <div className="font" style={font}>
                                {/* BODYTECH - VOGUE */}
                                {/* {clientoflist?.name} */}
                                {clientoflist?.name !== null
                                  ? clientoflist?.name
                                  : "Não cadastrado."}
                              </div>
                            </td>
                            <td>
                              <span className="font" style={subTitle}>
                                CPF/CNPJ
                              </span>
                              <div style={font}>
                                {/* 07.737.623/0077-99 */}
                                {clientoflist?.cnpj !== null
                                  ? clientoflist?.cnpj
                                  : "Não cadastrado."}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span className="font" style={subTitle}>
                                E-mail
                              </span>

                              <div className="font" style={font}>
                                {/* thiago.souza@bodytech.com.br */}
                                {/* {clientoflist?.email} */}
                                {clientoflist?.email !== null
                                  ? clientoflist?.email
                                  : "Não cadastrado."}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span className="font" style={subTitle}>
                                Endereço
                              </span>
                              <div className="font" style={font}>
                                {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                                {clientoflist?.address !== null
                                  ? clientoflist?.address
                                  : "Não cadastrado."}
                                {/* {clientoflist?.address} */}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      :
                      <></>

                  )}

           


                  <OsFormat
                    // id={Id}
                  />

                </>
              );
            }))
          }






        </div>
      </div>

    </div>
  );
});
