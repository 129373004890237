import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import React from "react";
import {  useHistory } from "react-router";

import "./Page.css";

const Page: React.FC = () => {
  // const { name } = useParams<{ name: string }>();
  const name = "test";
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div>testando</div>
      <IonContent>
        <IonButton
          onClick={() => {
            history.replace("/login");
          }}
        >
          LOGOUT
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Page;
