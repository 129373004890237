// import { IonicStorageModule } from '@ionic/storage';

// export const ionicStorageConfig = {
//   name: '_myappdb',
//   driverOrder: ['sqlite', 'indexeddb', 'websql']
// };

// IonicStorageModule.forRoot(ionicStorageConfig)

import { Storage, Drivers } from "@ionic/storage";

import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

export const storage = new Storage({
  name: "_main",
  storeName: "main",
  driverOrder: [
    CordovaSQLiteDriver._driver,
    Drivers.IndexedDB,
    Drivers.LocalStorage,
  ],
});

export const storageLocal = new Storage({
  name: "_main_local",
  storeName: "main_local",
  driverOrder: [
    CordovaSQLiteDriver._driver,
    Drivers.IndexedDB,
    Drivers.LocalStorage,
  ],
});

// export const storage = new Storage({
//     name: '__mydb',
//     driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
//   });

storage.create();
storageLocal.create();
