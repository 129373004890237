import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonRouterOutlet,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonHeader,
  IonMenuButton,
  IonContent,
} from "@ionic/react";
import React from "react";
import { Redirect, Route } from "react-router";
import { person, call } from "ionicons/icons";
import { Home as ComponentQualquer } from "./index";
import AccountTabPage from "../pages/AccountTabPage";
import TabPage from "../pages/TabPage";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const TabRootPage: React.FC = () => {
  // return <div>mobile</div>;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{"pageName"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Home">
            <div>conteudo 1</div>
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <div>conteudo 2</div>
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            <div>conteudo 3</div>
          </Tab>
        </Tabs>
      </IonContent>
    </IonPage>
  );
  
};

export default TabRootPage;
