/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AdicionarUsuario.css";
import { Table } from "react-bootstrap";
import { useTheme } from "styled-components";
import { ADMIN_COMPANY_URL, ADMIN_URL } from "../../constains";

function LookFiliaisEmpresa({ id }: any) {
  const [empresa, setEmpresa] = useState<any>([]);
  const { themeName } = useTheme();
  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    axios
      .get(`${ADMIN_COMPANY_URL}/${id}`, access)
      .then((res) => {
        setEmpresa(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

 

  return (
    <div className="AdicionarUsuario">
      <form>
        <div className="Linha">
          <div className="Coluna">
            <label>Nome:</label>
            <input
              disabled
              type="text"
              name="login"
              value={empresa.name}
              onChange={(event) =>
                setEmpresa((prevState: any) => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Endereço:</label>
            <input
              disabled
              type="text"
              name="login"
              value={empresa.address}
              onChange={(event) =>
                setEmpresa((prevState: any) => ({
                  ...prevState,
                  address: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Telefone:</label>
            <input
              disabled
              type="text"
              name="login"
              value={empresa.phone}
              onChange={(event) =>
                setEmpresa((prevState: any) => ({
                  ...prevState,
                  phone: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>CNPJ:</label>
            <input
              disabled
              type="text"
              name="login"
              value={empresa.cnpj}
              onChange={(event) =>
                setEmpresa((prevState: any) => ({
                  ...prevState,
                  cnpj: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Email:</label>
            <input
              disabled
              type="text"
              name="login"
              value={empresa.email}
              onChange={(event) =>
                setEmpresa((prevState: any) => ({
                  ...prevState,
                  email: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LookFiliaisEmpresa;
