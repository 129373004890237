import React from "react";
import { Button, Container, Modal, Nav } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";

export const Header = ({
  cliente,
  IdClient,
  equipamento,
  criarInspection,
  handleShow,
}: any) => {
  const history = useHistory();

  return (
    <>
      <div>
        <div
          style={{
            position: "sticky",
            top: "0",
            zIndex: "9999",
            backgroundColor: "white",
            padding: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingBottom: "10px",
            }}
          >
            Cliente: {cliente}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingBottom: "10px",
            }}
          >
            Equipamento: {equipamento}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                history.push(`/inspection/${IdClient}?name=${cliente}&clientid=${IdClient}`);
                
              }}
              variant="secondary"
              style={{
                textDecoration: "none",
                width: "50%",
                justifyContent: "center",
                display: "flex",
                margin: "0px 15px 0px 0px",
              }}
            >
              Voltar
            </Button>
            <Button
              variant="primary"
              style={{
                textDecoration: "none",
                width: "50%",
                justifyContent: "center",
                display: criarInspection === true ? "flex" : "none",
              }}
              onClick={() => {
                handleShow();
                // getData();
              }}
            >
              Nova inspeção
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
