import EditarUsuario from "../EditarUsuario/EditarUsuario";
// import { useState } from "react";
// import Nav from "react-bootstrap/Nav";
// import EditarFilial from "../EditarUsuario/Branch";
// import styled from 'styled-components';

function PillsExample(props: any) {

  // const [selected, setSelected] = useState<string>("usuario");
  // const componentOptions: { [key: string]: JSX.Element } = {
  //   usuario: <EditarUsuario id={props.id} />,
  //   filial: <EditarFilial id={props.id} />,
  // };

  
  return (
    <div>
      {/* <Nav variant="pills" defaultActiveKey="/home"> */}
        {/* <Nav.Item>
          <Nav.Link
            eventKey="usuario"
            style={{
              color: selected !== "usuario" ? "var(--text-color)" : "",
            }}
            // active = {selected == "day" ? true : false}
            onClick={() => setSelected("usuario")}
          >
            Usuário
          </Nav.Link>
        </Nav.Item> */}

        

        {/* <Nav.Item>
          <Nav.Link
            eventKey="filial"
            style={{
              color: selected !== "filial" ? "var(--text-color)" : "",
            }}
            // active = {selected == "day" ? true : false}
            onClick={() => setSelected("filial")}
          >
            Filiais
          </Nav.Link>
        </Nav.Item> */}
      {/* </Nav> */}
      <div><EditarUsuario id={props.id} /></div>
      {/* <div>{componentOptions[selected]}</div> */}
    </div>
  );
}

// const DivGeral = styled.div`
// max-height: 500px;
//   &::-webkit-scrollbar {
//     width: 5px;
//     height: 10px;
//     -webkit-border-radius: 16px;
//   }

//   &::-webkit-scrollbar-track-piece {
//     background-color: #ffffff;
//     -webkit-border-radius: 3px;
//   }

//   &::-webkit-scrollbar-thumb:vertical {
//     height: 5px;
//     background-color: #666;
//     -webkit-border-radius: 3px;
//   }
// `;


export default PillsExample;
