import React, { useEffect, useState } from "react";
import axios from "axios";
import "./EditarEmpresa.css";
import { CallNotification } from "../Alert";
import { ADMIN_COMPANY_URL } from "../../constains";

function EditarEmpresa({ id, getData, handleClose2 }: any) {
  const [empresa, setEmpresa] = useState({
    name: '',
    address: '',
    phone: '',
    cnpj: '',
    email: ''
  });

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();



    axios
      .put(`${ADMIN_COMPANY_URL}/${id}`, empresa, access)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          CallNotification(
            "Sucesso",
            "Empresa editada com sucesso!",
            "success"
          );
          // window.location.reload();
          getData();
          handleClose2();
        }
      })
      .catch((err) => {
        console.log(err);
        CallNotification("Error", "Falha ao editar empresa", "danger");
      });
  };

  useEffect(() => {
    axios
      .get(`${ADMIN_COMPANY_URL}/${id}`, access)
      .then((res) => {
        // console.log(res.data.data)
        const dados = res.data.data;
        setEmpresa({
          name: dados.name,
          address: dados.address,
          phone: dados.phone,
          cnpj: dados.cnpj,
          email: dados.email
        })
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  // console.log(empresa)

  return (
    <div className="AdicionarUsuario">
      <form onSubmit={onSubmitHandler}>
        <div className="Linha">
          <div className="Coluna">
            <label>Nome:</label>
            <input
              type="text"
              name="login"
              value={empresa.name}
              onChange={(event) =>
                setEmpresa((prevState) => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Endereço:</label>
            <input
              type="text"
              name="login"
              value={empresa.address}
              onChange={(event) =>
                setEmpresa((prevState) => ({
                  ...prevState,
                  address: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Telefone:</label>
            <input
              type="text"
              name="login"
              value={empresa.phone}
              onChange={(event) =>
                setEmpresa((prevState) => ({
                  ...prevState,
                  phone: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>CNPJ:</label>
            <input
              type="text"
              name="login"
              value={empresa.cnpj}
              onChange={(event) =>
                setEmpresa((prevState) => ({
                  ...prevState,
                  cnpj: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label>Email:</label>
            <input
              type="text"
              name="login"
              value={empresa.email}
              onChange={(event) =>
                setEmpresa((prevState) => ({
                  ...prevState,
                  email: event.target.value,
                }))
              }
              required
            ></input>
          </div>
        </div>

        <button className="btn btn-primary" type="submit">
          Confirmar
        </button>
      </form>
    </div>
  );
}

export default EditarEmpresa;
