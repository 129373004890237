import axios from "axios";
import { formatDistance, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AiFillEye,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlinePlus,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import styled from "styled-components";
import AdicionarOs from "../../components/AdicionarOs/adicionarOs";
import { CallNotification } from "../../components/Alert";
import Card from "../../components/card/card";
import { componentOptionsPriority, OS_STATUS, URL_OS } from "../../constains";
import {
  colorOptionsPriority,
  colorOptionsStatus,
} from "../../styles/cssVariables";
import { ptBR } from "date-fns/locale";
import EditarOs from "../../components/ViewOs/ViewOs";

export const MinhasOs = (): React.ReactElement => {
  const [id, setId] = useState("");
  const [OS, setOS] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const getData = () => {
    axios
      .get(URL_OS, access)
      .then((response) => {
        // console.log(response.data.data)
        setOS(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const componentOptionsPriority: { [key: number]: any } = {
    0: <AiOutlineArrowDown size={27} />,
    1: <AiOutlineArrowUp size={27} />,
    2: <AiOutlineArrowUp size={27} />,
  };

  const TakeId = (id: any) => {
    return setId(id);
  };
  return (
    <Container>
      <Cabecalho>
        <Titulo> Minhas OSs</Titulo>

        <Button
          style={{ height: "auto", fontSize: "20px" }}
          variant="primary"
          onClick={handleShow}
        >
          <AiOutlinePlus /> Nova OS
        </Button>
      </Cabecalho>

      <hr />

      <Os>
        {OS.map((value: any, index: any) => {
          return (
            <>
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <OsOrganization key={index}>
                    <>
                      <Imagem>Img </Imagem>
                      <Informacoes>
                        <OsIdName>{value?.title}</OsIdName>

                        <Osgrautempo>
                          <Grau
                            style={{
                              backgroundColor: `${
                                colorOptionsPriority[value?.priority]
                              }`,
                            }}
                          >
                            {componentOptionsPriority[value?.priority]}
                          </Grau>

                          <div
                            style={{
                              backgroundColor: `${
                                colorOptionsStatus[value?.status]
                              }`,
                              color: `black`,
                              borderRadius: "10px",
                              padding: "2px",
                            }}
                          >
                            {OS_STATUS[value?.status]}
                          </div>

                          <Tempo>
                            <BiTimeFive style={{ marginRight: "5px" }} />
                            {formatDistance(
                              new Date(value?.createAt),
                              new Date(),
                              {
                                locale: ptBR,
                              }
                            )}{" "}
                            Atrás.
                          </Tempo>
                        </Osgrautempo>
                      </Informacoes>
                    </>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "40px",
                        // alignItems: "flex-end",
                        marginLeft: "auto",
                        padding: "0px 10px 0px 0px",
                      }}
                    >
                      <AiFillEye
                        size={30}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleShow2();
                          TakeId(value.id);
                        }}
                      />
                    </div>
                  </OsOrganization>
                }
              />
            </>
          );
        })}
      </Os>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nova O.S.</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <AdicionarOs handleClose={handleClose} getData={getData} />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show2} size="lg" onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Ver Os</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <EditarOs handleClose={handleClose2} id={id} />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`;

const Cabecalho = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Titulo = styled.span`
  padding-left: 25px;
`;
const Imagem = styled.div`
  background-color: var(--gray-500);
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Informacoes = styled.div`
  display: flex;
  flex-direction: column;
`;
const OsIdName = styled.div`
  margin-left: 20px;
  color: var(--blue-700);
`;
const Osgrautempo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Grau = styled.span`
  /* border: 1px solid var(--red-700); */
  border-radius: 10px;
  height: auto;
  width: auto;
  /* color: var(--red-700); */
  color: black;
  /* background-color: var(--red-300); */
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  margin: 0px 10px 0px 0px;
`;
const Tempo = styled.span`
  margin-left: 10px;
  border: 1px solid var(--blue-700);
  border-radius: 10px;
  height: auto;
  width: auto;
  color: var(--blue-700);
  background-color: var(--blue-200);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const OsOrganization = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: red; */
`;

const Os = styled.div`
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
