// import {
//   IonTabs,
//   IonTabBar,
//   IonTabButton,
//   IonIcon,
//   IonRouterOutlet,
// } from "@ionic/react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
} from "@ionic/react";

import React from "react";
import "./TabRootPage.css";
import {Tab, Tabs} from "react-bootstrap";

const TabRootPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{"pageName"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Home">
            <div>conteudo 1</div>
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <div>conteudo 2</div>
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            <div>conteudo 3</div>
          </Tab>
        </Tabs>
      </IonContent>
    </IonPage>
  );
};

export default TabRootPage;
