import React, { memo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CreateModelo, DeleteModel, GetEquipamentOnly, GetModel } from './api';
import { Button, Container, InputGroup, Modal } from 'react-bootstrap';
import 'codemirror/lib/codemirror.css';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/theme/dracula.css';
import FormB from 'react-bootstrap/Form';

import axios from "axios";

import { useRequest } from 'ahooks';


import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import styled from 'styled-components';

import { Accordion } from '@mantine/core';
import { Token } from '../../helpers/token';
import { CallNotification } from '../../components/Alert';
import { INSPECTION_MODEL } from '../../constains';
import { BsTrash } from 'react-icons/bs';
import FilterClientes from '../../components/FilterClientes';
import { HiOutlinePencilSquare } from 'react-icons/hi2';



function InspectionCompanyModelos() {
  const { criarModelo, deletarModelo, visualizarModelo, editarModelo } = Token.getTokenData();
  let { id }: any = useParams();
  const history = useHistory();
  const [model, setModel] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [digitadoJsonSchema, setDigitadoJsonSchema] = useState('');
  const [digitadoUiSchema, setDigitadoUiSchema] = useState('');
  const [mudou, setMudou] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [idDelete, setIdDelete] = useState<any>(null);


  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };


  const options = {
    lineNumbers: true,
    // matchBrackets: true,
    // // mode: 'javascript',
    theme: 'dracula'
  };



  const handleClose = () => { setShow(false) };
  const handleClose2 = () => { setShow2(false) };
  const handleClose3 = () => { setShow3(false) };
  const handleShow = () => { setShow(true) };
  const handleShow2 = () => { setShow2(true) };
  const handleShow3 = () => { setShow3(true) };

  function getData() {
    GetModel(
      id,
      setModel);

  }

  useEffect(() => {
    getData()
  }, [mudou]);

  React.useEffect(() => {

    // const handleEdit = () => {
    axios
      .get(`${INSPECTION_MODEL}/${idDelete}`, access)
      .then((res) => {
        // console.log(res.data.data)
        // console.log(JSON.stringify(res.data.data.jsonSchema))
        setTitle(res.data.data.title)
        setDigitadoJsonSchema(JSON.stringify(res.data.data.jsonSchema))
        setDigitadoUiSchema(JSON.stringify(res.data.data.uiSchema))

      })
      .catch((error) => {

      });
    // }


  }, [idDelete])




  const handleEdit = () => {
    axios
      .put(`${INSPECTION_MODEL}/${idDelete}`,
        { title: title, jsonSchema: JSON.parse(digitadoJsonSchema), uiSchema: JSON.parse(digitadoUiSchema) },
        access)
      .then((res) => {
        // console.log(res)
      })
      .catch((error) => {

      });
  }


  


  function getInspections(): Promise<any> {
    // const { ClientId } = Token.getTokenData();
    // console.log('é o clientid', ClientId)
    return fetch(`${INSPECTION_MODEL}?companyId=${id}`)
      .then((r) => r.json())
      .catch((err) => console.error(err));
  }

  const { data, loading } = useRequest(getInspections, {
    cacheKey: "inspection-getModelsSchema",
    onSuccess: ({ data }: any) => {

      setModel(data);
      // store.setItem("Model Schema", data)

    },
    onError: ({ data }: any) => {

      const dataCash = async () => {

        try {

          // const valueModel: any = await store.getItem('Model Schema');
          // setModel(valueModel);

        } catch (err) {

          console.log(err);
        }

      }
      dataCash();

    },
  });













  function handleCreate() {
    CreateModelo(digitadoJsonSchema, digitadoUiSchema, title, id, getData);
    getData();
  }

  return (
    <Container fluid style={{ height: '90vh' }}>


      <div style={{
        position: 'sticky',
        top: '0',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '10px',
        zIndex: '99',
      }}>
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px 10px 0px' }}
        >Modelos</div>


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              history.push('/inspection');
            }}
            variant="secondary"
            style={{
              textDecoration: 'none',
              width: '50%',
              justifyContent: 'center',
              display: 'flex',
              margin: '0px 15px 0px 0px'
            }}>
            Voltar
          </Button>

          <Button
            variant="primary"
            style={{
              textDecoration: 'none',
              width: '50%',
              justifyContent: 'center',
              display: criarModelo == true ? 'flex' : 'none'
            }} onClick={handleShow}
          >
            Novo modelo
          </Button>

        </div>
      </div>







      {visualizarModelo == true ? FilterClientes(model, 'title')?.map((value: any, index: any) => {
        return (
          <div key={index} style={{
            display: 'flex',
            justifyContent: 'space-between',

            margin: '10px 0px 0px 0px',
            borderRadius: '10px'
          }}>


            <div style={{ width: '95%' }}>

              <Accordion defaultValue="customization">
                <Accordion.Item value="focus-ring">
                  <Accordion.Control>
                    {value?.title}
                  </Accordion.Control>
                  <Accordion.Panel>

                    <DisplayButton>
                      <Form
                        schema={value?.jsonSchema}
                        validator={validator}
                        uiSchema={value?.uiSchema}
                        disabled
                      />
                    </DisplayButton>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>



            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>


              <Button
                variant='primary'
                style={{
                  maxHeight: '40px',
                  display: deletarModelo == true ? 'flex' : 'none'
                }}
                onClick={() => {
                  handleShow3();
                  setIdDelete(value.id)

                }}>  <HiOutlinePencilSquare size={25} /></Button>





              <Button
                variant='danger'
                style={{
                  maxHeight: '40px',
                  display: deletarModelo == true ? 'flex' : 'none'
                }}
                onClick={() => {
                  handleShow2();
                  setIdDelete(value.id)
                  // DeleteModel(value.id, getData, () => { CallNotification("Info", "Existe inspeções utilizando este modelo", "info"); });
                  // setMudou(!mudou);
                }}>  <BsTrash size={25} /></Button>

            </div>

          </div >
        )


      }) : 'Este usuário não possui permissão para ver os modelos'}


      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Novo modelo</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: "#ededed" }}
        >


          <div style={{ margin: '0px 0px 10px 0px' }} >JsonSchema</div>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Título</InputGroup.Text>
            <FormB.Control
              onChange={(ev: any) => { setTitle(ev.target.value) }}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </InputGroup>

          {/* @ts-ignore */}
          <CodeMirror
            value={digitadoJsonSchema}
            options={options}
            onBeforeChange={(editor, data, code) => {

              setDigitadoJsonSchema(code);
            }}
          />




          <div style={{ margin: '10px 0px 10px 0px' }} >UiSchema</div>
          {/* @ts-ignore */}
          <CodeMirror
            value={digitadoUiSchema}
            options={options}
            onBeforeChange={(editor, data, code) => {

              setDigitadoUiSchema(code);
            }}
          />




        </Modal.Body>
        <Modal.Footer>
          <div style={{
            position: 'absolute',
            left: '0px',
            // width: '100%',

          }}><Button onClick={() => {
            setDigitadoJsonSchema('');
            setDigitadoUiSchema("{}");
          }} variant="primary">Limpar</Button></div>

          <Button
            variant="primary"
            onClick={() => {
              handleCreate();
              setMudou(!mudou);
              handleClose();

            }}
          >
            Salvar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>





      <Modal show={show2} size="lg" onHide={handleClose2}>
        <Modal.Header >
          <Modal.Title>Tem certeza que deseja excluir este modelo?</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: "#ededed", display: 'flex', justifyContent: 'space-evenly' }}
        >

          <Button
            variant="danger"
            onClick={() => {
              // @ts-ignore
              DeleteModel(idDelete, getData, () => { CallNotification("Info", "Existe inspeções utilizando este modelo", "info"); });
              setMudou(!mudou);
              handleClose2();
            }}>Confirmar</Button>


          <Button
            variant="secondary"
            onClick={() => {
              handleClose2()
            }}>cancelar</Button>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>


      {/* ===============================================x=xxxxxxxxxxxxxxxxxxxxxxxx============================================================ */}

      <Modal show={show3} size="lg" onHide={handleClose3}>
        <Modal.Header >
          <Modal.Title>Editar modelo</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: "#ededed" }}
        >


          <div style={{ margin: '0px 0px 10px 0px' }} >JsonSchema</div>

          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-sm">Título</InputGroup.Text>
            <FormB.Control
              onChange={(ev: any) => { setTitle(ev.target.value) }}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              value={title}
            />
          </InputGroup>

          {/* @ts-ignore */}
          <CodeMirror
            value={
              digitadoJsonSchema
              // JSON.parse(digitadoJsonSchema)
            }
            options={options}
            onBeforeChange={(editor, data, code) => {

              setDigitadoJsonSchema(code);
            }}
          />
          <div style={{ margin: '10px 0px 10px 0px' }} >UiSchema</div>
          {/* @ts-ignore */}
          <CodeMirror
            value={digitadoUiSchema
              // JSON.parse(digitadoUiSchema)
            }
            options={options}
            onBeforeChange={(editor, data, code) => {

              setDigitadoUiSchema(code);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div style={{
            position: 'absolute',
            left: '0px',
            // width: '100%',

          }}><Button onClick={() => {
            setDigitadoJsonSchema('');
            setDigitadoUiSchema("{}");
          }} variant="primary">Limpar</Button></div>

          <Button
            variant="primary"
            onClick={() => {
              handleEdit();
              setMudou(!mudou);
              handleClose3();

            }}
          >
            Salvar
          </Button>
          <Button variant="secondary" onClick={handleClose3}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>


    </Container>

  )


}

const DisplayButton = styled.div`
.btn{
  display: none;
}
.btn-info {
  display: none;
}
`;

export default memo(InspectionCompanyModelos)


