/* eslint-disable import/no-anonymous-default-export */
import React, { useState, memo } from "react";
import { Button, Container } from "react-bootstrap";
import { Token } from "../../helpers/token";
import { useHistory, useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import { COMPANY_URL, USER_BY_COMPANY } from "../../constains";
import { IonAccordionGroup, IonItem, IonLabel } from "@ionic/react";
import { IonSearchbar } from "@ionic/react";
import { Spin } from "react-cssfx-loading";
import useSWR, { useSWRConfig } from "swr";
import { FilterNumber, alfaFilter } from "../../helpers/utilsFunctions";

function Inspection() {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const { companyId, clientUser, id } = Token.getTokenData();
  const access = Token.getAccess();

  const url = `${COMPANY_URL}/${companyId}`;
  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const { data, isLoading: loading } = useSWR(url, fetcher);

  const url2 = `${USER_BY_COMPANY}/${companyId}`;
  const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);

  let theUser: any = [];
  if (loading2 == false) {
    theUser = allUsers?.data?.filter((value: any) => {
      return value.id == id;
    });
  }
  const finalArrayClients = theUser?.[0]?.clients?.map((value: any) => {
    return value.id;
  });


  
  // console.log('teste de index', data, loading)

// console.log(access)

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
        Aguarde...
      </div>
    );



  // console.log(data.data)
  // console.log(finalArrayClients)
  // console.log(theUser)


  return (
    <Container fluid>
      <div
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
          zIndex: "99",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          Clientes
        </div>
        {clientUser ? (
          <></>
        ) : (
          <Button
            onClick={() => {
              history.push(`inspection/modelos/${companyId}`);
            }}
            variant="dark"
            style={{
              textDecoration: "none",
              width: "100%",
            }}
          >
            Modelos
          </Button>
        )}
        <IonSearchbar
          onIonChange={({ detail }) => setSearch(detail?.value || "")}
        ></IonSearchbar>
      </div>
      <>
        {data?.data?.clients != null && data?.data?.clients?.length > 0
          ? alfaFilter(data?.data.clients, 'name', null)
        //   ? (data?.data.clients).sort((a, b) => a.name.localeCompare(b.name))
            .filter((client: any) =>
              client?.name?.toLowerCase()?.includes(search?.toLowerCase())
            )
            ?.filter((value: any) => {
              if (
                clientUser == true &&
                finalArrayClients.includes(value.id)
              ) {
                return value;
              }
              if (clientUser == false) {
                return value;
              }
            })
            ?.map((value: any, index: any) => {
              const { name = "--", address = "--" } = value;
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IonAccordionGroup
                    style={{
                      width: "100%",
                    }}
                  >
                    <IonItem
                      style={{
                        width: "100%",
                      }}
                      button
                      detail={true}
                      key={index}
                      onClick={() => {
                        history.push(`inspection/${value.id}?name=${name}&clientid=${value.id}`);
                      }}
                    >
                      <IonLabel>
                        <h3>{name}</h3>
                        <p>{address}</p>
                      </IonLabel>
                    </IonItem>
                  </IonAccordionGroup>
                </div>
              );
            })
          : "Nenhum cliente encontrado, verifique sua internet e tente novamente!"}
      </>
    </Container>
  );
}
export default memo(Inspection);
