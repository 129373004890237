/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Client, Company } from "../../../interfaces";
import { ToggleSelector } from "../../../styles/themes/styles";
import axios from "axios";
import { COMPANY_URL } from "../../../constains";
import { Token } from "../../../helpers/token";
interface Props {
  client: Client;
  setClient: (value: Client) => void;
  mode?: "edit" | "view";
}

export default function ({ client, setClient, mode = "edit" }: Props) {
  const [createClientUser, setCreateClientUser] =
    React.useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  const companyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  const access = Token.getAccess();

  useEffect(() => {
    getAllCompany();
  }, []);

  const getAllCompany = () => {
    axios
      .get(COMPANY_URL, access)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const genPassword = (): string => Math.random().toString(36).slice(-8);
  const setParameters = (key: string, ev: any) => {
    ev.persist();
    //@ts-ignore
    setClient((prevState: Client): void => ({
      ...prevState,
      [key]: ev?.target?.value,
    }));
  };
  return (
    <Form autoComplete={"off"}>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Empresas</Form.Label>

            <Form.Control
              autoComplete={"off"}
              type="email"
              placeholder="Nome da companhia"
              value={client.name}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("name", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Supervisor</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={client.supervisor}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("supervisor", ev)}
            />
            <Form.Text className="text-muted">
              Pessoa responsavel pela parte tecnica da empresa.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Company</Form.Label>
            <Select
              // defaultValue={options[0]}
              isClearable={true}
              isSearchable={true}
              isDisabled={mode === "view" ? true : false}
              name="company"
              options={companyOptions}
              onChange={(ev) =>
                setClient((prevState: Client): void => ({
                  ...prevState,
                  companyId: ev?.value,
                }))
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Celular</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Nome da companhia"
              value={client.cel}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("cel", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Nome da companhia"
              value={client.tel}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("tel", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="email"
              placeholder="E-mail"
              value={client.email}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("email", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Cep</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="cep"
              value={client.cep}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("cep", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="Endereço"
              value={client.address}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("address", ev)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="lat"
              value={client.lat}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("lat", ev)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              autoComplete={"off"}
              type="text"
              placeholder="longitude"
              value={client.lng}
              disabled={mode === "view" ? true : false}
              onChange={(ev) => setParameters("lng", ev)}
            />
          </Form.Group>
        </Col>
      </Row>
      {mode === "edit" ? (
        <>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Criar usuário para o cliente</Form.Label>
                <ToggleSelector
                  color="red"
                  checked={createClientUser}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={() => {
                    setCreateClientUser(!createClientUser);
                  }}
                />
                <Form.Text className="text-muted">
                  Cria um usuario para que o cliente possa abri novas OS
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {createClientUser ? (
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="password"
                    value={client.clientPassword}
                    onChange={(ev) => setParameters("clientPassword", ev)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      //@ts-ignore
                      setClient((prevState: Client) => ({
                        ...prevState,
                        clientPassword: genPassword(),
                      }));
                    }}
                  >
                    Gerar password
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}
    </Form>
  );
}
