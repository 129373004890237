import React, { useRef } from "react";
import { User } from "../../../interfaces";
import styled from "styled-components";
import { Form, InputGroup } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { getPlatforms } from "@ionic/react";
import { ADMIN_URL_IMAGE } from "../../../constains";

interface Props {
  getData: (data: void) => void;
  // handleClose: (data: void) => void;
  setUser: (data: any) => any;
  user: User;
  onChangeGetImage: (data: any) => any;
  onSubmitHandler: (data: any) => any;
  mode: string;
  image: any;
}

function AdicionarColaborador({
  getData,
  setUser,
  user,
  onChangeGetImage,
  mode,
  onSubmitHandler,
  image
}: Props) {

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const handleFileChange = (e: any) => {
    e.persist();
    onChangeGetImage(e);
  };
  const MyDiv = styled.div`
  &:hover {
    opacity: ${(mode === 'view' ? 1 : 0.8)};
    
  }
`;
  const platform = getPlatforms();
  const imageUrl = `${ADMIN_URL_IMAGE}/${user?.image?.id}`;



  return (
    <DivGeral>
      <form onSubmit={onSubmitHandler}>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

          }}

          >


            {image ? <div style={{
              marginTop: '-20px',
              borderRadius: '20px',
              width: '110px',
              height: '120px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
              cursor: 'pointer',
              marginRight: '10px',
              overflow: 'hidden',
              backgroundColor: '#101010fa',
            }}

            >
              <input
                disabled={mode === 'view' ? true : false}
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                type="file"
                id='ForInputCam2'
                onClick={(ev: any) => {
                  ev.target.value = ''
                }}
              />

              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  // borderRadius: '30px',
                }}
                onClick={handleButtonClick}
                src={image} /></div> :

              // (mode !== "edit" ?
              // (mode !== 'view' ?
              <div style={{
                position: 'relative',
                display: 'flex',
                height: '100%',
                width: ' 140px'
              }}>
                <MyDiv
                  onClick={handleButtonClick}
                  style={{
                    marginTop: '-20px',
                    backgroundColor: `${user?.image?.id !== undefined ? '#101010fa' : '#f9f4f4f9'}`,
                    borderRadius: '20px',
                    width: '110px',
                    height: '120px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid black',
                    cursor: `${mode !== 'view' ? 'pointer' : 'default'}`,
                    overflow: 'hidden'
                  }}>



                  {user?.image?.id !== undefined ?
                    <img

                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        // borderRadius: '30px',
                      }}

                      src={imageUrl} /> :
                    <BsFillPersonFill

                      // size={80}
                      style={{
                        borderRadius: '100%',
                        width: '100%',
                        height: '100%'

                      }}
                      color="black"
                    />


                  }

                  <input
                    disabled={mode === 'view' ? true : false}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    type="file"
                    id='ForInputCam2'
                    onClick={(ev: any) => {
                      ev.target.value = ''
                    }}
                  />
                </MyDiv>
              </div>
            }


            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%'
            }}>


              <InputGroup className="mb-3" style={{ fontSize: '11px' }}>
                <InputGroup.Text id="inputGroup-sizing-default" style={{ fontSize: '11px' }}>
                  Nome
                </InputGroup.Text>
                <Form.Control
                  style={{ fontSize: '11px' }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  type="text"
                  name="login"
                  value={user.name}
                  onChange={(event) => {
                    event.persist();

                    setUser((prevState: any) => ({
                      ...prevState,
                      name: event.target.value,
                    }));
                  }}
                  required
                  disabled={mode === 'view' ? true : false}
                />
              </InputGroup>

              <InputGroup className="mb-3" style={{ fontSize: '12px' }}>
                <InputGroup.Text id="inputGroup-sizing-default" style={{ fontSize: '12px' }}>
                  E-mail
                </InputGroup.Text>
                <Form.Control
                  style={{ fontSize: '12px' }}
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={(event) => {
                    event.persist();
                    setUser((prevState: any) => ({
                      ...prevState,
                      email: event.target.value,
                    }));
                  }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  disabled={mode === 'view' ? true : false}
                />
              </InputGroup>


              {mode !== "edit" ?
                (mode !== 'view' ?
                  <InputGroup className="mb-3" style={{ fontSize: '12px' }}>
                    <InputGroup.Text id="inputGroup-sizing-default" style={{ fontSize: '12px' }}>
                      Senha
                    </InputGroup.Text>
                    <Form.Control
                      style={{ fontSize: '12px' }}
                      name="password"
                      type="password"
                      value={user.password}
                      onChange={(event) => {
                        event.persist();
                        setUser((prevState: any) => ({
                          ...prevState,
                          password: event.target.value,
                        }));
                      }}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      disabled={mode === 'view' ? true : false}
                    />
                  </InputGroup>


                  : "")
                : <></>}



            </div>

          </div>

          {platform.includes("desktop") ?
            // =================================================================desktop==================================================
            <DivRow>
              <DivColumn>

                <DivRow>
                  <Span style={{ fontSize: '12px' }}>
                    OS:
                  </Span>
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarOS}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarOS: !user.criarOS,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarOS}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarOS: !user.editarOS,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>    <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarOS}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarOS: !user.deletarOS,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar              </DivRow> <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarOS}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarOS: !user.visualizarOS,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar
                </DivRow>




              </DivColumn>

              <DivColumn>

                <DivRow><Span style={{ fontSize: '12px' }}>Colaborador: </Span></DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarColaborador}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarColaborador: !user.criarColaborador,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarColaborador}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarColaborador: !user.editarColaborador,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>    <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarColaborador}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarColaborador: !user.deletarColaborador,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar              </DivRow> <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarColaborador}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarColaborador: !user.visualizarColaborador,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar              </DivRow>




              </DivColumn>



              <DivColumn>

                <DivRow><Span style={{ fontSize: '12px' }}>Cliente: </Span></DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarCliente}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarCliente: !user.criarCliente,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarCliente}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarCliente: !user.editarCliente,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>    <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarCliente}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarCliente: !user.deletarCliente,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar              </DivRow> <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarCliente}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarCliente: !user.visualizarCliente,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar              </DivRow>




              </DivColumn>


              <DivColumn>

                <DivRow><Span style={{ fontSize: '12px' }}>Modelo: </Span></DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarModelo}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarModelo: !user.criarModelo,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarModelo}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarModelo: !user.editarModelo,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>    <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarModelo}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarModelo: !user.deletarModelo,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar              </DivRow> <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarModelo}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarModelo: !user.visualizarModelo,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar
                </DivRow>

              </DivColumn>

              <DivColumn>
                <DivRow>
                  <Span style={{ fontSize: '12px' }}>
                    Inspeção:
                  </Span>
                </DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarInspection}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarInspection: !user.criarInspection,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarInspection}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarInspection: !user.editarInspection,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>
                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarInspection}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarInspection: !user.deletarInspection,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar
                </DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarInspection}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarInspection: !user.visualizarInspection,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar
                </DivRow>
              </DivColumn>

              <DivColumn>
                <DivRow>
                  <Span style={{ fontSize: '12px' }}>
                    Equipamento:
                  </Span>
                </DivRow>
                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.criarEquipamento}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        criarEquipamento: !user.criarEquipamento,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Criar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.editarEquipamento}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        editarEquipamento: !user.editarEquipamento,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Editar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>
                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.deletarEquipamento}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        deletarEquipamento: !user.deletarEquipamento,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Deletar
                </DivRow>

                <DivRow style={{ fontSize: '12px' }}>

                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px 10px 10px 0",
                    }}
                    className="Check"
                    checked={user.visualizarEquipamento}

                    onChange={(event) =>
                      setUser((prevState: any) => ({
                        ...prevState,
                        visualizarEquipamento: !user.visualizarEquipamento,
                      }))
                    }
                    type="checkbox"
                    disabled={mode === 'view' ? true : false}
                  />

                  Visualizar
                </DivRow>

              </DivColumn>

            </DivRow>
            :
            // ===============================================================mobile===================================================================//

            <div>

              <DivRow>
                <DivColumn>

                  <DivRow>
                    <Span style={{ fontSize: '12px' }}>
                      Colaborador:
                    </Span>
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarColaborador}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarColaborador: !user.criarColaborador,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarColaborador}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarColaborador: !user.editarColaborador,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>    <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarColaborador}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarColaborador: !user.deletarColaborador,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar              </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarColaborador}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarColaborador: !user.visualizarColaborador,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar              </DivRow>




                </DivColumn>
                <DivColumn>

                  <DivRow>
                    <Span style={{ fontSize: '12px' }}>
                      OS:
                    </Span>
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarOS}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarOS: !user.criarOS,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarOS}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarOS: !user.editarOS,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarOS}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarOS: !user.deletarOS,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar              </DivRow> <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarOS}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarOS: !user.visualizarOS,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar
                  </DivRow>




                </DivColumn>
              </DivRow>


              <DivRow>
                <DivColumn>

                  <DivRow><Span style={{ fontSize: '12px' }}>Cliente: </Span>
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarCliente}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarCliente: !user.criarCliente,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarCliente}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarCliente: !user.editarCliente,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarCliente}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarCliente: !user.deletarCliente,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar              </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarCliente}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarCliente: !user.visualizarCliente,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar              </DivRow>




                </DivColumn>
                <DivColumn>

                  <DivRow><Span style={{ fontSize: '12px' }}>Modelo: </Span></DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarModelo}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarModelo: !user.criarModelo,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarModelo}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarModelo: !user.editarModelo,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarModelo}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarModelo: !user.deletarModelo,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar              </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarModelo}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarModelo: !user.visualizarModelo,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar
                  </DivRow>

                </DivColumn>
              </DivRow>




              <DivRow>
                <DivColumn>
                  <DivRow>
                    <Span style={{ fontSize: '12px' }}>
                      Inspeção:
                    </Span>
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarInspection}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarInspection: !user.criarInspection,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarInspection}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarInspection: !user.editarInspection,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>
                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarInspection}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarInspection: !user.deletarInspection,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarInspection}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarInspection: !user.visualizarInspection,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar
                  </DivRow>
                </DivColumn>

                <DivColumn>
                  <DivRow>
                    <Span style={{ fontSize: '12px' }}>
                      Equipamento:
                    </Span>
                  </DivRow>
                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.criarEquipamento}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          criarEquipamento: !user.criarEquipamento,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Criar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.editarEquipamento}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          editarEquipamento: !user.editarEquipamento,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Editar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>
                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.deletarEquipamento}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          deletarEquipamento: !user.deletarEquipamento,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Deletar
                  </DivRow>

                  <DivRow style={{ fontSize: '12px' }}>

                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px 10px 10px 0",
                      }}
                      className="Check"
                      checked={user.visualizarEquipamento}

                      onChange={(event) =>
                        setUser((prevState: any) => ({
                          ...prevState,
                          visualizarEquipamento: !user.visualizarEquipamento,
                        }))
                      }
                      type="checkbox"
                      disabled={mode === 'view' ? true : false}
                    />

                    Visualizar
                  </DivRow>
                </DivColumn>
              </DivRow>


















            </div>
          }
        </div>
      </form>
    </DivGeral>
  );
}

const DivGeral = styled.div`
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  font-size: 16px;
  
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;

const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Span = styled.span`
  margin: 10px 0px;
  font-size: 15px;
`;

export default AdicionarColaborador;
