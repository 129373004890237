/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import Card from "../../../components/card/card";
import styled, { useTheme } from "styled-components";
import {  User } from "../../../interfaces";
import { ImBin } from "react-icons/im";
import { AiTwotoneEdit } from "react-icons/ai";
import { ADMIN_URL, ADMIN_URL_IMAGE } from "../../../constains";
import { CallNotification } from "../../../components/Alert";
import AdicionarUsuario from "../../../components/AdicionarUsuario/AdicionarUsuario";
// import LookFiliais from "../../../components/LookFiliais";
import Editar from "../../../components/EditarUsuario/index";
// import { useHistory } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
// import { FaBars } from "react-icons/fa";
// import Asid2 from "../../../components/Sidebar/Aside2";
import React from "react";
import ViewUser from "../../../components/ViewAdminUser/ViewUser";

function Users2() {
  // const [toggled, setToggled] = useState(false);
  const [id, setId] = useState<string>('');

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [list, setList] = useState<User[]>([]);
  const [apagar, setApagar] = useState<any>("");
  const { themeName } = useTheme();

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    getData();
  }, [apagar]);

  const getData = () => {
    axios
      .get(ADMIN_URL, access)
      .then((res) => {
        setList(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id: string) => {
    axios
      .delete(`${ADMIN_URL}/${id}`, access)
      .then((response) => {
        setApagar("foi deletado");
        CallNotification("Sucesso", "usuario deletado!", "success");
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


   const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const TakeId = (id: any) => {
    return setId(id);
  };



  return (
    <AllContent
      // className="content"
      style={{
        height: "calc(100vh - 106px)",
        display: "flex",
        width: "100%",
        // overflow: "auto",
      }}
    >

      <Container fluid>
        <Row>
          <Teste
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "0px",
              // overflow: "auto",
            }}
          >
            {/* <div className="adm"> eita poha
                <AdminMenu
                  onClick={() => {
                    setToggled(!toggled);
                  }}
                  className="adminMenu"
                >
                  {<FaBars color="white" />}krl kkkkkkkkkkkkkkkkkkk
                </AdminMenu>
              </div> */}

            <Col md={12}>
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {" "}
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={handleShow}
                      >
                        Adicionar usuário
                      </Button>
                    </div>
                    <DivScroll style={{ overflow: 'auto' }}>
                      {" "}
                      <Table
                        striped
                        hover
                        variant={themeName}
                        style={{ marginTop: "30px" }}
                      >
                        <thead>
                          <tr>
                            <Th></Th>
                            {/* <Th>ID</Th> */}
                            <Th>Nome</Th>
                            <Th>Empresa</Th>

                            {/* <Th>Criar Os</Th>
                            <Th>Editar Os</Th>
                            <Th>Deletar Os</Th>
                            <Th> Visualizar Os</Th>

                            <Th>Criar Colaborador</Th>
                            <Th>Editar Colaborador</Th>
                            <Th>Deletar Colaborador</Th>
                            <Th> Visualizar Colaborador</Th>

                            <Th>Criar Cliente</Th>
                            <Th>Editar Cliente</Th>
                            <Th>Deletar Cliente</Th>
                            <Th> Visualizar Cliente</Th>

                            <Th>Criar Inspeção</Th>
                            <Th>Editar Inspeção</Th>
                            <Th>Deletar Inspeção</Th>
                            <Th> Visualizar Inspeção</Th>

                            <Th>Criar Modelo</Th>
                            <Th>Editar Modelo</Th>
                            <Th>Deletar Modelo</Th>
                            <Th> Visualizar Modelo</Th> */}
                            {/* <Th>
                                Agendar Visitas
                              </Th> */}
                            <Th>Admin</Th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((props: User, index) => {
                            const { id = '', admin = false } = props;
                            const imageUrl = `${window.location.origin}${ADMIN_URL_IMAGE}/${props?.image?.id}`;

                            return (
                              <tr className="hoverTr" key={index}>
                                <td style={{ minWidth: '91px' }}>
                                  <img
                                    src={imageUrl}
                                    style={{ height: "50px", width: "70px" }}
                                    alt="imagem"
                                  />
                                </td>
                                {/* <td style={{ minWidth: '317px' }}>{props?.id}</td> */}
                                <td>{props?.name}</td>
                                <td>{props?.company?.name}</td>


                                {/* <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarOS}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.editarOS}
                                    readOnly
                                  />
                                </td>   <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.deletarOS}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.visualizarOS}
                                    readOnly
                                  />
                                </td>



                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarColaborador}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.editarColaborador}
                                    readOnly
                                  />
                                </td>   <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.deletarColaborador}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.visualizarColaborador}
                                    readOnly
                                  />
                                </td>



                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarCliente}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.editarCliente}
                                    readOnly
                                  />
                                </td>   <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.deletarCliente}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.visualizarCliente}
                                    readOnly
                                  />
                                </td>


                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarInspection}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.editarInspection}
                                    readOnly
                                  />
                                </td>   <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.deletarInspection}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.visualizarInspection}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarModelo}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.criarModelo}
                                    readOnly
                                  />
                                </td>   <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.deletarModelo}
                                    readOnly
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    defaultChecked={props?.visualizarModelo}
                                    readOnly
                                  />
                                </td> */}



                                {/* <td>
                                    <Form.Check
                                      type="checkbox"
                                      defaultChecked={props?.agendarVisita}
                                      readOnly
                                    />
                                  </td> */}

                                <td>

                                  <div
                                    style={{
                                      marginLeft: "7px",
                                    }}
                                  >

                                    {" "}

                                    <Form.Check
                                      type="checkbox"
                                      defaultChecked={admin}
                                    />

                                  </div>

                                </td>

                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    position: "relative",
                                    height: '68px'
                                  }}
                                  className="td"
                                >


                                  <Button
                                    className="view responsiveAll"
                                    onClick={() => {
                                      handleShow3();
                                      TakeId(id);
                                    }}
                                    variant="primary"
                                  >
                                    {
                                      <IoIosEye
                                        size="20px"
                                        className="responsive"
                                      />
                                    }
                                  </Button>


                                  <Button
                                    className="editEdit responsiveAll"
                                    variant="primary"
                                    onClick={() => {
                                      handleShow2();
                                      TakeId(id);
                                    }}
                                  >

                                    {
                                      <AiTwotoneEdit
                                        size="20px"
                                        className="responsive"
                                      />

                                    }

                                  </Button>

                                  <Button
                                    className="lixeira responsiveAll"
                                    onClick={() => handleDelete(id)}
                                    variant="primary"
                                  >
                                    {
                                      <ImBin
                                        size="20px"
                                        className="responsive"
                                      />
                                    }
                                  </Button>


                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </DivScroll>
                  </>
                }
              />
            </Col>
          </Teste>
        </Row>
        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar novo usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#ededed" }}>
            <AdicionarUsuario getData={getData} handleClose={handleClose} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal show={show2} size="lg" onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Editar usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body
          // style={{ backgroundColor: "#d6d4d4" }}
          >

            <Editar id={id} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>     
        
        
        
           <Modal show={show3} size="lg" onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Informações do usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body
          // style={{ backgroundColor: "#d6d4d4" }}
          >

            <ViewUser id={id} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


      </Container>
    </AllContent>
  );
}

const AllContent = styled.div`
  .hoverTr {
    .td {
      @media (max-width: 900px) {
        width: 300px;
      }
      @media (max-width: 800px) {
        width: auto;
      }
      @media (max-width: 700px) {
        width: 300px;
      }
      @media (max-width: 600px) {
        width: 300px;
      }
      @media (max-width: 500px) {
        width: 170px;
      }
      @media (max-width: 400px) {
        width: 130px;
      }
      @media (max-width: 300px) {
        width: 125px;
      }
    }

    @media (max-width: 768px) {
      .editEdit {
        visibility: visible !important;
        color: white;
      }
      .lixeira {
        visibility: visible !important;
        color: white;
      }
      .view {
        visibility: visible !important;
        color: white;
      }
    }
    .rowTd {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
    .lixeira {
      visibility: hidden;
      cursor: pointer;
    }
    .editEdit {
      visibility: hidden;
      cursor: pointer;
    }
    .view {
      visibility: hidden;
      cursor: pointer;
    }
    :hover {
      .lixeira {
        visibility: visible;
        color: white;
      }
      .editEdit {
        visibility: visible;
        color: white;
      }
      .view {
        visibility: visible;
        color: white;
      }
    }

    .responsiveAll {
      margin-left: 5px;
      .responsive {
        @media (max-width: 900px) {
          height: 20px;
          width: 20px;
        }
        @media (max-width: 800px) {
          height: 17px;
          width: 17px;
        }
        @media (max-width: 700px) {
          height: 15px;
          width: 15px;
        }
        @media (max-width: 600px) {
          height: 20px;
          width: 20px;
        }
        @media (max-width: 500px) {
          height: 15px;
          width: 15px;
        }
        @media (max-width: 400px) {
          height: 12px;
          width: 12px;
        }
        @media (max-width: 300px) {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
`;

const Teste = styled.div`
  .adm {
    display: none;
  }
`;

// const AdminMenu = styled.div`
//   position: relative;
//   margin-right: 30px;
//   top: -4px;
//   cursor: pointer;

//   @media (max-width: 1100px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 1000px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 900px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 800px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 700px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 600px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 500px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 400px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
//   @media (max-width: 300px) {
//     margin-right: 10px;
//     margin-left: 0px;
//   }
// `;

// const H6 = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   font-size: 35px;
//   height: 100px;

//   @media (max-width: 900px) {
//     font-size: 30px;
//   }
//   @media (max-width: 800px) {
//     font-size: 30px;
//   }
//   @media (max-width: 700px) {
//     font-size: 30px;
//   }
//   @media (max-width: 600px) {
//     font-size: 30px;
//   }
//   @media (max-width: 500px) {
//     font-size: 30px;
//   }
//   @media (max-width: 400px) {
//     font-size: 30px;
//   }
//   @media (max-width: 300px) {
//     font-size: 30px;
//   }
//   @media (max-width: 200px) {
//     font-size: 30px;
//   }
// `;

const Th = styled.th`
font-size: 9px!important;
`;

export default Users2;

const DivScroll = styled.div`
overflow-x: auto;
overflow-y: hidden;
white-space:nowrap;


&::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
