import { Action } from "redux";

interface IStatusReducer {
  action: { type?: string; payload?: any };
  state: IStatus;
}
interface IStatus {
  online: boolean;
}

export const R_STATUS_INITIAL_STATE = {
  online: true,
};

export const apiDataOfflineReducer = (
  state = R_STATUS_INITIAL_STATE,
  action: Action
): IStatus => {
  const { type, payload = {} } = action;
  switch (type) {
    case "API_DATA_OFFLINE":
      const { key, value } = payload;

      // console.log('redux', key)
      // console.log('redux', value)
      // console.log("redux, key value", key, value);
      return { ...state, [key]: value };

    default:
      return state;
  }
};
