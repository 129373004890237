
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import moment from 'moment';
import Select from 'react-select';
import FilterClientes from "../../../components/FilterClientes";
import { GetEquipamentOnly, GetEquipamentOnlyAll } from "../api";
import * as _ from 'lodash';
import useSWR, { useSWRConfig } from "swr";
import { COMPANY_URL, EQUIPMENT } from "../../../constains";
import { Token } from "../../../helpers/token";
const colors = [
  "#f0f016",
  "#df2e2e",
  "#141313",
  "#7595e7",
  "#e775cc",
  "#c4e80e",
  "#25d9f9",
  "#354c7d",
  "#657e37",
  "#baf5f9",
  "#787474",
  "#a90d5e",
  "#3ba288",
  "#95edc5",
  "#706703",
  "#efb7b7",
  "#f9e3e3",
  "#e77575",
]

export default function EquipmentGrafico({ search, timeCollect }: any) {
  const [equipamentos, setEquipamentos] = React.useState<any>();
  const [selectedEquipamento, setSelectedEquipamento] = React.useState<any>();
  const [inspections, setInspections] = React.useState<any>(null);
  const { companyId } = Token.getTokenData();

  const url = `${COMPANY_URL}/${companyId}?span=last${timeCollect.value}days`;
  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data, isLoading: loading } = useSWR(url, fetcher);
  const { mutate } = useSWRConfig();
  const run = React.useCallback(() => mutate(url), [mutate]);



  React.useEffect(() => {
    data?.data?.clients.map((value: any) => {
      if (value.id == search[0]?.value) {
        setEquipamentos(value.equipment)
      }
    })
    equipamentos?.map((value: any) => {
      if (value.id == selectedEquipamento) {
        setInspections(value.inspections)
      }
    })
  }, [search, data?.data?.clients, selectedEquipamento])


  const FiltradoFormdata = inspections?.map((valor: any) => {
    return { formData: valor.formData, data: moment(valor.createAt).format('DD/MM') }
  })
  //@ts-ignore
  function criarArrayDeObjetosComChavesInteiras(objeto) {
    const arrayDeObjetos: any = [];
    const novoObjeto: any = {};
    for (const chave in objeto) {
      if (Number.isInteger(objeto[chave])) {
        novoObjeto[chave] = objeto[chave];
      }
    }

    if (Object.keys(novoObjeto).length > 0) {
      arrayDeObjetos.push(novoObjeto);
    }

    for (const chave in objeto) {
      if (typeof objeto[chave] === 'object') {
        const subArray = criarArrayDeObjetosComChavesInteiras(objeto[chave]);
        arrayDeObjetos.push(...subArray);
      }
    }

    return arrayDeObjetos;
  }
  const dados = FiltradoFormdata?.map((value: any) => {


    const objetoFinal: any = {};

    for (const objeto of criarArrayDeObjetosComChavesInteiras(value.formData)) {
      for (const chave in objeto) {
        objetoFinal[chave] = objeto[chave];
      }
    }

    objetoFinal['name'] = value.data;

    return objetoFinal

  })
  let outputArray: any

  if (dados !== undefined) {

    const groupMap: any = new Map<string, any[]>();

    dados.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key !== 'name') {
          const prefix = key.substring(0, 5); // Pegar os primeiros 5 caracteres da chave
          if (!groupMap.has(prefix)) {
            groupMap.set(prefix, []);
          }
          groupMap.get(prefix).push({ [key]: item[key] });
        }
      });
    });

    outputArray = Array.from(groupMap.values());


  }

  const finalArray = outputArray?.map((value: any, index: any) => {


    const resultado = _.chain(value)
      .groupBy(obj => Object.keys(obj)[0])
      .mapValues(values => _.map(values, obj => Object.values(obj)[0]))
      .value();

    return resultado



  })


  const resultadoFinal = finalArray?.map((value: any) => {
    const chaves = Object.keys(value);
    const valores: any = Object.values(value);

    const resultado = valores[0].map((_: any, index: any) => {
      const novoObjeto: any = {};
      chaves.forEach((chave, i) => {
        novoObjeto[chave] = valores[i][index];
      });
      return novoObjeto;
    });

    return resultado
  })

  const FilterNumber = (array: any) => {
    // @ts-ignore
    return array.sort((a, b) => {
      const keyA = a.label.toLowerCase();
      const keyB = b.label.toLowerCase();

      const regex = /^(\d+)\s*-/;
      const matchA = keyA.match(regex);
      const matchB = keyB.match(regex);

      if (matchA && matchB) {
        const numericValueA = parseInt(matchA[1]);
        const numericValueB = parseInt(matchB[1]);

        if (numericValueA < numericValueB) {
          return -1;
        } else if (numericValueA > numericValueB) {
          return 1;
        }
      } else if (matchA && !matchB) {
        return -1;
      } else if (!matchA && matchB) {
        return 1;
      }

      const wordA = keyA.split('-')[1]?.trim();
      const wordB = keyB.split('-')[1]?.trim();

      if (wordA && wordB) {
        return wordA.localeCompare(wordB);
      } else if (wordA && !wordB) {
        return -1;
      } else if (!wordA && wordB) {
        return 1;
      }

      const numericA = parseInt(keyA);
      const numericB = parseInt(keyB);

      if (!isNaN(numericA) && !isNaN(numericB)) {
        return numericA - numericB;
      }

      return keyA.localeCompare(keyB);
    });
  }

  const optionsFiltered: [] = FilterClientes(equipamentos, 'title')?.map((value: any) => {
    return { label: value?.title, value: value?.id }
  }) || []

  return (

    <div>

      <Select
        placeholder='Selecione um equipamento'
        onChange={(ev: any) => { setSelectedEquipamento(ev.value); run() }}
        options={FilterNumber(optionsFiltered)}
      />



      {inspections !== null ?
        (!!inspections?.length ?
          // <ResponsiveContainer width="100%" height="100%">
          resultadoFinal?.map((value: any, key: any) => {
            const legendas: any = value?.map((objeto: any) => {
              const chaves = Object.keys(objeto);

              return chaves?.map(chave => {
                return chave;
              });
            })

            return <LineChart
              key={key}
              width={window.innerWidth > 991 ? window.innerWidth - 360 : window.innerWidth - 90}
              height={350}
              data={value}
              margin={{
                top: 5,
                right: 3,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />


              {legendas !== undefined ?
                legendas[0]?.map((string: any, index: any) => {
                  const array = ['name']
                  if (array.includes(string)) return
                  return (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={string}
                      stroke={colors[index]}
                      activeDot={{ r: 5 }}
                    />
                  )
                })
                : <>
                </>}
            </LineChart>

          }) : 'Este equipamento não possúi dados!')

        // </ResponsiveContainer>

        : <> Selecione um Cliente e depois um equipamento</>}

    </div>
  );
}

