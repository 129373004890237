/* eslint-disable import/no-anonymous-default-export */
import React, { useState, memo } from "react";
import { Button, Col, Container } from "react-bootstrap";
import { Client } from "../../interfaces";
import { Token } from "../../helpers/token";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { COMPANY_URL } from "../../constains";
import { IonAccordionGroup, IonItem, IonLabel } from "@ionic/react";
import { IonSearchbar } from "@ionic/react";
import * as localForage from "localforage";
import { Spin } from "react-cssfx-loading";
import { FilterNumber } from "../../helpers/utilsFunctions";

const idBranch = Token.getTokenData()?.companyId;

export const store = localForage.createInstance({
  name: "local storage app",
});

export const Files = () => {
  const [loading2, setLoading2] = useState<any>(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [search, setSearch] = useState("");
  // const getNewClient = () => GetClient(setClients); //TODO: remove GetClient from API
  const history = useHistory();

  function getClient(): Promise<any> {
    const { companyId } = Token.getTokenData();
    return fetch(`${COMPANY_URL}/${companyId}`)
      .then((r) => r.json())
      .catch((err) => console.error(err));
  }

  const { data, loading } = useRequest(getClient, {
    cacheKey: "inspection-getClient",
    cacheTime: -1,
    // staleTime: 300000,
    onError: ({ data }: any) => {
      const dataCash = async () => {
        try {
          const value: any = await store.getItem("clientes");
          // console.log(value)
          setClients(value);
        } catch (err) {
          console.log(err);
        }
      };
      dataCash();
    },
    onSuccess: ({ data }) => {
      const { clients } = data;
      setClients(clients);

      store.setItem("clientes", clients);
      setLoading2(false);
    },
  });

  if (loading) return <div> loading...</div>;

  return (
    <Container fluid>
      <div
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
          zIndex: "99",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          Clientes
        </div>

        <IonSearchbar
          onIonChange={({ detail }) => setSearch(detail?.value || "")}
        ></IonSearchbar>
      </div>
      <>
        {loading2 == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
            Aguarde...
          </div>
        ) : (
          FilterNumber(clients)
            .filter((client: any) =>
              client?.name?.toLowerCase()?.includes(search?.toLowerCase())
            )
            ?.map((value: Client, index: any) => {
              const { name = "--", address = "--" } = value;
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IonAccordionGroup
                    style={{
                      width: "100%",
                    }}
                  >
                    <IonItem
                      style={{
                        width: "100%",
                      }}
                      button
                      detail={true}
                      key={index}
                      onClick={() => {
                        history.push(`/files/${value.id}`);
                      }}
                    >
                      <IonLabel>
                        <h3>{name}</h3>
                        <p>{address}</p>
                      </IonLabel>
                    </IonItem>
                  </IonAccordionGroup>
                </div>
              );
            })
        )}
      </>
    </Container>
  );
};
