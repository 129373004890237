/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CiCirclePlus } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";
import { Token } from "../../../helpers/token";
import axios from "axios";
import { ADMIN_BRANCH_URL } from "../../../constains";
import { CallNotification } from "../../../components/Alert";
import { Checkbox, MultiSelect, Select, Slider, Switch, Tooltip } from "@mantine/core";
import { Spin } from "react-cssfx-loading";
import useUrlState from '@ahooksjs/use-url-state';
import { FcInfo } from "react-icons/fc";





export default function Config() {
    const [empresa, setEmpresa] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const access = Token.getAccess();
    const idEm = Token.getTokenData()?.companyId;
    const [state, setState] = useUrlState<any>({}); 
    const marks = [
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 80, label: '80' },
    ];

    useEffect(() => {
        axios
            .get(`${ADMIN_BRANCH_URL}/${idEm}`, access)
            .then((res) => {
                // console.log(res.data.data)
                // setEmpresa(res?.data?.data);

                setEmpresa((prev: any) => {
                    return { ...prev, ...res.data.data }
                });
                setState({
                    name: '',
                    title: '',
                    address: '',
                    cnpj: '',
                    phone: '',
                    email: '',
                    empresaInfo: '',
                    logo: '',
                    clienteLogo: '',
                    clienteInfo: '',
                    capaLogo: '',
                    titleCapa: '',
                    fontSize: '',
                    marginType: '',
                    textAli: '',
                    infoEmpresa: '',
                    companyId: idEm
                })
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {setState((prev: any) => { return { ...prev, title: empresa?.config?.title} })},[empresa])

    // console.log(empresa)


    if (loading)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                }}
            >
                <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
                Aguarde...
            </div>
        );
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%', margin: '10px' }}>



                <Form autoComplete={"off"}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="email"
                                    placeholder="Nome da empresa"
                                    value={empresa?.name}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, name: ev.target.value } });
                                        setState((prev: any) => { return { ...prev, name: ev.target.value } });

                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>CNPJ</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="CNPJ da empresa."
                                    value={empresa?.cnpj}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, cnpj: ev.target.value } });
                                        setState((prev: any) => { return { ...prev, cnpj: ev.target.value } });

                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Número de telefone"
                                    value={empresa?.phone}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, phone: ev.target.value } });
                                        setState((prev: any) => { return { ...prev, phone: ev.target.value } });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Título do relatório"
                                    value={empresa?.config?.title}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, title: ev.target.value } } });
                                        setState((prev: any) => { return { ...prev, title: ev.target.value } });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="email"
                                    placeholder=" Informe e-mail da empresa"
                                    value={empresa?.email}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, email: ev.target.value } });
                                        setState((prev: any) => { return { ...prev, email: ev.target.value } });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control
                                    autoComplete={"off"}
                                    type="text"
                                    placeholder="Endereço da empresa"
                                    value={empresa?.address}
                                    // disabled={mode === "view" ? true : false}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => { return { ...prev, address: ev.target.value } });
                                        setState((prev: any) => { return { ...prev, address: ev.target.value } });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <Select
                                    style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                                    label="Inserir logo no relatório:"
                                    placeholder="Selecione uma opção"
                                    data={['Logo da empresa', 'Logo do cliente', 'Não inserir logo']}
                                    value={empresa?.config?.logo}
                                    onChange={(ev) => {
                                        setEmpresa((prev: any) => ({ ...prev, config: { ...prev.config, logo: ev } }));
                                        setState((prev: any) => { return { ...prev, logo: ev } });
                                    }}
                                />
                                <Tooltip label="Selecione o tipo de logo para ser inserido na parte interna do relatório." color="cyan" >
                                    <div style={{ marginTop: '34px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                                </Tooltip>
                            </div>

                            
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                                <Select
                                    style={{ width: '100%' }}
                                    label="Inserir Informações da empresa no relatório:"
                                    placeholder="Selecione uma opção"
                                    data={['Capa', 'Página', 'Ambos', "Nenhum"]}
                                    value={empresa?.config?.infoEmpresa}
                                    onChange={(ev) => { setEmpresa((prev: any) => ({ ...prev, config: { ...prev.config, infoEmpresa: ev } }));
                                    setState((prev: any) => { return { ...prev, infoEmpresa: ev } });
                         
                                }}
                                />

                                <Tooltip label="Adiciona as informações da empresa cadastrada, na capa ou nas páginas de seu relatório, ou em ambos." color="cyan" >


                                    <div style={{ marginTop: '24px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                                </Tooltip>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>

                                {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px' }}>
                                    <Checkbox
                                        label="Adicionar informações da empresa no relatório (endereço, email, cnpj..)"
                                        // value={empresa?.empresaInfo}
                                        checked={empresa?.config?.empresaInfo}
                                        onChange={(ev) => {
                                            setEmpresa((prev: any) => {
                                                // console.log(prev)
                                                return { ...prev, config: { ...prev.config, empresaInfo: !empresa?.config?.empresaInfo } }
                                            });
                                            setState((prev: any) => { return { ...prev, empresaInfo: !empresa?.config?.empresaInfo } });
                                        }}
                                    />
                                    <Tooltip label="Adiciona no relatório as informações da empresa cadastrada." color="cyan" >
                                        <div style={{ marginTop: '-3px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                                    </Tooltip>
                                </div> */}



                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px' }}>

                                    <Checkbox
                                        label="Adicionar informações do cliente no relatório (endereço, email, cnpj..)"
                                        // value={empresa?.empresaInfo}
                                        checked={empresa?.config?.clienteInfo}
                                        onChange={(ev) => {
                                            setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, clienteInfo: !empresa?.config?.clienteInfo } } });
                                            setState((prev: any) => { return { ...prev, clienteInfo: !empresa?.config?.clienteInfo } });
                                        }}
                                    />
                                    <Tooltip label="Adiciona no relatório as informações do cliente cadastrado." color="cyan" >
                                        <div style={{ marginTop: '-3px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                                    </Tooltip>
                                </div>
                            </div>


                        </Col>
                    </Row>

                    <div style={{ marginBottom: '20px', marginTop: '15px', fontSize: '25px' }}>Configurações na capa:</div>
                    <Row>
                        <Col xs={12} md={12}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Form.Group className="mb-3" controlId="formBasicEmail" style={{ width: '100%' }}>
                                    <Form.Label>Título</Form.Label>
                                    <Form.Control
                                        autoComplete={"off"}
                                        type="text"
                                        placeholder="Texto na capa do relatóriio"
                                        value={empresa?.config?.titleCapa}
                                        // disabled={mode === "view" ? true : false}
                                        onChange={(ev) => {
                                            setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, titleCapa: ev.target.value } } });
                                            setState((prev: any) => { return { ...prev, titleCapa: ev.target.value } });
                                        }}
                                    />
                                </Form.Group>

                                <Tooltip label="Insira um texto para ser o título do seu relatório." color="cyan" >
                                    <div style={{ marginTop: '37px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {/* ================================================================================================================================================================== */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                        <div style={{
                            width: '100%',
                        }}>

                            <span style={{ fontSize: '16px' }}>

                                Tamanho do texto
                            </span>

                            <Slider marks={marks} value={empresa?.config?.fontSize} onChange={(ev) => {
                                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, fontSize: ev } } });
                                setState((prev: any) => { return { ...prev, fontSize: ev } });
                            }} />

                        </div>

                        <Tooltip label="Selecione o tamanho da fonte do título." color="cyan" >
                            <div style={{
                                marginTop: '20px',
                                marginLeft: '5px'
                            }}><FcInfo size={24} /></div>
                        </Tooltip>

                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                        <div style={{ width: '100%' }}>

                            <Select
                                style={{ width: '100%' }}
                                label="Margem da capa"
                                placeholder="Selecione o tipo de margem"
                                data={[
                                    { label: 'Contínua', value: 'solid' },
                                    { label: 'Pontilhada', value: 'dashed' },
                                    { label: 'Sem margem', value: 'none' },
                                ]}
                                value={empresa?.config?.marginType}
                                onChange={(ev) => {
                                    setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, marginType: ev } } });
                                    setState((prev: any) => { return { ...prev, marginType: ev } });
                                }}
                            />

                        </div>

                        <Tooltip label="Selecione o tipo de margem inserida na capa." color="cyan" >

                            <div style={{
                                marginTop: '30px',
                                marginLeft: '5px'
                            }}>
                                <FcInfo size={24} />
                            </div>

                        </Tooltip>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                        <div style={{ width: '100%' }}>

                            <Select
                                label="Alinhamento do texto"
                                placeholder="Selecione o tipo de alinhamento de texto"
                                data={[
                                    { label: 'Esquerda', value: 'left' },
                                    { label: 'Direita', value: 'right' },
                                    { label: 'Justificado', value: 'justify' },
                                    { label: 'centralizado', value: 'center' },
                                ]}
                                value={empresa?.config?.textAli}
                                onChange={(ev) => {
                                    setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, textAli: ev } } });
                                    setState((prev: any) => { return { ...prev, textAli: ev } });
                                    // console.log(ev)
                                }}
                            />

                        </div>
                        <Tooltip label="Selecione uma opção de alinhamento da fonte do título." color="cyan" >
                            <div style={{
                                marginTop: '30px',
                                marginLeft: '5px'
                            }}><FcInfo size={24} /></div>
                        </Tooltip>

                    </div>
                    <div style={{ width: '100%', marginBottom: '10px', display: 'flex', flexDirection: 'row', marginTop: '10px' }}>

                        <Select
                            style={{ width: '100%' }}
                            label="Logo na capa"
                            placeholder="Selecione o tipo de margem"
                            data={[
                                { label: 'Sim', value: 'true' },
                                { label: 'Não', value: 'false' }
                            ]}

                            value={empresa?.config?.capaLogo}
                            onChange={(ev) => {
                                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, capaLogo: ev } } });
                                setState((prev: any) => { return { ...prev, capaLogo: ev } });
                            }}
                        />
                        <Tooltip label="Selecione se deseja ou não inserir o logo na capa, mesmo pré-cofigurado." color="cyan" >
                            <div style={{
                                marginTop: '30px',
                                marginLeft: '5px'
                            }}><FcInfo size={24} /></div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}
