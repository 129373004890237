
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { MultipleImages } from "./MultipleImages";
import { INSPECTION } from "../../../constains";
import { Token } from "../../../helpers/token";
import useSWR, { mutate } from "swr";
import { Spin } from "react-cssfx-loading";


const Images = ({ inspectionId, mode1, mode2, image, setImage, imageEdit, setImageEdit, mode, disable, setDisable, setMode, sync, setSync, id }: any) => {
  const access = Token.getAccess();

  const url = `${INSPECTION}/images/${id}`;

  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const { data: data
    // = JSON.parse(inspectionOffLine)
    , isLoading } = useSWR(url, fetcher);

 React.useCallback(() => mutate(url), [mutate]);


  useEffect(() => {

    // console.log(data?.data?.images64)

    if(data?.data?.images64 !== undefined) {
      setImage(data?.data?.images64)
    }



  }, [data])



  if (isLoading) {

    return <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
      Aguarde...
    </div>
  }

  return (
    <Container>

      <MultipleImages
        mode={mode}
        image={image}
        setImage={setImage}
        mode1={mode1}
        mode2={mode2}
        imageEdit={imageEdit}
        setImageEdit={setImageEdit}
        disable={disable}
        setDisable={setDisable}
        setMode={setMode}
        sync={sync}
        setSync={setSync}
      />

    </Container >
  );
};

const Container = styled.div`
margin: 30px 0px 0px 0px;
  input {
    display: none;
  }
  .files-wr {
    padding: 20px;
    label {
      margin-bottom: 20px;
      border-bottom: 1px dashed #177cca;
      position: relative;
      display: inline-block;
      color: #177cca;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.2s;
      &:after {
        content: "+";
        width: 32px;
        height: 32px;
        border-radius: 5px;
        background-color: #177cca;
        position: absolute;
        top: -4px;
        right: -47px;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        transition: all 0.2s;
      }
      &:hover,
      &:active {
        color: #77c639;
        border-color: #77c639;
        &:after {
          background-color: #77c639;
        }
      }
    }

    .one-file {
      & ~ .one-file {
        label {
          display: none;
        }
      }
      &.error {
        border: none;
        .file-name {
          color: #ca4a17;
        }
      }
    }

    .file-item {
      position: relative;
      margin-top: 4px;
      display: flex;
      align-items: center;
      &.hide-btn {
        display: none;
      }
    }

    .file-name {
      font-size: 16px;
      font-style: italic;
      line-height: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
    .btn-del-file {
      margin-left: 5px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 3px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s;
      background-color: #177cca;
      &:hover,
      &:focus {
        background-color: #ca4a17;
      }
    }
  }
`;

export { Images };