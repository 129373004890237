import React, { Dispatch, SetStateAction } from "react";

import { Table, Button } from "react-bootstrap";
import { useTheme } from "styled-components";
import { 
  // CreateClient,
   DeleteClient, GetClient, UpdateClient } from "./api";
import { Client } from "../../../interfaces";
import { FaRegTrashAlt, FaEye, FaPencilAlt, FaMobileAlt } from "react-icons/fa";
import { BsFillTelephoneFill, BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import styled from "styled-components";
import { getPlatforms } from "@ionic/react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "../../../components/Modal";
import AdicionarClient from "./AdicionarClient";

interface Props {
  clients: Client[];
  setClients: Dispatch<SetStateAction<Client[]>>;
}

const DivButton = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  font-size: 1rem;
  svg {
    font-size: 1.8rem;
  }
`;

// const ImageDiv = styled.div`
//   background-color: red;
//   width: 50px;
//   height: 50px;
// `;
// const CenterContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// `;

// const Image = () => {
//   return (
//     <CenterContainer>
//       <ImageDiv>
//         <CenterContainer>ABC</CenterContainer>
//       </ImageDiv>
//     </CenterContainer>
//   );
// };

function BasicExample({ clients = [], setClients }: Props) {
  const theme = useTheme();
  const platform = getPlatforms();
  console.log("platform", platform);
  const [client, setClient] = React.useState<Client>({
    company: "",
    supervisor: "",
    cel: "",
    tel: "",
    email: "",
    address: "",
    cep: "",
    lat: "",
    lng: "",
  });
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleshow = (client: Client) => {
    setMode("view");
    setClient(client);
    setShow(true);
  };
  const handleEdit = (client: Client) => {
    setMode("edit");
    setClient(client);
    setShow(true);
  };

  const onSubmitHandler = () => {
    console.log("client", client);
    UpdateClient(client);
    GetClient(setClients);
    handleClose();
  };
  const [mode, setMode] = React.useState<"view" | "edit">("view");

  // if (!platform.includes("desktop")) return <Mobile />;
  return (
    <Content>
      <Table striped bordered hover variant={theme.themeName}>
        <thead>
          <tr>
            <th>Empresa</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients?.map((client: Client, index: number) => {
            const { name, id, email, cel, tel } = client;
            return (
              <tr key={index}>
                <td>
                  {/* <Table striped bordered hover variant={theme.themeName}> */}
                  <Table variant={theme.themeName}>
                    <tbody>
                      <tr>
                        <td colSpan={2}>{name}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FaMobileAlt color={theme.colors.textColor} /> {cel}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <BsFillTelephoneFill color={theme.colors.textColor} />{" "}
                          {tel}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <HiOutlineMail color={theme.colors.textColor} />{" "}
                          {email}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td style={{ width: "40px" }}>
                  <DivButton>
                    {!platform.includes("desktop") ? (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            color="red"
                            id="dropdown-basic"
                          >
                            <BsThreeDotsVertical />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Button
                                variant="primary"
                                style={{ width: "100%" }}
                                onClick={() => handleshow(client)}
                              >
                                <FaEye /> Visualizar
                              </Button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Button
                                variant="danger"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  DeleteClient(id, () => GetClient(setClients))
                                }
                              >
                                <FaRegTrashAlt /> Deletar
                              </Button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Button
                                variant="secondary"
                                onClick={() => handleEdit(client)}
                                style={{ width: "100%" }}
                              >
                                <FaPencilAlt /> Editar
                              </Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="primary"
                          onClick={() => handleshow(client)}
                        >
                          <FaEye />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() =>
                            DeleteClient(id, () => GetClient(setClients))
                          }
                        >
                          <FaRegTrashAlt />
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            handleEdit(client);
                          }}
                        >
                          <FaPencilAlt />
                        </Button>
                      </>
                    )}
                  </DivButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        title={`cliente ${client?.company}`}
        show={show}
        setShow={setShow}
        onSubmitHandler={mode === "edit" ? onSubmitHandler : () => {}}
        mode={mode}
        content={
          <AdicionarClient client={client} setClient={setClient} mode={mode} />
        }
      />
    </Content>
  );
}

export default React.memo(BasicExample);
