import React from "react";
import { IInspection } from "../../../interfaces";
import { useLocation } from "react-router-dom";
import { INSPECTION } from "../../../constains";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { IonSpinner } from "@ionic/react";
import { useRequest } from "ahooks";
import { alfaFilter } from "../../../helpers/utilsFunctions";

const formattedData = (formData: any, jsonSchema: any) => {
  const keys = Object?.keys(formData);
  return keys?.map((key: string, index: number) => {
    const { type, title, ...schemaObj } = jsonSchema?.properties[key] || {};
    const value = formData[key];

    let content: any = "";

    switch (type) {
      case "string":
        content = (
          <div
            style={{ display: "flex", flexDirection: "row", width: "100%" }}
          // className="subTitle"
          // className="column-container"
          >
            <div style={{ fontWeight: "bold" }}>{title}:</div>

            <div style={{ marginLeft: "10px" }}>{value}</div>
          </div>
        );
        break;
      case "object":
        const keys2 = Object?.keys(schemaObj?.properties) ?? [];
        const { properties = {} } = schemaObj || {};

        content = (
          <div
          >
            <table
              style={{
                width: "100%",
                margin: "20px 0px 20px 0px",
              }}
            >
              <thead>
                <tr>
                  <td
                    className="font"
                    colSpan={2}
                    style={{ backgroundColor: "#e6e5e5", fontWeight: "600" }}
                  >
                    {title}
                  </td>
                </tr>
              </thead>
            </table>

            <div
              style={{
                // flexDirection: 'row',
                display: "grid",
                gridTemplateColumns: `repeat(2, 1fr)`,
                gridGap: "10px",
              }}
            // className="content"
            >
              {keys2?.map((k) => (
                // console.log(properties[k].title == ' ')
                <>
                  <div style={{ margin: "0px 10px 0px 10px " }}>
                    <label style={{ fontWeight: "600", opacity: 0.7 }}>
                      {properties[k].title}
                      {properties[k].title == " " ? <></> : ":"}
                    </label>{" "}
                    <span
                      style={{
                        opacity: 0.8,
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {" "}
                      {value[k]}
                    </span>
                  </div>
                </>
              ))}
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return content;
    // return
  });
};

export const InspectionFormat = ({ infoClient, empresa, clientoflist, tipo, clientSelected, logo, infoEmpresa }: any) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const start: any = queryParams.get("start") ?? undefined;
  const end: any = queryParams.get("end") ?? undefined;
  const ano = queryParams.get("ano");
  const mes = parseInt(queryParams.get("mes") ?? "0", 10) ?? 0;
  const clientId: any = queryParams.get("clientId") ?? undefined;
  const title = queryParams.get("title") || null;
  const titleTitle = {
    backgroundColor: "#e6e5e5",
    fontWeight: "bold",
    font: "1.2rem sans-Serif",
  };
  const subTitle = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "800",
    opacity: 0.7,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const font = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "400",
    opacity: 0.5,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const fontBold = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "bold",
    opacity: 1,
    fontSize: "16px",
  };



  const name = queryParams.get("name");
  const cnpj = queryParams.get("cnpj");
  const address = queryParams.get("address");
  const phone = queryParams.get("phone");
  const email = queryParams.get("email");


  const fetcher = () => {
    const url = new URL(INSPECTION);
    url.search = new URLSearchParams({
      end: end,
      start: start,
      clientId: clientId,
      images: "true",
    }) as unknown as string;

    return fetch(url).then((res) => res.json());
  };

  const { data, loading } = useRequest(fetcher, {
  });

  if (!data && loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <IonSpinner></IonSpinner>
        <h2>O Relatório está sendo gerado, aguarde.</h2>
      </div>
    );

  const inspec = data?.data;





  const FilterNumber = (array: any[]) => {
    // @ts-ignore
    return array?.sort((a: any, b: any) => {
      const titleWithoutSpacesa = a?.equipment?.title?.replace(/\s/g, "");
      const titleWithoutSpacesb = b?.equipment?.title?.replace(/\s/g, "");
      const keyA = titleWithoutSpacesa.toLowerCase();
      const keyB = titleWithoutSpacesb.toLowerCase();
  
      const regex = /^(\d+)\s*-/;
      const matchA = keyA?.match(regex);
      const matchB = keyB?.match(regex);
  
      if (matchA && matchB) {
        const numericValueA = parseInt(matchA[1]);
        const numericValueB = parseInt(matchB[1]);
  
        if (numericValueA < numericValueB) {
          return -1;
        } else if (numericValueA > numericValueB) {
          return 1;
        }
      } else if (matchA && !matchB) {
        return -1;
      } else if (!matchA && matchB) {
        return 1;
      }
  
      const wordA = keyA?.split("-")[1]?.trim();
      const wordB = keyB?.split("-")[1]?.trim();
  
      if (wordA && wordB) {
        return wordA.localeCompare(wordB);
      } else if (wordA && !wordB) {
        return -1;
      } else if (!wordA && wordB) {
        return 1;
      }
  
      const numericA = parseInt(keyA);
      const numericB = parseInt(keyB);
  
      if (!isNaN(numericA) && !isNaN(numericB)) {
        return numericA - numericB;
      }
  
      return keyA?.localeCompare(keyB);
    });
  };




// console.log(inspec)








  return alfaFilter(inspec, 'equipment', 'title')?.map((inspection: IInspection, key: any) => {
    const {
      formData,
      images64,
      createAt,
      Inspectionmodel: { jsonSchema },
    } = inspection || {};

    const reFormat = formattedData(formData, jsonSchema);
  
    return (
      <Container style={{
      }}>





        {clientSelected?.length == 0 ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>Selecione um cliente para visualizar o relatório.</div> :
          (clientSelected?.map((clientoflist: any, key: any) => {
            return (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div
                  key={key}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div className='logoTitle' style={{ height: '90px' }}>
                    <h4 className="title">
                      {title}
                    </h4>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "30px",
                      right: "60px",
                      height: '250px'
                    }}
                  >
                    {logo == "" ?
                      (empresa?.config?.logo == 'Logo do cliente' || 'Logo da empresa' ?

                        (empresa?.config?.logo == 'Logo da empresa' ?

                          empresa?.image64 !== null ? (
                            <img
                              src={empresa?.image64}
                              alt="Logo"
                              style={{ width: "auto", maxHeight: "120px" }}
                            />
                          )
                            :
                            (
                              <>Não há logo cadastrado</>
                            )

                          :

                          <>logo do cliente</>)






                        : <></>)
                      :
                      (logo == "Logo do cliente" || logo == 'Logo da empresa' ?


                        (empresa?.config?.logo == 'Logo da empresa' ?

                          empresa?.image64 !== null ? (
                            <img
                              src={empresa?.image64}
                              alt="Logo"
                              style={{ width: "200px", maxHeight: "120px" }}
                            />
                          )
                            :
                            (
                              <>Não há logo cadastrado</>
                            )

                          :

                          <>logo do cliente</>) : <></>)
                    }
                  </div>
                </div>



                {infoEmpresa == "" ? (
                  empresa?.config?.infoEmpresa == 'Página' || empresa?.config?.infoEmpresa == 'Ambos' ? (
                    <>
                      {empresa?.name !== "" ? (
                        <span className="font">
                          {/* Iridium Engenharia */}
                          {name !== "" ? name : empresa?.name}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.phone !== "" ? (
                        <span className="font">
                          {/* Telefone: (21)21103544 */}
                          <span className="subTitle" style={fontBold}>
                            Telefone:{" "}
                          </span>
                          {phone !== "" ? phone : empresa?.phone}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.cnpj !== "" ? (
                        <span className="font">
                          {/* CNPJ: 36.174.476/0001-96 */}
                          <span className="subTitle" style={fontBold}>
                            CNPJ:{" "}
                          </span>
                          {cnpj !== "" ? cnpj : empresa?.cnpj}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.email !== "" ? (
                        <span className="font">
                          {/* Email: contato@termolibra.com.br */}

                          <span className="subTitle" style={fontBold}>
                            Email:{" "}
                          </span>
                          {email !== "" ? email : empresa?.email}
                        </span>
                      ) : (
                        ""
                      )}

                      {empresa.address !== "" ? (
                        <span className="font">
                          {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                          <span className="subTitle" style={fontBold}>
                            Endereço:{" "}
                          </span>

                          {address !== "" ? address : empresa?.address}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div style={{ height: "70px" }}></div>
                  )
                ) : infoEmpresa == "Página" || infoEmpresa == 'Ambos' ? (
                  <>
                    {empresa?.name !== "" ? (
                      <span className="font">
                        {/* Iridium Engenharia */}
                        {name !== "" ? name : empresa?.name}
                      </span>
                    ) : (
                      ""
                    )}

                    {empresa.phone !== "" ? (
                      <span className="font">
                        {/* Telefone: (21)21103544 */}
                        <span className="subTitle" style={fontBold}>
                          Telefone:{" "}
                        </span>
                        {phone !== "" ? phone : empresa?.phone}
                      </span>
                    ) : (
                      ""
                    )}

                    {empresa.cnpj !== "" ? (
                      <span className="font">
                        {/* CNPJ: 36.174.476/0001-96 */}
                        <span className="subTitle" style={fontBold}>
                          CNPJ:{" "}
                        </span>
                        {cnpj !== "" ? cnpj : empresa?.cnpj}
                      </span>
                    ) : (
                      ""
                    )}

                    {empresa.email !== "" ? (
                      <span className="font">
                        {/* Email: contato@termolibra.com.br */}

                        <span className="subTitle" style={fontBold}>
                          Email:{" "}
                        </span>
                        {email !== "" ? email : empresa?.email}
                      </span>
                    ) : (
                      ""
                    )}

                    {empresa.address !== "" ? (
                      <span className="font">
                        {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                        <span className="subTitle" style={fontBold}>
                          Endereço:{" "}
                        </span>

                        {address !== "" ? address : empresa?.address}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div style={{ height: "70px" }}></div>
                )}







                {infoClient == "" ? (empresa?.config?.clienteInfo && (
                  <Table key={key} style={{ marginTop: "20px", width: '100%' }}>
                    <thead>
                      <tr>
                        <td className="subTitle" colSpan={2} style={titleTitle}>
                          Informações do cliente
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="font" style={subTitle}>
                            Nome do cliente
                          </span>
                          <div className="font" style={font}>
                            {/* BODYTECH - VOGUE */}
                            {/* {clientoflist?.name} */}
                            {clientoflist?.name !== null
                              ? clientoflist?.name
                              : "Não cadastrado."}
                          </div>
                        </td>
                        {/* <td>
                          <span className="font" style={subTitle}>
                            CPF/CNPJ
                          </span>
                          <div style={font}> */}
                        {/* 07.737.623/0077-99 */}
                        {/* {clientoflist?.cnpj !== null
                              ? clientoflist?.cnpj
                              : "Não cadastrado."}
                          </div>
                        </td> */}
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <span className="font" style={subTitle}>
                            E-mail
                          </span>

                          <div className="font" style={font}>
                            {/* thiago.souza@bodytech.com.br */}
                            {/* {clientoflist?.email} */}
                            {clientoflist?.email !== null
                              ? clientoflist?.email
                              : "Não cadastrado."}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <span className="font" style={subTitle}>
                            Endereço
                          </span>
                          <div className="font" style={font}>
                            {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                            {clientoflist?.address !== null
                              ? clientoflist?.address
                              : "Não cadastrado."}
                            {/* {clientoflist?.address} */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )) : (
                  infoClient == "true" ?
                    <Table key={key} style={{ marginTop: "20px" }}>
                      <thead>
                        <tr>
                          <td className="subTitle" colSpan={2} style={titleTitle}>
                            Informações do cliente
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="font" style={subTitle}>
                              Nome do cliente
                            </span>
                            <div className="font" style={font}>
                              {/* BODYTECH - VOGUE */}
                              {/* {clientoflist?.name} */}
                              {clientoflist?.name !== null
                                ? clientoflist?.name
                                : "Não cadastrado."}
                            </div>
                          </td>
                          <td>
                            <span className="font" style={subTitle}>
                              CPF/CNPJ
                            </span>
                            <div style={font}>
                              {/* 07.737.623/0077-99 */}
                              {clientoflist?.cnpj !== null
                                ? clientoflist?.cnpj
                                : "Não cadastrado."}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span className="font" style={subTitle}>
                              E-mail
                            </span>

                            <div className="font" style={font}>
                              {/* thiago.souza@bodytech.com.br */}
                              {/* {clientoflist?.email} */}
                              {clientoflist?.email !== null
                                ? clientoflist?.email
                                : "Não cadastrado."}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span className="font" style={subTitle}>
                              Endereço
                            </span>
                            <div className="font" style={font}>
                              {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                              {clientoflist?.address !== null
                                ? clientoflist?.address
                                : "Não cadastrado."}
                              {/* {clientoflist?.address} */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    :
                    <></>

                )}





              </div>
            );
          }))
        }





        <Table style={{ width: '100%' }}>
          <thead>
            <tr>
              <td className="subTitle" colSpan={2} style={titleTitle}>
                Inspeção realizada
              </td>
            </tr>

          </thead>

        </Table>




        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#c0e5f3",
              width: "100%",
              borderRadius: "10px",
              margin: "15px 0px",
            }}
          >
            <span style={{ fontSize: "26px" }}>
              {/* @ts-ignore */}
              {inspection?.equipment?.title}
            </span>
            <span style={{ fontSize: "19px" }}>
              {/* @ts-ignore */}
              {inspection?.Inspectionmodel?.jsonSchema?.title}
            </span>

            <span>{moment(createAt).format("DD/MM/YYYY")}</span>
          </div>
        </div>

        <span style={{ fontWeight: "500", fontStyle: "oblique" }}>
          {/* @ts-ignore */}
          {inspection?.Inspectionmodel?.jsonSchema?.description}
        </span>
        {reFormat}

        <table
          style={{
            width: "100%",
            marginTop: "20px",
            margin: "10px 0px 20px 0px",
          }}
        >
          <thead>
            <tr>
              <td
                className="font"
                colSpan={2}
                style={{
                  backgroundColor: "#e6e5e5",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Fotos:
              </td>
            </tr>
          </thead>
        </table>

        <ImageContainer className="containerFoto" style={{
           pageBreakAfter: 'always',
        }}>
          {images64?.map((d, key) => (
            <img
              className="fotos"
              key={key}
              src={d}
              style={{ width: "450px", height: "680px" }}
            />
          ))}
        </ImageContainer>
        <br />
      </Container>
    );
  });
};



const ImageContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  justify-items: stretch; */
  /* margin-top: 30px;  */
  margin-bottom: 15px;
  /* padding-top: 30px; 

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 15px;

  img {
    /* height: 100px;
    width: auto; */
  /* } */
`;

const Container = styled.div`
  .column-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      font-weight: bold;
      font-size: 15px;
    }

    span {
      font-size: 12px;
    }
  }

  .row-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .content {
      div {
        padding: 5px;
      }
      display: flex;
      padding: 5px;
    }

    label {
      font-weight: bold;
      font-size: 15px;
    }

    span {
      font-size: 12px;
    }
  }
`;
