import React from "react";
import { Container, ToggleSelector } from "./styles";
import light from "./light";
import dark from "./dark";

import { useState, useEffect } from "react";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";

const SelectTheme: React.FC = ({ setTheme }: any) => {
  let TheTheme;
  let defaultTheme: string = window.localStorage.getItem("theme") || "light";
  if (defaultTheme === "dark") {
    TheTheme = true;
  } else {
    TheTheme = false;
  }

  const [checked, setChecked] = useState(TheTheme);

  const toggleDarkModeHandler = () => document.body.classList.toggle("dark");
  useEffect(() => {
    const confirm = checked ? "dark" : "light";

    const bodyList = document.body.classList || [];
    window.localStorage.setItem("theme", confirm);

    if (
      (checked && !bodyList.contains("dark")) ||
      (!checked && bodyList.contains("dark"))
    ) {
      toggleDarkModeHandler();
    }
  }, [checked]);

  setTheme(checked ? dark : light);
  // return (
  //   <IonToggle slot="end" name="darkMode" onIonChange={toggleDarkModeHandler} />
  // );
  return (
    <Container>
      <ToggleSelector
        id="themeToggle"
        checked={checked}
        uncheckedIcon={
          <BsFillSunFill color="yellow" style={{ margin: "0 0 4px 7px" }} />
        }
        checkedIcon={<BsFillMoonFill style={{ margin: "0 0 4px 7px" }} />}
        onChange={() => {
          setChecked(checked ? false : true);
        }}
      />
    </Container>
  );
};

export default SelectTheme;
