import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Geolocation, Position } from "@capacitor/geolocation";
import axios from "axios";
import { ADMIN_BRANCH_URL, USER_BY_COMPANY, USER_URL } from "../../constains";
import { Token } from "../../helpers/token";
import { apiGet } from "../../helpers/api";
import { getLocalData, getLocalData2, setLocalData } from "../../helpers/utils";
import Spinner from "react-bootstrap/Spinner";
import {
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
  getPlatforms,
  IonSpinner,
} from "@ionic/react";
import styled from "styled-components";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const MapContainer = (props: any) => {
  const access = Token.getAccess();
  const { id, companyId } = Token.getTokenData();
  const [users, setUsers] = useState([]);
  const [currentPosition, setCurrentPosition] = useState<{
    lat: GLfloat;
    lng: GLfloat;
  }>(null);
  const platform = getPlatforms();

  let currentShareLocation;

  const checkP = async () => {
    const permission = await Geolocation.checkPermissions();
    if (permission.location !== "granted")
      await Geolocation.requestPermissions();
  };

  checkP();

  getLocalData2("shareLocation").then((d) => {
    const { value } = d || {};
    if (value) currentShareLocation = value;
    else {
      if (!platform.includes("desktop")) {
        currentShareLocation = false;
        setLocalData("shareLocation", false);
      } else {
        currentShareLocation = true;
        setLocalData("shareLocation", true);
      }
    }
  });
  // console.log("currentShareLocation", currentShareLocation);
  const [shareLocation, setShareLocation] = useState(
    currentShareLocation || true
  );
  const [watch, setWatch] = useState<string>("1");

  const getData = () => {
    if (platform.includes("desktop"))
      apiGet(`${USER_BY_COMPANY}/${companyId}`, setUsers);
  };

  const _currentPosition = async () => {
    Geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000 })
      .then((coordinates) => {
        const {
          coords: { latitude, longitude },
        } = coordinates;
        // console.log("coordinates", coordinates);
        setCurrentPosition({ lat: latitude, lng: longitude });
      })
      .catch((err) => {
        console.error("Error", err);
        setCurrentPosition({ lat: -22.8982784, lng: -43.1128576 });
      });
  };

  React.useEffect(() => {
    if (shareLocation) getCurrentPosition();
    else stopTraking(watch);

    setLocalData("shareLocation", shareLocation);
  }, [shareLocation]);

  React.useEffect(() => {
    _currentPosition();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const sendPosition = (position: Position): void => {
    const {
      coords: { latitude, longitude },
    } = position;

    const location = { lat: latitude, lng: longitude };

    axios
      .post(`${USER_URL}/location/${id}`, location, access)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          // console.log("dados atualizados");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCurrentPosition = async () => {
    const _watch = await Geolocation.watchPosition(
      { enableHighAccuracy: true },
      (position, err) => {
        if (!position) return;
        sendPosition(position);
        // console.log("new position", position, err);
      }
    );
    setWatch(_watch);
  };

  const stopTraking = (id: string): void => {
    Geolocation.clearWatch({ id: id })
      .then((d) => {
        console.log("stopTraking", d);
      })
      .catch((e) => {
        console.error("Error", e);
      });
  };

  if (!platform.includes("desktop"))
    return (
      <>
        <IonItem>
          <IonLabel>Compartilhar Localização</IonLabel>
          <IonToggle
            slot="start"
            checked={shareLocation}
            onIonChange={() => setShareLocation(!shareLocation)}
          ></IonToggle>
        </IonItem>
        <IonItem>
          <h3>Acesse pelo navegador web para visualizar o mapa</h3>
        </IonItem>
      </>
    );

  if (!currentPosition)
    return (
      <LoadingContainer>
        <Spinner animation="border" />
      </LoadingContainer>
    );

  return (
    <>
      <Map
        google={props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={currentPosition}
      >
        {users?.map((user, index) => {
          const { name, location } = user;
          const { lat, lng } = location || { lat: null, lng: null };
          if (!lat || !lng) return;

          return (
            <Marker
              key={index}
              title={name}
              name={name}
              label={name}
              position={{ lat, lng }}
            />
          );
        })}
        {/* <Marker
              title={"teste"}
              name={"teste"}
              label={<span>testeee</span>}
              position={{ lat: -22.8982784, lng: -43.1128576 }}
            /> */}
      </Map>
    </>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner-border {
    --bs-spinner-width: 4rem !important;
    --bs-spinner-height: 4rem !important;
  }
`;
export default GoogleApiWrapper({
  apiKey: "AIzaSyBibqwg7zkuCR_Re6aKlDxp4R_3JZwUlp0",
})(MapContainer);
