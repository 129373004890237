import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "../../../components/Modal";
import AdicionarOs from "./AdicionarOs";
import { Os } from "../../../interfaces";
import { CreateOs, GetOs } from "./api";
import OsTable from "./OsTable";

export const OSs = (): React.ReactElement => {
  const [mode, setMode] = React.useState<"view" | "edit">("edit");
  const [show, setShow] = useState<boolean>(false);
  const [OSs, setOSs] = useState<Os[]>([]);
  const [OS, setOS] = useState<any>({
    title: "",
    supervisor: "",
    priority: 0,
    assignTo: "",
    text: "",
    client: "",
    companyId: 0,
  });
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  //@ts-ignore
  const getData = () => GetOs(setOSs);
  const onSubmitHandler = () => {
    CreateOs(OS, getData);

    handleClose();
  };

  useEffect(() => {
    //@ts-ignore
    GetOs(setOSs);
  }, []);

  return (
    <>
      <Button
        style={{ height: "auto", fontSize: "20px" }}
        variant="primary"
        onClick={() => {
          handleShow();
          setMode("edit");
        }}
      >
        <AiOutlinePlus /> Nova OS
      </Button>

      <OsTable OSs={OSs} setOSs={setOSs} />

      <Modal
        title="adicionar cliente"
        show={show}
        setShow={setShow}
        onSubmitHandler={onSubmitHandler}
        content={
          <AdicionarOs OS={OS} setOS={setOS} mode={mode} />
        }
      />
    </>
  );
};
