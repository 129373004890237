/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CiCirclePlus } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";
import { Token } from "../../helpers/token";
import axios from "axios";
import { ADMIN_BRANCH_URL } from "../../constains";
import { CallNotification } from "../../components/Alert";
import { Checkbox, Switch, Slider, Select, Tooltip } from "@mantine/core";
import { Spin } from "react-cssfx-loading";
import { FcInfo } from "react-icons/fc";




export default function Capa() {
  const [image, setImage] = useState<any>(null);
  const [empresa, setEmpresa] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const fileInputRef = useRef(null);
  const access = Token.getAccess();
  const idEm = Token.getTokenData()?.companyId;

  const handleButtonClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const onLoad = (fileString: any) => {
    setImage(fileString);
    setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, image: fileString } } });
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onLoad(reader.result);
  };
  const onChangeGetImage = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const handleFileChange = (e: any) => {
    e.persist();
    onChangeGetImage(e);
  };
  useEffect(() => {
    axios
      .get(`${ADMIN_BRANCH_URL}/${idEm}`, access)
      .then((res) => {
        // console.log(res.data.data)
        setEmpresa(res?.data?.data);
        setImage(res?.data?.data?.config?.image);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const updateConfig = () => {
    const put = {
      // name: empresa.name,
      // cnpj: empresa.cnpj,
      // email: empresa.email,
      // phone: empresa.phone,
      // address: empresa.address,
      // image64: image,
      config: empresa.config
    }

    axios
      .put(`${ADMIN_BRANCH_URL}/${idEm}`, put, access)
      .then((res) => {
        CallNotification('Sucesso', 'Configurações edidatas com sucesso', 'success')
      })
      .catch((error) => {
        console.error(error);
        CallNotification('Erro', 'Configurações não foram edidatas com sucesso', 'danger')
      });
  }

  // console.log(empresa)

  const marks = [
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 80, label: '80' },
  ];

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
        Aguarde...
      </div>
    );


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '90%', margin: '10px' }}>



        <Form autoComplete={"off"}>




          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Logo</Form.Label>



                {image ?

                  <div style={{
                    // marginTop: '-20px',
                    borderRadius: '20px',
                    width: 'auto%',
                    height: '300px',
                    maxHeight: '300px',
                    minHeight: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid black',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                  }}

                  >
                    <input
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      type="file"
                      id='ForInputCam2'
                      onClick={(ev: any) => {
                        ev.target.value = ''
                      }}
                    />

                    <img
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        // borderRadius: '30px',
                      }}
                      onClick={handleButtonClick}
                      src={image} />

                    <div
                      onClick={() => {
                        setImage(null);
                        setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, image: null } } });
                      }}
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '10px',
                        cursor: 'pointer'
                      }}>
                      <TbTrashXFilled size={40} />
                    </div>

                  </div>

                  :

                  // sem imagem selecionada

                  <div
                    onClick={handleButtonClick}
                    style={{
                      borderRadius: '20px',
                      width: '100%',
                      // height: '400px',
                      maxHeight: '300px',
                      minHeight: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '2px solid black',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      position: 'relative'
                    }}>
                    <>
                      <span style={{ fontSize: '27px' }}>
                        Adicionar imagem
                      </span>
                      <CiCirclePlus size={40} /></>
                    <input
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      type="file"
                      id='ForInputCam2'
                      onClick={(ev: any) => {
                        ev.target.value = ''
                      }}
                    />
                  </div>

                }

              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

                <Form.Group className="mb-3" controlId="formBasicEmail" style={{ width: '100%' }}>
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="Texto na capa do relatóriio"
                    value={empresa?.config?.titleCapa}
                    // disabled={mode === "view" ? true : false}
                    onChange={(ev) => {
                      setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, titleCapa: ev.target.value } } });
                    }}
                  />
                </Form.Group>
                <Tooltip label="Insira um texto para ser o título do seu relatório." color="cyan" >
                  <div style={{ marginTop: '14px', marginLeft: '5px' }}><FcInfo size={24} /></div>
                </Tooltip>
              </div>

            </Col>
          </Row>

        </Form>



        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>



 

          <h5 style={{ marginBottom: '10px' }}>Formatação do título:</h5>

          <div style={{ width: '100%', marginBottom: '15px', display: 'flex', flexDirection: 'row' }}>
<div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '10px'}}>


            <span style={{width: '200px', marginBottom: '10px', fontSize: '14px'}}>
Tamanho da fonte
            </span>

            <Slider
              style={{ width: '100%', paddingTop: '5px' }}
              marks={marks} value={empresa?.config?.fontSize} onChange={(ev) => {
                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, fontSize: ev } } });
              }} />

                
</div>

            <Tooltip label="Selecione o tamanho da fonte do título." color="cyan" >
              <div style={{
                 marginTop: '30px', 
                 marginLeft: '5px'
                  }}><FcInfo size={24} /></div>
            </Tooltip>

          </div>



<div style={{display: 'flex', flexDirection: 'row'}}>





          <div style={{ width: '100%', marginBottom: '10px', marginRight: '4px', display: 'flex', flexDirection: 'row' }}>

            <Select
            style={{width: '100%'}}
              label="Alinhamento do título"
              placeholder="Selecione o tipo de alinhamento de texto"
              data={[
                { label: 'Esquerda', value: 'left' },
                { label: 'Direita', value: 'right' },
                { label: 'Justificado', value: 'justify' },
                { label: 'centralizado', value: 'center' },
              ]}
              value={empresa?.config?.textAli}
              onChange={(ev) => {
                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, textAli: ev } } });

              }}
            />
              <Tooltip label="Selecione uma opção de alinhamento da fonte do título." color="cyan" >
              <div style={{
                 marginTop: '30px', 
                 marginLeft: '5px'
                  }}><FcInfo size={24} /></div>
            </Tooltip>
          </div>


          <div style={{ width: '100%', marginBottom: '10px',marginRight: '4px', display: 'flex', flexDirection: 'row' }}>

            <Select
                 style={{width: '100%'}}
              label="Margem da capa"
              placeholder="Selecione o tipo de margem"
              data={[
                { label: 'Contínua', value: 'solid' },
                { label: 'Pontilhada', value: 'dashed' },
                { label: 'Sem margem', value: 'none' },
              ]}

              value={empresa?.config?.marginType}
              onChange={(ev) => {
                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, marginType: ev } } });
              }}
            />
                <Tooltip label="Selecione o tipo de margem inserida na capa." color="cyan" >
              <div style={{
                 marginTop: '30px', 
                 marginLeft: '5px'
                  }}><FcInfo size={24} /></div>
            </Tooltip>
          </div>



          <div style={{ width: '100%', marginBottom: '10px', display: 'flex', flexDirection: 'row' }}>

            <Select
                 style={{width: '100%'}}
              label="Logo na capa"
              placeholder="Selecione o tipo de margem"
              data={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false' }
              ]}

              value={empresa?.config?.capaLogo}
              onChange={(ev) => {
                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, capaLogo: ev } } });
              }}
            />
            <Tooltip label="Selecione se deseja ou não inserir o logo na capa, mesmo pré-cofigurado." color="cyan" >
              <div style={{
                 marginTop: '30px', 
                 marginLeft: '5px'
                  }}><FcInfo size={24} /></div>
            </Tooltip>
          </div>







         {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>

            <Checkbox
              label="Adicionar logo na capa"
              // value={empresa?.empresaInfo}
              checked={empresa?.config?.capaLogo}
              onChange={(ev) => {
                // console.log(ev.target.value)
                setEmpresa((prev: any) => { return { ...prev, config: { ...prev.config, capaLogo: !empresa?.config?.capaLogo } } });
              }}
            />

          </div> */}


        </div>


        </div>





        <div style={{ margin: '20px' }}>
          <Button onClick={() => { updateConfig() }}>Atualizar</Button>
        </div>


      </div>




    </div>

  );
}
