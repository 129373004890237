import React, {
  memo,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Os } from "../../../interfaces";
import { useTheme } from "styled-components";
import { DeleteOs, GetOs, UpdateOs, GetOss, GetUser } from "../api";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import Modal from "../../../components/Modal";
import AdicionarOs from "../AdicionarOs";
import { formatDistance, subDays } from "date-fns";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import Card from "../../../components/card/card";
import { Table, Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import { FaRegTrashAlt, FaEye, FaPencilAlt, FaMobileAlt } from "react-icons/fa";
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  getPlatforms,
} from "@ionic/react";
import {
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from "@ionic/react";

import { OS_STATUS, URL_OS, USER_BY_COMPANY, ViewScroll } from "../../../constains";
import {
  colorOptionsPriority,
  colorOptionsStatus,
} from "../../../styles/cssVariables";
import { ptBR } from "date-fns/locale";
import { createOutline, eyeOutline, trashOutline } from "ionicons/icons";
import { parseJwt } from "../../../helpers/utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Token } from "../../../helpers/token";
import { useLocation } from "react-router-dom";
import useSWR from "swr";

interface Props {
  OSs: Os[];
  componentOptionsPriority: any;
  handleShow: (os: Os) => void;
  setOSs?: any;
  handleEdit: (os: Os) => void;
  onConfirm: any;
}

const Mobile = (props: Props) => {
  const { id: userId, visualizarOS, editarOS, deletarOS, }: any = Token.getTokenData();
  const { handleShow, setOSs, handleEdit, onConfirm } = props;
  const theme = useTheme();
  const [idOsDelete, setIdOsDelete] = useState<string>('');
  const [showConfirm, setShowConfirm] = useState(false);
  const { clientUser, companyId, id } = Token.getTokenData();

  const access = Token.getAccess();

  const url2 = `${USER_BY_COMPANY}/${companyId}`;
  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
  let theUser: any = []
  if (loading2 == false) {
    theUser = allUsers?.data?.filter((value: any) => {
      return value.id == id
    })
  }
  const finalArrayClients = theUser[0]?.clients?.map((value: any) => { return value.id })


  const onConfirm2 = () => {
    onConfirm(idOsDelete, userId)
  }




  return (
    <IonList>

      {props?.OSs?.filter((value: any) => {

        if (clientUser == true && finalArrayClients.includes(value.clientId)) { return value }
        if (clientUser == false) { return value }


      })?.map((os: any, index: number) => {
        const {
          title = "--",
          status = 0,
          createAt = '0',
          priority = 0,
          id = '0',
          text,
          client,
          users,
        } = os || "--";
        const { name: clientName } = client || { name: "--" };
        return (
          <IonItemSliding key={index}>
            <IonItem>
              <IonCard
                style={{
                  borderLeft: `8px solid ${colorOptionsStatus[status]}`,
                  width: "100%",
                }}
              >
                <IonCardHeader>
                  <IonCardTitle>
                    {props?.componentOptionsPriority[priority]} {title}
                  </IonCardTitle>
                  <IonCardSubtitle>
                    <CardText>{text}</CardText>
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Container fluid>
                      <Row>
                        <Col md={12}>
                          <Osgrautempo>
                            <div
                              style={{
                                backgroundColor: `${colorOptionsStatus[status]}`,
                                color: `black`,
                                borderRadius: "10px",
                                padding: "2px",
                              }}
                            >
                              {OS_STATUS[status]}
                            </div>

                            <Tempo>
                              <BiTimeFive
                                style={{ marginRight: "5px" }}
                                color={theme.colors.textColor}
                              />
                              {formatDistance(new Date(createAt), new Date(), {
                                locale: ptBR,
                              })}{" "}
                              Atrás.
                            </Tempo>
                          </Osgrautempo>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonItem>


            <IonItemOptions>

              {<IonItemOption onClick={() => handleShow(os)}>
                Visualizar
              </IonItemOption>}



              {!window.location?.pathname?.includes('/abrir_os') && clientUser ? '' :


                (deletarOS === false ? (
                  ""
                ) : (
                  <IonItemOption
                    color="danger"
                    onClick={() => props.onConfirm(id)}
                  >
                    Delete
                  </IonItemOption>))}








              {editarOS === false ? ('') :


                <IonItemOption color="dark" onClick={() => handleEdit(os)}>
                  Editar
                </IonItemOption>}

            </IonItemOptions>

          </IonItemSliding>
        );
      })}
    </IonList>
  );
};

const CardText = styled.div`
  max-width: 70%; // Limite maximo do texto
  white-space: nowrap; // Removendo quebra de linha
  overflow: hidden; // Removendo a barra de rolagem
  text-overflow: ellipsis;
`;

const Content = styled.div`
  font-size: 1rem;
  svg {
    font-size: 1.2rem;
  }
`;
const Imagem = styled.div`
  background-color: var(--gray-500);
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Informacoes = styled.div`
  display: flex;
  flex-direction: column;
`;
const OsIdName = styled.div`
  margin-left: 20px;
  color: var(--blue-700);
  width: auto;
`;
const Osgrautempo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Grau = styled.span`
  /* border: 1px solid var(--red-700); */
  border-radius: 10px;
  height: auto;
  width: auto;
  /* color: var(--red-700); */
  color: black;
  /* background-color: var(--red-300); */
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  margin: 0px 10px 0px 0px;
`;
const Tempo = styled.span`
  margin-left: 10px;
  border: 1px solid var(--blue-700);
  border-radius: 10px;
  height: auto;
  width: auto;
  color: var(--blue-700);
  background-color: var(--blue-200);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const OsOrganization = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: red; */
`;

const DivButton = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
`;

export { Mobile };
