/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Company, Os, Mode } from "../../../interfaces";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
import { IonTextarea } from "@ionic/react";
import { COMPANY_URL, USER_BY_COMPANY } from "../../../constains";
import axios from "axios";
import {
  getDataRequest,
  getLocalData,
  getLocalData2,
  parseJwt,
} from "../../../helpers/utils";
import { Tab, Tabs } from "react-bootstrap";
import { Images } from "./Images";
import { Token } from "../../../helpers/token";
import { ColourOption, colourOptions, colourStyles } from "../style";
import FilterClientes from "../../../components/FilterClientes";
import { useRequest } from "ahooks";
import useSWR, { useSWRConfig } from "swr";
import { MyOs } from "..";
import moment from "moment";

interface Props {
  mode?: Mode["mode"];
  OS: any;
  setOS: any;
  setHasChangeUser?: (value?: any) => void;
  myOs?: boolean;
}

export default function AdicionarOs({
  mode = "view",
  OS,
  setOS,
  setHasChangeUser = () => { },
  myOs = false,
}: Props) {
  const { companyId, id, name, email, clientUser } = Token.getTokenData();
  const access = Token.getAccess();

  const url2 = `${USER_BY_COMPANY}/${companyId}`;
  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
  let theUser: any = []
  if (loading2 == false) {
    theUser = allUsers?.data?.filter((value: any) => {
      return value.id == id
    })
  }
  const finalArrayClients = theUser[0]?.clients.map((value: any) => { return value.id })
  const time = new Date();

  console.log(time)

  const setParameters = (key: string, ev: any) => {
    ev.persist();
    //@ts-ignore
    setOS((prevState: Os): void => ({
      ...prevState,
      [key]: ev?.target?.value,
    }));
  };
  const setParametersSelect = (key: string, ev: any) => {
    // ev.persist();
    //@ts-ignore
    // console.log(ev);

    setOS((prevState: Os): void => ({
      ...prevState,
      [key]: ev?.value,
    }));
  };
  const setParametersSelect2 = (key: string, ev: any) => {
    // ev.persist();
    setHasChangeUser(true);


    //@ts-ignore
    setOS((prevState: Os): void => ({
      ...prevState,
      emailList: ev?.map((e: any) => ({
        id: e?.email,
      })),
      [key]: {
        [mode == "edit" ? "set" : "connect"]: ev?.map((e: any) => ({
          id: e?.value,
          name: e?.label
        })),
      },
    }));






  };



  useEffect(() => {
    if (mode == "create" && myOs) {
      setOS({ users: { connect: [{ id: id, name: name }] } })
    }
  }, [myOs, mode])

  const { cache, mutate, ...extraConfig } = useSWRConfig();
  const {
    // @ts-ignore
    data: { data },
  } = React.useMemo(() => {
    return cache.get(`${COMPANY_URL}/${companyId}`);
  }, [cache]);

  // const { data } = cache.get(`${COMPANY_URL}/${companyId}`);

  // const { data, loading } = useRequest(
  //   () => getDataRequest<any>(`${COMPANY_URL}/${companyId}`),
  //   {
  //     cacheKey: `${COMPANY_URL}/${companyId}`,
  //     cacheTime: -1,
  //   }
  // );

  let optionsCli = [{ value: "primeiro", label: "primeiro" }];

  optionsCli = React.useMemo(
    () =>
      data?.clients?.map((clients: any, index: any) => ({
        value: clients?.id,
        label: clients?.name,
      })) ?? [],
    [data]
  );

  const optionsTO = React.useMemo(
    () =>
      data?.users?.map((clients: any, index: any) => {

        if (clients.admin == false && clients.clientUser == false) {

          return {
            value: clients?.id,
            label: clients?.name,
            email: clients?.email,
          }
        }
      }

      ).filter((value: any) => {
        return value !== undefined
      })
      ?? [],
    [data]
  );




  console.log(OS)


  const defaultValue = React.useMemo(
    () => {




      if (mode == 'edit' && OS?.users?.set !== undefined) {
        // console.log('1')
        // @ts-ignore
        return OS?.users?.set?.map((user: any) => {

          return { value: user?.id, label: user?.name, email: user?.email };
        })
      }

      if (mode == 'edit' && OS?.users?.set == undefined) {
        // console.log('2')
        // @ts-ignore
        return OS?.users?.map((user: any) => {

          return { value: user?.id, label: user?.name, email: user?.email };
        })
      }

      if (mode == 'create' && OS?.users?.connect !== undefined) {
        // console.log('3')
        // @ts-ignore
        return OS?.users?.connect?.map((user: any) => {

          return {
            value: user?.id,
            label: user?.name,
            // email: user?.email
          }
        })
      }
      if (mode == 'view') {
        // console.log('4')
        // @ts-ignore
        return OS?.users?.map((user: any) => {

          return { value: user?.id, label: user?.name, email: user?.email }
        })
      }


      if (mode == "create" && myOs) {
        return [{ value: id, label: name, email: email }]
      }

    }
    ,
    [OS, data]
  );



  // useEffect(() => {
  //   setOS((prev: any) => {
  //     [{...prev, set: OS?.users }]
  //   })
  // }, [])

  // console.log(OS)
  // console.log(defaultValue)

  const optionsPri = [
    { value: 0, label: "Baixa" },
    { value: 1, label: "Média" },
    { value: 2, label: "Alta" },
  ];

  return (
    <ModalDiv>
      <Tabs defaultActiveKey="Informações" className="mb-3">
        <Tab eventKey="Informações" title="Informações">
          <Form autoComplete={"off"}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="Nome da Os"
                    value={OS?.title}
                    disabled={mode == "view" ? true : false}
                    onChange={(ev) => setParameters("title", ev)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Ref:</Form.Label>
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="Numero de referência gerado automaticamente"
                    value={OS?.ref}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* 
            <Row>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Orientação</Form.Label>
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    placeholder="Informação sobre a Os"
                    value={OS?.orientacao}
                    disabled={mode == "view" ? true : false}
                    onChange={(ev) => setParameters("orientacaos", ev)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Empresa</Form.Label>
                  <Select
                    //@ts-ignore
                    options={FilterClientes(optionsCli?.filter((value: any) => {

                      if (clientUser == true && finalArrayClients.includes(value.value)) { return value }
                      if (clientUser == false) { return value }


                    }), "label")}
                    value={optionsCli.filter(
                      (option: any) => option.value === OS?.clientId
                    )}
                    isDisabled={mode == "view" ? true : false}
                    onChange={(ev) => setParametersSelect("clientId", ev)}
                  />
                </Form.Group>
              </Col>

              {clientUser && window.location?.pathname?.includes('/abrir_os') ? <></> :
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Atribuído para:</Form.Label>

                    <Select
                      //@ts-ignore
                      value={
                        defaultValue
                      }
                      isDisabled={mode == "view" || myOs == true ? true : false}
                      onChange={(ev) => setParametersSelect2("users", ev)}
                      // defaultValue={optionsTO}
                      options={FilterClientes(optionsTO, "label")}
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable={true}

                    />
                  </Form.Group>
                </Col>
              }

            </Row>

            <Row>

              {clientUser && window.location?.pathname?.includes('/abrir_os') ? <></> :
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Supervisor:</Form.Label>
                    <Select
                      //@ts-ignore
                      options={FilterClientes(optionsTO, "label")}
                      value={optionsTO.filter(
                        (option: any) => option.value === OS?.assignTo
                      )}
                      isClearable={true}
                      isDisabled={mode == "view" ? true : false}
                      onChange={(ev) => setParametersSelect("assignTo", ev)}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Form.Group>
                </Col>
              }
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Prioridade</Form.Label>
                  <Select
                    //@ts-ignore
                    options={optionsPri}
                    value={optionsPri.filter(
                      (option) => option.value === OS?.priority
                    )}
                    isDisabled={mode == "view" ? true : false}
                    onChange={(ev) => setParametersSelect("priority", ev)}
                    menuPortalTarget={document.body}
                    isSearchable={false}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {mode == "edit" ? (
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Status:</Form.Label>
                    <Select
                      // defaultValue={[colourOptions[0], colourOptions[1]]}
                      // isMulti
                      value={colourOptions.filter(
                        (option) => option.value === OS.status
                      )}
                      onChange={(ev: any) => {
                        if (ev?.label == 'Concluida') {
                          setParametersSelect("endDate", { value: moment(time).add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')})
                        }

                        setParametersSelect("status", ev);

                      }}
                      isDisabled={false}
                      options={colourOptions}
                      // styles={colourStyles}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : null}
          </Form>
        </Tab>
        <Tab eventKey="descrição" title="Descrição">
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Informações da Os</Form.Label>

                <Div>
                  <IonTextarea
                    style={{ height: "100%" }}
                    rows={10}
                    disabled={mode == "view" ? true : false}
                    placeholder="Insira um texto"
                    value={OS?.text}
                    onIonChange={(e) =>
                      //@ts-ignore
                      setOS((prevState: Os): void => ({
                        ...prevState,
                        text: e.detail.value!,
                      }))
                    }
                  />
                </Div>
              </Form.Group>
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="Fotos" title="Fotos">
          <Images OS={OS} setOS={setOS} mode={mode} />
        </Tab>
      </Tabs>
    </ModalDiv>
  );
}

const Div = styled.div`
  border: 2px dotted;
  border-color: var(--color1);
  /* height: 209px; */
  padding: 1px;
  overflow-y: hidden;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;

const ModalDiv = styled.div`
  max-height: 589px;
  /* min-height: 450px; */
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
