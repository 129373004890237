import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import { ADMIN_URL_IMAGE, USER_URL } from "../../constains";
import { User } from "../../interfaces";
import { HiOutlineMail } from "react-icons/hi";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import {
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  getPlatforms,
} from "@ionic/react";
import { apiDelete } from "../../helpers/api";
import { CallNotification } from "../../components/Alert";
import Modal from "../../components/Modal";
import AdicionarColaborador from "./AdicionarColaborador/adicionarColaborador";
import { BsFillPersonFill } from "react-icons/bs";
import { Token } from "../../helpers/token";
const DivButton = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
`;
const Content = styled.div`
  font-size: 16px;
  svg {
    font-size: 20px;
  }
`;

const DivHoverEquipe = styled.div`
  .hoverParaEquipe {
    :hover {
      opacity: 0.6;
    }
  }
`;
const MinhaEquipe = ({
  user,
  setUser,
  users = [],
  getData,
  show,
  setShow,
  mode,
  setMode,
  colaborador,
  setColaborador,
}: any): React.ReactElement => {
  const [idDelete, setIdDelete] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState(undefined);
  const theme = useTheme();
  const platform = getPlatforms();
  const access = Token.getAccess();

  const handleClose = () => setShow(false);
  const handleshow = (user: User) => {
    setMode("view");
    setUser(user);
    setShow(true);
  };
  const handleEdit = (user: User) => {
    setMode("edit");
    setUser(user);
    setShow(true);
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    let _user = user;
    if (image)
      _user.image = { create: { image64: image, imageType: "Profile" } };

    if (mode === "edit") {
      let putUser: any;

      if (image !== undefined) {
        putUser = {
          image: (user.image = {
            create: { image64: image, imageType: "Profile" },
          }),

          name: user.name,
          email: user.email,

          criarOS: user.criarOS,
          criarColaborador: user.criarColaborador,
          criarCliente: user.criarCliente,
          criarModelo: user.criarModelo,
          criarInspection: user.criarInspection,
          criarEquipamento: user.criarEquipamento,

          deletarOS: user.deletarOS,
          deletarColaborador: user.deletarColaborador,
          deletarCliente: user.deletarCliente,
          deletarModelo: user.deletarModelo,
          deletarInspection: user.deletarInspection,
          deletarEquipamento: user.deletarEquipamento,

          editarCliente: user.editarCliente,
          editarColaborador: user.editarColaborador,
          editarOS: user.editarOS,
          editarModelo: user.editarModelo,
          editarInspection: user.editarInspection,
          editarEquipamento: user.editarEquipamento,

          visualizarCliente: user.visualizarCliente,
          visualizarColaborador: user.visualizarColaborador,
          visualizarOS: user.visualizarOS,
          visualizarModelo: user.visualizarModelo,
          visualizarInspection: user.visualizarInspection,
          visualizarEquipamento: user.visualizarEquipamento,
        };
      } else {
        putUser = {
          name: user.name,
          email: user.email,

          criarOS: user.criarOS,
          criarColaborador: user.criarColaborador,
          criarCliente: user.criarCliente,
          criarModelo: user.criarModelo,
          criarInspection: user.criarInspection,
          criarEquipamento: user.criarEquipamento,

          deletarOS: user.deletarOS,
          deletarColaborador: user.deletarColaborador,
          deletarCliente: user.deletarCliente,
          deletarModelo: user.deletarModelo,
          deletarInspection: user.deletarInspection,
          deletarEquipamento: user.deletarEquipamento,

          editarCliente: user.editarCliente,
          editarColaborador: user.editarColaborador,
          editarOS: user.editarOS,
          editarModelo: user.editarModelo,
          editarInspection: user.editarInspection,
          editarEquipamento: user.editarEquipamento,

          visualizarCliente: user.visualizarCliente,
          visualizarColaborador: user.visualizarColaborador,
          visualizarOS: user.visualizarOS,
          visualizarModelo: user.visualizarModelo,
          visualizarInspection: user.visualizarInspection,
          visualizarEquipamento: user.visualizarEquipamento,
        };
      }

      axios
        .put(`${USER_URL}/${user?.id}`, putUser, access)
        .then((response) => {
          CallNotification("Sucesso", "usuario editado", "success");
          getData();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          CallNotification("Error", "Falha ao cadastrar usuário", "danger");
        });
    } else {
      axios
        .post(USER_URL, _user, access)
        .then((response) => {
          CallNotification("Sucesso", "usuario cadastrado", "success");
          getData();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          CallNotification("Error", "Falha ao cadastrar usuário", "danger");
        });
    }
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onLoad(reader.result);
  };
  const onChangeGetImage = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const onLoad = (fileString: any) => setImage(fileString);
  const onConfirm = () => {
    apiDelete(USER_URL, idDelete, () => getData());
  };
  const FilterNumber = (array: any) => {
    // @ts-ignore
    return array?.sort((a, b) => {
      const keyA = a.name.toLowerCase();
      const keyB = b.name.toLowerCase();

      const regex = /^(\d+)\s*-/;
      const matchA = keyA.match(regex);
      const matchB = keyB.match(regex);

      if (matchA && matchB) {
        const numericValueA = parseInt(matchA[1]);
        const numericValueB = parseInt(matchB[1]);

        if (numericValueA < numericValueB) {
          return -1;
        } else if (numericValueA > numericValueB) {
          return 1;
        }
      } else if (matchA && !matchB) {
        return -1;
      } else if (!matchA && matchB) {
        return 1;
      }

      const wordA = keyA.split("-")[1]?.trim();
      const wordB = keyB.split("-")[1]?.trim();

      if (wordA && wordB) {
        return wordA.localeCompare(wordB);
      } else if (wordA && !wordB) {
        return -1;
      } else if (!wordA && wordB) {
        return 1;
      }

      const numericA = parseInt(keyA);
      const numericB = parseInt(keyB);

      if (!isNaN(numericA) && !isNaN(numericB)) {
        return numericA - numericB;
      }

      return keyA.localeCompare(keyB);
    });
  };

  return (
    <Content style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          width: "96%",
        }}
      >
        {FilterNumber(users)?.map((user: User, index: number) => {
          const imageUrl = `${ADMIN_URL_IMAGE}/${user?.image?.id}`;

          const { id = "0", name = "" } = user;
          return (
            <DivHoverEquipe key={index} style={{}}>
              <IonCard
                className="hoverParaEquipe"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIdDelete(id);
                  handleshow(user);
                  setColaborador("colaborador");
                }}
              >
                <IonCardHeader>
                  <IonCardTitle>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {user.name}

                      <DivButton>
                        {!platform.includes("desktop") ? <div></div> : <></>}
                      </DivButton>
                    </div>
                  </IonCardTitle>
                  <IonCardSubtitle
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItens: "center",
                    }}
                  >
                    <Imagem style={{ backgroundColor: "#f7f3f3" }}>
                      {user?.image?.id === undefined ? (
                        <BsFillPersonFill
                          style={{
                            // backgroundColor: 'black',
                            height: "50px",
                            width: "50px",
                            border: "3px solid black",
                            borderRadius: "100%",
                            color: "black",
                          }}
                        />
                      ) : (
                        <IonAvatar>
                          <img alt="user image" src={imageUrl} />
                        </IonAvatar>
                      )}
                    </Imagem>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        flexDirection: "row",
                      }}
                    >
                      <HiOutlineMail
                        size={25}
                        color={theme.colors.textColor}
                        style={{
                          margin: platform.includes("desktop")
                            ? "5px 5px 0px 0px"
                            : "5px 5px 0px 14px",
                        }}
                      />

                      {user.email}
                    </div>
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </DivHoverEquipe>
          );
        })}
      </div>

      <Modal
        type={"colaborador"}
        image={image}
        setImage={setImage}
        user={user}
        handleEditUser={handleEdit}
        setShowConfirm={setShowConfirm}
        colaborador={colaborador}
        title={mode == "add" ? "Adicionar colaborador" : user.name}
        show={show}
        setShow={setShow}
        onSubmitHandler={onSubmitHandler}
        mode={mode}
        content={
          <AdicionarColaborador
            image={image}
            onSubmitHandler={onSubmitHandler}
            // handleClose={handleClose}
            getData={getData}
            onChangeGetImage={onChangeGetImage}
            user={user}
            setUser={setUser}
            mode={mode}
          />
        }
      />

      <ModalConfirm
        title={"Tem certeza que deseja deletar este colaborador?"}
        show={showConfirm}
        onConfirm={onConfirm}
        setShow={setShowConfirm}
        colorButton={"danger"}
      />
    </Content>
  );
};

export default React.memo(MinhaEquipe);

const Imagem = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
`;
