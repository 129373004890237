import { CLIENT_URL, URL_OS, USER_URL, COMPANY_URL } from "../../constains";
import axios from "axios";
import { Client, Os, User } from "../../interfaces";
import { CallNotification } from "../../components/Alert";
import { Dispatch, SetStateAction } from "react";
import { Token } from "../../helpers/token";

const token = window.localStorage.getItem("accessToken");

const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

interface CreateProps {
  Os: Os;
  access: any;
  url: any;
  sucessCallback: any;
  failureCallback: any;
}

export const CreateOs = (
  Os: Os,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .post(URL_OS, Os, access)
    .then((res) => {
      // console.log(res.data)
      CallNotification("Sucesso", "Os cadastrada", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const DeleteOs = (
  id?: string,
  sucessCallback: () => void = () => {},
  failureCallback: () => void = () => {}
) => {
  return axios
    .delete(`${URL_OS}/${id}`, access)
    .then((res) => {
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};

// enum RequestType {
//   "client",
//   "user",
//   "os",
// }
// const types: RequestType = {
//   os: URL_OS,
// };

// export const Delete = (
//   id?: string,
//   type?: RequestType,
//   sucessCallback: () => void = () => {},
//   failureCallback: () => void = () => {}
// ) => {
//   if (!type) return;
//   return axios
//     .delete(`${types[type]}/${id}`, access)
//     .then((res) => {
//       sucessCallback();
//     })
//     .catch((error) => {
//       console.error(error);
//       CallNotification("Error", "Falha ao deletar usuario", "danger");
//       failureCallback();
//     });
// };

export const UpdateOs = (
  Os: Os,
  sucessCallback: (param?: any) => any = () => {},
  failureCallback: (param?: any) => any = () => {}
) => {
  return axios
    .put(`${URL_OS}/${Os?.id}`, Os, access)
    .then((res) => {
      sucessCallback(res);
      return res;
    })
    .catch((error) => {
      console.error(error);

      failureCallback(error);
      return undefined;
    });
};

export const GetOss = (
  setState: Dispatch<SetStateAction<any>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  const { companyId } = Token.getTokenData();

  return axios
    .get(`${COMPANY_URL}/${companyId}`, access)
    .then((res) => {
      setState(res.data.data.os);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

export const GetOs = (
  setState: Dispatch<SetStateAction<any>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  const { id } = Token.getTokenData();
  return axios
    .get(`${USER_URL}/${id}`, access)
    .then((res) => {
      setState(res.data.data);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};
export const GetUser = (
  id: any,
  setState: Dispatch<SetStateAction<User[]>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .get(`${USER_URL}/${id}`, access)
    .then((res) => {
      setState(res.data.user);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};
