import React, { useEffect, useState, CSSProperties } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Card from "../../components/card/card";
import styled from "styled-components";
import { COMPANY_URL, USER_BY_COMPANY, ViewScroll } from "../../constains";
import { apiGet } from "../../helpers/api";
import { PieOpenCloseOs } from "./PieOpenCloseOs";
import { Company } from "../../interfaces";
import { Token } from "../../helpers/token";
import { OssDatails, TopUsersOs, LatestOs } from "./ChartsComponents";

import { Spin } from "react-cssfx-loading"
import useSWR, { useSWRConfig } from "swr";

function DashboardOs() {
    //@ts-ignore
    const { clientUser, companyId, id } = Token.getTokenData();
    const access = Token.getAccess();


    const url = `${COMPANY_URL}/${companyId}`;
    const fetcher = (url: string) => fetch(url, access).then((res) => res.json());

    const { data, isLoading: loading } = useSWR(url, fetcher);
    // const { mutate } = useSWRConfig();


    // console.log(data, loading)




    const url2 = `${USER_BY_COMPANY}/${companyId}`;

    const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
    let theUser: any = []
    if (loading2 == false) {
        theUser = allUsers?.data?.filter((value: any) => {
            return value.id == id
        })
    }

    const finalArrayClients = theUser?.[0]?.clients?.map((value: any) => { return value.id })

    // console.log(allUsers, loading2)


    // console.log(data.data)

    const oss = data?.data?.os?.filter((value: any) => {

        if (clientUser == true && finalArrayClients.includes(value.clientId)) { return value }
        if (clientUser == false) { return value }


    }) || [];

    if (loading) {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh'
        }}>
            <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
            Aguarde...

        </div>
    }





    return (
        <>
            <Container fluid
            // style={{ paddingLeft: '30px' }}
            >
                <ViewScroll style={{
                    marginTop: '20px',
                    // paddingLeft: '20px'
                }}>
                    <Row >
                        <Col md={6}

                        >
                            <Card
                                // style={{ margin: '40px' }}
                                title="Status os"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <PieOpenCloseOs oss={oss} />
                                    </Content>
                                }
                            />
                        </Col>
                        <Col md={6}

                        >
                            <Card
                                title="Detalhes OS"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <OssDatails oss={oss} />
                                    </Content>
                                }
                            />
                        </Col>
                    </Row>

                    <Row >
                        <Col md={6}

                        >
                            <Card
                                title="OS usuarios"
                                category="quantidade de os por usuário"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <TopUsersOs oss={oss} />
                                    </Content>
                                }
                            />
                        </Col>
                        <Col md={6}

                        >
                            <Card
                                title="OS abertas"
                                category="ultimas os abertas "
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Content>
                                        <LatestOs oss={oss} />
                                    </Content>
                                }
                            />
                        </Col>
                    </Row>
                </ViewScroll>
            </Container>
        </>
    );
}

const Content = styled(ViewScroll)`
  /* max-height: 40vh; */
  height: 40vh;
  overflow: auto;
`;

export default DashboardOs;
