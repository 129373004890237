import { CLIENT_URL, COMPANY_URL, EQUIPMENT, INSPECTION, INSPECTION_MODEL } from "../../constains";
import axios from "axios";
import { Client } from "../../interfaces";
import { CallNotification } from "../../components/Alert";
import { Dispatch, SetStateAction } from "react";
import { Token } from "../../helpers/token";
// import { alert } from "ionicons/icons";

const token = window.localStorage.getItem("accessToken");
const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

// interface CreateProps {
//   client: Client;
//   access: any;
//   url: any;
//   sucessCallback: any;
//   failureCallback: any;
// }

export const CreateClient = (
  client: Client,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {
  return axios
    .post(`${CLIENT_URL}/`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "Cliente cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const DeleteClient = (
  id?: string,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .delete(`${CLIENT_URL}/${id}`, access)
    .then((res) => {
      CallNotification("Sucesso", "Item deletado!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};
export const DeleteModel = (
  id?: string,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .delete(`${INSPECTION_MODEL}/${id}`, access)
    .then((res) => {
      sucessCallback();
      CallNotification("Sucesso", "Modelo deletado!", "success");
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar Modelo", "danger");
      failureCallback();
    });
};


enum RequestType {
  "client",
  "user",
  "os",
  "equipment",
  "inspection"
}
const types: any = {
  client: CLIENT_URL,
  equipment: EQUIPMENT,
  inspection: INSPECTION
};

export const Delete = (
  id?: string,
  type?: RequestType,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  if (!type) return;
  return axios
    .delete(`${types[type]}/${id}`, access)
    .then((res) => {
      // console.log(res);
      CallNotification("Sucesso", "Item deletado", "success");
      sucessCallback();
    })
    .catch((error: any) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar Item no servidor!", "danger");
      failureCallback();
    });
};

export const UpdateClient = (
  client: Client,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {
  return axios
    .put(`${CLIENT_URL}/${client?.id}`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "usuario cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const GetClient = (
  setState: Dispatch<SetStateAction<Client[]>>,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {
  const { companyId } = Token.getTokenData();
  return axios
    .get(`${COMPANY_URL}/${companyId}`, access)
    .then((res) => {
      setState(res.data.data.clients);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

export const GetEquipamentOnlyAll = (
  setState: Dispatch<SetStateAction<Client>>,
  id?: any,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${EQUIPMENT}?clientId=${id}`, access)
    .then((res) => {
      console.log(res.data)
      setState(res.data.data);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};
export const GetEquipamentOnly = (
  setState: Dispatch<SetStateAction<any>>,
  setState2: Dispatch<SetStateAction<any>>,
  id?: any,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${EQUIPMENT}/${id}`, access)
    .then((res) => {
      // console.log("rodou", res.data.data)
      
      setState(res.data.data);
      setState2(res.data.data.InspectionmodelId);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};


export const GetModel = (
  branchId: any,
  setState: Dispatch<SetStateAction<Client>>,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${INSPECTION_MODEL}?companyId=${branchId}`, access)
    .then((res) => {
      // console.log(res.data)
      setState(res.data.data);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};

export const CreateModelo = (
  jsonSchema: any,
  uiSchema: any,
  title: string,
  companyId: string,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {


  try {
    return axios
      .post(`${INSPECTION_MODEL}`, {
        jsonSchema: JSON.parse(jsonSchema),
        uiSchema: JSON.parse(uiSchema),
        title: title,
        companyId: companyId,
      }, access)
      .then((res) => {
        sucessCallback();
        CallNotification("Sucesso", "Modelo cadastrado", "success");

      })
      .catch((error) => {
        console.error(error);
        CallNotification("Error", "Falha ao cadastrar modelo", "danger");
        failureCallback();
      });
  } catch (e) {
    console.error(e)
    CallNotification("Error", "Texto inválido", "danger");
  }

};

export const CreateEquipment = (
  equipment: any,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {


  try {
    return axios
      .post(`${EQUIPMENT}`, equipment, access)
      .then((res) => {
        sucessCallback();
        CallNotification("Sucesso", "Equipamento cadastrado", "success");
      

      })
      .catch((error) => {
        console.error(error);
        CallNotification("Error", "Falha ao cadastrar Equipamento", "danger");
        failureCallback();
        
      });
  } catch (e) {
    console.error(e)
    CallNotification("Error", "Texto inválido", "danger");
  }

};


export const EditEquipment = (
  id: any,
  equipment: any,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {


  try {
    return axios
      .put(`${EQUIPMENT}/${id}`, equipment, access)
      .then((res) => {
        sucessCallback();
        CallNotification("Sucesso", "Equipamento editado", "success");

      })
      .catch((error) => {
        console.error(error);
        CallNotification("Error", "Falha ao editar Equipamento", "danger");
        failureCallback();
      });
  } catch (e) {
    console.error(e)
    CallNotification("Error", "Texto inválido", "danger");
  }

};


export const CreateInspection = (
  // id: any,
  modelo: any,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { },
) => {


  try {
    return axios
      .post(`${INSPECTION}`, modelo, access)
      .then((res) => {
        // console.log(res)
        CallNotification("Sucesso", "Inspeção criada com sucesso", "success");
        sucessCallback();
     
      })
      .catch((error) => {
        console.error(error);
        failureCallback();
      });
  } catch (e) {
    console.error(e)
    CallNotification("Error", "Texto inválido", "danger");
  }

};

export const GetInspection = (
  id: any,
  setState: Dispatch<SetStateAction<any>>,
  // setState2: Dispatch<SetStateAction<any>>,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${INSPECTION}/${id}`, access)
    .then((res) => {

      // console.log(res.data.data)
      setState(res.data.data);
      // setState2(res.data.data.formData);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};
export const GetInspectionImages = (
  id: any,
  setState: Dispatch<SetStateAction<any>>,
  // setState2: Dispatch<SetStateAction<any>>,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${INSPECTION}/images/${id}`, access)
    .then((res) => {

      // console.log(res.data.data)
      setState(res.data.data.images64);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};
export const GetImages = (
  
  setState: Dispatch<SetStateAction<any>>,
  // setState2: Dispatch<SetStateAction<any>>,
  sucessCallback: () => void = () => { },
  failureCallback: () => void = () => { }
) => {
  return axios
    .get(`${INSPECTION}/images`, access)
    .then((res) => {

      // console.log(res)
      setState(res);
      // CallNotification("Sucesso", "Cliente obtido!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      // CallNotification("Error", "Falha ao obter cliente", "info");
      failureCallback();
    });
};

export const UpdateInspection = (
  id: any,
  inspection: any,
  sucessCallback: () => any = () => { },
  failureCallback: () => any = () => { }
) => {
  return axios
    .put(`${INSPECTION}/${id}`, inspection, access)
    .then((res) => {
      CallNotification("Sucesso", "Inspeção editada com sucesso", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao editar inspeção", "danger");
      failureCallback();
    });
};
