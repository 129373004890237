
export const FilterNumber = (array: unknown[]) => {
  // @ts-ignore
  return array?.sort((a: any, b: any) => {
    const keyA = a?.name?.toLowerCase();
    const keyB = b?.name?.toLowerCase();

    const regex = /^(\d+)\s*-/;
    const matchA = keyA?.match(regex);
    const matchB = keyB?.match(regex);

    if (matchA && matchB) {
      const numericValueA = parseInt(matchA[1]);
      const numericValueB = parseInt(matchB[1]);

      if (numericValueA < numericValueB) {
        return -1;
      } else if (numericValueA > numericValueB) {
        return 1;
      }
    } else if (matchA && !matchB) {
      return -1;
    } else if (!matchA && matchB) {
      return 1;
    }

    const wordA = keyA?.split("-")[1]?.trim();
    const wordB = keyB?.split("-")[1]?.trim();

    if (wordA && wordB) {
      return wordA.localeCompare(wordB);
    } else if (wordA && !wordB) {
      return -1;
    } else if (!wordA && wordB) {
      return 1;
    }

    const numericA = parseInt(keyA);
    const numericB = parseInt(keyB);

    if (!isNaN(numericA) && !isNaN(numericB)) {
      return numericA - numericB;
    }

    return keyA?.localeCompare(keyB);
  });
};





export const alfaFilter = (array: any, chave1: any, chave2?: any) => {

    const  alfanumSort = (a: any, b: any) => {
        const partsA = chave2 ? a?.[chave1]?.[chave2]?.split(/(\d+)/) : a?.[chave1]?.split(/(\d+)/)
        const partsB = chave2 ? b?.[chave1]?.[chave2]?.split(/(\d+)/) : b?.[chave1]?.split(/(\d+)/)

        // const partsAa = a?.[chave1].split(/(\d+)/);
        // const partsBb = b?.[chave1].split(/(\d+)/);


        for (let i = 0; i < partsA?.length; i++) {
          if (partsA[i] !== partsB[i]) {
            if (isNaN(partsA[i])) {
              return partsA[i]?.localeCompare(partsB[i]);
            } else {
              return parseInt(partsA[i]) - parseInt(partsB[i]);
            }
          }
        }
        return 0;
    }
    return array?.sort(alfanumSort) ?? array
}

