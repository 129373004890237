import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../router/ProvideAuth";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Token } from "../../helpers/token";
import { storageLocal } from "../../storage/ionic-storage-config";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Center,
} from "@mantine/core";
import svgLogo from "../../../resources/create-service.svg";
import Logo from "./Logo";

export default function Login() {
  const [onlyPassword, setOnlyPassword] = useState("");
  const [onlyLogin, setOnlyLogin] = useState("");
  let history = useHistory();
  const navigation = useIonRouter();
  const token = Token.getToken() || null;
  const auth: any = useAuth() || {};

  if (token) history.push("/dashboard");

  const confirmLogin = (event: any) => {
    event.preventDefault();
    const data = {
      email: onlyLogin.toLowerCase(),
      password: onlyPassword,
    };
    // @ts-ignore
    auth.signin(() => {
      // navigation.push("/dashboard", "forward", "push");
      history.push("/dashboard");
      // history.push("http://wifibr.com.br/api/dashboard");
      window.location.reload(); // reload because menu ir collapsed
    }, data);
  };

  return (
    <IonPage>
      <IonContent>
        <Container size={420} my={40} style={{ paddingTop: "5rem" }}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <Title ta="center">
              <Logo />
            </Title>
            <Title ta="center" color="#36abe0">
              <b style={{ color: "#36abe0" }}>T</b>ec{" "}
              <b style={{ color: "#36abe0" }}>O</b>s
            </Title>
            <br />

            <TextInput
              label="Email"
              placeholder="Email"
              required
              value={onlyLogin}
              onChange={(ev) => {
                ev.persist();
                setOnlyLogin(ev.target.value);
              }}
            />
            <PasswordInput
              label="Senha"
              placeholder="Senha"
              required
              mt="md"
              value={onlyPassword}
              onChange={(ev) => {
                ev.persist();
                setOnlyPassword(ev.target.value);
              }}
            />
            <Group justify="space-between" mt="lg">
              <Checkbox label="Lembrar" />
              {/* <Anchor component="button" size="sm">
                Forgot password?
              </Anchor> */}
            </Group>
            <Button fullWidth mt="xl" bg="#36abe0" onClick={confirmLogin}>
              Login
            </Button>
          </Paper>
        </Container>
      </IonContent>
    </IonPage>
  );
}
