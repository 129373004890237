import React, { useEffect } from "react";
// import ReactDOM from "react-dom/client";
//@ts-ignore
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { ProvideAuth } from "./router/ProvideAuth";
import { setupIonicReact } from "@ionic/react";
import { CallNotification } from "./components/Alert";
import { Provider } from "react-redux";
import store from "./store";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
setupIonicReact();
defineCustomElements(window);

// { hardwareBackButton: false }
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ProvideAuth>
      <App />
    </ProvideAuth>
  </Provider>
);

// ReactDOM.render(
//   <ProvideAuth>
//     <App />
//   </ProvideAuth>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
serviceWorker.register();
