import { IonTextarea } from "@ionic/react";
import React, { memo, useState } from "react";
import { Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import styled from "styled-components";
import { IMAGE_URL, URL_OS } from "../../../constains";
import { apiGet, apiPut, apiPost, apiDelete } from "../../../helpers/api";
import { getBase64FromImage } from "../../../helpers/utils";
import { Mode, Os } from "../../../interfaces";
import { MultipleImages } from "./MultipleImages";

const MAX_COUNT = 5;

interface Props {
  OS: Os;
  setOS: (os: any) => void;
  mode: Mode["mode"];
}

const Images = ({ OS, setOS, mode }: Props) => {
  const [onLoadImage, setOnloadImage] = React.useState<boolean>(false);
  const [image64, setImage] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");

  const { images = [] } = OS;

  const onLoad = (fileString: any) => {
    // console.log("onLoad image", fileString);
    setOnloadImage(true);
    setImage(fileString);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(e);

    const data = {
      image64: image64,
      osId: OS?.id,
      imageType: "os",
      comment: comment,
    };
    apiPost(IMAGE_URL, data, getOS);

    setOnloadImage(false);
  };

  React.useEffect(() => {
    if (OS?.id) getOS();
  }, []);

  const getOS = () => {
    apiGet(`${URL_OS}/${OS.id}`, setOS);
  };

  const onFileChange = (e) => {
    const file = e?.target?.files?.[0];
    getBase64FromImage(file, onLoad);
  };

  const removeImage = (id: string): void => {
    apiDelete(IMAGE_URL, id, getOS);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setOnloadImage(false);
    setImage("");
  };

  return (
    <Container>
      {mode == "edit" && !onLoadImage ? (
        <>
          <div className="files-wr" data-count-files="1">
            <div className="one-file">
              <label htmlFor="file-1">Adicionar imagem com comentário</label>
              <input
                id="file-1"
                type="file"
                accept="image/*"
                // {TODO: implement mult image}
                // multiple
                onChange={(event) => {
                  onFileChange(event);
                }}
                // disabled={fileLimit}
              />
            </div>
          </div>
        </>
      ) : null}
      {mode == "create" ? <h3>Crie a os antes de anexar as imagens</h3> : null}

      {onLoadImage ? (
        <OnLoadContainer>
          <img src={image64} alt="" height="100px" />
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="newImage">
              <IonTextarea
                value={comment}
                onIonChange={(e) => setComment(e?.detail?.value!)}
                placeholder="Adicione um comentário na imagem (opcional)"
              ></IonTextarea>
            </Form.Group>
            <Button type="submit">Salvar</Button>
            <Button onClick={onCancel} type="button" variant="secondary">
              Cancelar
            </Button>
          </Form>
        </OnLoadContainer>
      ) : null}

      {mode != "create" && mode != "view" && (
        <MultipleImages OS={OS} getOS={getOS} />
      )}

      <div className="uploaded-files-list">
        {images.map(({ image64, id, comment }: any, index) => (
          <ImageContainer key={index}>
            {mode == "view" ? null : (
              <>
                <button onClick={() => removeImage(id)}>
                  <span>&times;</span>
                </button>
              </>
            )}

            <img src={image64} alt="" />
            <IonTextarea value={comment} readonly={true}></IonTextarea>
            <hr color="gray" />
          </ImageContainer>
        ))}
      </div>
    </Container>
  );
};

const OnLoadContainer = styled.div`
  border: 1px solid red;
  border-radius: 5px;
  margin: 5px 6px;
  padding: 5px;

  display: flex;
  flex-direction: row;

  textarea {
    width: 100%;
  }
  form {
    width: 100%;
  }
  button {
    margin-right: 15px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  /* height: 300px; */
  /* width: 300px; */

  img {
    /* height: 100%; */
    /* width: 100%; */
  }

  button {
    position: absolute;
    right: 0px;
    width: 50px;
    height: 50px;
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      width: 45px;
      height: 45px;
    }
  }
`;

const Container = styled.div`
  input {
    display: none;
  }
  .files-wr {
    padding: 20px;
    label {
      margin-bottom: 20px;
      border-bottom: 1px dashed #177cca;
      position: relative;
      display: inline-block;
      color: #177cca;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.2s;
      &:after {
        content: "+";
        width: 32px;
        height: 32px;
        border-radius: 5px;
        background-color: #177cca;
        position: absolute;
        top: -4px;
        right: -47px;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        transition: all 0.2s;
      }
      &:hover,
      &:active {
        color: #77c639;
        border-color: #77c639;
        &:after {
          background-color: #77c639;
        }
      }
    }

    .one-file {
      & ~ .one-file {
        label {
          display: none;
        }
      }
      &.error {
        border: none;
        .file-name {
          color: #ca4a17;
        }
      }
    }

    .file-item {
      position: relative;
      margin-top: 4px;
      display: flex;
      align-items: center;
      &.hide-btn {
        display: none;
      }
    }

    .file-name {
      font-size: 16px;
      font-style: italic;
      line-height: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
    .btn-del-file {
      margin-left: 5px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 3px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s;
      background-color: #177cca;
      &:hover,
      &:focus {
        background-color: #ca4a17;
      }
    }
  }
`;

export { Images };
