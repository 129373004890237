import React, { useEffect, useRef } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { useTheme } from "styled-components";
import { parseJwt } from "../../helpers/utils";
import { Token } from "../../helpers/token";
interface ModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  title?: string;
  content: any;
  onSubmitHandler?: (value: any) => void;
  size?: "sm" | "lg" | "xl";
  mode?: "view" | "edit" | 'add';
  idCliente?: string;
  setShowConfirm?: (value: boolean) => void;
  client?: any;
  handleEdit?: any;
  handleEditUser?: any;
  setIdCliente?: (value: string) => void;
  colaborador?: string;
  user?: any;
  setImage?: any;
  image?: any;
  type?: string;
}

const Modal = ({
  show = false,
  setShow,
  title,
  content = "",
  onSubmitHandler,
  size = "lg",
  mode = "edit",
  idCliente,
  setShowConfirm,
  client,
  handleEdit,
  setIdCliente,
  colaborador,
  user,
  handleEditUser,
  setImage,
  image,
  type

}: ModalProps) => {
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  const deletarCliente = decoded?.payload.deletarCliente;
  const editarCliente = decoded?.payload.editarCliente;
  const deletarColaborador = decoded?.payload.deletarColaborador;
  const editarColaborador = decoded?.payload.editarColaborador;
  const handleClose = () => setShow(false);
  const theme = useTheme();
  const modalRefImage: any = useRef(null);
  const {  clientUser } = Token.getTokenData();


  useEffect(() => {
    if (user?.image?.id) {
      setImage(undefined)
    }
    return () => {
      if (image !== undefined) {
        setImage(undefined)
      }
    };

  }, [modalRefImage?.current?.dialog]);



  return (
    <>
      <BootstrapModal show={show} ref={modalRefImage} onHide={handleClose} size={size}>
        <BootstrapModal.Header
          closeButton
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textColor,
          }}
        >
          <BootstrapModal.Title style={{


          }}>

         {type == 'colaborador' && mode == 'add' ? 'Adicionar colaborador' : ''}
         {type == 'empresa' && mode == 'add' ? 'Adicionar empresa' : ''}
         {type == 'cliente_user' && mode == 'add' ? 'Adicionar usuário de Cliente' : ''}
         {/* {type == 'cliente_user' && mode == 'add' ? 'Adicionar Cliente' : ''} */}
         {mode == 'view' ? `${title}` : ''}
         {mode == 'edit' ? `${title}` : ''}

          </BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textColor,
          }}
        >
          <div style={{
            marginRight: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>





            {colaborador == 'colaborador' ?

              (mode !== 'view' ? <></> :
                <>
                  {editarColaborador === true ?
                    <Button
                      style={{ marginRight: '8px' }}
                      variant="primary"
                      onClick={() => {
                        handleEditUser(user);
                      }}
                    >
                      <FaPencilAlt />
                    </Button>
                    :
                    <>
                    </>
                  }


                  {deletarColaborador === true ?
                    <Button
                      variant="danger"
                      onClick={() => {
                        // @ts-ignore
                        // setIdCliente(idCliente);
                        // @ts-ignore
                        setShowConfirm(true);
                        setShow(!show)
                      }}
                    >
                      <FaRegTrashAlt />
                    </Button>
                    :
                    <>
                    </>}


                </>) :

              (mode !== 'edit' ? <>      {editarCliente === true ? (
                <Button
                  style={{ marginRight: '8px' }}
                  variant="secondary"
                  onClick={() => {
                    handleEdit(client);
                  }}
                >
                  <FaPencilAlt />
                </Button>
              ) : (
                ""
              )}


                {deletarCliente === true ? (
                  <Button
                    variant="danger"
                    onClick={() => {
                      // @ts-ignore
                      // setIdCliente(idCliente);
                      // @ts-ignore
                      setShowConfirm(true);
                      setShow(!show)
                    }}
                  >
                    <FaRegTrashAlt />
                  </Button>
                ) : (
                  ""
                )}</> : <></>)

            }






          </div>


          {content}
        </BootstrapModal.Body>

        <BootstrapModal.Footer
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textColor,
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {mode === "view" ? null : (clientUser && window.location?.pathname?.includes('/os') ? <></> :
            <>
              <Button variant="primary" onClick={onSubmitHandler}>
                Salvar
              </Button>
            </>
          )}
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};
export default React.memo(Modal);