import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  DivButton,
  DivGeral,
  DivPrimaria,
  DivSecundaria,
  SpanTexto,
} from "./style";
import "./styles.css";
import { Os } from "../../interfaces";


import RichText from "./RichText";
import { CallNotification } from "../Alert";
import axios from "axios";
import { URL_OS } from "../../constains";

interface Props {
  getData: (data: void) => void;
  handleClose: (data: void) => void;
}

function AdicionarOs({ handleClose, getData }: Props) {
  const [text, setText] = useState("");
  const [Os, setOs] = React.useState<Os>({
    title: "",
    supervisor: "",
    priority: 0,
    assignTo: "",
    text: "",
    client: "",
  });

  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    let _Os: any = Os;
    _Os.text = JSON.stringify(text).replaceAll('"', "'");

    axios
      .post(URL_OS, _Os, access)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          CallNotification("Sucesso", "usuario cadastrado", "success");
          getData();
          handleClose()
        }
      })
      .catch((err) => {
        console.log(err);
        CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      });
  };

  return (
    
        <DivGeral id="divGeral">
          <form onSubmit={onSubmitHandler}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <span>Título: </span>
              <input
                style={{ width: "100%", marginLeft: "10px" }}
                type="text"
                name="login"
                value={Os.title}
                onChange={(event) =>
                  setOs((prevState) => ({
                    ...prevState,
                    title: event?.target?.value,
                  }))
                }
                required
              />
            </div>

            <DivPrimaria>
              <DivSecundaria
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <SpanTexto style={{ marginRight: "12px" }}>Cliente:</SpanTexto>

                <select
                  style={{
                    border: "1px solid",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "16px",
                    width: "200px",
                  }}
                  value={Os.client}
                  onChange={(event: any) =>
                    setOs((prevState) => ({
                      ...prevState,
                      client: event?.target?.value,
                    }))
                  }
                >
                  <option value="Selecione">Selecione</option>
                  <option value="Primeiro Cliente">Primeiro Cliente</option>
                  <option value="Segundo Cliente">Segundo Cliente</option>
                  <option value="Terceiro Cliente">Terceiro Cliente</option>
                </select>

                <button
                  style={{
                    border: "1px solid",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "16px",
                    width: "30px",
                  }}
                >
                  +
                </button>
              </DivSecundaria>

              <DivSecundaria
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                }}
              >
                <SpanTexto>Prioridade:</SpanTexto>
                <Button
                  onClick={() =>
                    setOs((prevState) => ({
                      ...prevState,
                      priority: 0,
                    }))
                  }
                  variant="primary"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                  }}
                >
                  Baixa
                </Button>
                <Button
                  onClick={() =>
                    setOs((prevState) => ({
                      ...prevState,
                      priority: 1,
                    }))
                  }
                  variant="warning"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                  }}
                >
                  Média
                </Button>
                <Button
                  onClick={() =>
                    setOs((prevState) => ({
                      ...prevState,
                      priority: 2,
                    }))
                  }
                  variant="danger"
                  style={{
                    maxHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                  }}
                >
                  Alta
                </Button>
              </DivSecundaria>
            </DivPrimaria>
            <DivPrimaria>
              <SpanTexto style={{ marginLeft: "3px" }}>Supervisor:</SpanTexto>

              <select
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  width: "200px",
                }}
                value={Os.supervisor}
                onChange={(event: any) =>
                  setOs((prevState) => ({
                    ...prevState,
                    supervisor: event?.target?.value,
                  }))
                }
              >
                <option value="Selecione">Selecione</option>
                <option value="Primeiro supervisor">Primeiro supervisor</option>
                <option value="Segundo supervisor">Segundo supervisor</option>
                <option value="Terceiro supervisor">Terceiro supervisor</option>
              </select>
              <SpanTexto style={{ marginLeft: "3px" }}>
                Atribuído para:
              </SpanTexto>
              <select
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  width: "200px",
                }}
                value={Os.assignTo}
                onChange={(event: any) =>
                  setOs((prevState) => ({
                    ...prevState,
                    assignTo: event?.target?.value,
                  }))
                }
              >
                <option value="Selecione">Selecione</option>
                <option value="Primeiro tecnico">Primeiro técnico</option>
                <option value="Segundo tecnico">Segundo técnico</option>
                <option value="Terceiro tecnico">Terceiro técnico</option>
              </select>
            </DivPrimaria>

            <RichText setText={setText} />

            <DivButton>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  handleClose()
                }}
              >
                Cancelar
              </button>


              <button
                className="btn btn-primary"
                type="submit"
             
              >
                Confirmar
              </button>
            </DivButton>
          </form>
        </DivGeral>
      
  
  );
}

export default AdicionarOs;
