import React, { useState, memo } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "../../components/card/card";
import MinhaEquipe from "./MinhaEquipe";
import { getPlatforms } from "@ionic/react";
import { USER_URL, USER_BY_COMPANY, defaultUser, COMPANY_URL } from "../../constains";
import { add } from "ionicons/icons";
import styled from "styled-components";
import axios from "axios";

import {
  IonAvatar,
  IonCard,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlinePlus } from "react-icons/ai";
import { parseJwt } from "../../helpers/utils";
import { apiGet, apiDelete } from "../../helpers/api";
import { Token } from "../../helpers/token";
import { User } from "../../interfaces";
import { Spin } from "react-cssfx-loading"
import useSWR, { useSWRConfig } from "swr";

function Equipe() {
  const [colaborador, setColaborador] = useState<string>('');
  const { companyId } = Token.getTokenData();
  const [search, setSearch] = useState<string>("");
  const [mode, setMode] = useState<"edit" | "view" | "add">("add");
  const [show, setShow] = useState(false);

  const [user, setUser] = React.useState<User>({
    ...defaultUser,
    companyId,
  });

  const access = Token.getAccess

  const tokenLocal = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(tokenLocal);
  const criarColaborador = decoded?.payload.criarColaborador;
  const visualizarColaborador = decoded?.payload.visualizarColaborador;



  const url = `${USER_BY_COMPANY}/${companyId}`;
  const url2 = `${COMPANY_URL}/${companyId}`;
  const fetcher = (url: string) => fetch(url, access()).then((res) => res.json());

  const { data, isLoading: loading } = useSWR(url, fetcher);
  const { data: data2, isLoading: loading2 } = useSWR(url2, fetcher);
  const { mutate } = useSWRConfig();
  const run = React.useCallback(() => mutate(url), [mutate]);
  const run2 = React.useCallback(() => mutate(url2), [mutate]);


//  console.log(data)



  const handleShow = () => {
    setUser({ ...defaultUser, companyId });
    setShow(true);
  };

  React.useEffect(() => {
    run();
  }, []);



  const filteredUsers = data?.data?.filter((os: any) => {

    if (os.clientUser !== false) {
      return os.name?.toLowerCase()?.includes(search?.toLowerCase())
    }
  }
  );




  // console.log(data)



  return (
    <>{visualizarColaborador === true ?


      <Container fluid>






        <div style={{
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          // padding: '10px',
          borderRadius: '10px',
          zIndex: '9999',



        }}>

          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} >

            {

              criarColaborador === true ?




                <Button

                  style={{ width: '70%', height: '50px' }}
                  variant="primary"
                  onClick={() => { handleShow(); setMode('add'); setColaborador('colaborador') }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',

                    }}>


                    <AiOutlinePlus
                      style={{ margin: '0px 4px 2px 0px' }}
                    />
                    <span style={{ fontSize: '14px' }}>
                      Adicionar usuário de cliente
                    </span>


                  </div>



                </Button> : 'Este usuário não tem permissão para criar colaborador'}


          </div>

          <IonSearchbar
            value={search}
            onIonChange={(value: any) => setSearch(value?.detail?.value)}
          />

        </div>



        {loading == true ? < div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh'
        }}>
          <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
          Aguarde...

        </div> :
          <MinhaEquipe
            colaborador={colaborador}
            setColaborador={setColaborador}
            users={filteredUsers}
            getData={run}
            show={show}
            setShow={setShow}
            user={user}
            setUser={setUser}
            mode={mode}
            setMode={setMode}
            empresas={data2?.data?.clients}
          />
        }


        {/* <MinhaEquipe
          colaborador={colaborador}
          setColaborador={setColaborador}
          users={filteredUsers}
          getData={getData}
          show={show}
          setShow={setShow}
          user={user}
          setUser={setUser}
          mode={mode}
          setMode={setMode}
        /> */}


      </Container >




      : <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

      }}>Este usuário não tem permissão para visualizar Equipe</div>
    }
    </>
  );
};

export default memo(Equipe)