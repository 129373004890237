import { CLIENT_URL, COMPANY_URL } from "../../constains";
import axios from "axios";
import { Client } from "../../interfaces";
import { CallNotification } from "../../components/Alert";
import { Dispatch, SetStateAction } from "react";
import { Token } from "../../helpers/token";

const token = window.localStorage.getItem("accessToken");
const access = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

// interface CreateProps {
//   client: Client;
//   access: any;
//   url: any;
//   sucessCallback: any;
//   failureCallback: any;
// }

export const CreateClient = (
  client: Client,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  return axios
    .post(`${CLIENT_URL}`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "Cliente cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const DeleteClient = (
  id?: string,
  sucessCallback: () => void = () => {},
  failureCallback: () => void = () => {}
) => {
  return axios
    .delete(`${CLIENT_URL}/${id}`, access)
    .then((res) => {
      CallNotification("Sucesso", "Item deletado!", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};

enum RequestType {
  "client",
  "user",
  "os",
}
const types: any = {
  client: CLIENT_URL,
};

export const Delete = (
  id?: string,
  type?: RequestType,
  sucessCallback: () => void = () => {},
  failureCallback: () => void = () => {}
) => {
  if (!type) return;
  return axios
    .delete(`${types[type]}/${id}`, access)
    .then((res) => {
      console.log(res);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao deletar usuario", "danger");
      failureCallback();
    });
};

export const UpdateClient = (
  client: Client,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {

  delete client.equipment
  return axios
    .put(`${CLIENT_URL}/${client?.id}`, client, access)
    .then((res) => {
      CallNotification("Sucesso", "usuario cadastrado", "success");
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      CallNotification("Error", "Falha ao cadastrar usuário", "danger");
      failureCallback();
    });
};

export const GetClient = (
  setState: Dispatch<SetStateAction<Client[]>>,
  sucessCallback: () => any = () => {},
  failureCallback: () => any = () => {}
) => {
  const { companyId} = Token.getTokenData();
  return axios
    .get(`${COMPANY_URL}/${companyId}`, access)
    .then((res) => {
      setState(res.data.data.clients);
      sucessCallback();
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};
