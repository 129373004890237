import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, Table } from "react-bootstrap";
import styled, { useTheme } from "styled-components";
import { INSPECTION_COMPANY, USER_BY_COMPANY, ViewScroll } from "../../../constains";
import axios from "axios";
import _ from 'lodash';
import { Token } from "../../../helpers/token";
import { GetInspectionsCompany } from "../api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";







function DashboardInspectionRecent({ search, timeCollect }: any) {
    const { companyId, id, clientUser } = Token.getTokenData();
    const theme = useTheme();
    const history = useHistory();

    const url = `${INSPECTION_COMPANY}/${companyId}?span=last${timeCollect.value}days`;

    const fetcher = (url: string) => fetch(url).then((res) => res.json());
    const { data, isLoading: loading } = useSWR(url, fetcher);
    const { mutate } = useSWRConfig();
    const run = React.useCallback(() => mutate(url), [mutate]);

    const tratarFiltro = search.map((valor: any) => {
        return valor.label
    })

    // @ts-ignore
    function verificarStringNoArray(array, string) {
        // Utilize o método includes() para verificar se a string está presente no array
        return array.includes(string);
    }

    useEffect(() => {
        run();
       }, []);

       const url2 = `${USER_BY_COMPANY}/${companyId}`;

       const { data: allUsers, isLoading: loading2 } = useSWR(url2, fetcher);
       let theUser: any = []
       if (loading2 == false) {
           theUser = allUsers?.data?.filter((value: any) => {
               return value.id == id
           })
       }
       const finalArrayClients = theUser?.[0]?.clients?.map((value: any) => { return value.id })


    //    ?.filter((value: any) => {

    //     if ( clientUser == true && finalArrayClients?.includes(value.id)) { return value } 
    //     if ( clientUser == false ) { return value } 


    //   })


   
       

    return (
        <>
            <Container fluid
            // style={{ paddingLeft: '30px' }}
            >
                <ViewScroll style={{
                    marginTop: '20px',
                    // paddingLeft: '20px'
                }}>

                    <div style={{ maxWidth: '100vw', overflowX: 'auto' }}>

                        <Table striped bordered hover variant={theme.themeName}>
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Equipamento</th>
                                    <th>Data</th>
                                    <th>Nome</th>
                                </tr>
                            </thead>




                            <tbody>

                                {data?.data?.filter((value: any) => {


                                    if (search.length == 0) {
                                        return value
                                    }


                                    if (verificarStringNoArray(tratarFiltro, value?.equipment?.client?.name)) {
                                        return value
                                    }


                                })     ?.filter((value: any) => {

                                    if ( clientUser == true && finalArrayClients?.includes(value.equipment.client.id)) { return value } 
                                    if ( clientUser == false ) { return value } 
                            
                            
                                  })
                                    ?.map((data: any, index: any) => {

                                        // console.log(data.equipment.client.id)

                                        const dataHoraBrasil = moment(data.createAt).subtract(0, 'hours');
                                        const formatoDataHora = 'DD-MM-YYYY HH:mm';

                                        // console.log(data)

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#0d6efd',
                                                            // textDecoration: 'underline'
                                                        }}
                                                        onClick={() => {
                                                            history.push(`inspection/${data?.equipment?.clientId}?name=${data?.equipment?.client?.name}&clientid=${data?.equipment?.clientId}`);
                                                             // inspection/${data?.equipment?.clientId}?name=${data?.equipment?.client?.name}&clientid=${data?.equipment?.clientId}

                                                            // setCliente(data?.equipment?.client?.name)
                                                            window.localStorage.setItem('clienteSelecionado', data?.equipment?.client?.name)

                                                        }}
                                                    >

                                                        {data?.equipment?.client?.name || '--'}
                                                    </span>
                                                </td>
                                                <td>

                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#0d6efd',
                                                            // textDecoration: 'underline'
                                                        }}
                                                        onClick={() => {
                                                            history.push(`/inspection/${data?.equipment?.clientId}/${data?.equipment?.id}?name=${data?.equipment?.client?.name}&equiname=${data?.equipment?.title}&equiid=${data?.equipment?.id}&clientid=${data?.equipment?.clientId}`);




                                                            window.localStorage.setItem("IdClient", data.equipment.clientId);



                                                        
                                                            window.localStorage.setItem('clienteSelecionado', data?.equipment?.client?.name)

                                                          
                                                            window.localStorage.setItem('Equipamento', data.equipment.title)

                                                        }}
                                                    >
                                                        {data.equipment.title}
                                                    </span>
                                                </td>
                                                <td>
                                                    {dataHoraBrasil.format(formatoDataHora)}
                                                </td>

                                                <td >
                                                    <span
                                                        onClick={() => { history.push(`/onlyInspection/${data.id}?voltar=/inspection/${data?.equipment?.clientId}/${data?.equipment?.id}?&name=${data?.equipment?.client?.name}&equiname=${data?.equipment?.title}&equiid=${data?.equipment?.id}&clientid=${data?.equipment?.clientId}`) }}
                                                         style={{
                                                            cursor: 'pointer',
                                                            color: '#0d6efd',
                                                            // textDecoration: 'underline'
                                                        }}>     {data.Inspectionmodel.title}</span>

                                                </td>

                                            </tr>
                                        )

                                    })}



                            </tbody>
                        </Table>


                    </div>



                </ViewScroll>
            </Container >
        </>

    );
}

const Content = styled(ViewScroll)`
  /* max-height: 40vh; */
  height: 40vh;
  overflow: auto;
  max-width: 100%;
`;

export default DashboardInspectionRecent;
