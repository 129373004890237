import { useRequest } from 'ahooks';
import moment from 'moment';
import React from 'react';
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
import { OS_STATUS, URL_OS } from '../../../constains';
import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';


export default function OsFormat({ infoClient, empresa, clientoflist, tipo, clientSelected, logo, infoEmpresa }: any) {
  const titleTitle = {
    backgroundColor: "#e6e5e5",
    fontWeight: "bold",
    font: "1.2rem sans-Serif",
  };
  const subTitle = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "800",
    opacity: 0.7,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const font = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "400",
    opacity: 0.5,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const fontBold = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "bold",
    opacity: 1,
    fontSize: "16px",
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ano = queryParams.get("ano");
  const mes = parseInt(queryParams.get("mes") ?? "0", 10) ?? 0;
  const clientId = queryParams.get("clientId");
  const name = queryParams.get("name");
  const cnpj = queryParams.get("cnpj");
  const address = queryParams.get("address");
  const phone = queryParams.get("phone");
  const email = queryParams.get("email");
  const title = queryParams.get("title") || null;
  const fetcher = () => {
    const url = `${URL_OS}/?mes=${mes + 1}&ano=${ano}&images=true${clientId ? "&clientId=" + clientId : ""
      }`;

    return fetch(url).then((res) => res.json());
  };

  const { data, loading } = useRequest(fetcher, {
    // cacheKey: url,
  });


  if (!data && loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <IonSpinner></IonSpinner>
        <h2>O Relatório está sendo gerado, aguarde.</h2>
      </div>
    );
  // if (true) return "carregando...";
  const oss = data?.data;


  // console.log(oss)

  return (
    <Container style={{
      // pageBreakAfter: 'always',
      // pageBreakBefore: 'always'
    }}>

      <Table
        style={{ marginTop: '10px' }}
      >



        {oss.map((osItem: any, key: any) => {

          return (<div>



            {clientSelected?.length == 0 ? <div style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>Selecione um cliente para visualizar o relatório.</div> :
              (clientSelected?.map((clientoflist: any, key: any) => {
                return (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <div className='logoTitle' style={{ height: '90px' }}>
                        <h4 className="title">
                          {title}
                        </h4>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          top: "30px",
                          right: "60px",
                          height: '250px'
                        }}
                      >
                        {logo == "" ?
                          (empresa?.config?.logo == 'Logo do cliente' || 'Logo da empresa' ?

                            (empresa?.config?.logo == 'Logo da empresa' ?

                              empresa?.image64 !== null ? (
                                <img
                                  src={empresa?.image64}
                                  alt="Logo"
                                  style={{ width: "auto", maxHeight: "120px" }}
                                />
                              )
                                :
                                (
                                  <>Não há logo cadastrado</>
                                )

                              :

                              <>logo do cliente</>)






                            : <></>)
                          :
                          (logo == "Logo do cliente" || logo == 'Logo da empresa' ?


                            (empresa?.config?.logo == 'Logo da empresa' ?

                              empresa?.image64 !== null ? (
                                <img
                                  src={empresa?.image64}
                                  alt="Logo"
                                  style={{ width: "200px", maxHeight: "120px" }}
                                />
                              )
                                :
                                (
                                  <>Não há logo cadastrado</>
                                )

                              :

                              <>logo do cliente</>) : <></>)
                        }
                      </div>
                    </div>



                    {infoEmpresa == "" ? (
                      empresa?.config?.infoEmpresa == 'Página' || empresa?.config?.infoEmpresa == 'Ambos' ? (
                        <>
                          {empresa?.name !== "" ? (
                            <span className="font">
                              {/* Iridium Engenharia */}
                              {name !== "" ? name : empresa?.name}
                            </span>
                          ) : (
                            ""
                          )}

                          {empresa.phone !== "" ? (
                            <span className="font">
                              {/* Telefone: (21)21103544 */}
                              <span className="subTitle" style={fontBold}>
                                Telefone:{" "}
                              </span>
                              {phone !== "" ? phone : empresa?.phone}
                            </span>
                          ) : (
                            ""
                          )}

                          {empresa.cnpj !== "" ? (
                            <span className="font">
                              {/* CNPJ: 36.174.476/0001-96 */}
                              <span className="subTitle" style={fontBold}>
                                CNPJ:{" "}
                              </span>
                              {cnpj !== "" ? cnpj : empresa?.cnpj}
                            </span>
                          ) : (
                            ""
                          )}

                          {empresa.email !== "" ? (
                            <span className="font">
                              {/* Email: contato@termolibra.com.br */}

                              <span className="subTitle" style={fontBold}>
                                Email:{" "}
                              </span>
                              {email !== "" ? email : empresa?.email}
                            </span>
                          ) : (
                            ""
                          )}

                          {empresa.address !== "" ? (
                            <span className="font">
                              {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                              <span className="subTitle" style={fontBold}>
                                Endereço:{" "}
                              </span>

                              {address !== "" ? address : empresa?.address}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div style={{ height: "70px" }}></div>
                      )
                    ) : infoEmpresa == "Página" || infoEmpresa == 'Ambos' ? (
                      <>
                        {empresa?.name !== "" ? (
                          <span className="font">
                            {/* Iridium Engenharia */}
                            {name !== "" ? name : empresa?.name}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.phone !== "" ? (
                          <span className="font">
                            {/* Telefone: (21)21103544 */}
                            <span className="subTitle" style={fontBold}>
                              Telefone:{" "}
                            </span>
                            {phone !== "" ? phone : empresa?.phone}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.cnpj !== "" ? (
                          <span className="font">
                            {/* CNPJ: 36.174.476/0001-96 */}
                            <span className="subTitle" style={fontBold}>
                              CNPJ:{" "}
                            </span>
                            {cnpj !== "" ? cnpj : empresa?.cnpj}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.email !== "" ? (
                          <span className="font">
                            {/* Email: contato@termolibra.com.br */}

                            <span className="subTitle" style={fontBold}>
                              Email:{" "}
                            </span>
                            {email !== "" ? email : empresa?.email}
                          </span>
                        ) : (
                          ""
                        )}

                        {empresa.address !== "" ? (
                          <span className="font">
                            {/* Endereço: Avenida Nova York, 614 A, Bonsucesso, Rio de Janeiro - RJ */}
                            <span className="subTitle" style={fontBold}>
                              Endereço:{" "}
                            </span>

                            {address !== "" ? address : empresa?.address}
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div style={{ height: "70px" }}></div>
                    )}







                    {infoClient == "" ? (empresa?.config?.clienteInfo && (
                      <Table key={key} style={{ marginTop: "20px", width: '100%' }}>
                        <thead>
                          <tr>
                            <td className="subTitle" colSpan={2} style={titleTitle}>
                              Informações do cliente
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className="font" style={subTitle}>
                                Nome do cliente
                              </span>
                              <div className="font" style={font}>
                                {/* BODYTECH - VOGUE */}
                                {/* {clientoflist?.name} */}
                                {clientoflist?.name !== null
                                  ? clientoflist?.name
                                  : "Não cadastrado."}
                              </div>
                            </td>
                            {/* <td>
                          <span className="font" style={subTitle}>
                            CPF/CNPJ
                          </span>
                          <div style={font}> */}
                            {/* 07.737.623/0077-99 */}
                            {/* {clientoflist?.cnpj !== null
                              ? clientoflist?.cnpj
                              : "Não cadastrado."}
                          </div>
                        </td> */}
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span className="font" style={subTitle}>
                                E-mail
                              </span>

                              <div className="font" style={font}>
                                {/* thiago.souza@bodytech.com.br */}
                                {/* {clientoflist?.email} */}
                                {clientoflist?.email !== null
                                  ? clientoflist?.email
                                  : "Não cadastrado."}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span className="font" style={subTitle}>
                                Endereço
                              </span>
                              <div className="font" style={font}>
                                {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                                {clientoflist?.address !== null
                                  ? clientoflist?.address
                                  : "Não cadastrado."}
                                {/* {clientoflist?.address} */}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )) : (
                      infoClient == "true" ?
                        <Table key={key} style={{ marginTop: "20px" }}>
                          <thead>
                            <tr>
                              <td className="subTitle" colSpan={2} style={titleTitle}>
                                Informações do cliente
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="font" style={subTitle}>
                                  Nome do cliente
                                </span>
                                <div className="font" style={font}>
                                  {/* BODYTECH - VOGUE */}
                                  {/* {clientoflist?.name} */}
                                  {clientoflist?.name !== null
                                    ? clientoflist?.name
                                    : "Não cadastrado."}
                                </div>
                              </td>
                              <td>
                                <span className="font" style={subTitle}>
                                  CPF/CNPJ
                                </span>
                                <div style={font}>
                                  {/* 07.737.623/0077-99 */}
                                  {clientoflist?.cnpj !== null
                                    ? clientoflist?.cnpj
                                    : "Não cadastrado."}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <span className="font" style={subTitle}>
                                  E-mail
                                </span>

                                <div className="font" style={font}>
                                  {/* thiago.souza@bodytech.com.br */}
                                  {/* {clientoflist?.email} */}
                                  {clientoflist?.email !== null
                                    ? clientoflist?.email
                                    : "Não cadastrado."}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <span className="font" style={subTitle}>
                                  Endereço
                                </span>
                                <div className="font" style={font}>
                                  {/* Av. das Américas, 8585 - Barra da Tijuca, Rio de Janeiro - RJ, 22793-081 */}
                                  {clientoflist?.address !== null
                                    ? clientoflist?.address
                                    : "Não cadastrado."}
                                  {/* {clientoflist?.address} */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        :
                        <></>

                    )}





                  </div>
                );
              }))
            }









            <Table
              style={{ marginTop: '10px' }}
            >
              <thead>
                <tr>
                  <td
                    className='subTitle'
                    colSpan={2}
                    style={titleTitle}>
                    Ordem de serviço realizada
                  </td>
                </tr>

              </thead>

            </Table>





            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#c0e5f3",
                width: "100%",
                borderRadius: "10px",
                margin: "15px 0px",
                height: '50px'
              }}
            >
              <span style={{ fontSize: "26px", backgroundColor: 'transparent' }}>
                {osItem.title}
              </span>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>



                <span
                  className='font'
                  style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%' }}>
                  ID: <span style={{ opacity: 0.7, display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%' }}>Os#{osItem?.ref}</span>
                </span>



                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Data/Hora de criação
                  </span>
                  <div
                    className='font'
                    style={font}>

                    {moment(osItem?.createAt).format("DD/MM/YYYY [às] HH:mm")}
                  </div>
                </span>


                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Data/Hora de conclusão
                  </span>
                  <div
                    className='font'
                    style={font}>

                    {moment(osItem?.endDate).format("DD/MM/YYYY [às] HH:mm")}
                  </div>
                </span>



              </div>



              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <span
                  style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%', flexDirection: 'column' }}
                >
                  <span
                    className='font'
                    style={subTitle}>
                    Técnico
                  </span>
                  <div
                    className='font'
                    style={font}>
                    {/* {osItem?.text == '' ? 'Nada informado.' : osItem?.text} */}
                    {osItem?.users.map((tecnicos: any) => { return tecnicos.name }).length < 1 ?
                      'Não informado.'
                      :
                      osItem?.users.map((tecnicos: any) => { return tecnicos.name })}
                  </div>
                </span>




                <span style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%', flexDirection: 'column' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Status da ordem de serviço
                  </span>
                  <div
                    className='font'
                    style={font}>
                    {OS_STATUS[osItem?.status]}
                  </div>
                </span>
                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                </span>
              </div>

            </div>

            <Table>
              <thead>
                <tr></tr>
              </thead>
              <tbody>

                <tr>
                  <td
                  // colSpan={2}
                  >
                    <span
                      className='font'
                      style={subTitle}>
                      Descrição
                    </span>
                    <div
                      className='font'
                      style={font}>
                      {osItem?.text == '' ? 'Nada informado.' : osItem?.text}
                    </div>
                  </td>
                </tr>




              </tbody >

            </Table>

            <Table
              style={{
                width: "100%",
                marginTop: "10px",
                margin: "10px 0px 20px 0px",
              }}
            >
              <thead>
                <tr>
                  <td
                    className="font"
                    colSpan={2}
                    style={{
                      backgroundColor: "#e6e5e5",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Fotos:
                  </td>
                </tr>
              </thead>
            </Table>
            <ImageContainer className="containerFoto" style={{ pageBreakAfter: 'always', }}>
              {osItem?.images?.map((d: any, key: any) => (
                <img className="fotos" key={key} src={d?.image64} style={{ width: '250px', height: '370px' }} />
              ))}
            </ImageContainer>
            <br />

            {/* <Table
              style={{ marginTop: '0px' }}
            >
              <thead>
                <tr>
                  <td
                    className='subTitle'
                    colSpan={2}
                    style={Title}>
                    Relatório de Serviço
                  </td>
                </tr>

              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      1 - Informe o nome da pessoa que acompanhou o serviço
                    </span>
                    <div
                      className='font'
                      style={font}>
                      João
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      2 - Diagnóstico das máquinas
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Motor do Flap com eixo danificado.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      3 - Solução aplicada/realizada
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Solicitar a troca do motor do Flap da evaporadora da gerência.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      4 - Houve algum serviço extra?
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Não.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      5 - Assinatura do Responsável
                    </span>
                    <div
                      className='font'
                      style={font}>
                      assinatura
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      6 - Foto
                    </span>
                    <div
                      className='font'
                      style={font}>
                      fotos
                    </div>
                  </td>
                </tr>

              </tbody>



            </Table> */}

          </div>)
        })}



      </Table >

    </Container>
  )
}

const ImageContainer = styled.div`
  margin-bottom: 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 15px;

  img {
  }
`;

const Container = styled.div`
  .column-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      font-weight: bold;
      font-size: 15px;
    }

    span {
      font-size: 12px;
    }
  }

  .row-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .content {
      div {
        padding: 5px;
      }
      display: flex;
      padding: 5px;
    }

    label {
      font-weight: bold;
      font-size: 15px;
    }

    span {
      font-size: 12px;
    }
  }
`;