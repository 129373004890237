import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "../../components/Modal";
import AdicionarOs from "./AdicionarOs";
import { Mode, Os } from "../../interfaces";
import { CreateOs, DeleteOs, GetOs, GetUser, UpdateOs } from "./api";
import OsTable from "./OsTable";
import { getLocalData2, parseJwt } from "../../helpers/utils";
import Select from "react-select";
import {
  OS_INITIAL_STATE,
  USER_URL,
  optionsPriority,
  optionsStatus,
} from "../../constains";
import { FilterOs } from "../../components/FilterOs/Filter";
import { SelectFilter } from "../../components/FilterOs/SelectFilter";
import { Dropdown } from "../../components/FilterOs/DropDown";
import { CallNotification } from "../../components/Alert";
import { Spin } from "react-cssfx-loading";
import { useRequest } from "ahooks";
import { Token } from "../../helpers/token";
import useSWR, { useSWRConfig } from "swr";

export const MyOs = (): React.ReactElement => {
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  const idUser = decoded?.payload?.id;
  const id = decoded?.payload?.companyId;
  const [hasChangeUser, setHasChangeUser] = useState(false);
  const osInitial = { ...OS_INITIAL_STATE, companyId: `${id}` };
  const [osFilter, setOsFilter] = useState<any>(null);
  const [osFilterStatus, setOsFilterStatus] = useState(null);
  const [mode, setMode] = React.useState<Mode["mode"]>("edit");
  const [osFilterPriority, setOsFilterPriority] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);
  const [OS, setOS] = useState<Os>(osInitial);
  const [showOSOpen, setShowOsOpen] = useState<boolean>(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setHasChangeUser(false);
  };
  const access = Token.getAccess();
  const { companyId, clientUser } = Token.getTokenData();

 



  const fetcher = (url: string) => fetch(url, access).then((res) => res.json());
  const { data, isLoading: loading } = useSWR(`${USER_URL}/${idUser}`, fetcher);
  const { mutate } = useSWRConfig();
  // console.log("data", data.data.os);
  const run = React.useCallback(() => mutate(`${USER_URL}/${idUser}`), [mutate]);

  //@ts-ignore
  const criarOS = decoded?.payload.criarOS;

  const onConfirm = (idOsDelete: string, userId: string) => {
    DeleteOs(idOsDelete, () => {
      // GetUser(userId, setOSs);
      run();
      CallNotification("Sucesso", "Os deletada!", "success");
    });
  };

  const onSubmitHandler = () => {

    if (mode == "edit") {
      let _Os = OS;

      delete _Os?.client;
      delete _Os.images;
      if (!hasChangeUser) {
        delete _Os.users
      }
      _Os.companyId = `${companyId}`

     


      UpdateOs(
        _Os,
        () => {
          run();
          CallNotification("Sucesso", "Os editada", "success");
        },
        () => {
          CallNotification("Error", "Os não pode ser editada", "danger");
        }
      );
    }

    if (mode == "create") {
      let createOs: any = OS;


      if (!clientUser && createOs?.users?.connect !== undefined) {
        // @ts-ignore
        createOs.users.connect = createOs?.users?.connect?.map((value: any) => {
          return { id: value.id }
        })
      }

      if (clientUser) { createOs.status = 4 }


      createOs.companyId = `${companyId}`

      CreateOs(createOs, run);

    }





    handleClose();
  };

  const OSs = useMemo(() => data?.user, [data]);


  return (
    <>
      {criarOS === false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Este usuário não possui permissão para criar Os
        </div>
      ) : (
        <Button
          style={{ height: "auto", fontSize: "20px" }}
          variant="primary"
          onClick={() => {
            setMode("create");
            setOS(osInitial);
            handleShow();
          }}
        >
          <AiOutlinePlus /> Nova OS
        </Button>
      )}

      <Dropdown
        children={
          <SelectFilter
            optionsPriority={optionsPriority}
            osFilterPriority={osFilterPriority}
            setOsFilterPriority={setOsFilterPriority}
            optionsStatus={optionsStatus}
            osFilterStatus={osFilterStatus}
            setOsFilterStatus={setOsFilterStatus}
            showOSOpen={showOSOpen}
            setShowOsOpen={setShowOsOpen}
          />
        }
      />

      {loading == true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Spin color="#0f0f0f" width="100px" height="100px" duration="3s" />
          Aguarde...
        </div>
      ) : (
        <OsTable
          OSs={

            FilterOs
              (OSs?.os,
                osFilterPriority,
                // @ts-ignore
                osFilterStatus,
                showOSOpen)
          }

          OS={OS}
          setOS={setOS}
          setShow={setShow}
          show={show}
          mode={mode}
          setMode={setMode}
          hasChangeUser={hasChangeUser}
          onConfirm={onConfirm}
        />
      )}

      <Modal
        title=""
        show={show}
        setShow={setShow}
        onSubmitHandler={onSubmitHandler}
        // mode={mode}
        content={
          <AdicionarOs
            OS={OS}
            setOS={setOS}
            mode={mode}
            setHasChangeUser={setHasChangeUser}
            myOs={true}
          />
        }
      />
    </>
  );
};
