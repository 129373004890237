import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import styled from "styled-components";
import AdicionarColaborador from "../Equipe/AdicionarColaborador/adicionarColaborador";
import Card from "../../components/card/card";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { ADMIN_URL_IMAGE, USER_BY_COMPANY } from "../../constains";
import { User } from "../../interfaces";
import { Token } from "../../helpers/token";

export const MinhaEquipe = (): React.ReactElement => {
  const { companyId } = Token.getTokenData();
  const access = Token.getAccess();

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = () => {
    axios
      .get(`${USER_BY_COMPANY}/${companyId}`, access)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Cabecalho>
        <Titulo> Minha Equipe</Titulo>

        <Button
          style={{ height: "auto", fontSize: "20px" }}
          variant="primary"
          onClick={handleShow}
        >
          <AiOutlinePlus /> Novo colaborador
        </Button>
      </Cabecalho>

      <hr />

      <Colaborador>
        {users?.map((user: User, index) => {
          const imageUrl = `${window.location.origin}${ADMIN_URL_IMAGE}/${user?.image?.id}`;
          var randomColor = Math.floor(Math.random() * 16777215).toString(16);

          return (
            <>
              {users.length === 1 ? (
                <div>Não há colaboradores cadastrados!</div>
              ) : (
                <div>
                  {" "}
                  {user?.admin === true ? (
                    ""
                  ) : (
                    <Card
                      title=""
                      category=""
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <ColaboradorOrganization>
                          <>
                            <Imagem
                              style={{ backgroundColor: `#${randomColor}` }}
                            >
                              {user?.image?.id === undefined ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {user?.name.charAt(0).toUpperCase()}

                                  {user?.name.indexOf(" ") === -1 ? (
                                    ""
                                  ) : (
                                    <div>
                                      {user?.name
                                        .charAt(user?.name.indexOf(" ") + 1)
                                        .toUpperCase()}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <img
                                  src={imageUrl}
                                  style={{ height: "70px", width: "70px" }}
                                  alt=""
                                />
                              )}
                            </Imagem>
                            <Informacoes>
                              <ColaboradorName>{user?.name} </ColaboradorName>

                              <ColaboradorEstadotempo>
                                <Estado>
                                  <VscDebugBreakpointLog
                                    style={{ marginRight: "5px" }}
                                  />{" "}
                                  Indisponível
                                </Estado>
                                <Tempo>
                                  {" "}
                                  <GoLocation
                                    style={{ marginRight: "5px" }}
                                  />{" "}
                                  há 8 meses
                                </Tempo>
                              </ColaboradorEstadotempo>
                            </Informacoes>
                          </>
                        </ColaboradorOrganization>
                      }
                    />
                  )}
                </div>
              )}
            </>
          );
        })}
      </Colaborador>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Agente</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#ededed" }}>
          <AdicionarColaborador getData={getData} handleClose={handleClose} />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`;

const Cabecalho = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Titulo = styled.span`
  padding-left: 25px;
`;
const Imagem = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
`;

const Informacoes = styled.div`
  display: flex;
  flex-direction: column;
`;
const ColaboradorName = styled.div`
  margin-left: 20px;
  color: var(--blue-700);
`;
const ColaboradorEstadotempo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Estado = styled.span`
  border: 1px solid var(--red-700);
  border-radius: 10px;
  height: auto;
  width: auto;
  color: var(--red-700);
  background-color: var(--red-300);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;
const Tempo = styled.span`
  margin-left: 10px;
  border: 1px solid var(--blue-700);
  border-radius: 10px;
  height: auto;
  width: auto;
  color: var(--blue-700);
  background-color: var(--blue-200);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const ColaboradorOrganization = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* background-color: red; */
`;

const Colaborador = styled.div`
  min-height: 400px;
  min-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    -webkit-border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }
`;
