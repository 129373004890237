/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AdicionarBranch.css";
import { CallNotification } from "../Alert";
import { ADMIN_BRANCH_URL, ADMIN_COMPANY_URL } from "../../constains";
import { Branch } from "../../interfaces";
import Select from "react-select";

function AdicionarBranch({ getData, handleClose }: any) {

  const [empresa, setEmpresa] = useState<any>({ name: '', address: '', phone: '', cnpj: '', email: '' });
  const newBranch = {
    name: empresa.name,
    address: empresa.address,
    phone: empresa.phone,
    cnpj: empresa.cnpj,
    email: empresa.email
  };
  const token = window.localStorage.getItem("accessToken");
  const access = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };




  const onSubmitHandler = (event: any) => {
    event.preventDefault();

  

    axios
      .post(ADMIN_BRANCH_URL, newBranch, access)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          CallNotification("Sucesso", "Filial cadastrada com sucesso", "success");
          getData();
          handleClose();
        }

      })
      .catch((err) => {
        console.log(err);
        CallNotification("Error", "Falha ao cadastrar filial", "danger");
      });
  };



  return (
    <div className="AdicionarUsuario">
      <form onSubmit={onSubmitHandler}>
        <div className="Linha">
          <div className="Coluna">
            <label style={{ width: "95%", marginLeft: "11px" }}>Nome:</label>
            <input
              style={{ width: "96.2%", marginLeft: "11px" }}
              type="text"
              name="login"
              value={empresa.name}
              onChange={(event) => setEmpresa((prev: any) => ({ ...prev, name: event.target.value }))}
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label style={{ width: "95%", marginLeft: "11px" }}>Endereço:</label>
            <input
              style={{ width: "96.2%", marginLeft: "11px" }}
              type="text"
              name="login"
              value={empresa.address}
              onChange={(event) => setEmpresa((prev: any) => ({ ...prev, address: event.target.value }))}
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label style={{ width: "95%", marginLeft: "11px" }}>Telefone:</label>
            <input
              style={{ width: "96.2%", marginLeft: "11px" }}
              type="text"
              name="login"
              value={empresa.phone}
              onChange={(event) => setEmpresa((prev: any) => ({ ...prev, phone: event.target.value }))}
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label style={{ width: "95%", marginLeft: "11px" }}>CNPJ:</label>
            <input
              style={{ width: "96.2%", marginLeft: "11px" }}
              type="text"
              name="login"
              value={empresa.cnpj}
              onChange={(event) => setEmpresa((prev: any) => ({ ...prev, cnpj: event.target.value }))}
              required
            ></input>
          </div>
        </div>
        <div className="Linha">
          <div className="Coluna">
            <label style={{ width: "95%", marginLeft: "11px" }}>Email:</label>
            <input
              style={{ width: "96.2%", marginLeft: "11px" }}
              type="text"
              name="login"
              value={empresa.email}
              onChange={(event) => setEmpresa((prev: any) => ({ ...prev, email: event.target.value }))}
              required
            ></input>
          </div>
        </div>
        <button className="btn btn-primary" type="submit">
          Adicionar
        </button>
      </form >
    </div >
  );
}

export default AdicionarBranch;
