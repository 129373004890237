import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import {
  buildOutline,
  buildSharp,
  businessOutline,
  businessSharp,
  documentAttachOutline,
  documentOutline,
  documentSharp,
  fileTrayFullOutline,
  fileTrayFullSharp,
  fileTrayStackedOutline,
  fileTrayStackedSharp,
  gridOutline,
  gridSharp,
  idCardOutline,
  idCardSharp,
  imagesOutline,
  imagesSharp,
  mapOutline,
  mapSharp,
  peopleOutline,
  peopleSharp,
  personOutline,
  personSharp,
  settingsOutline
} from "ionicons/icons";
import React from "react";
import { useLocation } from "react-router-dom";
import { parseJwt } from "../../helpers/utils";
import "./Menu.css";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

// const clientPages: AppPage[] = [
//   {
//     title: "OS's",
//     url: "/admin/os",
//     iosIcon: documentOutline,
//     mdIcon: documentSharp,
//   },
// ];

const adminPagaes: AppPage[] = [
  {
    title: "Usuarios",
    url: "/admin/usuarios",
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
  {
    title: "company",
    url: "/admin/company",
    iosIcon: businessOutline,
    mdIcon: businessSharp,
  },
  {
    title: "company client",
    url: "/admin/client",
    iosIcon: idCardOutline,
    mdIcon: idCardSharp,
  },
  {
    title: "OS's",
    url: "/admin/os",
    iosIcon: documentOutline,
    mdIcon: documentSharp,
  },
  {
    title: "Images",
    url: "/admin/images",
    iosIcon: imagesOutline,
    mdIcon: imagesSharp,
  },
];

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    iosIcon: gridOutline,
    mdIcon: gridSharp,
  },
  {
    title: "Equipe",
    url: "/equipe",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Empresas",
    url: "/empresas",
    iosIcon: idCardOutline,
    mdIcon: idCardSharp,
  },
  {
    title: "Usuário dos clientes",
    url: "/clientes",
    iosIcon: idCardOutline,
    mdIcon: idCardSharp,
  },
  {
    title: "OS's",
    url: "/os",
    iosIcon: documentOutline,
    mdIcon: documentSharp,
  },
  {
    title: "Minhas Os",
    url: "/minhas-os",
    iosIcon: fileTrayFullOutline,
    mdIcon: fileTrayFullSharp,
  },
  {
    title: "Inspeção",
    url: "/inspection",
    iosIcon: buildOutline,
    mdIcon: buildSharp,
  },
  {
    title: "Localização",
    url: "/localizacao",
    iosIcon: mapOutline,
    mdIcon: mapSharp,
  },
  {
    title: "Relatório",
    url: "/relatorio",
    iosIcon: documentAttachOutline,
    mdIcon: documentAttachOutline,
  },
  {
    title: "Arquivos",
    url: "/files",
    iosIcon: fileTrayStackedOutline,
    mdIcon: fileTrayStackedSharp,
  },
  {
    title: "Configurações",
    url: "/config",
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
  }
];

const Menu: React.FC = () => {
  const location = useLocation();
  if (location.pathname.includes("login")) return null;
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  let isAdminPage = false;
  if (location.pathname.includes("admin") && decoded?.payload?.admin === true)
    isAdminPage = true;

  return (
    <>
      {isAdminPage ? (
        <>
          <IonMenu contentId="main" type="overlay">
            <IonContent>
              <IonList id="inbox-list">
                <IonListHeader>Admin</IonListHeader>
                <IonNote>area administrativa</IonNote>
                {adminPagaes.map((appPage, index) => {
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" icon={appPage.iosIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                })}
              </IonList>
            </IonContent>
          </IonMenu>
        </>
      ) : (
        <>
          <IonMenu contentId="main" type="overlay"
        
          >
            <IonContent >
              <IonList id="inbox-list"


              >
                <IonListHeader>Tec OS</IonListHeader>
                <IonNote>{`Bem vindo ${decoded?.payload?.name}`}</IonNote>
                {appPages.map((appPage, index) => {
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                    
                      >
                        <IonIcon slot="start" icon={appPage.iosIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                })}
              </IonList>
            </IonContent>
          </IonMenu>
        </>
      )}
    </>
  );
};

export default Menu;
