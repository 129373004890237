import { useRequest } from 'ahooks';
import moment from 'moment';
import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { OS_STATUS, URL_OS } from '../../constains';
import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";


export default function OsFormat() {
  const [oss, setOss] = useState<any>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Title = {
    backgroundColor: "#e6e5e5",
    fontWeight: "bold",
    font: "1.2rem sans-Serif",
  };
  const subTitle = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "800",
    opacity: 0.7,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const font = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "400",
    opacity: 0.5,
    // font: '1.25rem AmstelvarAlpha sans-Serif'
  };
  const fontBold = {
    // backgroundColor: '#e6e5e5',
    fontWeight: "bold",
    opacity: 1,
    fontSize: "16px",
  };

  const clientId = queryParams.get("clientId");
  const osId = queryParams.get("osid");

  // console.log(osId)

  const fetcher = () => {
    const url = `${URL_OS}/${osId}`;

    return fetch(url).then((res) => res.json());
  };

  const { data, loading } = useRequest(fetcher, {
    // cacheKey: url,
  });

  console.log(data?.data)
  const osItem = data?.data


  if (!data && loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <IonSpinner></IonSpinner>
        <h2>O Relatório está sendo gerado, aguarde.</h2>
      </div>
    );
  // if (true) return "carregando...";



  // console.log(oss)

  return (
    <>
      <Table
        style={{ marginTop: '10px' }}
      >
        <thead>
          <tr>
            <td
              className='subTitle'
              colSpan={2}
              style={Title}>
              Ordem de serviço realizada
            </td>
          </tr>

        </thead>

      </Table>

      <Table
        style={{ marginTop: '10px' }}
      >



       

           <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#c0e5f3",
                width: "100%",
                borderRadius: "10px",
                margin: "15px 0px",
                height: '50px'
              }}
            >
              <span style={{ fontSize: "26px", backgroundColor: 'transparent' }}>
                {osItem.title}
              </span>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>



                <span
                  className='font'
                  style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%' }}>
                  ID: <span style={{ opacity: 0.7, display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%' }}>Os#{osItem?.ref}</span>
                </span>



                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Data/Hora de criação
                  </span>
                  <div
                    className='font'
                    style={font}>

                    {moment(osItem?.createAt).format("DD/MM/YYYY [às] HH:mm")}
                  </div>
                </span>


                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Data/Hora de conclusão
                  </span>
                  <div
                    className='font'
                    style={font}>

                    {moment(osItem?.endDate).format("DD/MM/YYYY [às] HH:mm")}
                  </div>
                </span>



              </div>



              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <span
                  style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%', flexDirection: 'column' }}
                >
                  <span
                    className='font'
                    style={subTitle}>
                    Técnico
                  </span>
                  <div
                    className='font'
                    style={font}>
                    {/* {osItem?.text == '' ? 'Nada informado.' : osItem?.text} */}
                    {osItem?.users.map((tecnicos: any) => { return tecnicos.name }).length < 1 ?
                      'Não informado.'
                      :
                      osItem?.users.map((tecnicos: any) => { return tecnicos.name })}
                  </div>
                </span>




                <span style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'flex-start', height: '100%', flexDirection: 'column' }}>
                  <span
                    className='font'
                    style={subTitle}>
                    Status da ordem de serviço
                  </span>
                  <div
                    className='font'
                    style={font}>
                    {OS_STATUS[osItem?.status]}
                  </div>
                </span>
                <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                </span>
              </div>

            </div>

            <Table>
              <thead>
                <tr></tr>
              </thead>
              <tbody>

                <tr>
                  <td
                  // colSpan={2}
                  >
                    <span
                      className='font'
                      style={subTitle}>
                      Descrição
                    </span>
                    <div
                      className='font'
                      style={font}>
                      {osItem?.text == '' ? 'Nada informado.' : osItem?.text}
                    </div>
                  </td>
                </tr>


                {/* <tr>
                  <td >
                    <span
                      className='font'
                      style={subTitle}>
                      Check-in
                    </span>
                    <div
                      className='font'
                      style={font}>

                      {osItem?.check_in == null ? 'Não efetuou o check-in' :
                        moment(os?.check_in).format("DD/MM/YYYY [às] HH:mm")
                      }
                    </div>
                  </td>

                  <td >
                    <span
                      className='font'
                      style={subTitle}>
                      Checkout
                    </span>
                    <div
                      className='font'
                      style={font}>

                      {osItem?.check_out == null ? 'Não efetuou o checkout' :
                        moment(os?.check_out).format("DD/MM/YYYY [às] HH:mm")}
                    </div>
                  </td>
                </tr> */}

              </tbody >

            </Table>

            <Table
              style={{
                width: "100%",
                marginTop: "10px",
                margin: "10px 0px 20px 0px",
              }}
            >
              <thead>
                <tr>
                  <td
                    className="font"
                    colSpan={2}
                    style={{
                      backgroundColor: "#e6e5e5",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Fotos:
                  </td>
                </tr>
              </thead>
            </Table>
            <ImageContainer className="containerFoto">
              {osItem?.images?.map((d: any, key: any) => (
                <img className="fotos" key={key} src={d?.image64} style={{ width: '250px', height: '370px' }} />
              ))}
            </ImageContainer>
            <br />

            {/* <Table
              style={{ marginTop: '0px' }}
            >
              <thead>
                <tr>
                  <td
                    className='subTitle'
                    colSpan={2}
                    style={Title}>
                    Relatório de Serviço
                  </td>
                </tr>

              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      1 - Informe o nome da pessoa que acompanhou o serviço
                    </span>
                    <div
                      className='font'
                      style={font}>
                      João
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      2 - Diagnóstico das máquinas
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Motor do Flap com eixo danificado.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      3 - Solução aplicada/realizada
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Solicitar a troca do motor do Flap da evaporadora da gerência.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      4 - Houve algum serviço extra?
                    </span>
                    <div
                      className='font'
                      style={font}>
                      Não.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      5 - Assinatura do Responsável
                    </span>
                    <div
                      className='font'
                      style={font}>
                      assinatura
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='font'
                      style={subTitle}>
                      6 - Foto
                    </span>
                    <div
                      className='font'
                      style={font}>
                      fotos
                    </div>
                  </td>
                </tr>

              </tbody>



            </Table> */}

          </div>
   



      </Table >







    </>
  )
}

const ImageContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  justify-items: stretch; */
  margin-bottom: 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 15px;

  img {
    /* height: 100px;
    width: auto; */
  }
`;