import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "../../components/Modal";
// import AdicionarOs from "./AdicionarOs";
import AdicionarOs from "../MyOs/AdicionarOs";
import { Company, Mode, Os } from "../../interfaces";
import { CreateOs, GetOs, UpdateOs, GetOss } from "../MyOs/api";
import OsTable from "../MyOs/OsTable";
import {
  getDataRequest,
  getLocalData,
  getLocalData2,
  parseJwt,
  useRequestCache,
} from "../../helpers/utils";
import Card from "../../components/card/card";
import { IonSearchbar } from "@ionic/react";
import { SelectFilter } from "../../components/FilterOs/SelectFilter";
import {
  COMPANY_URL,
  optionsPriority,
  optionsStatus,
  OS_INITIAL_STATE,
  URL_OS,
} from "../../constains";
import { FilterOs } from "../../components/FilterOs/Filter";
import { Dropdown } from "../../components/FilterOs/DropDown";
// import { decode } from "querystring";
import { DeleteOs } from "./api";
import { CallNotification } from "../../components/Alert";
import { Spin } from "react-cssfx-loading";
import { useRequest } from "ahooks";
import { Token } from "../../helpers/token";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";

interface ICompanyRequest {
  data: Company;
}

export const OsCli = (): React.ReactElement => {
  // const [loading, setLoading] = useState<any>(true);
  const [osFilterPriority, setOsFilterPriority] = useState<any>(null);
  const [osFilterStatus, setOsFilterStatus] = useState<any>(null);
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  const { companyId, clientUser, id } = Token.getTokenData();
  const access = Token.getAccess();
  const [search, setSearch] = useState<string>("");
  const [hasChangeUser, setHasChangeUser] = useState(false);

  const [mode, setMode] = React.useState<Mode["mode"]>("edit");
  const [show, setShow] = useState<boolean>(false);

  const osInitial: Os = { ...OS_INITIAL_STATE, companyId: `${companyId}` };

  const [OS, setOS] = useState<Os>(osInitial);
  const [showOSOpen, setShowOsOpen] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setHasChangeUser(false);
  };
  //@ts-ignore
  const criarOS = decoded?.payload.criarOS;
  const visualizarOS = decoded?.payload.visualizarOS;


  // console.log(criarOS)

  const url = `${COMPANY_URL}/${companyId}`;
  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data, isLoading: loading } = useSWR(url, fetcher);
  const { mutate, onError } = useSWRConfig();

  // console.log("data", data.data.os);
  const run = React.useCallback(() => mutate(url), [mutate]);


// console.log(OS)

  const updateOs2 = async (data: any) => {
    let _Os = OS;
    delete _Os.client;
    delete _Os.images;

    // @ts-ignore
    if (!hasChangeUser) {
      delete _Os.users;
    };

    // @ts-ignore
    if (!clientUser && _Os?.users?.set !== undefined) {
      // @ts-ignore
      _Os.users.set = _Os?.users?.set?.map((value: any) => {
        return { id: value.id }
      })
    }



    const updatedOsReturn = await axios.put(
      `${URL_OS}/${_Os?.id}`,
      _Os,
      access
    );
    const updatedOs: Os = updatedOsReturn?.data.data ?? {};
    const allOs: Os[] = data?.data?.os ?? [];

    const osWithoutNew = allOs.filter((os) => os.id !== updatedOs.id);
    data.data.os = [updatedOs, ...osWithoutNew];

    CallNotification("Sucesso", "Os editada com sucesso", "success");

console.log(data)
    return data;
  };



  const onSubmitHandler = () => {

    if (mode == "edit") {

      mutate(url, updateOs2, {
        revalidate: true,
        // rollbackOnError: true,
      });


    }
    if (mode == "create") {
      let createOs: any = OS;


      if (!clientUser && createOs?.users?.connect !== undefined) {
        // @ts-ignore
        createOs.users.connect = createOs?.users?.connect?.map((value: any) => {
          return { id: value.id }
        })
      }

      if (clientUser) { createOs.status = 4 }

      createOs.createdBy = `${id}`


      CreateOs(createOs, run);

    }


    handleClose();
  };

  const filteredOss = React.useMemo(
    () =>
      data?.data?.os?.filter((os: any) =>
        os?.title?.toLowerCase()?.includes(search?.toLowerCase())
      ),
    [data]
  );

  // console.log(data?.data?.os)

  const onConfirm = (idOsDelete: string, userId: string) => {
    DeleteOs(idOsDelete, () => {
      run();
      CallNotification("success", "Os deletada!", "success");
    });
  };

  return (
    <>
      {visualizarOS === true ? (
        <>



          {(!window.location?.pathname?.includes('/abrir_os')) && clientUser ? '' : (criarOS === true ? (
            <Button
              style={{ height: "auto", fontSize: "20px" }}
              variant="primary"
              onClick={() => {
                setMode("create");
                setOS(osInitial);
                handleShow();
              }}
            >
              <AiOutlinePlus /> Nova OS
            </Button>
          ) : (
            <div style={{ margin: "10px" }}>
              {/* <Button
                style={{ height: "auto", fontSize: "20px" }}
                variant="primary"
                onClick={() => {
                  setMode("create");
                  setOS(osInitial);
                  handleShow();
                }}
                disabled={true}
              >
                <AiOutlinePlus /> Nova OS
              </Button> */}
              Seu usuário não possui permissão para criar Os!
            </div>
          ))}





          <div
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   flexDirection: "column",
          //   alignItems: "center",
          // }}
          >
            <IonSearchbar
              // style={{ width: "370px", margin: "0px 0px 10px 0px" }}
              value={search}
              onIonChange={(value) => setSearch(value?.detail?.value || "")}
            />

            <Dropdown
              children={
                <SelectFilter
                  optionsPriority={optionsPriority}
                  osFilterPriority={osFilterPriority}
                  setOsFilterPriority={setOsFilterPriority}
                  optionsStatus={optionsStatus}
                  osFilterStatus={osFilterStatus}
                  setOsFilterStatus={setOsFilterStatus}
                  showOSOpen={showOSOpen}
                  setShowOsOpen={setShowOsOpen}
                />
              }
            />

            {/* <SelectFilter
          optionsPriority={optionsPriority}
          osFilterPriority={osFilterPriority}
          setOsFilterPriority={setOsFilterPriority}
          optionsStatus={optionsStatus}
          osFilterStatus={osFilterStatus}
          setOsFilterStatus={setOsFilterStatus}
        /> */}
          </div>
          {loading == true ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Spin
                color="#0f0f0f"
                width="100px"
                height="100px"
                duration="3s"
              />
              Aguarde...
            </div>
          ) : (
            <OsTable
              OSs={FilterOs(
                filteredOss,
                osFilterPriority,
                osFilterStatus,
                showOSOpen
              )}

              OS={OS}
              setOS={setOS}
              setShow={setShow}
              show={show}
              mode={mode}
              setMode={setMode}
              hasChangeUser={hasChangeUser}
              onConfirm={onConfirm}
            />
          )}
          <Modal
            title=""
            show={show}
            setShow={setShow}
            onSubmitHandler={onSubmitHandler}
            content={
              <AdicionarOs
                OS={OS}
                setOS={setOS}
                mode={mode}
                setHasChangeUser={setHasChangeUser}
              />
            }
          />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Este usuário não possui permissão para visualizar todas as Os
        </div>
      )}
    </>
  );
};
