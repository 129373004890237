import moment from 'moment';
import React from 'react';
import { useLocation } from "react-router-dom";
import { alfaFilter } from '../../../helpers/utilsFunctions';


export default function Inspections({
    inspection,
    os }: any) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const inspectionIds = queryParams.get("inspection") || null;
    const clienteName = queryParams.get("clienteName") || '';

    return (
        <div style={{ marginTop: '20px' }}>
            {alfaFilter(inspection, 'equipment', 'title')?.map((value: any, key: any) => {
                // console.log(value)

                return (



                    <a
                    style={{textDecoration: 'none', color: 'black'}}
                        target='blank'
                        href={`/onlyInspection/${value.id}?voltar=/inspection/${value?.equipment?.clientId}/${value?.equipment?.id}?&name=${clienteName}&equiname=${value?.equipment?.title}&equiid=${value?.equipment?.id}&clientid=${value?.equipment?.clientId}`}
                        key={key} >
                        <div
                            style={{
                                height: '50px',
                                width: '100%',
                                backgroundColor: '#cbe1ee',
                                borderRadius: '10px',
                                padding: '10px',
                                margin: '10px 10px',
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >


{value?.equipment?.title} -{" "}{value.Inspectionmodel?.title} -{" "}
                            {moment(value?.createAt).format("DD/MM/YYYY - HH:mm")}
                        </div>
                    </a>


                )
            })}

        </div>
    )
}