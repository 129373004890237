import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../../components/card/card";
import { Agenda } from "./Agenda";
import { Mapa } from "./Mapa";
import { MinhaEquipe } from "./MinhaEquipe";
import { MinhasOs } from "./MinhasOs";
import { DivGeral } from "./styles";
// import jwt_decode from "jwt-decode";
import Mobile from "./Mobile";
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  getPlatforms,
} from "@ionic/react";
import { calendar, personCircle, map, informationCircle } from "ionicons/icons";


export const Home = (): React.ReactElement => {
  // const accessToken: string = localStorage.getItem("accessToken") || ""
  const platform = getPlatforms();
  console.log("platform", platform);
  if (!platform.includes("desktop")) return <Mobile />;

  // return (  <IonTabs>
  //   <IonTabBar slot="bottom">
  //     <IonTabButton tab="schedule">
  //       <IonIcon icon={calendar} />
  //       <IonLabel>Schedule</IonLabel>
  //       <IonBadge>6</IonBadge>
  //     </IonTabButton>

  //     <IonTabButton tab="speakers">
  //       <IonIcon icon={personCircle} />
  //       <IonLabel>Speakers</IonLabel>
  //     </IonTabButton>

  //     <IonTabButton tab="map">
  //       <IonIcon icon={map} />
  //       <IonLabel>Map</IonLabel>
  //     </IonTabButton>

  //     <IonTabButton tab="about">
  //       <IonIcon icon={informationCircle} />
  //       <IonLabel>About</IonLabel>
  //     </IonTabButton>
  //   </IonTabBar>
  // </IonTabs>)
  return (
    <DivGeral>
      <Container fluid>
       
        <Row>
          <Col md={6}>
            <Card
              title=""
              category=""
              ctTableFullWidth
              ctTableResponsive
              content={<MinhasOs />}
            />
          </Col>
          <Col md={6}>
            <Card
              title=""
              category=""
              ctTableFullWidth
              ctTableResponsive
              content={<MinhaEquipe />}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card
              title=""
              category=""
              ctTableFullWidth
              ctTableResponsive
              content={<Agenda />}
            />
          </Col>
          <Col md={6}>
            <Card
              title=""
              category=""
              ctTableFullWidth
              ctTableResponsive
              content={<Mapa />}
            />
          </Col>
        </Row>
      </Container>
    </DivGeral>
  );
};
