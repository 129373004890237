import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { DeleteOs, GetOs, UpdateOs } from "../api";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import Modal from "../../../components/Modal";
import AdicionarOs from "../AdicionarOs";
import { formatDistance, subDays } from "date-fns";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import Card from "../../../components/card/card";
import { Table, Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import { FaRegTrashAlt, FaEye, FaPencilAlt, FaMobileAlt } from "react-icons/fa";
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";

import { getPlatforms } from "@ionic/react";

import { OS_STATUS, URL_OS, ViewScroll } from "../../../constains";
import {
  colorOptionsPriority,
  colorOptionsStatus,
} from "../../../styles/cssVariables";
import { ptBR } from "date-fns/locale";
import { Mode, Os } from "../../../interfaces";
import { createOutline, eyeOutline, trashOutline } from "ionicons/icons";
import { parseJwt } from "../../../helpers/utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  FcHighPriority,
  FcLowPriority,
  FcMediumPriority,
} from "react-icons/fc";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { Token } from "../../../helpers/token";

interface Props {
  OSs: Os[];
  setOSs?: any;
  setOS: Dispatch<SetStateAction<Os>>;
  OS: Os;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  mode: Mode["mode"];
  setMode: Dispatch<SetStateAction<Mode["mode"]>>;
  hasChangeUser: boolean;
  onConfirm: (osId: string, userId: string) => void;
}

function BasicExample({
  OSs = [],
  setOSs,
  setOS,
  OS,
  mode,
  setMode,
  show,
  setShow,
  hasChangeUser,
  onConfirm,
}: Props) {
  const token = window.localStorage.getItem("accessToken") || "";
  const decoded = parseJwt(token);
  const platform = getPlatforms();
  const theme = useTheme();
  //@ts-ignore
  const handleClose = () => setShow(false);
  const { companyId, clientUser, id } = Token.getTokenData();
  const handleShow = (Os: Os) => {
    // console.log("os", Os)
    setMode("view");
    //@ts-ignore
    setOS(Os);
    setShow(true);
  };

  // console.log(OSs)
  // console.log(location.pathname.includes('/abrir_os'))

  const handleEdit = (Os: Os) => {
    // console.log(Os)
    setMode("edit");
    setOS(Os);
    setShow(true);
  };

//  console.log(OS)



  const filterOs: any = OSs.filter((value) => {
    if (window.location?.pathname?.includes('/abrir_os') && value.createdBy == id) { return value.status == 4  } 
    if (!window.location?.pathname?.includes('/abrir_os')) { return value.status !== 4 } 
  })

  const componentOptionsPriority: { [key: number]: any } = {
    0: <FcLowPriority size={27} />,
    1: <FcMediumPriority size={27} />,
    2: <FcHighPriority size={27} />,
  };
 
  // if (!platform.includes("desktop")) return <Mobile />;
  return (
    <Content>
      <ViewScroll style={{ height: "100%" }}>
        {!platform.includes("desktop") ? (
          <>
            <Mobile
              OSs={filterOs}
              componentOptionsPriority={componentOptionsPriority}
              handleShow={handleShow}
              setOSs={setOSs}
              handleEdit={handleEdit}
              onConfirm={onConfirm}
            />
          </>
        ) : (
          <Desktop
            OSs={filterOs}
            handleShow={handleShow}
            setOSs={setOSs}
            handleEdit={handleEdit}
            componentOptionsPriority={componentOptionsPriority}
            onConfirm={onConfirm}
          />
        )}
      </ViewScroll>
    </Content>
  );
}

const Content = styled.div`
  font-size: 1rem;
  svg {
    font-size: 1.2rem;
  }
`;

export default React.memo(BasicExample);
