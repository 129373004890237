import React, { useEffect, useMemo, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import _Config from "./Empresa";
import Inspection from "./Inspection";

export default function Config(
  { inspection,
    os,
    setOs,
    setInspection }: any) {
  const [selected, setSelected] = useState<any>(
    "config"
  );
  const componentOptions: { [key: string]: JSX.Element } = {
    config: <_Config />,
    inspection: <Inspection
    // @ts-ignore
      inspection={inspection}
      os={os}
      setOs={setOs}
      setInspection={setInspection} />
  };

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "9",
        }}
      >
        <Nav
          fill
          variant="tabs"
          defaultActiveKey={"config"}
          style={{
            // position: "fixed", zIndex: "999", opacity: '1',
            backgroundColor: `white`,
            width: "100%",
          }}
        >
          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="config"
              style={{
                color: selected !== "config" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("config");
                window.localStorage.setItem("config", "config");
              }}
            >
              Configurações
            </Nav.Link>
          </Nav.Item>

          <Nav.Item style={{ width: "50%" }}>
            <Nav.Link
              eventKey="inspection"
              style={{
                color: selected !== "inspection" ? "#a6a1a1" : "",
              }}
              // active = {selected == "day" ? true : false}
              onClick={() => {
                setSelected("inspection");
                window.localStorage.setItem("config", "inspection");
              }}
            >
              Inspeções
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <div>{componentOptions[selected]}</div>
    </div>
  )

}