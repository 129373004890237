import { Preferences } from "@capacitor/preferences";
import { useRequest } from "ahooks";
import axios from "axios";
import { Token } from "./token";
import { storage, storageLocal } from "../storage/ionic-storage-config";
import React from "react";
import { CallNotification } from "../components/Alert";
import { /*useSelector,*/ useDispatch } from "react-redux";
import Resizer from "react-image-file-resizer";

import { signal } from "@preact/signals-react";
import { Dispatch } from "redux";
import { methodOf } from "lodash";

export const parseJwt = (token: string) => {
  if (!token) return "";
  var base64Url = token.split(".")[1] || "";
  var base64 = base64Url?.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const formNameImage = (name: string): string => {
  const nameArray = name.split(" ");
  if (nameArray.length > 1)
    return `${nameArray[0].charAt(0).toUpperCase()} ${nameArray[1]
      .charAt(0)
      .toUpperCase()}`;
  if (nameArray.length > 0) return `${nameArray[0].charAt(0).toUpperCase()}`;
  else return "";
};

export const getBase64FromImage = (
  file: any,
  onLoad: { (fileString: any): void; (arg0: string | ArrayBuffer | null): void }
) => {

  Resizer.imageFileResizer(
    file as Blob,
    550, // nova largura desejada
    700, // nova altura desejada
    "JPEG", // formato da imagem resultante
    40, // qualidade (0 a 100)
    0, // rotação (0, 90, 180, 270)
    (base64) => {
      // Callback chamado com a imagem em base64
      onLoad(base64);
    },
    "base64" // tipo de saída ('base64' ou 'blob')
  );

  // let reader = new FileReader();
  // reader.readAsDataURL(file);
  // reader.onload = () => {
  //   onLoad(reader.result);
  // };
};

export const setLocalData2 = async (key: string, value: any) => {
  await Preferences.set({
    key: key,
    value: JSON.stringify({
      key: key,
      value: value,
    }),
  });
};

export const getLocalData2 = async (key: string) => {
  const ret = await Preferences.get({ key: key });
  return JSON.parse(ret?.value ?? "null");
};

export const setLocalData = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalData = (key: string, defaultValue?: any) => {
  const item = window.localStorage.getItem(key) as string;
  // console.log("item", item);
  try {
    return JSON.parse(item);
  } catch (error) {
    return defaultValue ?? null;
  }
};

// OFFLINE DATA

export const useLocalStorage = (chave: string, dispatch: Dispatch) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState<null | any>(null);

  const deleteLocalStorageData = async (id: string) => {
    try {
      const result = await removeStorageLocalData(chave, id);
      // atualiza local state, usando redux nao é mais necessario
      setData(result);
      // Atualizar redux apos remover dados
      dispatch({
        type: "API_DATA_OFFLINE",
        payload: { key: chave, value: result },
      });

      CallNotification("deletado com sucesso", "", "success");
    } catch (error) {
      setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const updateToServerLocalData = async (onFinish = () => { }) => {
    // console.log(storageLocal)
    const keys = await storageLocal.keys();
    keys.forEach(async (key) => {
      // console.log('key da função com botão', key)
      // console.log("key", key);
      // console.log("chegou");
      // (async() => )()
      const values = await storageLocal.get(key);
      console.log("values da função com botão", values);
      console.log("key", key);

      values.forEach(async (element: any) => {
        const { data, method, url, key, id } = element;
        // console.log('elemento',element)
        // console.log('meth',method)
        // console.log("key da função com botão segundo", key)


        if (method == 'POST' ) {

          await axios
            .post(url, data, access)
            .then((d) => {
              (async () => {
                const updatedData = await removeStorageLocalData(key, id);
                setData(updatedData);
                dispatch({
                  type: "API_DATA_OFFLINE",
                  payload: { key: chave, value: updatedData },
                });
              })();
            })
            .catch((err) => {
              CallNotification(
                "falha ao fazer upload dos dados",
                "verifique sua conexão com a internet",
                "danger"
              );
              console.log("deu error", err, "data", data);

              return null;
            })
        }

        if (method == 'PUT' ) {

          await axios
            .put(url, data, access)
            .then((d) => {
              (async () => {
                const updatedData = await removeStorageLocalData(key, id);
                setData(updatedData);
                dispatch({
                  type: "API_DATA_OFFLINE",
                  payload: { key: chave, value: updatedData },
                });
              })();
            })
            .catch((err) => {
              CallNotification(
                "falha ao fazer upload dos dados",
                "verifique sua conexão com a internet",
                "danger"
              );
              console.log("deu error", err, "data", data);

              return null;
            })
        }










      });
    });
    onFinish();
  };



  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await storageLocal.get(chave);
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
    error,
    deleteLocalStorageData,
    setData,
    updateToServerLocalData,
  };
};


export const addStorageLocalData = async (
  chave: string,
  data: unknown,
  onFinal = () => { },
  dispatch: Dispatch
) => {
  const currentData = (await storageLocal.get(chave)) ?? [];
  console.log("currentData", currentData);
  storageLocal.set(chave, [...currentData, data]);
  dispatch({
    type: "API_DATA_OFFLINE",
    payload: { key: chave, value: [...currentData, data] },
  });
  onFinal();
};

export const removeStorageLocalData = async (
  chave: string,
  id: any,
  onSuccess: () => any = () => { }
) => {
  // const dispatch = useDispatch();
  const currentData = await storageLocal.get(chave);
  const updatedData = currentData.filter((d: any) => d.id !== id);
  storageLocal.set(chave, updatedData);
  onSuccess();
  // dispatch({
  //   type: "API_DATA_OFFLINE",
  //   payload: { key: chave, value: updatedData },
  // });
  return updatedData;
};

export const updateStorageLocalData = async (
  chave: string,
  id: any,
  value: any,
  onSuccess: () => any = () => { },
  dispatch: Dispatch
) => {
  // const dispatch = useDispatch();
  const currentData = await storageLocal.get(chave);
  const dataWithoutUpdate = currentData.filter((d: any) => d.id !== id);

  const newData = [...dataWithoutUpdate, value];
  storageLocal.set(chave, newData);
  onSuccess();
  dispatch({
    type: "API_DATA_OFFLINE",
    payload: { key: chave, value: newData },
  });
  return newData;
};

// TODO :ADD DEFAULT OPTION
export const useCustomRequest = (pathname: string, options: any) => {
  return useRequest(() => {
    return axios(pathname);
  }, options);
};

const { companyId, id } = Token.getTokenData();
const access = Token.getAccess();

export function getDataRequest<T>(url: string): Promise<T> {
  return fetch(url, access)
    .then((r) => r.json())
    .catch((err) => console.error(err));
}

export const useRequestCache = (url: string) => {
  return useRequest((url) => getDataRequest(url), {
    cacheKey: url + "123",
    cacheTime: -1,
    defaultParams: [url],
    setCache: (data) => {
      // const {data: {status = false}} = data;
      if (data?.data == undefined) {
        return;
      }
      // @ts-ignore
      if (data?.data?.status == false) {
        return;
      }

      localStorage.setItem(url + "123", JSON.stringify(data));
      console.log("-- setCache useRequestCache", data, url);
    },
    getCache: () => {
      const cacheValue = JSON.parse(localStorage.getItem(url + "123") || "{}");
      console.log("getCache useRequestCache", cacheValue);
      return cacheValue;
    },
    // @ts-ignore
    onSuccess: ({ data }) => {
      console.log("onSuccess useRequestCache", data, url);
    },
    onError: (data) => {
      console.log("onError useRequestCache", data, url);
    },
  });
};




















export const updateToServerLocalDataSemNotificacao = async (dispatch: any) => {
  // console.log('rodou')
  const keys = await storageLocal.keys();

  // console.log(keys)
  keys.forEach(async (key) => {
    // console.log("key", key);
    // (async() => )()
    const values = await storageLocal.get(key);
    // console.log("values", values);
    if (!(values.length > 0)) return

    values.forEach(async (element: any) => {
      const { data, method, url, key, id } = element;







      if (method == 'POST' ) {

        await axios
        .post(url, data, access)
        .then((d) => {
          (async () => {
            const updatedData = await removeStorageLocalData(key, id);
            // setData(updatedData);
            // console.log(updatedData)
            dispatch({
              type: "API_DATA_OFFLINE",
              payload: { key: key, value: updatedData },
            });
          })();
        })
        .catch((err) => {



          return null;
        });
      }
      
      if (method == 'PUT' ) {
        await axios
        .put(url, data, access)
        .then((d) => {
          (async () => {
            const updatedData = await removeStorageLocalData(key, id);
            // setData(updatedData);
            // console.log(updatedData)
            dispatch({
              type: "API_DATA_OFFLINE",
              payload: { key: key, value: updatedData },
            });
          })();
        })
        .catch((err) => {



          return null;
        });
      }















      
    });
  });

};


